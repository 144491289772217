import * as React from 'react';
import * as qs from 'qs';
import { flattenDeep, map, orderBy } from 'lodash';

import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';
import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';

import { endpoints } from '@components';

import { IInviteProps } from '../Invite';
import { useInviteContext } from './useInviteContext';

const { useMemo } = React;

type TProps = Pick<IInviteProps, 'communities' | 'isFetchingCommunities'>;

/**
 * Fetch programs
 */
export const usePrograms = (props: TProps) => {
  const {
    communities,
    isFetchingCommunities,
  } = props;

  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const communityIdsParam = useMemo(() => {
    if (!isFetchingCommunities && communities) {
      return qs.stringify(
        { community_ids: map(communities, (c) => c.id) },
        { arrayFormat: 'brackets' },
      );
    }
  }, [communities, isFetchingCommunities]);
  const fetchProgramsURL = useMemo(() => {
    if (communityIdsParam) {
      return `${apiEndpoint}/${endpoints.communityProgramsEndpoint}?client_id=${clientId}&${communityIdsParam}`;
    }
  }, [apiEndpoint, clientId, communityIdsParam]);

  const {
    data: fetchedPrograms,
    isFetching,
    error,
  } = useBackendServerQuery({
    fetchParams: {
      input: fetchProgramsURL || '',
    },
    useQueryParams: {
      options: {
        enabled: !!fetchProgramsURL,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
        onError: (err) => {
          console.error('Error fetching groups:', err); // Handle errors gracefully
        },
      },
    },
  });

  const programs: IProject[] = useMemo(() => {
    if (!Array.isArray(fetchedPrograms)) {
      return [];
    }
    return orderBy(flattenDeep(fetchedPrograms), ['title'], ['asc']);
  }, [fetchedPrograms]);

  return {
    programs: Object.freeze(programs),
    isFetching,
    error,
  };
};
