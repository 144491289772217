import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useAuth } from '@frontend/context/authContext';
import {
 useQueries, useQuery, UseQueryOptions, UseQueryResult,
} from '@tanstack/react-query';
import { useCallback } from 'react';

type BackendServerFetchConfigurator = (
  input: RequestInfo | URL,
  init?: RequestInit,
) => {
  input: RequestInfo | URL;
  init: RequestInit;
};

const backendServerFetchConfigurator = (backendServerApiEndpoint: string, token: string | null) => (
  input: RequestInfo | URL,
  init?: RequestInit,
) => {
  const isRelativeUrl = input.toString().startsWith('/');
  const url = isRelativeUrl ? `${backendServerApiEndpoint}${input}` : input;
  const headers = {
    ...init?.headers,
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  return { input: url, init: { ...init, headers } };
};

const backendServerFetchResponse = (
  backendServerFetchConfigurator: BackendServerFetchConfigurator,
  fetchFn: typeof fetch,
) => async (input: RequestInfo | URL, init?: RequestInit) => {
  const { input: configInput, init: config } = backendServerFetchConfigurator(input, init);
  return await fetchFn(configInput, config);
};

export const getBackendServerFetch = (backendServerApiEndpoint: string, token: string | null) =>
  backendServerFetchResponse(backendServerFetchConfigurator(backendServerApiEndpoint, token), fetch);

export const useBackendServerFetch = () => {
  const { token } = useAuth();
  const currentBackendServerApiEndpoint = backendServerApiEndpoint();

  const createRequestConfig = useCallback(
    (input: RequestInfo | URL, init?: RequestInit) =>
      backendServerFetchConfigurator(currentBackendServerApiEndpoint, token)(input, init),
    [token, currentBackendServerApiEndpoint],
  );

  const backendServerFetchCallback = useCallback(
    async (input: RequestInfo | URL, init?: RequestInit) => {
      const resp = await backendServerFetchResponse(createRequestConfig, fetch)(input, init);
      // Check if the response is ok
      if (!resp.ok) {
        throw new Error('Failed to fetch data from backend server', { cause: resp });
      }
      const json = await resp.json();
      return json.data;
    },
    [createRequestConfig],
  );

  const backendServerFetchResponseCallback = useCallback(
    async (input: RequestInfo | URL, init?: RequestInit) =>
      backendServerFetchResponse(createRequestConfig, fetch)(input, init),
    [createRequestConfig],
  );

  return {
    backendServerFetch: backendServerFetchCallback,
    backendServerFetchResponse: backendServerFetchResponseCallback,
  };
};

type useQueryBackendServerParams<TData = unknown> = {
  fetchParams: { input: RequestInfo | URL; init?: RequestInit | undefined };
  useQueryParams?: {
    options: Omit<UseQueryOptions<unknown, Error, TData>, 'queryKey' | 'queryFn'>;
  };
};

export const useBackendServerQuery = <TData = unknown>({
  fetchParams,
  useQueryParams,
}: useQueryBackendServerParams<TData>) => {
  const { backendServerFetch } = useBackendServerFetch();
  const query = useQuery({
    ...useQueryParams?.options,
    queryKey: ['backendServer', fetchParams.input],
    queryFn: () => backendServerFetch && backendServerFetch(fetchParams.input, fetchParams.init),
  } as UseQueryOptions<unknown, Error, TData>);
  return query;
};

export const useBackendServerQueries = <TData = unknown>(params: useQueryBackendServerParams<TData>[]) => {
  const { backendServerFetch } = useBackendServerFetch();
  const queries = useQueries({
    queries: params.map(
      (params) =>
        ({
          ...params.useQueryParams?.options,
          queryKey: ['backendServer', params.fetchParams.input],
          queryFn: () => backendServerFetch && backendServerFetch(params.fetchParams.input, params.fetchParams.init),
        } as UseQueryOptions<unknown, Error, TData>),
    ),
  }) as UseQueryResult<TData, Error>[];
  return queries;
};
