import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import { useCallback } from 'react';
import { GET_ORDERS_FOR_DASHBOARD_CSV_QUERY } from '../queries';
import { GetOrdersForDashboardCsv, GetOrdersForDashboardCsvVariables } from '../queries/types/GetOrdersForDashboardCsv';

type IOptions = LazyQueryHookOptions<GetOrdersForDashboardCsv, GetOrdersForDashboardCsvVariables>;

export function useGetOrdersForDashboardCsv(options: IOptions = {}) {
  const [
    getOrdersForDashboardCsv,
    {
      loading,
    },
  ] = useLazyQuery<GetOrdersForDashboardCsv, GetOrdersForDashboardCsvVariables>(
    GET_ORDERS_FOR_DASHBOARD_CSV_QUERY,
    options,
  );

  const downloadOrdersCsv = useCallback(async (
    options: IOptions,
  ) => {
    const orders = await getOrdersForDashboardCsv(options);
    const csv = orders?.data?.getOrdersForDashboardCsv;
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'orders.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }, [getOrdersForDashboardCsv]);

  return {
    isOrdersCsvLoading: loading,
    downloadOrdersCsv,
  };
}
