import React, {
 useCallback, useEffect, useRef, useState,
} from 'react';
import {
 Button, Card, Col, Empty, message, Modal, Row, Skeleton, Space, Spinner, Tag, Typography,
} from '@revfluence/fresh';
import {
 ArrowLeftIcon, BoxesStackedIcon, GearIcon, PlusIcon, TrashIcon,
 TriangleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { pluralize } from '@frontend/app/utils/strings';
import { useHistory, useParams } from 'react-router-dom';
import { GET_CATALOG_COLLECTIONS_QUERY, GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { GET_ALL_CATALOG_STATS_QUERY, GET_BRAND_CATALOG_STATS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { CatalogType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GetCatalogCollectionsQuery_getCatalogCollectionsByCatalogId, GetCatalogCollectionsQuery_getCatalogCollectionsByCatalogId_collection_metadata } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCatalogCollectionsQuery';
import { SortableList } from '@frontend/app/components';
import { map } from 'lodash';
import { ISortableListProps } from '@frontend/app/components/SortableList/SortableList';
import styles from './CollectionItems.scss';
import { useGetCatalogCollections } from '../hooks/useGetCatalogCollections';
import { pfaV2Routes } from '../../routes';
import { CatalogSelectionModal } from '../CatalogSelectionModal/CatalogSelectionModal';
import { useBasicSelectionContext } from '../CatalogSelectionModal/BasicSelectionContext';
import { useDeleteCatalogCollection } from '../hooks/useDeleteCatalogCollection';
import { useGetStatsByCatalogCollectionId } from '../hooks/useGetStatsByCatalogCollectionId';
import { useGetCatalogDetails } from '../hooks/useGetCatalogDetails';
import { AdvancedSelectionModal } from '../AdvancedSelectionModal/AdvancedSelectionModal';
import { reloadPreviewIframe } from '../reloadPreviewIframe';
import { useUpdateCatalogCollectionRanks } from '../hooks/useUpdateCatalogCollectionRanks';

const { Title, Text } = Typography;

const EmptyScreen = () => {
  const { setIsOpen } = useBasicSelectionContext();

  return (
    <Empty
      size="small"
      image={<BoxesStackedIcon />}
      description={(
        <>
          <Text type="secondary">
            Start adding collection here consisting of products you want your creators to shop from
          </Text>
          <Row justify="center">
            <Button icon={<PlusIcon />} onClick={() => setIsOpen(true)}>
              Create Collection Item
            </Button>
          </Row>
        </>
      )}
    />
  );
};

const CollectionItem = ({
  id,
  title,
  isDefaultCatalog = false,
  metadata = null,
}: {
  id: string;
  title: string;
  isDefaultCatalog?: boolean;
  metadata?: GetCatalogCollectionsQuery_getCatalogCollectionsByCatalogId_collection_metadata | null;
}) => {
  const history = useHistory();

  const { catalogId } = useParams<{ catalogId: string }>();

  const { stats, loading } = useGetStatsByCatalogCollectionId({
    variables: {
      catalogCollectionId: Number(id),
    },
  });

  const { deleteCatalogCollection, loading: isDeleting } = useDeleteCatalogCollection({
    refetchQueries: [
      GET_CATALOG_COLLECTIONS_QUERY,
      GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
      GET_ALL_CATALOG_STATS_QUERY,
      GET_BRAND_CATALOG_STATS_QUERY,
    ],
    onCompleted: () => {
      reloadPreviewIframe();
    },
  });

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Are you sure you want to delete this collection item?',
      icon: <TriangleExclamationIcon />,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        deleteCatalogCollection({
          variables: {
            id: Number(id),
          },
        });
      },
      width: 570,
    });
  };

  const handleClick = () => {
    if (isDefaultCatalog) {
      return;
    }
    history.push(
      pfaV2Routes.settings.brandCatalogsDetailsCollection.replace(':catalogId', catalogId).replace(':collectionId', id),
    );
  };

  const productsCount = isDefaultCatalog ? metadata.importedProductCount : stats?.productCount ?? 0;
  const variantsCount = isDefaultCatalog ? metadata.importedVariantCount : stats?.variantCount ?? 0;

  return (
    <Card className={styles.collectionCard} onClick={handleClick} style={{ cursor: isDefaultCatalog ? 'default' : 'pointer' }}>
      <Row align="middle" justify="space-between">
        <Col>
          <Space size="middle">
            {/* <Avatar src={image || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'} shape="square" size={46} /> */}
            <Text weight="semibold">{title}</Text>
          </Space>
        </Col>
        <Col>
          <Space size="middle">
            {loading ? <Spinner /> : (
              <Tag>
                {productsCount}
                {' '}
                {pluralize(productsCount, 'Product')}
                {' '}
                |
                {' '}
                {variantsCount}
                {' '}
                {pluralize(variantsCount, 'Variant')}
              </Tag>
            )}
            {!isDefaultCatalog
            && (
            <Space>
              <Button icon={<GearIcon />} />
              <Button icon={<TrashIcon />} loading={isDeleting} onClick={handleDelete} />
            </Space>
            )}

          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const CollectionItems = () => {
  const { setIsOpen } = useBasicSelectionContext();

  const { catalogId } = useParams<{ catalogId: string }>();

  const { catalog } = useGetCatalogDetails({ variables: { id: Number(catalogId) } });

  const isDefaultCatalog = catalog?.type === CatalogType.DEFAULT;

  const { collections: collectionsRaw, loading, refetch: refetchCatalogCollections } = useGetCatalogCollections({
    variables: {
      brandCatalogId: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const [collections, setCollections] = useState(collectionsRaw);

  const { updateCollectionRanks } = useUpdateCatalogCollectionRanks({
    onCompleted: (updated) => {
      if (updated) {
        refetchCatalogCollections();
        reloadPreviewIframe();
      } else {
        setCollections(collectionsRaw);
      }
    },
    onError: (error) => {
      message.error(error?.message || 'Failed to update collection ranks');
      setCollections(collectionsRaw);
    },
  });

  useEffect(() => {
    setCollections(collectionsRaw);
  }, [collectionsRaw]);

  const sortableListRef = useRef<HTMLDivElement>();

  const history = useHistory();

  const handleBack = () => {
    history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', catalogId));
  };

  const handleUpdateSort = useCallback<ISortableListProps<GetCatalogCollectionsQuery_getCatalogCollectionsByCatalogId>['onChange']>(
    (newCollections) => {
      setCollections(map(newCollections, (item) => item.data));
      updateCollectionRanks({
        variables: {
          brandCatalogId: Number(catalogId),
          collectionRankInput: newCollections?.map((item, index) => ({
            collectionId: isDefaultCatalog ? Number(item.data.collectionId) : Number(item.data.id),
            rank: index,
          })),
        },
      });
    },
    [catalogId, isDefaultCatalog, updateCollectionRanks],
  );

  const items = map(
    collections,
    (collection) => ({
      id: String(collection.id),
      data: collection,
      render: () => (
        <CollectionItem
          key={collection.id}
          id={String(collection.id)}
          title={collection.label || collection.collection?.name}
          isDefaultCatalog={isDefaultCatalog}
          metadata={collection?.collection?.metadata}
        />
      ),
    }),
  );

  return (
    <>
      <Space direction="vertical" className={styles.CollectionItems} size="large">
        <Space size="middle" className={styles.header} align="center">
          <Button icon={<ArrowLeftIcon />} type="text" onClick={handleBack} />
          <Title level={4} className={styles.title}>
            Collections
          </Title>
        </Space>
        <Space direction="vertical" size="middle">
          <Row align="middle" justify="space-between">
            <Col>
              <Text weight="semibold">Collection Items</Text>
            </Col>
            <Col>
              {!isDefaultCatalog && (
              <Button type="link" icon={<PlusIcon />} onClick={() => setIsOpen(true)}>
                Create Collection Item
              </Button>
)}
            </Col>
          </Row>
          <Space direction="vertical" className="pl-4">
            {loading && <div data-testid="skeleton"><Skeleton /></div>}
            {!loading
              && (collections.length ? (
                <SortableList<GetCatalogCollectionsQuery_getCatalogCollectionsByCatalogId>
                  handlePosition="left"
                  onChange={handleUpdateSort}
                  options={items}
                  sortableListRef={sortableListRef}
                  itemClassName="!p-0 !border-none"
                />
            ) : (
              <EmptyScreen />
            ))}
          </Space>
        </Space>
      </Space>
      <CatalogSelectionModal />
      <AdvancedSelectionModal />
    </>
  );
};
