import gql from 'graphql-tag';

import {
  ONBOARDING_TEMPLATE_FRAGMENT,
  USER_FRAGMENT,
} from './fragments';

export const GET_ALL_PROJECTS = gql`
  query GetAllProjectsQuery($withSpecsOnly: Boolean) {
    projects: getAllProjects(withSpecsOnly: $withSpecsOnly) {
      id
      title
      splashImageUrl
      specKey
      isFlexibleSpec
      workletSpecKeys
      status
      archivedByUser {
        ...UserFragment
      }
      applicationPageTemplateName
      applicationFormFields {
        memberFieldSchemas {
          schemaId
          required
          label
          order
        }
        dbColumns {
          name
          required
          label
          order
        }
      }
      archivedDate
      deletedDate
      communityId
      emailTemplateId
      published
      submittedForReview
      templateName
      customLandingPagePath
      gcrEnabled
      gcrApproverIds
      onboardingTemplateConfig {
        ...OnboardingTemplateFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${ONBOARDING_TEMPLATE_FRAGMENT}
`;

export const GET_ALL_PROJECTS_WITH_DELETED_COUNT = gql`
  query GetAllProjectsWithDeletedCountQuery {
    count: getAllProjectsWithDeletedCount
  }
`;

export const GET_ALL_PROJECTS_WITH_NOTIFICATIONS = gql`
  query GetAllProjectsWithNotificationsQuery {
    projects: getAllProjects(withSpecsOnly: false) {
      id
      title
      splashImageUrl
      status
      notificationCount
      activeMemberCount
    }
  }
`;

export const GET_ALL_CONTENT_APPROVAL_MANAGER_PROJECTS = gql`
  query GetAllContentApprovalManagerProjectsQuery($archived: Boolean) {
    projects: getAllContentApprovalManagerProjects(archived: $archived) {
      id
      title
      splashImageUrl
      status
      notificationCount
      activeMemberCount
      createdDate
      updatedDate
    }
  }
`;

export const GET_ALL_PUBLISHED_PROJECTS = gql`
  query GetAllPublishedProjectsQuery {
    projects: getAllPublishedProjects {
      id
      title
      status
      splashImageUrl
      customLandingPagePath
      applicationPageTemplateName
      archivedDate
      applicationFormFields {
        memberFieldSchemas {
          schemaId
          required
          label
          order
        }
        dbColumns {
          name
          required
          label
          order
        }
      }
      onboardingTemplateConfig {
        ...OnboardingTemplateFragment
      }
    }
  }
  ${ONBOARDING_TEMPLATE_FRAGMENT}
`;

export const GET_ALL_PROJECTS_WITH_CONTENT_GUIDELINES = gql`
  query GetAllProjectsWithContentGuidelinesQuery {
    projects: getAllProjectsWithContentGuidelines {
      id
      title
      splashImageUrl
    }
  }
`;
