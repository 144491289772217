/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: { input: string; output: string };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: Date | string; output: Date | string };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date | string; output: Date | string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: URL | string; output: URL | string };
};

export type AdFilter = {
  __typename?: 'AdFilter';
  adAccountIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  adIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  objective?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Range>;
  search?: Maybe<Scalars['String']['output']>;
};

export type AdFilterInput = {
  clientAdAccounts: ClientAdAccountsInput;
  objective?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<RangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['String']['output'];
  intervalInsights?: Maybe<Array<Maybe<MetaInsights>>>;
  metaAdAccounts?: Maybe<Array<Maybe<MetaAdAccount>>>;
  name?: Maybe<Scalars['String']['output']>;
  sumInsights?: Maybe<MetaSumInsights>;
};

export type ClientIntervalInsightsArgs = {
  filter: MetaInsightsFilterInput;
};

export type ClientMetaAdAccountsArgs = {
  filter?: InputMaybe<MetaAdAccountFilterInput>;
};

export type ClientSumInsightsArgs = {
  filter?: InputMaybe<MetaInsightsFilterInput>;
};

export type ClientAdAccountsInput = {
  adAccountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientId: Scalars['String']['input'];
};

export type ClientKeywords = {
  __typename?: 'ClientKeywords';
  attributionTarget: Scalars['String']['output'];
  clientId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  entitySources?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  entityTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  keywords: Array<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientKeywordsInput = {
  attributionTarget: Scalars['String']['input'];
  entitySources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entityTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords: Array<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

export type Interval = {
  __typename?: 'Interval';
  date: Scalars['Date']['output'];
  period: Period;
};

export type IntervalInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  period: Period;
};

export type MetaAd = {
  __typename?: 'MetaAd';
  adAccount?: Maybe<MetaAdAccount>;
  adAccountId: Scalars['String']['output'];
  adCampaignId: Scalars['String']['output'];
  adSet?: Maybe<MetaAdSet>;
  adSetId: Scalars['String']['output'];
  campaign?: Maybe<MetaAdCampaign>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creatives?: Maybe<Array<Maybe<MetaAdCreative>>>;
  id: Scalars['String']['output'];
  intervalInsights?: Maybe<Array<Maybe<MetaInsights>>>;
  name: Scalars['String']['output'];
  status: MetaAdStatus;
  sumInsights?: Maybe<MetaSumInsights>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MetaAdCreativesArgs = {
  filter?: InputMaybe<MetaAdCampaignFilterInput>;
};

export type MetaAdIntervalInsightsArgs = {
  filter: MetaInsightsFilterInput;
};

export type MetaAdSumInsightsArgs = {
  filter?: InputMaybe<MetaInsightsFilterInput>;
};

export type MetaAdAccount = {
  __typename?: 'MetaAdAccount';
  campaigns?: Maybe<Array<Maybe<MetaAdCampaign>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  intervalInsights?: Maybe<Array<Maybe<MetaInsights>>>;
  name: Scalars['String']['output'];
  status: MetaAdAccountStatus;
  sumInsights?: Maybe<MetaSumInsights>;
  timezone: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MetaAdAccountCampaignsArgs = {
  filter?: InputMaybe<MetaAdCampaignFilterInput>;
};

export type MetaAdAccountIntervalInsightsArgs = {
  filter: MetaInsightsFilterInput;
};

export type MetaAdAccountSumInsightsArgs = {
  filter?: InputMaybe<MetaInsightsFilterInput>;
};

export type MetaAdAccountFilterInput = {
  currency?: InputMaybe<Array<Scalars['Currency']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<MetaAdAccountStatus>>;
};

export enum MetaAdAccountStatus {
  Active = 'ACTIVE',
  AnyActive = 'ANY_ACTIVE',
  Closed = 'CLOSED',
  Disabled = 'DISABLED',
  InGracePeriod = 'IN_GRACE_PERIOD',
  PendingClosure = 'PENDING_CLOSURE',
  PendingRiskReview = 'PENDING_RISK_REVIEW',
  PendingSettlement = 'PENDING_SETTLEMENT',
  Unsettled = 'UNSETTLED',
}

export type MetaAdCampaign = {
  __typename?: 'MetaAdCampaign';
  adAccount?: Maybe<MetaAdAccount>;
  adAccountId: Scalars['String']['output'];
  adSets?: Maybe<Array<Maybe<MetaAdSet>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  intervalInsights?: Maybe<Array<Maybe<MetaInsights>>>;
  name: Scalars['String']['output'];
  objective: MetaAdCampaignObjective;
  startTime: Scalars['DateTime']['output'];
  status: MetaAdCampaignStatus;
  stopTime?: Maybe<Scalars['DateTime']['output']>;
  sumInsights?: Maybe<MetaSumInsights>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MetaAdCampaignAdSetsArgs = {
  filter?: InputMaybe<MetaAdSetFilterInput>;
};

export type MetaAdCampaignIntervalInsightsArgs = {
  filter: MetaInsightsFilterInput;
};

export type MetaAdCampaignSumInsightsArgs = {
  filter?: InputMaybe<MetaInsightsFilterInput>;
};

export type MetaAdCampaignFilterInput = {
  objective?: InputMaybe<Array<MetaAdCampaignObjective>>;
  status?: InputMaybe<Array<MetaAdCampaignStatus>>;
};

export enum MetaAdCampaignObjective {
  AppInstalls = 'APP_INSTALLS',
  BrandAwareness = 'BRAND_AWARENESS',
  Conversions = 'CONVERSIONS',
  EventResponses = 'EVENT_RESPONSES',
  LeadGeneration = 'LEAD_GENERATION',
  LinkClicks = 'LINK_CLICKS',
  LocalAwareness = 'LOCAL_AWARENESS',
  Messages = 'MESSAGES',
  MobileAppInstalls = 'MOBILE_APP_INSTALLS',
  None = 'NONE',
  OfferClaims = 'OFFER_CLAIMS',
  OutcomeAppPromotion = 'OUTCOME_APP_PROMOTION',
  OutcomeAwareness = 'OUTCOME_AWARENESS',
  OutcomeEngagement = 'OUTCOME_ENGAGEMENT',
  OutcomeLeads = 'OUTCOME_LEADS',
  OutcomeSales = 'OUTCOME_SALES',
  OutcomeTraffic = 'OUTCOME_TRAFFIC',
  PageLikes = 'PAGE_LIKES',
  PostEngagement = 'POST_ENGAGEMENT',
  ProductCatalogSales = 'PRODUCT_CATALOG_SALES',
  Reach = 'REACH',
  StoreVisits = 'STORE_VISITS',
  VideoViews = 'VIDEO_VIEWS',
}

export enum MetaAdCampaignStatus {
  Active = 'ACTIVE',
  AdsetPaused = 'ADSET_PAUSED',
  Archived = 'ARCHIVED',
  CampaignPaused = 'CAMPAIGN_PAUSED',
  Deleted = 'DELETED',
  Disapproved = 'DISAPPROVED',
  InProcess = 'IN_PROCESS',
  Paused = 'PAUSED',
  PendingBillingInfo = 'PENDING_BILLING_INFO',
  PendingReview = 'PENDING_REVIEW',
  Preapproved = 'PREAPPROVED',
  WithIssues = 'WITH_ISSUES',
}

export type MetaAdCreative = {
  __typename?: 'MetaAdCreative';
  actorId: Scalars['String']['output'];
  adAccount?: Maybe<MetaAdAccount>;
  adAccountId: Scalars['String']['output'];
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  instagramActorId?: Maybe<Scalars['String']['output']>;
  instagramBrandedContent?: Maybe<Scalars['JSON']['output']>;
  instagramPermalinkUrl?: Maybe<Scalars['URL']['output']>;
  name: Scalars['String']['output'];
  sourceInstagramMediaId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<MetaAdCreativeStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MetaAdCreativeFilterInput = {
  status?: InputMaybe<Array<MetaAdCreativeStatus>>;
};

export enum MetaAdCreativeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  InProcess = 'IN_PROCESS',
  WithIssues = 'WITH_ISSUES',
}

export type MetaAdFilterInput = {
  adStatus?: InputMaybe<Array<MetaAdStatus>>;
};

export type MetaAdSet = {
  __typename?: 'MetaAdSet';
  adAccount?: Maybe<MetaAdAccount>;
  adAccountId: Scalars['String']['output'];
  adCampaignId: Scalars['String']['output'];
  ads?: Maybe<Array<Maybe<MetaAd>>>;
  campaign?: Maybe<MetaAdCampaign>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  intervalInsights?: Maybe<Array<Maybe<MetaInsights>>>;
  name: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  status?: Maybe<MetaAdSetStatus>;
  stopTime?: Maybe<Scalars['DateTime']['output']>;
  sumInsights?: Maybe<MetaSumInsights>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MetaAdSetAdsArgs = {
  filter?: InputMaybe<MetaAdFilterInput>;
};

export type MetaAdSetIntervalInsightsArgs = {
  filter: MetaInsightsFilterInput;
};

export type MetaAdSetSumInsightsArgs = {
  filter?: InputMaybe<MetaInsightsFilterInput>;
};

export type MetaAdSetFilterInput = {
  status?: InputMaybe<Array<MetaAdSetStatus>>;
};

export enum MetaAdSetStatus {
  Active = 'ACTIVE',
  AdsetPaused = 'ADSET_PAUSED',
  Archived = 'ARCHIVED',
  CampaignPaused = 'CAMPAIGN_PAUSED',
  Deleted = 'DELETED',
  Disapproved = 'DISAPPROVED',
  InProcess = 'IN_PROCESS',
  Paused = 'PAUSED',
  PendingBillingInfo = 'PENDING_BILLING_INFO',
  PendingReview = 'PENDING_REVIEW',
  Preapproved = 'PREAPPROVED',
  WithIssues = 'WITH_ISSUES',
}

export enum MetaAdStatus {
  Active = 'ACTIVE',
  AdsetPaused = 'ADSET_PAUSED',
  Archived = 'ARCHIVED',
  CampaignPaused = 'CAMPAIGN_PAUSED',
  Deleted = 'DELETED',
  Disapproved = 'DISAPPROVED',
  InProcess = 'IN_PROCESS',
  Paused = 'PAUSED',
  PendingBillingInfo = 'PENDING_BILLING_INFO',
  PendingReview = 'PENDING_REVIEW',
  Preapproved = 'PREAPPROVED',
  WithIssues = 'WITH_ISSUES',
}

export type MetaInsights = {
  __typename?: 'MetaInsights';
  clicks?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Interval>;
  purchase?: Maybe<Money>;
  reach?: Maybe<Scalars['Int']['output']>;
  roas?: Maybe<Scalars['Float']['output']>;
  spend?: Maybe<Money>;
};

export type MetaInsightsFilter = {
  __typename?: 'MetaInsightsFilter';
  range?: Maybe<Range>;
};

export type MetaInsightsFilterInput = {
  isAspireSourced?: InputMaybe<Scalars['Boolean']['input']>;
  range: RangeInput;
};

export type MetaSumInsights = {
  __typename?: 'MetaSumInsights';
  totalAds?: Maybe<Scalars['Int']['output']>;
  totalClicks?: Maybe<Scalars['Int']['output']>;
  totalCreators?: Maybe<Scalars['Int']['output']>;
  totalImpressions?: Maybe<Scalars['Int']['output']>;
  totalPosts?: Maybe<Scalars['Int']['output']>;
  totalPurchase?: Maybe<Money>;
  totalReach?: Maybe<Scalars['Int']['output']>;
  totalRoas?: Maybe<Scalars['Float']['output']>;
  totalSpend?: Maybe<Money>;
};

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Currency']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateClientKeywords?: Maybe<ClientKeywords>;
};

export type MutationUpdateClientKeywordsArgs = {
  clientId: Scalars['ID']['input'];
  keywords: ClientKeywordsInput;
};

export enum Network {
  Meta = 'Meta',
  TikTok = 'TikTok',
  YouTube = 'YouTube',
}

export type OrderBy = {
  ordering?: InputMaybe<Ordering>;
  prop: SortProp;
};

export enum Ordering {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type Paging = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum Period {
  All = 'All',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year',
}

export type Query = {
  __typename?: 'Query';
  client?: Maybe<Client>;
  clientKeywords: Array<Maybe<ClientKeywords>>;
  metaAd?: Maybe<MetaAd>;
  metaAdAccount?: Maybe<MetaAdAccount>;
  metaAdCampaign?: Maybe<MetaAdCampaign>;
  metaAdSet?: Maybe<MetaAdSet>;
};

export type QueryClientArgs = {
  id: Scalars['String']['input'];
};

export type QueryClientKeywordsArgs = {
  clientId: Scalars['ID']['input'];
};

export type QueryMetaAdArgs = {
  id: Scalars['String']['input'];
};

export type QueryMetaAdAccountArgs = {
  id: Scalars['String']['input'];
};

export type QueryMetaAdCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryMetaAdSetArgs = {
  id: Scalars['String']['input'];
};

export type Range = {
  __typename?: 'Range';
  endDate: Scalars['Date']['output'];
  precision: Period;
  startDate: Scalars['Date']['output'];
};

export type RangeInput = {
  endDate: Scalars['Date']['input'];
  precision?: InputMaybe<Period>;
  startDate: Scalars['Date']['input'];
};

export enum Sort {
  Clicks = 'Clicks',
}

export enum SortProp {
  Clicks = 'Clicks',
}

export type RoasCard_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalRoas?: number | null;
} & { ' $fragmentName'?: 'RoasCard_CurrentIntervalMetaSumInsightsFragment' };

export type RoasCard_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalRoas?: number | null;
} & { ' $fragmentName'?: 'RoasCard_PreviousIntervalMetaSumInsightsFragment' };

export type RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalRoas?: number | null;
} & { ' $fragmentName'?: 'RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment' };

export type SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalPurchase?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment' };

export type SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalPurchase?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment' };

export type SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalPurchase?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment' };

export type TotalSpendCard_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalClicks?: number | null;
  totalSpend?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'TotalSpendCard_CurrentIntervalMetaSumInsightsFragment' };

export type TotalSpendCard_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalClicks?: number | null;
  totalSpend?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'TotalSpendCard_PreviousIntervalMetaSumInsightsFragment' };

export type TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalSpend?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment' };

export type Clicks_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalClicks?: number | null;
} & { ' $fragmentName'?: 'Clicks_CurrentIntervalMetaSumInsightsFragment' };

export type Clicks_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalClicks?: number | null;
} & { ' $fragmentName'?: 'Clicks_PreviousIntervalMetaSumInsightsFragment' };

export type Impressions_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalImpressions?: number | null;
} & { ' $fragmentName'?: 'Impressions_CurrentIntervalMetaSumInsightsFragment' };

export type Impressions_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalImpressions?: number | null;
} & { ' $fragmentName'?: 'Impressions_PreviousIntervalMetaSumInsightsFragment' };

export type Members_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalCreators?: number | null;
} & { ' $fragmentName'?: 'Members_CurrentIntervalMetaSumInsightsFragment' };

export type Members_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalCreators?: number | null;
} & { ' $fragmentName'?: 'Members_PreviousIntervalMetaSumInsightsFragment' };

export type Posts_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalPosts?: number | null;
} & { ' $fragmentName'?: 'Posts_CurrentIntervalMetaSumInsightsFragment' };

export type Posts_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalPosts?: number | null;
} & { ' $fragmentName'?: 'Posts_PreviousIntervalMetaSumInsightsFragment' };

export type Reach_CurrentIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalReach?: number | null;
} & { ' $fragmentName'?: 'Reach_CurrentIntervalMetaSumInsightsFragment' };

export type Reach_PreviousIntervalMetaSumInsightsFragment = {
  __typename?: 'MetaSumInsights';
  totalReach?: number | null;
} & { ' $fragmentName'?: 'Reach_PreviousIntervalMetaSumInsightsFragment' };

export type AdSpendVsSalesChart_MetaIntervalInsightsFragment = {
  __typename?: 'MetaInsights';
  interval?: { __typename?: 'Interval'; date: Date | string } | null;
  spend?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
  purchase?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
} & { ' $fragmentName'?: 'AdSpendVsSalesChart_MetaIntervalInsightsFragment' };

export type RoasOverTimeChart_MetaInsightsFragment = {
  __typename?: 'MetaInsights';
  roas?: number | null;
  interval?: { __typename?: 'Interval'; date: Date | string } | null;
} & { ' $fragmentName'?: 'RoasOverTimeChart_MetaInsightsFragment' };

export type SocialAdsAnalytics_QueryFragment = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    currentInterval?:
      | ({ __typename?: 'MetaSumInsights' } & {
          ' $fragmentRefs'?: {
            TotalSpendCard_CurrentIntervalMetaSumInsightsFragment: TotalSpendCard_CurrentIntervalMetaSumInsightsFragment;
            SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment: SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment;
            RoasCard_CurrentIntervalMetaSumInsightsFragment: RoasCard_CurrentIntervalMetaSumInsightsFragment;
            Clicks_CurrentIntervalMetaSumInsightsFragment: Clicks_CurrentIntervalMetaSumInsightsFragment;
            Reach_CurrentIntervalMetaSumInsightsFragment: Reach_CurrentIntervalMetaSumInsightsFragment;
            Impressions_CurrentIntervalMetaSumInsightsFragment: Impressions_CurrentIntervalMetaSumInsightsFragment;
            Members_CurrentIntervalMetaSumInsightsFragment: Members_CurrentIntervalMetaSumInsightsFragment;
            Posts_CurrentIntervalMetaSumInsightsFragment: Posts_CurrentIntervalMetaSumInsightsFragment;
          };
        })
      | null;
    currentIntervalsIntervalInsights?: Array<
      | ({ __typename?: 'MetaInsights' } & {
          ' $fragmentRefs'?: {
            AdSpendVsSalesChart_MetaIntervalInsightsFragment: AdSpendVsSalesChart_MetaIntervalInsightsFragment;
            RoasOverTimeChart_MetaInsightsFragment: RoasOverTimeChart_MetaInsightsFragment;
          };
        })
      | null
    > | null;
    currentIntervalsIntervalInsightsNotAspireSourced?: Array<
      | ({ __typename?: 'MetaInsights' } & {
          ' $fragmentRefs'?: {
            AdSpendVsSalesChart_MetaIntervalInsightsFragment: AdSpendVsSalesChart_MetaIntervalInsightsFragment;
            RoasOverTimeChart_MetaInsightsFragment: RoasOverTimeChart_MetaInsightsFragment;
          };
        })
      | null
    > | null;
    previousInterval?:
      | ({ __typename?: 'MetaSumInsights' } & {
          ' $fragmentRefs'?: {
            TotalSpendCard_PreviousIntervalMetaSumInsightsFragment: TotalSpendCard_PreviousIntervalMetaSumInsightsFragment;
            SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment: SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment;
            RoasCard_PreviousIntervalMetaSumInsightsFragment: RoasCard_PreviousIntervalMetaSumInsightsFragment;
            Clicks_PreviousIntervalMetaSumInsightsFragment: Clicks_PreviousIntervalMetaSumInsightsFragment;
            Reach_PreviousIntervalMetaSumInsightsFragment: Reach_PreviousIntervalMetaSumInsightsFragment;
            Impressions_PreviousIntervalMetaSumInsightsFragment: Impressions_PreviousIntervalMetaSumInsightsFragment;
            Members_PreviousIntervalMetaSumInsightsFragment: Members_PreviousIntervalMetaSumInsightsFragment;
            Posts_PreviousIntervalMetaSumInsightsFragment: Posts_PreviousIntervalMetaSumInsightsFragment;
          };
        })
      | null;
    currentIntervalNotAspireSourced?:
      | ({ __typename?: 'MetaSumInsights' } & {
          ' $fragmentRefs'?: {
            TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment: TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment;
            SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment: SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment;
            RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment: RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment;
          };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'SocialAdsAnalytics_QueryFragment' };

export type GetInsightsByClientIdQueryVariables = Exact<{
  clientId: Scalars['String']['input'];
  currentIntervalRange: RangeInput;
  previousIntervalRange: RangeInput;
}>;

export type GetInsightsByClientIdQuery = { __typename?: 'Query' } & {
  ' $fragmentRefs'?: { SocialAdsAnalytics_QueryFragment: SocialAdsAnalytics_QueryFragment };
};

export const TotalSpendCard_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<TotalSpendCard_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SalesGeneratedCard_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const RoasCard_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
  ],
} as unknown) as DocumentNode<RoasCard_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const Clicks_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Clicks_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Clicks_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const Reach_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Reach_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReach' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Reach_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const Impressions_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Impressions_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalImpressions' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Impressions_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const Members_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Members_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCreators' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Members_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const Posts_CurrentIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Posts_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Posts_CurrentIntervalMetaSumInsightsFragment, unknown>;
export const AdSpendVsSalesChart_MetaIntervalInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AdSpendVsSalesChart_MetaIntervalInsightsFragment, unknown>;
export const RoasOverTimeChart_MetaInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roas' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RoasOverTimeChart_MetaInsightsFragment, unknown>;
export const TotalSpendCard_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<TotalSpendCard_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SalesGeneratedCard_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const RoasCard_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
  ],
} as unknown) as DocumentNode<RoasCard_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const Clicks_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Clicks_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Clicks_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const Reach_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Reach_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReach' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Reach_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const Impressions_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Impressions_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalImpressions' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Impressions_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const Members_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Members_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCreators' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Members_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const Posts_PreviousIntervalMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Posts_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } }],
      },
    },
  ],
} as unknown) as DocumentNode<Posts_PreviousIntervalMetaSumInsightsFragment, unknown>;
export const TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment, unknown>;
export const SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment, unknown>;
export const RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
  ],
} as unknown) as DocumentNode<RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment, unknown>;
export const SocialAdsAnalytics_QueryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialAdsAnalytics_Query' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Query' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentInterval' },
                  name: { kind: 'Name', value: 'sumInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RoasCard_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Clicks_CurrentIntervalMetaSumInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Reach_CurrentIntervalMetaSumInsights' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Impressions_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Members_CurrentIntervalMetaSumInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Posts_CurrentIntervalMetaSumInsights' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentIntervalsIntervalInsights' },
                  name: { kind: 'Name', value: 'intervalInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentIntervalsIntervalInsightsNotAspireSourced' },
                  name: { kind: 'Name', value: 'intervalInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'previousInterval' },
                  name: { kind: 'Name', value: 'sumInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'previousIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TotalSpendCard_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesGeneratedCard_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RoasCard_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Clicks_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Reach_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Impressions_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Members_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Posts_PreviousIntervalMetaSumInsights' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentIntervalNotAspireSourced' },
                  name: { kind: 'Name', value: 'sumInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsights',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Clicks_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Reach_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReach' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Impressions_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalImpressions' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Members_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCreators' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Posts_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roas' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Clicks_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Reach_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReach' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Impressions_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalImpressions' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Members_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCreators' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Posts_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
  ],
} as unknown) as DocumentNode<SocialAdsAnalytics_QueryFragment, unknown>;
export const GetInsightsByClientIdDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInsightsByClientId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RangeInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'previousIntervalRange' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RangeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SocialAdsAnalytics_Query' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Clicks_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Reach_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReach' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Impressions_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalImpressions' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Members_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCreators' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Posts_CurrentIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roas' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Clicks_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalClicks' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Reach_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalReach' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Impressions_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalImpressions' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Members_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCreators' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Posts_PreviousIntervalMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalSpend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPurchase' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MetaSumInsights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalRoas' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialAdsAnalytics_Query' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Query' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentInterval' },
                  name: { kind: 'Name', value: 'sumInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesGeneratedCard_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RoasCard_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Clicks_CurrentIntervalMetaSumInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Reach_CurrentIntervalMetaSumInsights' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Impressions_CurrentIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Members_CurrentIntervalMetaSumInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Posts_CurrentIntervalMetaSumInsights' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentIntervalsIntervalInsights' },
                  name: { kind: 'Name', value: 'intervalInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentIntervalsIntervalInsightsNotAspireSourced' },
                  name: { kind: 'Name', value: 'intervalInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdSpendVsSalesChart_MetaIntervalInsights' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoasOverTimeChart_MetaInsights' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'previousInterval' },
                  name: { kind: 'Name', value: 'sumInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'previousIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TotalSpendCard_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesGeneratedCard_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RoasCard_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Clicks_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Reach_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Impressions_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Members_PreviousIntervalMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Posts_PreviousIntervalMetaSumInsights' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'currentIntervalNotAspireSourced' },
                  name: { kind: 'Name', value: 'sumInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'range' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'currentIntervalRange' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isAspireSourced' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TotalSpendCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SalesGeneratedCard_CurrentIntervalNotAspireSourcedMetaSumInsights',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsights' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetInsightsByClientIdQuery, GetInsightsByClientIdQueryVariables>;
