import * as React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import { FormInstance } from 'antd/lib/form';
import {
  Button, Col, Form, Row, Select, Typography,
} from '@revfluence/fresh';
import { OFFER_PAYOUT_TYPE, OFFER_PAYOUT_TYPE_PROMO } from '@affiliates/types/globalTypes';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { PlusIcon } from '@revfluence/fresh-icons/solid/esm';
import { useClientFeature } from '@frontend/applications/AffiliatesApp/contexts/ClientFeatureContext';
import { OfferPayoutClickAmount } from './OfferPayoutClickAmount';
import { OfferPayoutConversionAmount } from './OfferPayoutConversionAmount';
import { OfferPayoutConversionAndSaleAmount } from './OfferPayoutConversionAndSaleAmount';
import { OfferPayoutSaleAmount } from './OfferPayoutSaleAmount';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import {
  FormAsyncAction, ICommonFormElementProps, ICommonFormValues, IPayoutVariant, OfferFormModes,
} from '../../../types';
import styles from '../../../OfferForm.scss';
import { MultiplePayoutOption } from './MutiplePayoutOption';
import { OfferConversionType } from '../../AffiliateLinkSection/FormElements';

const { Option } = Select;
const { useMemo } = React;
interface IEvents {
  onBlur: () => void;
  onFocus: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

interface IProps extends ICommonFormElementProps<'payoutType'> {
  events: {
    onBlur: IEvents['onBlur'];
    options: {
      [OFFER_PAYOUT_TYPE.CONVERSION]: IEvents;
      [OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]: IEvents;
      [OFFER_PAYOUT_TYPE.SALE]: IEvents;
      [OFFER_PAYOUT_TYPE.CLICK]: IEvents;
    };
  };
  formattedConversionType: string;
  showCostPerClick: boolean;
  value: ICommonFormValues['payoutType'] | ICommonFormValues['payoutOptions'];
  source: OFFER_SOURCE;
  isNewFlow: boolean;
  mode: OfferFormModes;
  payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO;
  disabledConversionType: boolean;
  formRef: FormInstance;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
}

export const OfferPayoutOptions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  events,
  formattedConversionType,
  name,
  showCostPerClick,
  value,
  source,
  isNewFlow,
  mode,
  payoutType,
  disabledConversionType,
  formRef,
  handleFormAsyncActions,
}) => {
  const { migrateToGraphQL } = useClientFeature();
  const rules = [
    { required: true, message: 'Please input your offer payment option!' },
  ];
  let enableOfferPayout = disabled;
  let isPayoutTypeDisabled = false;
  if (migrateToGraphQL && source == OFFER_SOURCE.TUNE) {
    (value as IPayoutVariant[]).forEach((v) => {
      if (v.isMemberAdded) {
        isPayoutTypeDisabled = true;
      }
    });
  }
  if (source == OFFER_SOURCE.TUNE && migrateToGraphQL) {
    enableOfferPayout = false;
  }
  if (source == OFFER_SOURCE.SHOPIFY && isNewFlow) {
    enableOfferPayout = false;
  }
  const renderPayoutType = useMemo((): React.ReactElement => {
    switch (value) {
      case OFFER_PAYOUT_TYPE.CONVERSION:
        return (
          <OfferPayoutConversionAmount disabled={enableOfferPayout} name="flatPayout" />
        );
      case OFFER_PAYOUT_TYPE.CLICK:
        return (
          <OfferPayoutClickAmount disabled={enableOfferPayout} name="flatPayout" />
        );
      case OFFER_PAYOUT_TYPE.SALE:
        return (
          <OfferPayoutSaleAmount disabled={enableOfferPayout} name="percentPayout" />
        );
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return (
          <OfferPayoutConversionAndSaleAmount disabled={enableOfferPayout} name="flatPayout" />
        );
      default:
        return null;
    }
  }, [
    value,
    enableOfferPayout,
  ]);

  if (migrateToGraphQL) {
    return (
      <>
        {
          source === OFFER_SOURCE.TUNE && (
            <>
              <OfferConversionType
                disabled={disabledConversionType}
                name="conversionType"
              />
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Payout Type"
                    name={name}
                  >
                    <Select
                      size="large"
                      placeholder="Choose Payout option"
                      disabled={isPayoutTypeDisabled}
                    >
                      <Option
                        {...events.options[OFFER_PAYOUT_TYPE.CONVERSION]}
                        value={OFFER_PAYOUT_TYPE.CONVERSION}
                      >
                        Cost per
                        {' '}
                        {formattedConversionType}
                        {' '}
                        (CPA)
                      </Option>
                      <Option
                        {...events.options[OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]}
                        value={OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE}
                      >
                        Cost per
                        {' '}
                        {formattedConversionType}
                        {' '}
                        + Commission per Sale (CPA+CPS)
                      </Option>
                      <Option
                        {...events.options[OFFER_PAYOUT_TYPE.SALE]}
                        value={OFFER_PAYOUT_TYPE.SALE}
                      >
                        Commission per Sale (CPS)
                      </Option>
                      {showCostPerClick && (
                        <Option
                          {...events.options[OFFER_PAYOUT_TYPE.CLICK]}
                          value={OFFER_PAYOUT_TYPE.CLICK}
                        >
                          Cost per Click (CPC)
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        }
        <Row className={cx(styles.mPayoutConatiner)}>
          <Col span={24}>
            <Form.List
              name="payoutOptions"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({
                    key, name,
                  }, index) => (
                    <MultiplePayoutOption
                      formattedConversionType={formattedConversionType}
                      showCostPerClick={showCostPerClick}
                      handleFormAsyncActions={handleFormAsyncActions}
                      key={key}
                      source={source}
                      name={name}
                      payoutTypeTune={payoutType}
                      remove={remove}
                      payoutIndex={index}
                      disabled={false}
                      mode={mode}
                      payouts={value as IPayoutVariant[]}
                      formRef={formRef}
                    />
                  ))}
                  {
                    fields.length < 6 ? (
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add({ isDefault: false, payoutType: source === OFFER_SOURCE.TUNE ? payoutType : OFFER_PAYOUT_TYPE.SALE, isMemberAdded: false })}
                              className={cx(styles.addPayoutButton)}
                              size="large"
                            >
                              <PlusIcon />
                              Add Payout
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Typography.Text>You have reached the maximum limit of 6 payout options for this offer</Typography.Text>
                      </Row>
                    )
                  }
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        {renderPayoutType}
      </>
    );
  }
  return (
    <>
      <Form.Item
        className={cx({
          [styles.halfRow]: !isNull(value),
        })}
        label="Payout Options"
        name={name}
        rules={rules}
      >
        <Select
          size="large"
          placeholder="Choose Payout option"
          id={testIds.payoutType}
          disabled={disabled}
          onBlur={events.onBlur}
        >
          <Option
            {...events.options[OFFER_PAYOUT_TYPE.CONVERSION]}
            value={OFFER_PAYOUT_TYPE.CONVERSION}
          >
            Cost per
            {' '}
            {formattedConversionType}
            {' '}
            (CPA)
          </Option>
          <Option
            {...events.options[OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]}
            value={OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE}
          >
            Cost per
            {' '}
            {formattedConversionType}
            {' '}
            + Commission per Sale (CPA+CPS)
          </Option>
          <Option
            {...events.options[OFFER_PAYOUT_TYPE.SALE]}
            value={OFFER_PAYOUT_TYPE.SALE}
          >
            Commission per Sale (CPS)
          </Option>
          {showCostPerClick && (
            <Option
              {...events.options[OFFER_PAYOUT_TYPE.CLICK]}
              value={OFFER_PAYOUT_TYPE.CLICK}
            >
              Cost per Click (CPC)
            </Option>
          )}
        </Select>
      </Form.Item>
      {renderPayoutType}

    </>
  );
});
