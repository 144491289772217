import { useQuery } from '@apollo/client';
import { GET_TAGS_BY_ENTITY_ID_AND_TYPE } from '@frontend/app/queries';
import { GetTagsByEntityIdAndTypeQuery, GetTagsByEntityIdAndTypeQueryVariables } from '@frontend/app/queries/types/GetTagsByEntityIdAndTypeQuery';

export function useFetchTagsByEntityIdAndType(options = {}) {
  return useQuery<GetTagsByEntityIdAndTypeQuery, GetTagsByEntityIdAndTypeQueryVariables>(
    GET_TAGS_BY_ENTITY_ID_AND_TYPE,
    options,
  );
}
