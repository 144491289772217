import {
  ITableCellType,
  ITableColumnConfig,
} from '@components';
import {
  filter,
  keyBy,
  map,
  reduce,
} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { getFieldTypeConfig } from '@frontend/utils';

import { useMemberColumns } from '@frontend/app/components/MemberTable/hooks';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { IColumn } from '@frontend/app/types/Columns';
import { TTask } from '@frontend/app/containers/Projects/types';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { FieldType } from '@frontend/app/types/Fields';
import { PAYPAL_APP_FIELD } from '@frontend/applications/PaymentsApp/appFields';
import { formatMessageDateWithTime } from '@frontend/app/utils';

import { CustomFieldType } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import {
  ColumnKey,
  ProjectsPageState,
} from '../constants';

const {
  useCallback,
  useMemo,
} = React;

interface IOptions {
  cellClassNames?: { [key: string]: string };
  headerCellClassName?: string;
  headerCellClassNames?: { [key: string]: string };
  pageState?: ProjectsPageState;
  showInlineCTA?: boolean;
  task?: TTask;
  isOfferPromoLink?: boolean
}

interface IUseWorkItemsTableColumnConfig {
  columnConfigs: { [key: string]: ITableColumnConfig };
  visibleColumnIds: string[];
}

const isColumn = (value: unknown): value is IColumn => (
  typeof value === 'object' && 'schemaId' in value
);

const convertToCellType = (fieldType: string | CustomFieldType): ITableCellType => {
  switch (fieldType) {
    case FieldType.BOOLEAN:
      return 'boolean';
    case FieldType.DATE:
      return 'date';
    case FieldType.NUMBER:
      return 'numeric';
    default:
      return 'default';
  }
};

export const useWorkItemsTableColumnConfig = ({
  pageState,
  showInlineCTA = false,
  task,
  isOfferPromoLink = false,
}: IOptions): IUseWorkItemsTableColumnConfig => {
  /**
   * Feature flags
   */
  const isAutomatedApprovalCPSEnabled = useClientFeatureEnabled(ClientFeature.AUTOMATED_APPROVAL_CPS);
  const isNewWorkletMenuEnabled = useClientFeatureEnabled(ClientFeature.WORKET_MENU);

  /**
   * Workflow columns
   */
  const workflowColumnConfigs = useMemo(
    (): { [key: string]: ITableColumnConfig } => ({
      [ColumnKey.Member]: {
        field: ColumnKey.Member,
        headerName: 'Name',
        searchable: true,
        searchValue: (value) => value?.name,
        lockPosition: true,
        maxWidth: 400,
        minWidth: 200,
        width: 300,
      },
      [ColumnKey.Email]: {
        field: ColumnKey.Email,
        headerName: 'Email',
        searchable: true,
        width: 240,
      },
      [ColumnKey.DateAdded]: {
        field: ColumnKey.DateAdded,
        headerName: 'Date Added',
        lockWidth: true,
        searchable: true,
        searchValue: (value) => moment(value).format('MMM DD, YYYY'),
        width: 140,
        cellType: convertToCellType(FieldType.DATE),
      },
      [ColumnKey.DaysInStage]: {
        field: ColumnKey.DaysInStage,
        headerName: 'Days in Stage',
        lockWidth: true,
        searchable: true,
        width: 140,
      },
      [ColumnKey.CTA]: {
        field: ColumnKey.CTA,
        headerName: 'Action',
        lockWidth: true,
        searchable: true,
        searchValue: (value) => value?.taskMetaData?.singleCTAText || '',
        width: 140,
        sortable: false,
      },
      [ColumnKey.DateCompleted]: {
        field: ColumnKey.DateCompleted,
        headerName: 'Date Completed',
        searchable: true,
        searchValue: (value) => moment(value).format('MMM DD, YYYY'),
        lockWidth: true,
        width: 150,
        cellType: convertToCellType(FieldType.DATE),
      },
      [ColumnKey.LastMessage]: {
        field: ColumnKey.LastMessage,
        headerName: 'Last Message',
        searchable: true,
        searchValue: (value) => (value ? formatMessageDateWithTime(value.internalDate) : ''),
        lockWidth: true,
        width: 240,
      },
      [ColumnKey.Owners]: {
        field: ColumnKey.Owners,
        headerName: 'Owners',
        searchable: true,
        searchValue: (value) => reduce(value, (acc, owner) => (acc + owner.name), ''),
        width: 120,
      },
    }),
    [],
  );

  const allInProgressColumns: ITableColumnConfig[] = [
    {
      field: ColumnKey.TaskName,
      headerName: 'Stage',
      searchable: true,
      searchValue: (value) => value.taskName,

    },
  ];

  const allInProgressColumnsForWorklet: ITableColumnConfig[] = [
    {
      field: ColumnKey.WorkletName,
      headerName: 'Stage',
    },
    {
      field: ColumnKey.WorkletTaskName,
      headerName: 'Status',
    },
  ];

  const workletColumns: ITableColumnConfig[] = [
    {
      field: ColumnKey.WorkletTaskName,
      headerName: 'Status',
    },
  ];

  /**
   * Member columns
   */
  const memberColumns = useMemberColumns();
  const {
    data: {
      schemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();
  const memberColumnDefs = useMemo(
    (): { [key: string]: ITableColumnConfig } => reduce(
      {
        ...keyBy(schemas, (schema) => schema.name),
        ...keyBy(memberColumns, (column) => column.field),
      },
      (result, value, field) => ({
        ...result,
        [field]: {
          field: isColumn(value) ? value.field : value.id,
          headerName: isColumn(value) ? value.headerName : value.name,
          cellType: convertToCellType(value.type),
          searchable: true,
        } as ITableColumnConfig,
      }),
      {},
    ),
    [memberColumns, schemas],
  );

  /**
   * Task columns
   * NOTE: Hardcoded; Platform violation
   * TODO: Save on back end
   */

  const getTaskColumns = useCallback(
    (task: TTask): ITableColumnConfig[] => {
      const baseCPSColumns: ITableColumnConfig[] = [
        {
          field: 'address',
          headerName: 'Address',
        },
        {
          field: 'country',
          headerName: 'Country',
        },
      ];
      switch (task.workletSpecUri) {
        case 'SendContractWorkletSpecification':
          switch (task.taskId) {
            case 'submit_contract_task':
              return [memberColumnDefs.email];
            case 'send_contract_task':
              return [
                memberColumnDefs.email,
              ];
            case 'member_sign_contract_task':
            case 'brand_sign_contract_task':
              return [
                {
                  field: 'pending_contracts',
                  headerName: 'Pending Contracts',
                },
              ];
          }
          break;
        case 'PaymentWorkletSpecification':
          switch (task.taskId) {
            case 'send_payment_task':
              return [
                {
                  field: PAYPAL_APP_FIELD,
                  headerName: 'PayPal Address',
                },
                {
                  field: 'last_payment_sent',
                  headerName: 'Last Payment Sent',
                  cellType: 'date',
                },
              ];
          }
          break;
        case 'SendTermsWorkletSpecification':
          switch (task.taskId) {
            case 'send_terms_task':
              return [
                memberColumnDefs.email,
              ];
            case 'waiting_for_agreement_task':
            case 'fix_error_sending_terms_task':
              return [
                {
                  field: 'message',
                  headerName: 'Error',
                },
              ];
            case 'review_terms_task':
              return [
                {
                  field: 'deliverables',
                  headerName: 'Deliverables',
                },
                {
                  field: 'price',
                  headerName: 'Price',
                  cellType: 'numeric',
                  ...getFieldTypeConfig('numeric'),
                },
              ];
          }
          break;
        case 'ReviewContentWorkletSpecification':
          switch (task.taskId) {
            case 'waiting_for_content_task':
            case 'review_content_task':
            case 'waiting_for_posts_task':
              return [
                {
                  field: 'next_deadline',
                  headerName: 'Next Deadline',
                  cellType: 'date',
                },
                {
                  field: 'open_deliverables',
                  headerName: 'Open Deliverables',
                  cellType: 'numeric',
                  ...getFieldTypeConfig('numeric'),
                },
                {
                  field: 'completed_deliverables',
                  headerName: 'Completed Deliverables',
                  cellType: 'numeric',
                  ...getFieldTypeConfig('numeric'),
                },
              ];
          }
          break;
        case 'ConfirmAddressWorkletSpecification':
          switch (task.taskId) {
            case 'confirm_addresses_task':
            case 'waiting_for_address_confirmation_task':
            case 'submit_order_task':
              return [
                memberColumnDefs.Address1,
                memberColumnDefs.Address2,
                memberColumnDefs.City,
                memberColumnDefs.State,
                memberColumnDefs.PostalCode,
                memberColumnDefs.Country,
              ];
          }
          break;
        case 'SendProductWorkletSpecification':
          switch (task.taskId) {
            case 'submit_order_task':
              return [
                memberColumnDefs.Address1,
                memberColumnDefs.Address2,
                memberColumnDefs.City,
                memberColumnDefs.State,
                memberColumnDefs.PostalCode,
                memberColumnDefs.Country,
              ];
            // @TODO add these back once PFA actually updates work item with relevant data
            // case 'send_order_task':
            //   return [
            //     {
            //       field: 'products_sent',
            //       headerName: 'Products Sent',
            //     },
            //   ];
            // case 'order_in_transit_task':
            //   return [
            //     {
            //       field: 'products_sent',
            //       headerName: 'Products Sent',
            //     },
            //     {
            //       field: 'tracking_code',
            //       headerName: 'Tracking Code',
            //     },
            //     {
            //       field: 'expected_delivery',
            //       headerName: 'Expected Delivery',
            //       cellType: 'date',
            //     },
            //   ];
          }
          break;
        case 'CreatorProductSelectionWorkletSpecification':
          switch (task.taskId) {
            case 'review_order_request_task':
              if (isAutomatedApprovalCPSEnabled) {
                return baseCPSColumns.concat([
                  {
                    field: 'status',
                    headerName: 'Status',
                  },
                ]);
              }
              return baseCPSColumns;
          }
          break;
        case 'OfflineCreatorProductSelectionWorkletSpecification':
          switch (task.taskId) {
            case 'offline_review_order_request_task':
              return baseCPSColumns.concat([
                {
                  field: 'quantity',
                  headerName: 'Quantity',
                },
                {
                  field: 'products',
                  formatValue: (value) => value.join(', '),
                  headerName: 'Product Selected',
                },
              ]);
            case 'offline_fulfill_order_task':
              return [
                {
                  field: 'quantity',
                  headerName: 'Original Quantity',
                },
                {
                  field: 'products',
                  formatValue: (value) => value.join(', '),
                  headerName: 'Original Product Selected',
                },
              ];
            case 'offline_order_in_transit_task':
              return [
                {
                  field: 'shipmentStatus',
                  headerName: 'Shipment Status',
                },
                {
                  field: 'trackingNumber',
                  headerName: 'Tracking Number',
                },
              ];
          }
          break;
        case 'SalesLinkWorkletSpecification':
        case 'PromoCodesWorkletSpecification':
        case 'SalesLinkPromoCodesWorkletSpecification':
          switch (task.taskId) {
            case 'fix_error_promo_task':
              return [
                {
                  field: 'promoCode',
                  headerName: 'Promo Code',
                },
                {
                  field: 'promoCodeStatus',
                  headerName: 'Status',
                },
              ];
            case 'send_link_task':
              return [
                {
                  cellType: 'link',
                  field: 'affiliateLink',
                  hrefField: 'affiliateLink',
                  anchorTitleField: 'affiliateLink',
                  headerName: 'Link',
                  useAnchor: true,
                  openInNewTab: true,
                },
              ];
            case 'send_promo_task':
              return [
                {
                  field: 'promoCode',
                  headerName: 'Promo Code',
                },
                ...(isOfferPromoLink ? [{
                  cellType: 'link' as ITableCellType,
                  field: 'affiliateLink',
                  hrefField: 'affiliateLink',
                  anchorTitleField: 'affiliateLink',
                  headerName: 'Link',
                  useAnchor: true,
                  openInNewTab: true,
                }] : []),
              ];
            case 'send_link_and_promo_task':
              return [
                {
                  cellType: 'link',
                  field: 'affiliateLink',
                  hrefField: 'affiliateLink',
                  anchorTitleField: 'affiliateLink',
                  headerName: 'Link',
                  useAnchor: true,
                  openInNewTab: true,
                },
                {
                  field: 'promoCode',
                  headerName: 'Promo Code',
                },
              ];
          }
          break;
        default:
          return [];
      }
    },
    [memberColumnDefs, isAutomatedApprovalCPSEnabled, isOfferPromoLink],
  );

  return useMemo(
    (): IUseWorkItemsTableColumnConfig => {
      if (pageState === ProjectsPageState.Completed) {
        return {
          columnConfigs: workflowColumnConfigs,
          visibleColumnIds: filter([
            ColumnKey.ID,
            ColumnKey.Member,
            ColumnKey.Email,
            memberColumnDefs.Instagram?.field,
            ColumnKey.Owners,
            ColumnKey.DateCompleted,
          ]),
        };
      } else if (
        pageState === ProjectsPageState.AllInProgress
        || pageState === ProjectsPageState.Task
        || pageState === ProjectsPageState.Worklet
      ) {
        const taskColumnConfigs = task ? getTaskColumns(task) : [];
        let columnConfigs = [];
        if (pageState === ProjectsPageState.Task) {
          columnConfigs = taskColumnConfigs;
        } else if (pageState === ProjectsPageState.Worklet) {
          columnConfigs = workletColumns;
        } else if (isNewWorkletMenuEnabled) {
          columnConfigs = allInProgressColumnsForWorklet;
        } else {
          columnConfigs = allInProgressColumns;
        }

        return {
          columnConfigs: {
            ...workflowColumnConfigs,
            ...keyBy(columnConfigs, (col) => col.field),
          },
          visibleColumnIds: filter([
            ColumnKey.ID,
            ColumnKey.Member,
            ColumnKey.Email,
            showInlineCTA ? ColumnKey.CTA : undefined,
            ...map(columnConfigs, (col) => col.field),
            memberColumnDefs.Instagram?.field,
            memberColumnDefs.TikTok?.field,
            ColumnKey.LastMessage,
            ColumnKey.Owners,
            ColumnKey.DaysInStage,
            ColumnKey.DateAdded,
          ]),
        };
      }
      return {
        columnConfigs: {},
        visibleColumnIds: [],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      memberColumnDefs,
      pageState,
      showInlineCTA,
      task,
      workflowColumnConfigs,
      getTaskColumns,
      isOfferPromoLink,
    ],
  );
};
