import {
  Loading3QuartersOutlined,
  ExclamationCircleFilled,
  DownOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Typography,
} from '@revfluence/fresh';
import {
  MountainIcon,
  CircleCheckIcon,
  FolderUserIcon,
  ListCheckIcon,
} from '@revfluence/fresh-icons/solid/esm';
import {
  FolderUserIcon as RegularFolderUserIcon,
  CircleCheckIcon as RegularCircleCheckIcon,
  MountainIcon as RegularMountainIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { Menu } from '@revfluence/fresh';
import cx from 'classnames';
import {
  get,
  map,
  chain,
  first,
} from 'lodash';
import * as React from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import * as qs from 'qs';

import { logger } from '@common';
import {
  EllipsisLabel,
  FlagIcon,
  NodeSegmentIcon,
  HORIZONTAL_TOOLTIP_OFFSET,
  VERTICAL_TOOLTIP_OFFSET,
} from '@frontend/app/components';
import { ICounts, ITaskCounts } from '@frontend/app/containers/Projects/hooks';
import { BullseyePointerIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { ITaskUI, hasError, hasNotification } from '@frontend/app/utils/task';
import {
  TProject,
  TTask,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';

import {
 NavigationMenuItem,
} from '@frontend/shadcn/components/ui/navigation-menu';
import { Small } from '@frontend/shadcn/components/typography/small';
import { cva } from 'class-variance-authority';
import {
  Tooltip, TooltipContent, TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import NavigationPanel, { TreeItem, TreeItemGroup } from '@frontend/app/refresh-components/NavigationPanel';
import {
  ProjectsPageState,
  ProjectsRouteRoot,
  Task,
} from '../../constants';

import styles from './FolderList.scss';
import { getMembersCount } from '../../utils';

interface IProps {
  className?: string | string[];
  counts?: ICounts;
  workletSpecUri?: string;
  onTaskSelected: THandleTaskSelected;
  pageState: ProjectsPageState;
  project: TProject;
  task: TTask;
  tasks: TTask[];
  worklets?: TWorklet[];
}

// TODO: TYPE GRAPHQL !!!

const { Text } = Typography;

const { useCallback, useMemo } = React;

const menuListItemVariants = cva(
  'flex items-center gap-3 pl-4 pr-3 py-3 w-full cursor-pointer rounded-lg font-medium text-foreground relative hover:bg-sideNav-item-hover',
  {
    variants: {
      placement: {
        sub: 'justify-between !pl-12 !font-normal',
      },
      active: {
        true: 'bg-border',
      },
    },
  },
);

export const FolderList: React.FC<IProps> = (props) => {
  const {
    className,
    counts,
    onTaskSelected,
    pageState,
    project,
    task,
    tasks,
    workletSpecUri,
    worklets,
  } = props;

  const isNewWorkletMenuEnabled = useClientFeatureEnabled(ClientFeature.WORKET_MENU);

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const isFlexEnabled = useClientFeatureEnabled(ClientFeature.FLEXIBLE_PROJECT);

  const history = useHistory();
  const location = useLocation();
  const { predefinedSegments } = useMemberListContext();

  const handleApplicantsItemClicked = () => {
    if (!predefinedSegments || !project?.id) {
      return;
    }

    const segment = predefinedSegments.find((seg) => seg.title === 'Applicants');
    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/${project.id}/applicants`,
      search: segment ? qs.stringify({ segmentId: segment.id }) : '',
    });
  };

  const handleInvitedItemClicked = () => {
    if (!predefinedSegments || !project?.id) {
      return;
    }

    const segment = predefinedSegments.find((seg) => seg.title === 'Invited');
    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/${project?.id}/invited`,
      search: segment ? qs.stringify({ segmentId: segment.id }) : '',
    });
  };

  const handleRejectedItemClicked = () => {
    if (!predefinedSegments || !project?.id) {
      return;
    }

    const segment = predefinedSegments.find((seg) => seg.title === 'Rejected');
    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/${project?.id}/rejected`,
      search: segment ? qs.stringify({ segmentId: segment.id }) : '',
    });
  };

  const handleCompletedItemClicked = () => {
    if (!project?.id) {
      return;
    }

    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/${project.id}/completed`,
      search: undefined,
    });
  };

  const handleOverviewClicked = () => {
    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/${project.id}/overview`,
      search: '',
    });
  };

  const handleFindCreatorsClicked = () => {
    history.push({
      ...location,
      pathname: `${ProjectsRouteRoot}/${project.id}/find_creators`,
      search: '',
    });
  };

  // This method will be deleted after the feature flag `workletMenu` be deleted.
  const renderTasksMenuItems = useCallback(() => {
    const renderGroup = (taskGroup: TTask[]) => (
      map(taskGroup, (t, index) => {
        const taskCounts: ITaskCounts = counts?.[t.workletSpecUri]?.[t.taskId] || { total: 0, unViewed: 0 };
        const taskUI: ITaskUI = (() => {
          try {
            return JSON.parse(t?.taskMetaData?.taskUI);
          } catch (err) {
            logger.error(`Error parsing ${t.taskId} taskUI`, err);
            return undefined;
          }
        })();

        const hasError = taskUI?.type === 'error';
        const isTaskActive = task && task.workletSpecUri === t.workletSpecUri && task.taskId === t.taskId;
        const isTaskTransient = taskUI?.isTransient || false;
        const hasNotification = !isTaskActive && taskCounts.unViewed > 0;

        // Hide transient tasks with no count
        const showTask = (
          !taskUI
          || !isTaskTransient
          || (isTaskTransient && taskCounts.total > 0)
        );
        if (!showTask) {
          return undefined;
        }

        const hasConnectorBefore = index >= 1;
        const hasConnectorAfter = taskGroup.length > 1 && index < taskGroup.length - 1;
        const baseIconWrapperClassName = cx(
          styles.iconWrapper,
          {
            [styles.iconConnectorBefore]: hasConnectorBefore,
            [styles.iconConnectorAfter]: hasConnectorAfter,
          },
        );

        const icon = (() => {
          if (hasError) {
            return (
              <ExclamationCircleFilled
                className={cx(styles.errorIcon, styles.antIcon)}
                size={16}
              />
            );
          } if (isTaskTransient) {
            return (
              <Loading3QuartersOutlined
                className={cx(styles.transientIcon, styles.antIcon)}
                size={16}
                spin
              />
            );
          }
          return (
            <NodeSegmentIcon
              className={styles.nodeSegmentIcon}
              size={16}
            />
          );
        })();

        return (
          <Menu.Item
            className={cx(
              styles.item,
              { [styles.transient]: isTaskTransient },
            )}
            danger={hasError}
            icon={(
              <span
                className={cx(
                  'anticon',
                  baseIconWrapperClassName,
                )}
                role="img"
              >
                {/* TODO: anticon wrapper */}
                {icon}
                {hasNotification && (
                  <span className={styles.notificationDot} />
                )}
              </span>
            )}
            key={`${t.workletSpecUri}/${t.taskId}`}
            onClick={() => onTaskSelected(t.workletSpecUri, t.taskId)}
          >
            <EllipsisLabel
              tooltipPlacement="right"
            >
              <span className={styles.innerItemLabel}>
                {t.taskName}
              </span>
            </EllipsisLabel>
            <div className={styles.accessory}>
              {taskCounts.total}
            </div>
          </Menu.Item>
        );
      })
    );

    return chain(tasks)
      .groupBy((taskArg: TTask) => taskArg.workletSpecKey)
      .toPairs()
      .map(([name, taskGroup]) => (
        <React.Fragment key={name}>
          {renderGroup(taskGroup)}
        </React.Fragment>
      ))
      .value();
  },
    [
      tasks,
      task,
      counts,
      onTaskSelected,
    ]);

  const selectedKeys = useMemo(() => {
    if (
      [
        ProjectsPageState.Completed,
        ProjectsPageState.Applicants,
        ProjectsPageState.Invited,
      ].includes(pageState)
    ) {
      return [pageState];
    }

    if (isNewWorkletMenuEnabled && workletSpecUri) {
      return [workletSpecUri];
    } else if (task && task.workletSpecUri && task.taskId) {
      return [`${task.workletSpecUri}/${task.taskId}`];
    }

    return [pageState];
  }, [isNewWorkletMenuEnabled, workletSpecUri, task, pageState]);

  const renderWorkletMenuItems = useCallback(() => {
    const renderGroup = (taskGroup: TTask[]) => {
      let totalCount: number = 0;
      let hasAnyErrorTask: boolean = false;
      let hasAnyNotificationTask: boolean = false;

      taskGroup.forEach((t) => {
        const taskCounts: ITaskCounts = counts?.[t.workletSpecUri]?.[t.taskId] || { total: 0, unViewed: 0 };
        totalCount += taskCounts.total;
        hasAnyErrorTask = (hasAnyErrorTask || hasError(t, taskCounts.total));
        hasAnyNotificationTask = (hasAnyNotificationTask || hasNotification(t, taskCounts.total));
      });

      const icon = (() => {
        if (hasAnyErrorTask) {
          return (
            <TriangleExclamationIcon fill="#d9534f" />
          );
        }
        return null;
      })();

      const currentWorkletSpecUri = first(taskGroup).workletSpecUri;

      const workletFirstTaskId = (() => {
        const workletSpecUri = currentWorkletSpecUri;
        return workletSpecUri.includes('CustomWorklet-') ? first(taskGroup).taskId : undefined;
      })();

      if (isRefreshUIEnabled) {
        return (
          <NavigationMenuItem
            className={menuListItemVariants({ placement: 'sub', active: selectedKeys[0] === currentWorkletSpecUri })}
            onClick={() => onTaskSelected(currentWorkletSpecUri, workletFirstTaskId)}
            value={currentWorkletSpecUri}
          >
            <span className="absolute left-5">
              {icon}
            </span>
            <Tooltip>
              <TooltipTrigger className="overflow-hidden text-ellipsis whitespace-nowrap">
                <Small className="font-normal">{getWorkletNameBySpecURI(currentWorkletSpecUri, worklets)}</Small>
              </TooltipTrigger>
              <TooltipContent>
                {getWorkletNameBySpecURI(currentWorkletSpecUri, worklets)}
              </TooltipContent>
            </Tooltip>
            <div className="flex items-center ml-auto">
              <Small className="font-normal">{totalCount > 0 ? totalCount : ''}</Small>
              {hasAnyNotificationTask && (
                <Badge dot style={{ fontSize: '8px' }} />
              )}
            </div>
          </NavigationMenuItem>
        );
      }

      return (
        <Menu.Item
          className={cx(
            styles.itemForWorklet,
          )}
          danger={hasAnyErrorTask}
          icon={(
            <span
              className={cx(
                'anticon',
              )}
              role="img"
            >
              {icon}
            </span>
          )}
          key={currentWorkletSpecUri}
          onClick={() => onTaskSelected(currentWorkletSpecUri, workletFirstTaskId)}
        >
          <EllipsisLabel
            align={[
              HORIZONTAL_TOOLTIP_OFFSET,
              VERTICAL_TOOLTIP_OFFSET,
            ]}
            tooltipPlacement="right"
          >
            {getWorkletNameBySpecURI(currentWorkletSpecUri, worklets)}
          </EllipsisLabel>
          <div className={styles.accessory}>
            <Text
              className={styles.totalCount}
              style={{ color: 'inherit' }}
            >
              {totalCount > 0 ? totalCount : ''}
            </Text>
            {hasAnyNotificationTask && (
              <Badge
                dot
                style={{
                  fontSize: '8px', position: 'absolute', top: '-8px', right: '-4px',
                }}
              />
            )}
          </div>
        </Menu.Item>
      );
    };

    return chain(tasks)
      .groupBy((taskArg: TTask) => taskArg.workletSpecKey)
      .toPairs()
      .map(([name, taskGroup]) => (
        <React.Fragment key={name}>
          {renderGroup(taskGroup)}
        </React.Fragment>
      ))
      .value();
  }, [tasks, isRefreshUIEnabled, worklets, counts, selectedKeys, onTaskSelected]);

  const buildInProgress = useMemo(() => {
    if (isNewWorkletMenuEnabled) {
      return (
        <>
          <Menu.Item
            className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
            icon={(
              <span
                className="anticon"
                role="img"
              >
                <ListCheckIcon
                  className={styles.inProgressIcon}
                  fontSize={16}
                />
              </span>
            )}
            key={ProjectsPageState.AllInProgress}
            onClick={() => onTaskSelected(undefined, undefined)}
          >
            <Text className={styles.mainItemMenu}>All in Progress</Text>
            <div className={styles.accessory}>
              {get(counts, 'all_in_progress', 0).toLocaleString()}
            </div>
          </Menu.Item>
          {renderWorkletMenuItems()}
        </>
      );
    } else {
      return (
        <Menu.SubMenu
          icon={(
            <span
              className="anticon"
              role="img"
            >
              <ListCheckIcon
                className={styles.inProgressIcon}
                fontSize={16}
              />
            </span>
          )}
          key="in-progress"
          title="In Progress"
        >
          <Menu.Item
            className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
            icon={(
              <span
                className="anticon"
                role="img"
              >
                <NodeSegmentIcon
                  className={styles.nodeSegmentIcon}
                  size={16}
                />
              </span>
            )}
            key={ProjectsPageState.AllInProgress}
            onClick={() => onTaskSelected(undefined, undefined)}
          >
            <span className={styles.innerItemLabel}>
              All in progress
            </span>
            <div className={styles.accessory}>
              {get(counts, 'all_in_progress', 0).toLocaleString()}
            </div>
          </Menu.Item>
          {renderTasksMenuItems()}
        </Menu.SubMenu>
      );
    }
  }, [counts, isNewWorkletMenuEnabled, onTaskSelected, renderTasksMenuItems, renderWorkletMenuItems]);

  const renderOldUI = () => (
    <Menu
      className={cx(
          isNewWorkletMenuEnabled ? styles.ProjectsMenuWorklet : styles.ProjectsMenu,
          className,
        )}
      expandIcon={<DownOutlined />}
      defaultOpenKeys={['prospects', 'in-progress']}
      mode="inline"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
      noIndent
      selectedKeys={selectedKeys}
    >
      <Menu.Item
        key={ProjectsPageState.Overview}
        className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
        icon={(
          <span
            className="anticon"
            role="img"
          >
            {isNewWorkletMenuEnabled ? (
              <RegularMountainIcon
                className={styles.overviewIcon}
                fontSize={16}
              />
              )
                : (
                  <MountainIcon
                    className={styles.overviewIcon}
                    fontSize={16}
                  />
                )}
          </span>
          )}
        onClick={handleOverviewClicked}
      >
        {isNewWorkletMenuEnabled
            ? <Text className={styles.mainItemMenu}>Overview</Text>
            : <span className={styles.overviewLabel}>Overview</span>}
      </Menu.Item>
      <Menu.Item
        key={ProjectsPageState.FindCreators}
        className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
        icon={(
          <span
            className="anticon"
            role="img"
          >
            {isNewWorkletMenuEnabled ? (
              <BullseyePointerIcon
                className={styles.findCreatorsIcon}
              />
              )
                : (
                  <FlagIcon
                    className={styles.findCreatorsIcon}
                    size={16}
                  />
                )}
          </span>
          )}
        onClick={handleFindCreatorsClicked}
      >
        {isNewWorkletMenuEnabled
            ? <Text className={styles.mainItemMenu}>Find Creators</Text>
            : <span className={styles.findCreatorsLabel}>Find Creators</span>}
      </Menu.Item>
      <Menu.SubMenu
        className={styles.subMenuItemLabel}
        icon={(
          <span
            className="anticon"
            role="img"
          >
            {isNewWorkletMenuEnabled ? (
              <RegularFolderUserIcon
                className={styles.applicantsIcon}
                fontSize={16}
              />
              )
                : (
                  <FolderUserIcon
                    className={styles.applicantsIcon}
                    fontSize={16}
                  />
                )}
          </span>
          )}
        key="prospects"
        title="Prospects"
      >
        <Menu.Item
          className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
          key={ProjectsPageState.Invited}
          onClick={handleInvitedItemClicked}
        >
          <span className={cx(styles.prospectsItemLabel, styles.innerItemLabel)}>
            Invited
          </span>
          <div className={styles.accessory}>
            {getMembersCount('invited', counts, isNewWorkletMenuEnabled)}
          </div>
        </Menu.Item>
        <Menu.Item
          className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
          key={ProjectsPageState.Applicants}
          onClick={handleApplicantsItemClicked}
        >
          <span className={cx(styles.prospectsItemLabel, styles.innerItemLabel)}>
            Applicants
          </span>
          <div className={styles.accessory}>
            {getMembersCount('new', counts, isNewWorkletMenuEnabled)}
          </div>
        </Menu.Item>
        <Menu.Item
          className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
          key={ProjectsPageState.Rejected}
          onClick={handleRejectedItemClicked}
        >
          <span className={cx(styles.prospectsItemLabel, styles.innerItemLabel)}>
            Rejected
          </span>
          <div className={styles.accessory}>
            {getMembersCount('rejected', counts, isNewWorkletMenuEnabled)}
          </div>
        </Menu.Item>
      </Menu.SubMenu>
      {project?.specKey && (
      <>
        <Menu.Divider className={styles.divider} />
        {buildInProgress}
        <Menu.Divider className={styles.divider} />
        <Menu.Item
          className={isNewWorkletMenuEnabled ? styles.itemForWorklet : styles.item}
          icon={(
            <span
              className="anticon"
              role="img"
            >
              {isNewWorkletMenuEnabled ? (
                <RegularCircleCheckIcon
                  className={styles.completedIcon}
                />
                  )
                    : (
                      <CircleCheckIcon
                        className={styles.completedIcon}
                        fontSize={16}
                      />
                    )}
            </span>
              )}
          key={ProjectsPageState.Completed}
          onClick={handleCompletedItemClicked}
        >
          {isNewWorkletMenuEnabled
                ? <Text className={styles.mainItemMenu}>Completed</Text>
                : <span>Completed</span>}
          <div className={styles.accessory}>
            {getMembersCount('completed', counts, isNewWorkletMenuEnabled)}
          </div>
        </Menu.Item>
      </>
        )}
    </Menu>
    );

    const createNavigationItems = (): (TreeItemGroup | TreeItem)[] => {
      const memberCount = (type: string) => {
        const countValue = getMembersCount(type, counts, isNewWorkletMenuEnabled) ?? '0';
        return isNaN(Number(countValue)) ? 0 : Number(countValue); // Return 0 for NaN
      };
      return [
        {
          id: ProjectsPageState.Overview,
          title: 'Overview',
          icon: <MountainIcon className="text-icon-teal text-base" />,
          onClick: handleOverviewClicked,
        },
        {
          id: ProjectsPageState.FindCreators,
          title: 'Find Creators',
          icon: <BullseyePointerIcon className="text-icon-blue text-base" />,
          onClick: handleFindCreatorsClicked,
        },
        {
          id: 'prospects',
          title: 'Prospects',
          icon: <FolderUserIcon className="text-success text-base" />,
          collapsible: true,
          items: [
            {
              id: ProjectsPageState.Invited,
              title: 'Invited',
              count: memberCount('invited'),
            },
            {
              id: ProjectsPageState.Applicants,
              title: 'Applicants',
              count: memberCount('new'),
            },
            {
              id: ProjectsPageState.Rejected,
              title: 'Rejected',
              count: memberCount('rejected'),
            },
          ],
        },
        {
          id: ProjectsPageState.AllInProgress,
          title: 'All in Progress',
          icon: <ListCheckIcon className="text-icon-blue text-base" />,
          count: Number(get(counts, 'all_in_progress', 0)),
          collapsible: true,
          items: chain(tasks)
            .groupBy((taskArg: TTask) => taskArg.workletSpecUri)
            .map((taskGroup, workletSpecUri) => {
              const totalCount = taskGroup.reduce((sum, t) => {
                const taskCounts: ITaskCounts = counts?.[t.workletSpecUri]?.[t.taskId] || { total: 0, unViewed: 0 };
                return sum + taskCounts.total;
              }, 0);

              const hasAnyErrorTask = taskGroup.some((t) => hasError(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total || 0));
              const hasAnyNotificationTask = taskGroup.some((t) => hasNotification(t, counts?.[t.workletSpecUri]?.[t.taskId]?.total || 0));

              const workletName = getWorkletNameBySpecURI(workletSpecUri, worklets);

              return {
                id: workletSpecUri,
                title: workletName || '',
                count: totalCount,
                icon: hasAnyErrorTask ? <TriangleExclamationIcon fill="#d9534f" /> : undefined,
                badge: hasAnyNotificationTask,
              };
            })
            .value(),
          },
        {
          id: ProjectsPageState.Completed,
          title: 'Completed',
          icon: <CircleCheckIcon className="text-icon-lime text-base" />,
          count: memberCount('completed'),
        },
      ];
    };

    const renderNewUI = () => (
      <NavigationPanel
        items={createNavigationItems()}
        selectedId={selectedKeys[0]}
        onItemClick={(id) => {
          switch (id) {
            case ProjectsPageState.Overview:
              handleOverviewClicked();
              break;
            case ProjectsPageState.FindCreators:
              handleFindCreatorsClicked();
              break;
            case ProjectsPageState.Invited:
              handleInvitedItemClicked();
              break;
            case ProjectsPageState.Applicants:
              handleApplicantsItemClicked();
              break;
            case ProjectsPageState.Rejected:
              handleRejectedItemClicked();
              break;
            case ProjectsPageState.AllInProgress:
              onTaskSelected(undefined, undefined);
              break;
            case ProjectsPageState.Completed:
              handleCompletedItemClicked();
              break;
              default:
                // This will handle the sub-items of "All in Progress"
                onTaskSelected(id, (isFlexEnabled && project?.isFlexibleSpec) ? `${id}-${Task.NeedsAction}` : undefined);
          }
        }}
      />
    );
  return isRefreshUIEnabled && isNewWorkletMenuEnabled ? renderNewUI() : renderOldUI();
};

FolderList.defaultProps = {
  className: [],
  counts: {},
};
