import { DateRangeOptions, IDateRangeSettings } from '@frontend/app/components';
import { useDateFilterSettings } from '@frontend/app/hooks';
import { FulfillmentStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { endOfDay, startOfDay, subYears } from 'date-fns';
import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

export interface PFADashboardContextState {
  dateRangeSettings: IDateRangeSettings;
  filterDrawerOpen: boolean;
  ownerIds: string[];
  localOwnerIds: string[];
  projectIds: number[];
  localProjectIds: number[];
  fulfillmentStatus: FulfillmentStatus[];
  localFulfillmentStatus: FulfillmentStatus[];
}

export interface PFADashboardContextSetters {
  setFilterDrawerOpen: (open: boolean) => void;
  setOwnerIds: (ownerIds: string[]) => void;
  setLocalOwnerIds: (ownerIds: string[]) => void;
  setProjectIds: (projectIds: number[]) => void;
  setLocalProjectIds: (projectIds: number[]) => void;
  setFulfillmentStatus: (status: FulfillmentStatus[]) => void;
  setLocalFulfillmentStatus: (status: FulfillmentStatus[]) => void;
  setState: (state: Partial<PFADashboardContextState>) => void;
  resetState: () => void;
}

const initialState: PFADashboardContextState = {
  dateRangeSettings: null,
  filterDrawerOpen: false,
  ownerIds: [],
  localOwnerIds: [],
  projectIds: [],
  localProjectIds: [],
  fulfillmentStatus: [],
  localFulfillmentStatus: [],
};

const initialSetters: PFADashboardContextSetters = {
  setFilterDrawerOpen: () => {},
  setOwnerIds: () => {},
  setLocalOwnerIds: () => {},
  setProjectIds: () => {},
  setLocalProjectIds: () => {},
  setFulfillmentStatus: () => {},
  setLocalFulfillmentStatus: () => {},
  setState: () => {},
  resetState: () => {},
};

export enum Actions {
  SetFilterDrawerOpen = 'SET_FILTER_DRAWER_OPEN',
  SetOwnerIds = 'SET_OWNER_IDS',
  SetLocalOwnerIds = 'SET_LOCAL_OWNER_IDS',
  SetProjectIds = 'SET_PROJECT_IDS',
  SetLocalProjectIds = 'SET_LOCAL_PROJECT_IDS',
  SetFulfillmentStatus = 'SET_FULFILLMENT_STATUS',
  SetLocalFulfillmentStatus = 'SET_LOCAL_FULFILLMENT_STATUS',
  SetState = 'SET_STATE',
  ResetState = 'RESET_STATE',
}

type IActions = {
  type: Actions.SetFilterDrawerOpen;
  payload: boolean;
} | {
  type: Actions.SetOwnerIds;
  payload: string[];
} | {
  type: Actions.SetLocalOwnerIds;
  payload: string[];
} | {
  type: Actions.SetProjectIds;
  payload: number[];
} | {
  type: Actions.SetLocalProjectIds;
  payload: number[];
} | {
  type: Actions.SetFulfillmentStatus;
  payload: FulfillmentStatus[];
} | {
  type: Actions.SetLocalFulfillmentStatus;
  payload: FulfillmentStatus[];
} | {
  type: Actions.SetState;
  payload: Partial<PFADashboardContextState>;
} | {
  type: Actions.ResetState;
};

type PFADashboardContextProps = PFADashboardContextState & PFADashboardContextSetters;

export const PFADashboardContext = createContext<PFADashboardContextProps>({
  ...initialState,
  ...initialSetters,
});

export const PFADashboardProvider: React.FC = ({ children }) => {
  const dateRangeSettings = useDateFilterSettings(DateRangeOptions.LAST_6_MONTHS);

  const [state, dispatch] = useReducer(
    (state: PFADashboardContextState, action: IActions): PFADashboardContextState => {
      switch (action.type) {
        case Actions.SetFilterDrawerOpen:
          return { ...state, filterDrawerOpen: action.payload };
        case Actions.SetOwnerIds:
          return { ...state, ownerIds: action.payload };
        case Actions.SetLocalOwnerIds:
          return { ...state, localOwnerIds: action.payload };
        case Actions.SetProjectIds:
          return { ...state, projectIds: action.payload };
        case Actions.SetLocalProjectIds:
          return { ...state, localProjectIds: action.payload };
        case Actions.SetFulfillmentStatus:
          return { ...state, fulfillmentStatus: action.payload };
        case Actions.SetLocalFulfillmentStatus:
          return { ...state, localFulfillmentStatus: action.payload };
        case Actions.SetState:
          return { ...state, ...action.payload };
        case Actions.ResetState:
          return initialState;
        default:
          return state;
      }
    },
    initialState,
  );

  const actions = useMemo<PFADashboardContextSetters>(
    () => ({
      setFilterDrawerOpen: (open: boolean) => dispatch({ type: Actions.SetFilterDrawerOpen, payload: open }),
      setOwnerIds: (ownerIds: string[]) => dispatch({ type: Actions.SetOwnerIds, payload: ownerIds }),
      setLocalOwnerIds: (ownerIds: string[]) => dispatch({ type: Actions.SetLocalOwnerIds, payload: ownerIds }),
      setProjectIds: (projectIds: number[]) => dispatch({ type: Actions.SetProjectIds, payload: projectIds }),
      setLocalProjectIds: (projectIds: number[]) => dispatch({ type: Actions.SetLocalProjectIds, payload: projectIds }),
      setFulfillmentStatus: (status: FulfillmentStatus[]) => dispatch({ type: Actions.SetFulfillmentStatus, payload: status }),
      setLocalFulfillmentStatus: (status: FulfillmentStatus[]) => dispatch({ type: Actions.SetLocalFulfillmentStatus, payload: status }),
      setState: (state: Partial<PFADashboardContextState>) => dispatch({ type: Actions.SetState, payload: state }),
      resetState: () => dispatch({ type: Actions.ResetState }),
    }),
    [],
  );

  const memoizedValue = useMemo<PFADashboardContextProps>(
    () => ({
      ...state,
      dateRangeSettings: {
        ...dateRangeSettings,
        dateRange: {
          ...dateRangeSettings.dateRange,
          startDate: startOfDay(dateRangeSettings.dateRange?.startDate || subYears(new Date(), 10)),
          endDate: endOfDay(dateRangeSettings.dateRange?.endDate || new Date()),
        },
      },
      ...actions,
    }),
    [actions, dateRangeSettings, state],
  );

  return <PFADashboardContext.Provider value={memoizedValue}>{children}</PFADashboardContext.Provider>;
};

export const usePFADashboardContext = () => useContext(PFADashboardContext);
