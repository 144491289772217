import { GetOfferById_offer } from '@affiliates/queries/types/GetOfferById';
import { OFFER_STATUS } from '@affiliates/types/globalTypes';
import { isNull } from 'lodash';
import { OfferMemberStatus } from '../types';

export function composeLinkStatus(offer: GetOfferById_offer, status: OfferMemberStatus, deletedDate: Date | null) {
  if (status === OfferMemberStatus.PENDING) return OfferMemberStatus.PENDING;
  if (!isNull(offer.archivedDate)) {
    return OfferMemberStatus.DEACTIVATED;
  }
  if (offer?.links[0]?.status === OFFER_STATUS.PAUSED) {
    return OfferMemberStatus.PAUSED;
  }
  if (status === OfferMemberStatus.PAUSED && !isNull(deletedDate)) return OfferMemberStatus.DELETED;
  if (status === OfferMemberStatus.PAUSED) {
    return OfferMemberStatus.DEACTIVATED;
  }
  if (offer.expired) {
    return OfferMemberStatus.EXPIRED;
  }
  return status;
}
