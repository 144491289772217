import * as React from 'react';
import { Typography } from '@revfluence/fresh';

import { IOfferDetailsProps } from '@affiliates/components/OfferSummaryCard/types';
import { OfferSummarySection } from './OfferSummarySection';

import styles from './OfferConfirmation.scss';

const { Paragraph, Link } = Typography;

interface IProps {
  onRemoveOffer: () => void;
  offerDetails?: IOfferDetailsProps;
  canMigrateProgram?: boolean;
  onChangeOffer: () => void;
}

export const OfferPromoConfirmation = (props: IProps) => {
  const {
    onRemoveOffer, onChangeOffer, canMigrateProgram, offerDetails,
  } = props;
  return (
    <div className={styles.OfferConfirmation}>
      <div className={styles.testingSection}>
        <div className={styles.pixelCodeSection}>
          <h3>Congrats on setting up your Promo Code Offer.</h3>
          <Paragraph>
            You are now ready to start generating unique promo codes for your members. You also have the ability to deactivate and provide new codes to influencers if any code becomes compromised.
            {' '}
            Performance metrics and creator payouts can be done through the
            {' '}
            <Link
              href="https://intercom.help/aspireiq_elevate/en/articles/5083095-overview-of-sales-tracking-dashboard"
              rel="noopener noreferrer"
              target="_blank"
            >
              Sales Tracking dashboard
            </Link>
            .
          </Paragraph>
        </div>
        <div className={`${styles.summaryCard} w-1/2`}>
          <OfferSummarySection
            offerDetails={offerDetails}
            onChangeOffer={onChangeOffer}
            onRemoveOffer={onRemoveOffer}
            canMigrateProgram={canMigrateProgram}
          />
        </div>
      </div>
    </div>
  );
};
