import * as React from 'react';
import { Checkbox, Form } from '@revfluence/fresh';
import styles from '../../../OfferForm.scss';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'codeSuffix'> {
    onAddSuffixChange: (checked: boolean) => void;
    checked: boolean;
}
export const AddSuffix: React.FC<Readonly<IProps>> = React.memo(({
    // disabled,
    onAddSuffixChange,
    checked,
}) => {
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        onAddSuffixChange(checked);
    };
    return (
      <>
        <Form.Item className={styles.customRadioGroup}>
          <Checkbox onChange={handleCheckboxChange} checked={checked}>
            Add Suffix
          </Checkbox>
        </Form.Item>
      </>
    );
});
AddSuffix.displayName = 'AddSuffix';
