import * as React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';

import {
  Form,
  Select,
  Spinner,
  Typography,
} from '@revfluence/fresh';
import { IShopifyFormElementProps, ProductCollections } from '@affiliates/components';
import styles from '../../../OfferForm.scss';

const { Option } = Select;
const { Text } = Typography;
interface IProps extends IShopifyFormElementProps<'productCollections'> {
  collections: ProductCollections[] | null;
  value: number[] | null;
  disabled?: boolean;
}

export const OfferCodeCollectionOptions: React.FC<Readonly<IProps>> = React.memo(({
  collections,
  name,
  value,
  disabled = false,
}) => {
  const placeholder = <div>Select Collection(s)</div>;

  return (
    <>
      <div className={styles.customLabelText}>
        <Text strong>Applies To</Text>
      </div>
      <Form.Item
        className={cx({
          [styles.halfRow]: !isNull(value),
        })}
        label="Allow Redemption Only on These Product Collections"
        name={name}
      >
        <Select
          placeholder={placeholder}
          size="large"
          mode="multiple"
          notFoundContent={(collections === null) ? <Spinner size="small" /> : null}
          allowClear
          style={{ width: '100%' }}
          disabled={disabled}
        >
          {collections?.map((collection) => (
            <Option key={collection.id} value={collection.id}>
              {collection.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
});

OfferCodeCollectionOptions.displayName = 'OfferCodeCollectionOptions';
