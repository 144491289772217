import React from 'react';
import { cn } from '@/shadcn/lib/utils';

// Define an interface for paragraph props
interface PProps extends React.ComponentPropsWithoutRef<'p'> {
  fontWeight?: 'font-normal' | 'font-medium' | 'font-semibold' | 'font-bold';
}

export const P = React.forwardRef<HTMLParagraphElement, PProps>(
  ({
    className, fontWeight, ...props
  }, ref) => {
    // Default classes for paragraph
    const defaultClasses = 'text-sm leading-normal mb-0';

    // Determine the appropriate font weight class, default to 'font-regular'
    const fontWeightClass = fontWeight || 'font-regular';

    return (
      <p
        ref={ref}
        className={cn(defaultClasses, fontWeightClass, className)} // Combine default classes, font weight, and any additional class names
        {...props}
      />
    );
  },
);

P.displayName = 'P';
