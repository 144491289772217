import React from 'react';
import {
  ChartBarIcon,
  CartShoppingIcon,
  SignalStreamIcon,
  ImageIcon,
  CreditCardIcon,
  GiftIcon,
  FileIcon,
  CommentDollarIcon,
  SackDollarIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { SecondaryNavProps } from '../../Layout';
import {
  MenuHeader,
  MenuHeaderLayout,
  MenuIconWrapper,
  MenuItemContent,
  MenuLayout,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuTitle,
} from '../menuLayout';
import {
  CONTENT_APP_ID,
  FULFILLMENT_APP_ID,
  PAYMENT_APP_ID,
  SALES_APP_ID,
  SOCIAL_POST_APP_ID,
  CONTRACT_APP_ID,
} from '@/app/constants/applicationIds';

const menuConfig = (isBudgetAllocationEnabled: boolean, isSocialAdsAnalyticsEnabled: boolean) => [
  {
    section: 'Reporting',
    items: [
      { title: 'Impact Dashboard', icon: ChartBarIcon, to: '/impact_dashboard' },
      { title: 'Sales Tracking', icon: CartShoppingIcon, to: `/app/${SALES_APP_ID}` },
      { title: 'Social Analytics', icon: SignalStreamIcon, to: `/app/${SOCIAL_POST_APP_ID}` },
      isSocialAdsAnalyticsEnabled && { title: 'Ads Analytics', icon: SignalStreamIcon, to: '/social' },
      { title: 'Content', icon: ImageIcon, to: `/app/${CONTENT_APP_ID}` },
    ].filter(Boolean),
  },
  {
    section: 'Finance',
    items: [
      { title: 'Payments', icon: CreditCardIcon, to: `/app/${PAYMENT_APP_ID}` },
      isBudgetAllocationEnabled && {
        title: 'Budget Dashboard',
        icon: SackDollarIcon,
        to: '/budget/dashboard',
      },
    ].filter(Boolean),
  },
  {
    section: 'Partnerships',
    items: [
      { title: 'Product Fulfillment', icon: GiftIcon, to: `/app/${FULFILLMENT_APP_ID}` },
      { title: 'Contracts', icon: FileIcon, to: `/app/${CONTRACT_APP_ID}` },
      { title: 'Ads Permissions', icon: CommentDollarIcon, to: '/influencer_whitelist' },
    ],
  },
];

export const SecondaryReportingNav = ({
  onItemSelected,
  isBudgetAllocationEnabled,
  isSocialAdsAnalyticsEnabled,
}: SecondaryNavProps) => (
  <MenuLayout>
    {menuConfig(isBudgetAllocationEnabled, isSocialAdsAnalyticsEnabled).map((section, index) => (
      <div key={index}>
        <MenuHeaderLayout>
          <MenuHeader>{section.section}</MenuHeader>
        </MenuHeaderLayout>
        <MenuList>
          {section.items.map((item, idx) => (
            <MenuListItem key={idx}>
              <MenuLink onClick={onItemSelected} to={item.to}>
                <MenuIconWrapper>
                  <item.icon className="h-4 w-4 m-auto" />
                </MenuIconWrapper>
                <MenuItemContent>
                  <MenuTitle>{item.title}</MenuTitle>
                </MenuItemContent>
              </MenuLink>
            </MenuListItem>
          ))}
        </MenuList>
      </div>
    ))}
  </MenuLayout>
);
