import {
  get,
  isUndefined,
  keyBy,
  groupBy,
  mapValues,
  sortBy,
  isNull,
  includes,
} from 'lodash';
import * as React from 'react';

import {
  TCondition,
  TProject,
  TTask,
  TWorklet,
} from '@frontend/app/containers/Projects/types';

import {
  useGetAllTasksQuery,
  useGetAllPresetConditionsQuery,
  useGetAllProjectsQuery,
  useGetAllContentApprovalManagerProjectsQuery,
  useGetCountsForProjectQuery,
  useGetAllWorkletsForParentSpecQuery,
  useGetAllWorkletsQuery,
  useGetCurrentClient,
  useGetProfile,
} from '@frontend/app/hooks';
import { useProjectContext } from '@frontend/app/context';
import { Task } from '../constants';

const {
  useCallback,
  useEffect,
  useMemo,
  useState,
} = React;

interface IOptions {
  defaultProjectId?: TProject['id'];
  defaultWorkletSpecUri?: TWorklet['specURI'];
  defaultTaskId?: TTask['taskId'];
  fetchWorkItems?: boolean;
}

export interface ITaskCounts {
  total: number;
  unViewed: number;
}

export interface ICounts {
  [key: string]: { [taskId: string]: ITaskCounts | number } | number;
}

export type TGetTaskBySpecUriAndId = (workletSpecUri: TTask['workletSpecUri'], taskId: TTask['taskId']) => TTask;

export interface IProjectsApp {
  counts: ICounts;
  getProjectById: (projectId: TProject['id']) => TProject;
  getTaskBySpecUriAndId: TGetTaskBySpecUriAndId;
  isLoading: boolean;
  presetConditions: TCondition[];
  project: TProject;
  projects: TProject[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  refetchCounts: () => Promise<any>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  refetchData: () => Promise<any>;
  setProjectId: React.Dispatch<React.SetStateAction<TProject['id']>>;
  setWorkletSpecUri: React.Dispatch<React.SetStateAction<TTask['workletSpecUri']>>;
  setTaskBySpecUriAndId: (workletSpecUri: TTask['workletSpecUri'], taskId: TTask['taskId']) => void;
  task: TTask;
  tasks: TTask[];
  worklets: TWorklet[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  refetchWorklets: () => Promise<any>;
  getTasksNeedAttention: TTask[];
}

/**
 * Container hook for the Projects App; contains:
 * - Projects
 * - Tasks
 * - WorkItems
 * - Counts
 */
export const useProjectsApp = (options?: IOptions): IProjectsApp => {
  const {
    fetchWorkItems = false,
    defaultProjectId,
    defaultWorkletSpecUri,
    defaultTaskId,
  } = options || {};
  const { setProject: setGlobalProject } = useProjectContext();
  const { client } = useGetCurrentClient();
  const { profile } = useGetProfile();

  // Loading triggered by refetch
  const [isRefetching, setRefetching] = useState(false);

  const isGcrUser = useMemo(() => {
    const clientId = client.id;
    const {
      auth0User: {
        appMetadata: {
          clients,
        },
      },
    } = profile;
    return includes(['manager:content_approver', 'manager:content_viewer'], clients[clientId]?.roles[0]);
  }, [client, profile]);

  const {
    data: {
      projects: projectsData = undefined,
    } = {},
    loading: isProjectsLoading,
    refetch: refetchProjects,
  } = useGetAllProjectsQuery({
    skip: isGcrUser,
  });

  const {
    data: { projects: contentApprovalManagerProjects } = {},
    loading: contentApprovalManagerProjectsLoading,
  } = useGetAllContentApprovalManagerProjectsQuery({
    variables: {
      archived: false,
    },
    skip: !isGcrUser,
  });

  const projects = useMemo(
    () => (isGcrUser
        ? sortBy(contentApprovalManagerProjects, (project) => project.id)
        : sortBy(projectsData, (project) => project.id)),
    [projectsData, contentApprovalManagerProjects, isGcrUser],
  );

  const projectById = useMemo(
    () => keyBy(projects, (p: TProject) => p.id),
    [projects],
  );
  const getProjectById = useCallback(
    (projectId: TProject['id']): TProject => (
      get(projectById, projectId) as TProject
    ),
    [projectById],
  );
  const [projectId, setProjectIdState] = useState<TProject['id']>(defaultProjectId);
  const setProjectId = useCallback(
    (id: TProject['id']) => setProjectIdState(getProjectById(id)?.id),
    [getProjectById],
  );
  const project: TProject = useMemo(
    () => getProjectById(projectId),
    [getProjectById, projectId],
  );
  const specKey = useMemo(
    () => project?.specKey,
    [project],
  );

  useEffect(() => {
    if (project) {
      setGlobalProject(project);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  /**
   * Stages
   */
  const {
    loading: isUnflexibleWorkletsLoading,
    data: { worklets: unflexibleWorklets } = {},
    refetch: refetchUnflexibleWorklets,
  } = useGetAllWorkletsForParentSpecQuery({
    variables: {
      specKey: project?.specKey,
    },
    skip: !project?.specKey,
  });

  const {
    loading: isFlexibleWorkletsLoading,
    data: { worklets: flexibleWorklets } = {},
    refetch: refetchFlexibleWorklets,
  } = useGetAllWorkletsQuery({
    variables: {
      specKeys: project?.workletSpecKeys,
    },
    skip: !project?.isFlexibleSpec && !project?.workletSpecKeys,
  });

  const isWorkletsLoading = !project || project?.isFlexibleSpec ? isFlexibleWorkletsLoading : isUnflexibleWorkletsLoading;
  const worklets = project?.isFlexibleSpec ? flexibleWorklets : unflexibleWorklets;
  const refetchWorklets = project?.isFlexibleSpec ? refetchFlexibleWorklets : refetchUnflexibleWorklets;

  const workletBySpecUri = useMemo(
    () => keyBy(worklets, (w) => w.specURI),
    [worklets],
  );
  const getWorkletBySpecUri = useCallback(
    (workletSpecUri: TWorklet['specURI']): TWorklet => (
      get(workletBySpecUri, workletSpecUri)
    ),
    [workletBySpecUri],
  );
  const [workletSpecUri, setWorkletSpecUriState] = useState<TWorklet['specURI']>(defaultWorkletSpecUri);
  const setWorkletSpecUri = useCallback(
    (specUri: TWorklet['specURI']) => setWorkletSpecUriState(getWorkletBySpecUri(specUri)?.specURI),
    [getWorkletBySpecUri],
  );

  /**
   * Tasks
   */
  const {
    data: {
      tasks = undefined,
    } = {},
    loading: isTasksLoading,
    refetch: refetchTasks,
  } = useGetAllTasksQuery({
    variables: {
      programId: project?.id,
    },
    skip: !project?.id,
  });
  const taskBySpecUriAndId = useMemo(
    () => mapValues(
      groupBy(tasks, 'workletSpecUri'),
      (tasksBySpec) => mapValues(
        keyBy(tasksBySpec, 'taskId'),
      ),
    ),
    [tasks],
  );
  const getTaskBySpecUriAndId = useCallback(
    (specUri: TTask['workletSpecUri'], taskId: TTask['taskId']): TTask => {
      if ([Task.NeedsAction, Task.Done, Task.All].some((t) => taskId?.includes(t))) {
        return {
          taskId,
          workletSpecUri: specUri,
          taskMetaData: {},
        } as TTask;
      }
      return taskBySpecUriAndId?.[specUri]?.[taskId];
    },
    [taskBySpecUriAndId],
  );

  const getTasksNeedAttention: TTask[] = useMemo(
    () => (tasks || []).filter((task) => isNull(task.customServiceId)),
    [tasks],
  );

  const [taskId, setTaskIdState] = useState<TTask['taskId']>(defaultTaskId);
  const setTaskBySpecUriAndId = useCallback(
    (specUri: TTask['workletSpecUri'], taskId: TTask['taskId']) => {
      if ([Task.NeedsAction, Task.Done, Task.All].some((t) => taskId?.includes(t))) {
        setTaskIdState(taskId);
        return;
      }
      setTaskIdState(getTaskBySpecUriAndId(specUri, taskId)?.taskId);
    },
    [getTaskBySpecUriAndId],
  );
  const task: TTask = useMemo(
    () => getTaskBySpecUriAndId(workletSpecUri, taskId),
    [getTaskBySpecUriAndId, workletSpecUri, taskId],
  );

  /**
   * Counts
   */
  const {
    data: {
      counts = undefined,
    } = {},
    loading: isCountsLoading,
    refetch: refetchAllCounts,
  } = useGetCountsForProjectQuery({
    fetchPolicy: 'no-cache',
    variables: { projectId },
    skip: !projectId,
  });

  /**
   * Preset Conditions (used for "move to X stage" operation)
   */
  const {
    data: {
      presetConditions = undefined,
    } = {},
    loading: isPresetConditionsLoading,
    refetch: refetchPresetConditions,
  } = useGetAllPresetConditionsQuery({
    variables: { specKey },
    skip: !specKey,
  });

  /**
   * Loading?
   */
  const isLoading = (
    isUndefined(projects)
    || (projectId && isUndefined(project))
    || (fetchWorkItems && project?.specKey && isUndefined(tasks))
    || isProjectsLoading
    || isTasksLoading
    || isCountsLoading
    || isPresetConditionsLoading
    || isWorkletsLoading
    || isRefetching
    || contentApprovalManagerProjectsLoading
  );

  /**
   * Refetch
   */
  const refetchCounts = async () => {
    if (projectId) {
      await refetchAllCounts({ projectId });
    }
  };

  const refetchData = async () => {
    setRefetching(true);
    await refetchProjects();
    if (specKey) {
      await refetchPresetConditions({ specKey });
    }
    if (projectId) {
      await refetchTasks({ programId: projectId });
      await refetchAllCounts({ projectId });
    }
    setRefetching(false);
  };

  useEffect(
    () => {
      (async (specKey) => {
        setRefetching(true);
        if (specKey) {
          await refetchPresetConditions({ specKey });
        }
        if (projectId) {
          await refetchTasks({ programId: projectId });
        }
        setRefetching(false);
      })(specKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specKey],
  );

  useEffect(
    () => {
      (async (projectId) => {
        if (projectId) {
          await refetchAllCounts({ projectId });
        }
      })(projectId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  return {
    counts,
    getProjectById,
    getTaskBySpecUriAndId,
    isLoading,
    presetConditions,
    project,
    projects: projects as TProject[],
    refetchCounts,
    refetchData,
    setProjectId,
    setWorkletSpecUri,
    setTaskBySpecUriAndId,
    task,
    tasks,
    worklets,
    refetchWorklets,
    getTasksNeedAttention,
  };
};
