import * as React from 'react';

import { IAffiliateLinksFormValues } from '@affiliates/components';
import {
 OFFER_CONVERSION_TYPE, OFFER_PAYOUT_TYPE, OFFER_SOURCE,
} from '@affiliates/types/globalTypes';
import { OfferFormContainer } from './OfferFormContainer';
import { IBaseProps, IChildArgs } from './types';

interface IProps extends IBaseProps<IAffiliateLinksFormValues> {
}

export const NewAffiliateOfferContainer: React.FC<Readonly<IProps>> = (props) => {
  const initialValues: IChildArgs<IAffiliateLinksFormValues>['initialValues'] = {
    conversionTrackingType: null,
    conversionType: OFFER_CONVERSION_TYPE.CONVERSIONS,
    payoutOptions: [{
      label: '',
      isDefault: true,
      payoutType: OFFER_PAYOUT_TYPE.CONVERSION,
      isMemberAdded: false,
    }],
    description: '',
    expirationDate: null,
    flatPayout: null,
    imageUrl: null,
    name: '',
    isAdvanceUrlEnable: false,
    payoutType: OFFER_PAYOUT_TYPE.CONVERSION,
    percentPayout: null,
    source: OFFER_SOURCE.TUNE,
    status: null,
    url: '',
    utmSource: '',
    multipleDomain: [],
    creatorDeeplink: true,
    allowMultipleConversions: false,
    thirdPartyTracking: null,
    isThirdPartyIntegration: false,
  };

  const offerDetails: IChildArgs<IAffiliateLinksFormValues>['offerDetails'] = {
    hasMembers: false,
    id: null,
    imageUrl: null,
    isDeleted: false,
    isExpired: false,
    linkedId: null,
  };

  return (
    <OfferFormContainer
      initialValues={initialValues}
      offerDetails={offerDetails}
      {...props}
    />
  );
};
