import React from 'react';
import { cn } from '@/shadcn/lib/utils';

// Define an interface for H1 props
interface H1Props extends React.ComponentPropsWithoutRef<'h1'> {
  fontWeight?: 'font-normal' | 'font-medium' | 'font-semibold' | 'font-bold'; // Optional font weight prop
}

export const H1 = React.forwardRef<HTMLHeadingElement, H1Props>(
  ({
    className, fontWeight = 'font-normal', children, ...props
  }, ref) => {
    // Default H1 classes
    const defaultClasses = 'scroll-m-20 text-xl leading-xxl-loose tracking-tight';

    return (
      <h1
        ref={ref}
        className={cn(defaultClasses, fontWeight, className)} // Combine default classes, font weight, and any additional class names
        {...props}
      >
        {children}
      </h1>
    );
  },
);

H1.displayName = 'H1';
