import * as React from 'react';

import { Form, Input } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'groupName'> {
}

export const OfferCodeGroupName: React.FC<Readonly<IProps>> = React.memo(({
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your promo code group name!' },
  ];
  return (
    <Form.Item label="Promo Code Group Name" name={name} rules={rules}>
      <Input
        placeholder="e.g. Ambassador Promo Codes"
        size="large"
      />
    </Form.Item>
  );
});
