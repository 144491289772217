import { MutationHookOptions, useMutation } from '@apollo/client';
import { DELETE_IMPORTED_COLLECTION_BY_ID_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { DeleteImportedCollectionById, DeleteImportedCollectionByIdVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/DeleteImportedCollectionById';

type IOptions = MutationHookOptions<DeleteImportedCollectionById, DeleteImportedCollectionByIdVariables>;

export const useDeleteImportedCollectionById = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<DeleteImportedCollectionById, DeleteImportedCollectionByIdVariables>(
    DELETE_IMPORTED_COLLECTION_BY_ID_MUTATION,
    options,
  );

  return {
    deleteImportedCollectionById: mutate,
    isDeletingImportedCollectionById: loading,
  };
};
