import * as React from 'react';
import { Notice } from '@affiliates/AspireUI';
import { Button } from '@frontend/shadcn/components/ui/button';
import { EmailComposerModal } from '@frontend/app/components/MessageComposer/EmailComposerModal';
import { ApolloProvider } from '@apollo/client';
import { useAppContext } from '@frontend/context/AppContext';
import { isOfferShopify } from '../MembersWizard/utils';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { PendingAction } from './PendingMembers';
import { TMemberTableRow } from './types';

interface ISuccessNoticeProps {
  onClose?: () => void;
  successNoticeCount: number;
  successNoticeAction: PendingAction | null;
  offerSource: OFFER_SOURCE;
  members?: TMemberTableRow[];
  subject?: string;
  initialMessage?: string;
  source?: string;
  onMessageSent?: () => void;
  setIsDeletedAction?: (isDeletedAction: boolean) => void;
  isDeletedAction: boolean;
}

export interface PendingOperation {
  count: number;
  action: PendingAction;
}

export const SuccessNotice: React.FC<ISuccessNoticeProps> = ({
  onClose,
  successNoticeAction,
  successNoticeCount,
  offerSource,
  members,
  subject,
  initialMessage,
  source,
  onMessageSent,
  setIsDeletedAction,
  isDeletedAction,
}) => {
  const [showEmailCompose, setShowEmailCompose] = React.useState(false);
  const { apolloClient } = useAppContext();
  if (successNoticeAction === PendingAction.Deleting && isDeletedAction) {
    setIsDeletedAction(false);
  }
  const getSuccessMessage = (count: number, action: PendingAction, offerSource: OFFER_SOURCE): string => {
    const itemType = isOfferShopify(offerSource) ? 'Code' : 'Link';
    const itemTypes = isOfferShopify(offerSource) ? 'Codes' : 'Links';

    switch (action) {
      case PendingAction.Generating:
        return `Successfully Generated ${count} ${count === 1 ? itemType : itemTypes}`;
      case PendingAction.Deactivating:
        return `Successfully Deactivated ${count} ${count === 1 ? itemType : itemTypes} from the offer`;
      case PendingAction.Refreshing:
        return `Successfully Refreshed ${count} ${count === 1 ? itemType : itemTypes}`;
      case PendingAction.Deleting:
        return `Successfully Removed ${count} ${count === 1 ? 'member' : 'members'} from the offer`;
      case PendingAction.Mixed:
        return `Successfully Processed ${count} ${count === 1 ? 'member' : 'members'} from the offer`;
      default:
        return `Successfully Completed operation for ${count} ${count === 1 ? 'member' : 'members'}`;
    }
  };

  const handleNotify = React.useCallback(() => {
    setShowEmailCompose(true);
  }, []);

  const handleEmailClose = React.useCallback(() => {
    setShowEmailCompose(false);
  }, []);

  // Auto-dismiss success notice after 20 seconds only if EmailComposerModal is not open
  React.useEffect(() => {
    if (!showEmailCompose) {
      const timer = setTimeout(() => {
        if (onClose) onClose();
      }, 20000);

      return () => clearTimeout(timer);
    }
  }, [onClose, showEmailCompose]);

  return (
    <>
      <Notice
        type="success"
        message={(
          <div className="flex w-full items-center justify-between">
            {getSuccessMessage(successNoticeCount, successNoticeAction, offerSource)}
            {successNoticeAction === PendingAction.Deleting
              && members.length > 0
              && members.some((member) => member.email) && (
                <Button onClick={handleNotify} size="sm" variant="outline">
                  Notify Members
                </Button>
              )}
          </div>
        )}
        onClose={onClose}
      />
      <ApolloProvider client={apolloClient}>
        <EmailComposerModal
          isOpen={showEmailCompose}
          onClose={handleEmailClose}
          // @ts-ignore
          members={members}
          subject={subject}
          initialMessage={initialMessage}
          source={source}
          onMessageSent={onMessageSent}
          refreshUi
          hideButton
        />
      </ApolloProvider>
    </>
  );
};
