import {
  Drawer, Row, Typography,
} from '@revfluence/fresh';
import React, { useEffect, useState } from 'react';
import { LoadSpinner } from '@components';
import { useGetOfferAffiliateConversionHistoryById } from '../../hooks/useGetOfferAffiliateConversionHistoryById';
import { GetOfferAffiliateConversionHistoryById_offerAffiliateConversionHistory } from '../../queries/types/GetOfferAffiliateConversionHistoryById';
import { OfferDetailCard } from '../AffiliatesApp/components/OfferDetailCard';
import styles from './OfferConversionHistoryDrawer.scss';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { OfferConversionHistoryDrawerTable } from './OfferConversionHistoryDrawerTable';

interface IProps {
  offerId: number,
  onClose: () => void,
  open: boolean,
  source: OFFER_SOURCE,
}
const { Title } = Typography;
export const OfferConversionHistoryDrawer: React.FC<IProps> = (props) => {
  const {
    onClose, open, offerId, source,
  } = props;
  const [offerConversionHistory, setOfferConversionHistory] = useState<GetOfferAffiliateConversionHistoryById_offerAffiliateConversionHistory[]>([]);
  const offerConversionHistoryQuery = useGetOfferAffiliateConversionHistoryById({
    variables: {
      offerId: Number(offerId),
    },
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (!offerConversionHistoryQuery.loading && offerConversionHistoryQuery.data) {
      setOfferConversionHistory(offerConversionHistoryQuery.data.offerAffiliateConversionHistory || []);
    }
  }, [offerConversionHistoryQuery.loading, offerConversionHistoryQuery.data]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      size="large"
      width="calc(100% - 200px)"
      title={(
        <Title className={styles.drawerHeader} level={4}>Offer Conversions Report</Title>
      )}
    >
      <Row justify="space-between" align="middle">
        <OfferDetailCard showCta={false} infoButtonClassName />
      </Row>
      {offerConversionHistoryQuery.loading ? (
        <LoadSpinner />
      ) : (
        <OfferConversionHistoryDrawerTable offerConversionHistory={offerConversionHistory} source={source} />
      )}
    </Drawer>
  );
};
