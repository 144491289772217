import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_BACKEND_SERVER_TOKEN } from '@frontend/app/queries';

import {
  GetBackendServerToken,
} from '@frontend/app/queries/types/GetBackendServerToken';

type IOptions = QueryHookOptions<GetBackendServerToken>;

export const useGetBackendServerToken = (options: IOptions = {}) => (
    useQuery<GetBackendServerToken>(GET_BACKEND_SERVER_TOKEN, options)
);
