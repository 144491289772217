import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

interface IUseGetData<T> {
  data: T;
  error: Error | null;
  loading: boolean;
}

export function useGetData<T>(url: string, skipFetch?: boolean): IUseGetData<T> {
  const { isLoading: loading, error, data } = useBackendServerQuery<T>({
    fetchParams: { input: url },
    useQueryParams: { options: { enabled: !skipFetch } },
  });
  return {
    loading,
    data,
    error,
  };
}
