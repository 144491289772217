import * as React from 'react';
import { Drawer } from 'antd';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';

import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import { BulkPaymentsApp, IBulkPaymentContainerProps, APPLICATION_STRING } from '@frontend/applications/PaymentsApp/BulkPaymentsApp/containers';
import { useAppContext } from '@frontend/context/AppContext';

import { IDateRangeVariables } from '@frontend/app/hooks';
import styles from './STABulkPayments.scss';

interface ISTABulkPaymentsProps extends Omit<IBulkPaymentContainerProps, 'onCancel' | 'onDone' | 'application'> {
  visible: boolean;
  onClose: () => void;
  paymentPeriod?: string;
  dateFilterRange: IDateRangeVariables;
}

export const STABulkPayments: React.FC<Readonly<ISTABulkPaymentsProps>> = (props) => {
  const { apolloClient } = useAppContext();

  if (!props.visible) {
    return null;
  }
  return (
    <Drawer
      visible={props.visible}
      className={styles.STABulkPayments}
      onClose={props.onClose}
      maskClosable={false}
      width="80%"
    >
      <ApplicationProvider
        backendServerApiEndpoint={backendServerApiEndpoint()}
        analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
        backendServerWebEndpoint={backendServerWebEndpoint()}
        clientId={props.clientId}
        clientName={props.clientName}
      >
        {apolloClient
          && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          <ApolloHooksProvider client={apolloClient}>
            <BulkPaymentsApp
              key={`${props.visible}`} // Forces component to re-render when visible state changes (used to reset payment flow)
              application={APPLICATION_STRING.SALES_TRACKING}
              clientId={props.clientId}
              clientName={props.clientName}
              onCancel={props.onClose}
              onDone={props.onClose}
              showAmountDue
              newPayments={props.newPayments}
              paymentGroupName={props.paymentGroupName}
              paymentGroupMetadata={props.paymentGroupMetadata}
              onPaymentGroupMade={props.onPaymentGroupMade}
              onPaymentCompleted={props.onPaymentCompleted}
              paymentPeriod={props.paymentPeriod}
              dateFilterRange={props.dateFilterRange}
            />
          </ApolloHooksProvider>
)}
      </ApplicationProvider>
    </Drawer>
  );
};
