import { AppendMembersVariables } from '@affiliates/queries/types/AppendMembers';
import { RemoveMembersVariables } from '@affiliates/queries/types/RemoveMembers';
import { ForceCheckInLinksVariables } from '@affiliates/queries/types/ForceCheckInLinks';
import { ForceCheckInPromosVariables } from '@affiliates/queries/types/ForceCheckInPromos';
import {
  OFFER_PAYOUT_TYPE,
  OFFER_PRICE_RULE_TYPE,
  OFFER_PROMO_CODE_STATUS,
  OFFER_PROMO_PREFIX_TYPE,
  OFFER_SOURCE,
} from '@affiliates/types/globalTypes';
import { IWorkItem } from '@services/workflow';
import { GetOfferById_offer, GetOfferById_offer_links } from '../../queries/types/GetOfferById';
import { IPayoutVariant } from '../OfferForm';
import { UpdatePayoutMemberPromoVariables } from '../../queries/types/UpdatePayoutMemberPromo';
import { UpdatePayoutMemberLinkVariables } from '../../queries/types/UpdatePayoutMemberLink';

export type TMember = Readonly<{
  affiliateOfferId: number;
  affiliateId?: number;
  firstName: string;
  id: number;
  inOffer: boolean;
  forceCheckIn: boolean;
  instagramUsername: string | null;
  lastName: string;
  previousCode: {
    code: string;
    end: Date;
    start: Date;
  };
  programIds: readonly number[];
  name: string;
  payoutId: number;
  deepLink?: { link: string; error: string | null; warning: string | null; focused: boolean };
  newCode?: string;
  startDate?: Date;
  endDate?: Date;
  promoCodeStatus?: OFFER_PROMO_CODE_STATUS;
  status?: string;
  additionalDeeplinks?: { label: string; url: string, id: number }[];
}>;
export type TSelectedMember = Readonly<TMember & {
  code: string;
  isDuplicate: boolean;
  selected: boolean;
  selectedPrograms: string;
}>;

export type TProgram = Readonly<{
  id: number;
  memberCount: number;
  memberCountInOffer: number;
  memberCountWithCodes: number;
  memberCountWithLinks: number;
  name: string;
}>;

export type TMode = 'add' | 'deactivate' | 'refresh' | 'refreshDates' | 'editPayout' | 'deepLink';
export type ForceCheckInVariables = ForceCheckInLinksVariables | ForceCheckInPromosVariables;

export type TFetchPrograms = () => Promise<readonly TProgram[]>;
export type TFetchMembers = (programIds: readonly number[], page: number, limit: number, searchByName?: string, isNewFlow?: boolean) => Promise<readonly TMember[]>;
export type TOnSave<Mode extends TMode, ForceCheckIn extends ForceCheckInVariables> = (
  variables: (Mode extends 'deactivate' ? RemoveMembersVariables : Mode extends 'editPayout' ? UpdatePayoutMemberPromoVariables | UpdatePayoutMemberLinkVariables : AppendMembersVariables),
  programIds: readonly string[],
  forceCheckInVariables?: ForceCheckIn,
) => Promise<void>;

interface IBaseProps<Mode extends TMode, ForceCheckIn extends ForceCheckInVariables> {
  onClose: () => void;
  offerId: number;
  offerName: string;
  onSave: TOnSave<Mode, ForceCheckIn>;
  visible: boolean;
  isNewFlow?: boolean
}

export interface IAddMembersBase extends IBaseProps<'add', ForceCheckInVariables> {
  fetchMembers: TFetchMembers;
  fetchPrograms: TFetchPrograms;
  offerSource: OFFER_SOURCE;
  isWorkflow: boolean;
  workItems?: IWorkItem[];
  payouts?: IPayoutVariant[] | null;
  defaultPayoutId?: number | null;
  migrateToGraphQL?: boolean;
  fetchMembersCount?: (programIds: number[], searchByName?: string) => Promise<number>;
  isUngrouped?: boolean;
  domains?: string[];
  isCreatorDeepLinkAllowed?: boolean;
  isProjects?: boolean;
}

export interface IAddPromoCodeMembers extends IAddMembersBase {
  offerSource: OFFER_SOURCE.SHOPIFY;
  codeGenerationStrategy: OFFER_PROMO_PREFIX_TYPE;
  discountAmount: number;
  discountType: OFFER_PRICE_RULE_TYPE;
  promoCodeSuffix: string;
  promoCodePrefix?: string;
  payoutType?: string;
  startDate?: string;
  endDate?: string;
  flatPayout?: number;
  percentPayout?: number;
}

export interface IAddTrackingLinksMembers extends IAddMembersBase {
  offerSource: OFFER_SOURCE.TUNE;
  payoutType?: OFFER_PAYOUT_TYPE;
  flatPayout?: GetOfferById_offer_links['flatPayout'];
  percentPayout?: GetOfferById_offer_links['percentPayout'];
  expirationDate?: GetOfferById_offer['expirationDate'];
  offerLink?: string;
}

export type TAddMembersProps = Readonly<
  | IAddPromoCodeMembers
  | IAddTrackingLinksMembers
>;

export interface IRefreshMembersBase extends IBaseProps<'refresh', ForceCheckInPromosVariables> {
  offerSource: OFFER_SOURCE;
  members: readonly TMember[];
  refreshDatesOnly: boolean;
  isWorkflow: boolean;
  workItems?: IWorkItem[];
  payoutType?: string;
  startDate?: string;
  endDate?: string;
  flatPayout?: number;
  percentPayout?: number;
  offerStartDate?: string;
  offerEndDate?: string;
  failedPromoCodeErrors?: OFFER_PROMO_CODE_STATUS[];
  isProjects?: boolean;
  migrateToGraphQL?: boolean;
}
export interface IRefreshPromoCodeMembers extends IRefreshMembersBase {
  offerSource: OFFER_SOURCE.SHOPIFY;
  discountAmount: number;
  discountType: OFFER_PRICE_RULE_TYPE;
}

export interface IRefreshTrackingLinksMembers extends IRefreshMembersBase {
  offerSource: OFFER_SOURCE.TUNE;
  offer: GetOfferById_offer;
  payoutType?: OFFER_PAYOUT_TYPE;
  flatPayout?: GetOfferById_offer_links['flatPayout'];
  percentPayout?: GetOfferById_offer_links['percentPayout'];
  expirationDate?: GetOfferById_offer['expirationDate'];
}

export type TRefreshMembersProps = Readonly<
  | IRefreshPromoCodeMembers
  | IRefreshTrackingLinksMembers
>;
export interface IDeactivateMembersBase extends IBaseProps<'deactivate', undefined> {
  offerSource: OFFER_SOURCE;
  members: readonly TMember[];
  payoutType?: string;
  startDate?: string;
  endDate?: string;
  flatPayout?: number;
  percentPayout?: number;
  isProjects?: boolean;
  migrateToGraphQL?: boolean;
}

export interface IDeactivatePromoCodeMembers extends IDeactivateMembersBase {
  discountAmount: number;
  discountType: OFFER_PRICE_RULE_TYPE;
  offerSource: OFFER_SOURCE.SHOPIFY;
}

export interface IDeactivateTrackingLinksMembers extends IDeactivateMembersBase {
  offerSource: OFFER_SOURCE.TUNE;
  offer: GetOfferById_offer;
  payoutType?: OFFER_PAYOUT_TYPE;
  flatPayout?: GetOfferById_offer_links['flatPayout'];
  percentPayout?: GetOfferById_offer_links['percentPayout'];
  expirationDate?: GetOfferById_offer['expirationDate'];
}

export type TDeactivateMembersProps = Readonly<
  | IDeactivatePromoCodeMembers
  | IDeactivateTrackingLinksMembers
>;

export type TActiveDates = Readonly<{
  endDate?: string;
  endTime?: string;
  startDate?: string;
  startTime?: string;
}>;
export const ACTIVE_DATE_FORMAT = 'MMM D, YYYY';
export const ACTIVE_TIME_FORMAT = 'hh:mm A';

export interface IAffiliateDeeplinkInfo {
  affiliateDeeplinkId: number;
  memberId: number;
  memberName: string;
  label?: string
}
export interface IAdditionalDeeplinkGroupedMember {
  [label: string]: IAffiliateDeeplinkInfo[]
}
export interface IAdditionalDeeplinkGroupedState{
  [label: string]: {
    newLabel?: string;
    url?: string
    prevLabel?: string; // never changing
  };
}
export interface IManageDeeplinkForm{
  [label: string]: {
    label: string;
    url: string
  };
}
export interface IAdditionalDeeplinkFormData {
  additionalDeeplinkData: {
    affiliateDeeplinkIds: number[];
    newLabel: string;
    prevLabel: string;
    url?: string;
  };
}
