import * as React from 'react';
import { isEmpty } from 'lodash';

import { Image } from 'antd-v5';
import {
 Button, Card, Col, Row, Space, Typography, message, Alert,
} from '@revfluence/fresh';
import {
  ArrowUpRightAndArrowDownLeftFromCenterIcon,
  RotateLeftIcon,
  RotateRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  DownloadIcon,
} from '@revfluence/fresh-icons/regular/esm';

import { Link } from 'react-router-dom';
import { UserAvatar } from '@frontend/app/components';
import { CONTENT_SOURCE } from '@frontend/app/utils';
import { InstagramContent } from '@frontend/app/components/EmbeddedContent/InstagramContent';
import { TikTokEmbed, YoutubeEmbed } from '@frontend/app/components/EmbeddedContent';
import PinterestEmbed from '@frontend/app/components/EmbeddedContent/PinterestEmbed';
import { Carousel } from './components/Carousel';
import { TContentGallery } from '../../types';

import styles from './Gallery.scss';

export const Gallery = ({
  resources,
  caption,
  description,
  fitPreview = 'contain',
  memberData,
  versionSelector,
  isLatestVersion = false,
}: TContentGallery) => {
  const [active, setActive] = React.useState(0);
  const [openPreview, setOpenPreview] = React.useState(false);

  if (isEmpty(resources)) {
    return null;
  }

  const mediaUrl = resources[active]?.src;

  const isEmbeddedContent = resources[active]?.contentSource === CONTENT_SOURCE.INSTAGRAM_EMBED
    || resources[active]?.contentSource === CONTENT_SOURCE.YOUTUBE_EMBED
    || resources[active]?.contentSource === CONTENT_SOURCE.TIKTOK_EMBED
    || resources[active]?.contentSource === CONTENT_SOURCE.PINTEREST_EMBED;

  const shouldShowExpandIcon = !isEmbeddedContent;

  const contentCardHeight = isEmbeddedContent ? 'fitContent' : '475px';

  const onDownload = () => {
    fetch(mediaUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        // Extract the last part of the URL to use as the filename
        const segments = mediaUrl.split('/');
        const filename = segments[segments.length - 1];

        // Use the extracted filename for the download attribute
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((e) => {
        // Assuming message.error is a function to show error messages
        message.error(e.message);
      });
  };
  return (
    <div className={styles.Gallery}>
      <Row gutter={8} align="middle" justify="space-between">
        <Col>
          <Link
            to={{
              pathname: '/creator',
              search: `?creatorId=${memberData?.creatorId}`,
            }}
            target="_blank"
          >
            <Row gutter={8} align="middle">
              <Col>
                <UserAvatar name={memberData?.name} profilePicture={memberData?.creatorProfilePicture} size="default" />
              </Col>
              <Col className={styles.UserTitle}>
                <Typography.Title level={5}>{memberData?.name}</Typography.Title>
              </Col>
            </Row>
          </Link>
        </Col>
        <Col>{versionSelector}</Col>
      </Row>
      <Row
        style={{
          marginTop: '24px',
          marginBottom: '24px',
        }}
      >
        {versionSelector && !isLatestVersion && (
          <Alert
            type="info"
            message="You are viewing a previous version of this content. Info, Comments, and Guidelines will reflect current data."
          />
        )}
      </Row>
      <Card
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          height: contentCardHeight,
          overflow: 'hidden',
        }}
        bodyStyle={{
          padding: 0,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {(shouldShowExpandIcon && resources[active]?.type !== 'video') && (
          <Button
            icon={<ArrowUpRightAndArrowDownLeftFromCenterIcon />}
            size="small"
            onClick={() => setOpenPreview(!openPreview)}
            style={{
              position: 'absolute',
              top: '8px',
              left: '8px',
              zIndex: 1,
            }}
          />
        )}
        {resources[active]?.contentSource === CONTENT_SOURCE.INSTAGRAM_EMBED && <InstagramContent url={mediaUrl} />}
        {resources[active]?.contentSource === CONTENT_SOURCE.YOUTUBE_EMBED && <YoutubeEmbed videoUrl={mediaUrl} />}
        {resources[active]?.contentSource === CONTENT_SOURCE.TIKTOK_EMBED && mediaUrl?.includes('tiktok.com') && (
          <TikTokEmbed url={mediaUrl} />
        )}
        {resources[active]?.contentSource === CONTENT_SOURCE.PINTEREST_EMBED && mediaUrl?.includes('pinterest.com') && (
          <PinterestEmbed url={mediaUrl} />
        )}
        {resources[active]?.type === 'image' && (
          <Image
            height="100%"
            width="100%"
            src={mediaUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: fitPreview,
            }}
            preview={
              openPreview
                ? {
                    visible: true,
                    onVisibleChange: (visible) => setOpenPreview(visible),
                    toolbarRender: (_, {
 actions: {
 onRotateLeft, onRotateRight, onZoomOut, onZoomIn,
},
}) => (
  <Space size={12} className="toolbar-wrapper">
    <DownloadIcon onClick={onDownload} />
    <RotateLeftIcon onClick={onRotateLeft} />
    <RotateRightIcon onClick={onRotateRight} />
    <MagnifyingGlassMinusIcon onClick={onZoomOut} />
    <MagnifyingGlassPlusIcon onClick={onZoomIn} />
  </Space>
                    ),
                  }
                : false
            }
          />
        )}
        {resources[active]?.type === 'video' && (
          /* eslint-disable jsx-a11y/media-has-caption */
          <video src={mediaUrl} controls style={{ width: '100%', height: '100%' }} />
        )}
      </Card>

      {resources.length > 1 && (
        <Carousel
          resources={resources}
          onSelect={(index) => {
            setActive(index);
            setOpenPreview(false);
          }}
        />
      )}

      <Space direction="vertical" size={2} style={{ marginTop: '24px' }}>
        <Typography.Title level={4} style={{ margin: 0, wordBreak: 'break-all' }}>
          {caption}
        </Typography.Title>
        <Typography.Text className={styles.description} style={{ wordBreak: 'break-all' }}>
          {description}
        </Typography.Text>
      </Space>
    </div>
  );
};
