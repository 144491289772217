// @ts-strict-ignore
import * as React from 'react';
import { Col, Row, message } from '@revfluence/fresh';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useCommunityByIdQuery } from '@frontend/app/containers/Communities/AddOrEditCommunity/hooks/useCommunityByIdQuery';
import {
  useGetCurrentClient,
  useGetCurrentClientMetadata,
  useGetMemberQuery,
  useGetProfile,
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import styles from './GroupCreatorDetail.scss';
import ContentHighlight from './ContentHighlight';
import CreatorProfile from './CreatorProfile';
import { formatMember } from '../GcrUtils';
import CreatorTopbar from './CreatorTopbar';
import { fetchSocialDataForMember } from './useFetchSocialDataForMember';
import SocialMetrics from './SocialMetrics';

interface IProps {
  data: string;
}

export const GroupCreatorDetail: React.FC<IProps> = React.memo(() => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const groupId = params.get('groupId');
  const { client } = useGetCurrentClient();
  const [memberRecord, setMemberRecord] = useState(null);
  const [socialProfileData, setSocialProfile] = useState(null);
  const { backendServerFetchResponse } = useBackendServerFetch();
  /**
   * socialProfileData = {
   * instagram: [{data: {}}],
   * tiktok: [{data: {}}]
   * }
   */

  const [brandApprovalError, setBrandApprovalError] = useState<boolean>(false);
  const [brandNoteError, setbrandNoteError] = useState<boolean>(false);

  const { profile, refetch: refetchUserProfile } = useGetProfile();
  const { data: { member = null } = {}, refetch: refetchMemberQuery } = useGetMemberQuery(
    Number(params.get('creatorId')),
  );
  // getting selected group
  const { data: { community = null } = {} } = useCommunityByIdQuery(Number(groupId), {
    skip: !groupId,
  });
  const { data: { schemas: memberFieldSchemas = null } = {} } = useMemberFieldSchemasQuery();
  const { clientMetadata: { applicantReviewFields = {} } = {} } = useGetCurrentClientMetadata({
    fetchPolicy: 'cache-first',
  });
  const clientId = client.id;
  const {
    auth0User: {
      appMetadata: { clients },
    },
  } = profile;
  const role = clients[clientId].roles[0];
  useEffect(() => {
    if (brandApprovalError) {
      message.error('Brand Approval not configured');
      setBrandApprovalError(false);
    }
    if (brandNoteError) {
      message.error('Brand Note not configured');
      setbrandNoteError(false);
    }
    if (member) {
      const formattedMember = formatMember(member, community?.memberFieldSchemas || memberFieldSchemas);
      setMemberRecord(formattedMember);
    }
  }, [community, member, brandApprovalError, brandNoteError, memberFieldSchemas]);

  const updateSocialProfile = useCallback(async () => {
    if (memberRecord?.metaFields.instagram) {
      const instagramResponse = await fetchSocialDataForMember({
        url: `${backendServerApiEndpoint()}/social_account`,
        clientId: client.id,
        username: memberRecord.metaFields.instagram,
        network: 'instagram',
        include_social_profile_data: true,
        backendServerFetchResponse,
      });
      setSocialProfile((previouSocialProfile) => ({ ...previouSocialProfile, instagram: instagramResponse.data }));
    }
    if (memberRecord?.metaFields.tiktok) {
      const tiktok = await fetchSocialDataForMember({
        url: `${backendServerApiEndpoint()}/social_account`,
        clientId: client.id,
        username: memberRecord.metaFields.tiktok,
        network: 'tiktok',
        include_social_profile_data: true,
        backendServerFetchResponse,
      });
      setSocialProfile((previouSocialProfile) => ({ ...previouSocialProfile, tiktok: tiktok.data }));
    }
  }, [memberRecord, client.id, backendServerFetchResponse]);

  useEffect(() => {
    if (memberRecord && client) {
      if (memberRecord.metaFields.instagram || memberRecord.metaFields.tiktok) {
        updateSocialProfile();
      } else {
        setSocialProfile({});
      }
    }
  }, [client, memberRecord, updateSocialProfile]);

  return (
    socialProfileData
    && memberRecord && ( // {}
      <div className={styles.creatorDetail}>
        {/* head section */}
        <CreatorTopbar
          memberRecord={memberRecord}
          groupId={groupId}
          history={history}
          community={community}
          setBrandApprovalError={setBrandApprovalError}
          refetchMemberQuery={refetchMemberQuery}
          isContentApprover={role === 'manager:content_approver'}
        />

        <div className={styles.creatorProfileWrapper}>
          {/* profile detail */}
          <CreatorProfile
            memberRecord={memberRecord}
            socialProfileData={socialProfileData}
            applicantReviewFields={applicantReviewFields}
            memberFieldSchemas={memberFieldSchemas}
          />

          {/* content highlight and social metrics */}
          <div className={styles.contentHighlightSocialMetrics}>
            <Row gutter={16}>
              <Col xl={12} md={24}>
                <ContentHighlight
                  socialProfileData={socialProfileData}
                  memberRecord={memberRecord}
                  refetchMemberQuery={refetchMemberQuery}
                  community={community}
                  setbrandNoteError={setbrandNoteError}
                  userProfile={profile}
                  refetchUserProfile={refetchUserProfile}
                  applicantReviewFields={applicantReviewFields}
                  isContentApprover={role === 'manager:content_approver'}
                />
              </Col>
              <Col xl={12} md={24} xs={24}>
                <SocialMetrics memberRecord={memberRecord} socialProfileData={socialProfileData} client={client} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  );
});
