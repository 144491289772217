import { endpoints } from '@components';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

import { useInviteContext } from './useInviteContext';

/**
 * Fetch communities
 */
export const useCommunities = () => {
  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const fetchCommunitiesURL = apiEndpoint && clientId
    ? `${apiEndpoint}/${endpoints.communitiesEndpoint}?client_id=${clientId}`
    : null;

  const {
    isFetching,
    data: communities,
    error,
  } = useBackendServerQuery({
    fetchParams: {
      input: fetchCommunitiesURL || '',
    },
    useQueryParams: {
      options: {
        enabled: !!fetchCommunitiesURL,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
      },
    },
  });

  return {
    communities: Object.freeze(communities),
    isFetching,
    error,
  };
};
