// @ts-strict-ignore
import React, {
 useEffect, useRef, useImperativeHandle, useMemo,
} from 'react';
import {
 isArray, isEmpty, isFunction, map, size,
} from 'lodash';

import { TermsType } from '@frontend/app/types/globalTypes';
import { IApplicationContainerHandle } from '@frontend/app/containers/Application/ApplicationContainer';
import { useGetTermsSettingsForProgram } from '@frontend/app/hooks';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useFetchSocialAccountData } from '@frontend/applications/SocialPostApp/useFetchSocialAccountData';
import { backendServerWebEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { EventName } from '@common';
import { useFetchMetaBusinesses } from '@frontend/applications/SocialPostApp/useFetchMetaBusinesses';
import { IInputTermsTemplate } from '@frontend/applications/TermsApp/components/BulkTerms/types/CollaborationDetails';

import { BulkTerms as BulkTermsComponent } from '../../components/BulkTerms/BulkTerms';
import { IInputMember } from '../../components/BulkTerms/types/Member';
import { useTermsConfigQuery } from './useTermsConfigQuery';
import MessageComposer from '../../components/BulkTerms/MessageComposer/MessageComposer';
import { IState } from '../../components/BulkTerms/types/state';
import { TContentGuideline } from '../../components/BulkTerms/types/ContentGuidelines';

interface IProps {
  memberIds: number[];
  onClose: () => void;
  onSave: (state: IState) => Promise<boolean>;
  onSetTitle: (title: string) => void;
  onSwitchTermsType: () => void;
  programId: number;
  clientName: string;
}

export const BulkTerms = React.memo(
  React.forwardRef<IApplicationContainerHandle, IProps>((props, ref) => {
    const {
 memberIds, onClose, programId, onSave, onSetTitle, clientName, onSwitchTermsType,
} = props;

    // configure the "onClickConnectInstagram" onClick flow
    const { backendServerApiEndpoint, clientId } = useApplication();
    const addEvent = useEventContext();
    const oauthUrl = React.useMemo((): string => {
      const backend = backendServerWebEndpoint();
      const oauthEndpoint = `${backend}brands/${encodeURIComponent(clientId)}/facebook/aspirex_oauth`;
      const redirect = window && `${window?.location?.origin}/ig_auth_result/${clientId}`;
      const oauthScope = encodeURIComponent('aspirex_add_brand_account_mentions');
      return `${oauthEndpoint}?oauth_type=${oauthScope}&redirect=${redirect}`;
    }, [clientId]);
    const onClickConnectInstagram = React.useCallback(
      (e) => {
        e.preventDefault();

        addEvent(EventName.OAuthGrantStart, { app: 'home_page' });
        const newWindow = window.open(oauthUrl, '_blank');
        newWindow?.focus();
      },
      [addEvent, oauthUrl],
    );

    const {
      data: socialAccounts,
      error: socialAccountError,
      refetch: refetchSocialAccount,
    } = useFetchSocialAccountData({
      url: `${backendServerApiEndpoint}/social_account/bca_account`,
      clientId,
      fetchAll: true,
    });

    const { data: metaBusinesses, error: metaBusinessesError, refetch: refetchMetaBusinesses } = useFetchMetaBusinesses(
      {
        url: `${backendServerApiEndpoint}/meta_businesses`,
        clientId,
      },
    );

    const {
      data: tiktokSparkAdAccounts,
      error: tiktokSparkAdAccountsError,
      refetch: refetchTiktokSparkAdAccounts,
    } = useFetchSocialAccountData({
      url: `${backendServerApiEndpoint}/tiktok/ad_accounts/spark_ads`,
      clientId,
      fetchAll: true,
    });

    const businessAccountMissing: boolean = useMemo(() => !!metaBusinessesError || size(metaBusinesses) === 0, [
      metaBusinessesError,
      metaBusinesses,
    ]);

    const clientNeedsAuthForBrandedContent = useMemo(
      () =>
        !socialAccountError
        && (!isArray(socialAccounts) || socialAccounts.length === 0 || !socialAccounts[0].has_branded_content_tag_access),
      [socialAccountError, socialAccounts],
    );

    const clientNeedsAuthForPartnershipAds = useMemo(
      () =>
        !socialAccountError
        && (!isArray(socialAccounts) || socialAccounts.length === 0 || !socialAccounts[0].has_partnership_ads_access),
      [socialAccountError, socialAccounts],
    );

    const clientNeedsBCAAccount = useMemo(
      () => !socialAccountError && (!isArray(socialAccounts) || socialAccounts.length === 0),
      [socialAccountError, socialAccounts],
    );

    const tiktokSparkAdsAccountMissing = useMemo(
      () => !!tiktokSparkAdAccountsError || size(tiktokSparkAdAccounts) === 0,
      [tiktokSparkAdAccountsError, tiktokSparkAdAccounts],
    );

    const checkSocialAccountIntervalRef = useRef<ReturnType<typeof setInterval>>();
    const checkBusinessesAccountIntervalRef = useRef<ReturnType<typeof setInterval>>();
    const checkTiktokSparkAdAccountsIntervalRef = useRef<ReturnType<typeof setInterval>>();

    // Checking to see if insights have been linked after linking insights in terms
    useEffect(() => {
      if (
        (clientNeedsAuthForBrandedContent || clientNeedsAuthForPartnershipAds || clientNeedsBCAAccount)
        && isFunction(refetchSocialAccount)
      ) {
        checkSocialAccountIntervalRef.current = setInterval(() => {
          refetchSocialAccount();
        }, 5000);
      } else if (checkSocialAccountIntervalRef.current) {
        clearInterval(checkSocialAccountIntervalRef.current);
      }
      return () => {
        if (checkSocialAccountIntervalRef.current) {
          clearInterval(checkSocialAccountIntervalRef.current);
        }
      };
    }, [
      clientNeedsAuthForBrandedContent,
      clientNeedsAuthForPartnershipAds,
      clientNeedsBCAAccount,
      refetchSocialAccount,
      checkSocialAccountIntervalRef,
    ]);

    useEffect(() => {
      if (businessAccountMissing && isFunction(refetchMetaBusinesses)) {
        checkBusinessesAccountIntervalRef.current = setInterval(() => {
          refetchMetaBusinesses();
        }, 5000);
      } else if (checkBusinessesAccountIntervalRef.current) {
        clearInterval(checkBusinessesAccountIntervalRef.current);
      }
      return () => {
        if (checkBusinessesAccountIntervalRef.current) {
          clearInterval(checkBusinessesAccountIntervalRef.current);
        }
      };
    }, [checkBusinessesAccountIntervalRef, businessAccountMissing, refetchMetaBusinesses]);

    useEffect(() => {
      if (tiktokSparkAdsAccountMissing && isFunction(refetchTiktokSparkAdAccounts)) {
        checkTiktokSparkAdAccountsIntervalRef.current = setInterval(() => {
          refetchTiktokSparkAdAccounts();
        }, 5000);
      } else if (checkTiktokSparkAdAccountsIntervalRef.current) {
        clearInterval(checkTiktokSparkAdAccountsIntervalRef.current);
      }
    }, [tiktokSparkAdsAccountMissing, refetchTiktokSparkAdAccounts]);

    const bulkTermsRef = useRef<IApplicationContainerHandle>(null);

    useImperativeHandle(ref, () => ({
      showWarningOnClose: () => !!(bulkTermsRef.current?.showWarningOnClose && !bulkTermsRef.current?.saving),
    }));

    const termsConfig = useTermsConfigQuery({
      variables: {
        memberIds,
        programId,
      },
      fetchPolicy: 'no-cache',
    });

    const {
      loading: termsSettingsLoading,
      error: termsSettingsError,
      data: { termsTemplate = null } = {},
    } = useGetTermsSettingsForProgram({
      variables: {
        programId,
        termsType: TermsType.ADVANCED,
      },
      skip: !programId,
      fetchPolicy: 'no-cache',
    });

    const loading = termsConfig.loading || termsSettingsLoading;

    const contentGuidelinesTemplates: TContentGuideline[] = React.useMemo(() => {
      if (loading || !termsConfig.data || termsConfig.error) {
        return [];
      }

      return map(termsConfig.data.termsConfig.settings.contentGuidelines, (guideline) => ({
        ...guideline,
        id: Math.floor(Math.random() * 1000000),
      }));
    }, [loading, termsConfig.data, termsConfig.error]);

    const termsTemplateSettings: IInputTermsTemplate | undefined = React.useMemo(() => {
      if (termsSettingsLoading || termsSettingsError) {
        return null;
      }
      return {
        contentUsageRightType: termsTemplate.contentUsageRightType,
        contentUsageRightValue: termsTemplate.contentUsageRightValue,
        compensationPaymentAvailable: termsTemplate.compensationPaymentAvailable,
        compensationPaymentValue: termsTemplate.compensationPaymentValue,
        compensationFreeProductAvailable: termsTemplate.compensationFreeProductAvailable,
        compensationFreeProductValue: termsTemplate.compensationFreeProductValue,
        compensationCommissionAvailable: termsTemplate.compensationCommissionAvailable,
        compensationCommissionValue: termsTemplate.compensationCommissionValue,
        noCompensationAvailable: termsTemplate.noCompensationAvailable,
        templateId: termsTemplate.emailTemplate.id,
        subject: termsTemplate.emailTemplate.subject,
        text: termsTemplate.emailTemplate.text,
      };
    }, [termsTemplate, termsSettingsLoading, termsSettingsError]);

    const members: IInputMember[] = React.useMemo(() => {
      if (loading || !termsConfig.data || termsConfig.error) {
        return [];
      }

      return map(
        termsConfig.data.termsConfig.members,
        (member): IInputMember => {
          const { fields } = member;
          const reach = fields?.tiktokFollowers
            + fields?.youtubeFollowers
            + fields?.instagramFollowers
            + fields?.pinterestFollowers;
          return {
            email: member.email || '',
            id: member.id,
            imageUrl: member.profilePicture || null,
            instagram: null,
            name: member.name || '',
            reach,
            ...member.fields,
          };
        },
      );
    }, [loading, termsConfig.data, termsConfig.error]);

    if (loading) {
      return (
        <BulkTermsComponent
          clientName={clientName}
          ref={bulkTermsRef}
          loading
          onClose={onClose}
          programId={programId}
        />
      );
    }

    if (termsConfig.error) {
      throw termsConfig.error;
    }

    if (termsSettingsError) {
      throw termsSettingsError;
    }

    if (!termsConfig.data || isEmpty(members)) {
      throw new Error('No data returned.');
    }

    const {
      termsConfig: {
        settings: {
          contentUsageRights: contentRightsTemplates,
          daysToApproveContent,
          paymentPeriod,
          isBrandedContentEnabled,
          whitelistingLabel,
          advancedTerms,
          creatorEditEnabled: isCreatorEditEnabled,
        },
      },
    } = termsConfig.data;

    return (
      <BulkTermsComponent
        paymentPeriod={paymentPeriod}
        daysToApproveContent={daysToApproveContent}
        onClickConnectInstagram={onClickConnectInstagram}
        businessAccountMissing={businessAccountMissing}
        clientNeedsAuthForBrandedContent={clientNeedsAuthForBrandedContent}
        clientNeedsAuthForPartnershipAds={clientNeedsAuthForPartnershipAds}
        clientNeedsBCAAccount={clientNeedsBCAAccount}
        tiktokSparkAdsAccountMissing={tiktokSparkAdsAccountMissing}
        clientId={clientId}
        advancedTerms={advancedTerms}
        ref={bulkTermsRef}
        isBrandedContentEnabled={isBrandedContentEnabled}
        whitelistingLabel={whitelistingLabel}
        contentGuidelinesTemplates={contentGuidelinesTemplates}
        contentRightsTemplates={contentRightsTemplates}
        onClose={onClose}
        onSave={onSave}
        isCreatorEditEnabled={isCreatorEditEnabled}
        loading={false}
        members={members}
        clientName={clientName}
        onSwitchTermsType={onSwitchTermsType}
        renderEmailComposer={(templateId, initialMessage, state, dispatch) => (
          <MessageComposer dispatch={dispatch} state={state} initialMessage={initialMessage} templateId={templateId} />
        )}
        onSetTitle={onSetTitle}
        termsTemplate={termsTemplateSettings}
        programId={programId}
      />
    );
  }),
);
BulkTerms.displayName = 'BulkTerms';
