import { IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';

import { IArtifact } from '@frontend/applications/Shared/context/applicationContext';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

const querystring = require('querystring');

export interface IContract extends IArtifact {
  id: string;
  title: string;
  remote_identifier: string;
  is_complete: boolean;
  status: string;
  created_ts: number;
  cancelled: boolean;
  signers: IContractSigner[];
  activation_names: string[];
}

export interface IUpdateContractPayload extends IAssignmentAPIPayload {
  cancelled?: boolean;
}

export interface IContractSigner {
  name: string;
  email: string;
  role: SignerRole;
}

interface IContractFetchQueryParams {
  client_id: string;
  member_id?: string;
}

export enum SignerRole {
  BrandRole = 'Brand',
  CreatorRole = 'Creator',
}

export interface IValidateBulkContractRequest {
  client_id: string;
  member_ids: string;
  search_query: string;
}

interface Member {
  id: number;
  email: string;
  name: string;
}

export function useFetchContractsData(url: string, params: IContractFetchQueryParams) {
  const filterString = querystring.stringify(params);

  const finalUrl = `${url}?${filterString}`;

  const { isLoading: loading, error, data } = useBackendServerQuery<IContract[]>({
    fetchParams: { input: finalUrl },
  });

  return {
    loading,
    data,
    error,
  };
}

type ContractDownloadResponse = {
  file_url: string;
};

export function useFetchContractDownloadData(url: string, clientId: string, skip: boolean) {
  const filterString = querystring.stringify({ client_id: clientId });
  const finalUrl = `${url}?${filterString}`;

  const { isLoading: loading, error, data } = useBackendServerQuery<ContractDownloadResponse>({
    fetchParams: { input: finalUrl },
    useQueryParams: {
      options: {
        enabled: !skip,
      },
    },
  });

  return {
    loading,
    data,
    error,
  };
}

export function useValidateBulkSendMembers(url: string, params: IValidateBulkContractRequest) {
  const filterString = querystring.stringify(params);

  const finalUrl = `${url}?${filterString}`;

  const { isLoading: loading, error, data } = useBackendServerQuery<Member[]>({
    fetchParams: { input: finalUrl },
  });

  return {
    loading,
    data,
    error,
  };
}
