import { Client, cacheExchange, fetchExchange } from 'urql';

const getClient = ({ token }: { token: string }) => {
  const client = new Client({
    url: '/social_graphql',
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });
  return client;
};

export default getClient;
