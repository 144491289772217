// calculateStats.ts
interface OfferLevelStats {
  conversions: number;
  sales: number;
  avgSale: number;
  payoutEarned: number;
  payoutMade: number;
  clicks: number;
  currencies?: string[]; // Optional field for currencies
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calculateStats = (result: any[], currencies: string[]): OfferLevelStats => {
  // Initialize stats object
  const stats: OfferLevelStats = {
    conversions: 0,
    sales: 0,
    avgSale: 0,
    payoutEarned: 0,
    payoutMade: 0,
    clicks: 0,
  };

  // Aggregate stats from each entry in result
  result.forEach((stat) => {
    stats.conversions += stat.conversions || 0;
    stats.sales += Number(stat.sales) || 0;
    stats.avgSale += Number(stat.avgSale) || 0;
    stats.payoutEarned += Number(stat.payoutEarned) || 0;
    stats.payoutMade += stat.payoutMade || 0;
    stats.clicks += stat.clicks || 0;
  });

  // Add currencies (if passed)
  stats.currencies = currencies;

  return stats;
};
