import * as React from 'react';
import cx from 'classnames';
import { ApolloProvider } from '@apollo/client';
import { isEmpty, filter, size } from 'lodash';

import { ISTAComposerMemberFieldIds, useCheckInSendWorkItems, useUpdateOfferMutation } from '@affiliates/hooks';
import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { SEND_STA_TASK_ID } from '@affiliates/types/globalTypes';
import { getObjectForTask } from '@affiliates/utils';
import { logger } from '@common';
import { EmailComposer, IEmailComposer, IPreviewConfig } from '@frontend/app/components';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';
import { useMessagingContext } from '@frontend/hooks';
import { useAppContext } from '@frontend/context/AppContext';
import { STAEmailComposer } from './STAEmailComposer';
import initialEmailState from './initialEmailState';

import styles from './STASendItems.scss';
import { useClientFeature } from '../../contexts/ClientFeatureContext';

interface IProps {
  onCancel?(): void;
  onNext?(): void;
  taskId: SEND_STA_TASK_ID;
  memberIds: number[];
  emailSubject?: string;
  staComposerMemberFieldIds: ISTAComposerMemberFieldIds;
  offers: GetOffersBySearchQuery_offers[];
  workItemIds: string[];
}

export const STAEmailComposerContainer: React.FC<Readonly<IProps>> = ({
  onCancel,
  onNext,
  memberIds,
  taskId,
  staComposerMemberFieldIds,
  offers,
  workItemIds,
  emailSubject,
}) => {
  const { apolloClient } = useAppContext();
  const [previewConfig, setPreviewConfig] = React.useState<IPreviewConfig>(null);
  const messageFormRef = React.useRef<IEmailComposer>(null);
  const { migrateToGraphQL, MemberPortal: memberPortalFeatureFlagEnabled } = useClientFeature();
  const [initialMessage] = React.useState(
    initialEmailState(taskId, offers, staComposerMemberFieldIds, memberPortalFeatureFlagEnabled, migrateToGraphQL),
  );
  const {
    showSuccessMessage,
  } = useMessagingContext();

  const contentTop = React.useMemo(() => {
    switch (taskId) {
      case SEND_STA_TASK_ID.send_link_and_promo_task:
        return (
          <>
            <div className={styles.headline6}>Send these members their promo codes and links.</div>
            <div>
              Send a personal message or use the template below to send members their sales tracking items. You
              {' '}
              <strong>must</strong>
              include the promo code and sales tracking links magic labels included below to send the correct promo code
              and link to each member if you are sending in bulk.
            </div>
          </>
        );
      case SEND_STA_TASK_ID.send_link_task:
        return (
          <>
            <div className={styles.headline6}>Send these members their links.</div>
            <div>
              Send a personal message or use the template below to send members their links. You
              {' '}
              <strong>must</strong>
              {' '}
              include the sales tracking links magic labels included below to send the correct link to each member if
              you are sending in bulk.
            </div>
          </>
        );
      case SEND_STA_TASK_ID.send_promo_task:
        return (
          <>
            <div className={styles.headline6}>Send these members their promo codes.</div>
            <div>
              Send a personal message or use the template below to send members their promo codes. You
              {' '}
              <strong>must</strong>
              {' '}
              include the sales tracking promo codes magic labels included below to send the
              correct promo code to each member if you are sending in bulk.
            </div>
          </>
        );
      default:
        return null;
    }
  }, [taskId]);

  const defaultEmailSubject = React.useMemo(() => {
    let subject = 'Here is your sales tracking ';
    switch (taskId) {
      case SEND_STA_TASK_ID.send_link_and_promo_task:
        subject += 'promo code and link';
        break;
      case SEND_STA_TASK_ID.send_link_task:
        subject += 'link';
        break;
      case SEND_STA_TASK_ID.send_promo_task:
        subject += 'promo code';
        break;
      default:
        break;
    }

    return subject;
  }, [taskId]);

  const [editOffer] = useUpdateOfferMutation();
  const [checkInSendWorkItems] = useCheckInSendWorkItems();

  const handleUpdateOffer = React.useCallback(async () => {
    try {
      const updatedOffers = filter(offers, (offer) => offer.showSendConfirmationScreen);
      for (const offer of updatedOffers) {
        // Update offer to disable confirmation screen
        await editOffer({ variables: { id: offer.id, data: { showSendConfirmationScreen: false } } });
      }

      // Move work items to next step
      if (!isEmpty(workItemIds)) {
        await checkInSendWorkItems({ variables: { taskId, workItemIds } });
      }
      const memberCount = size(memberIds);
      const isPlural = memberCount > 1;
      const directObject = getObjectForTask(taskId, isPlural);
      const message = isPlural
        ? `${memberCount} people have been emailed their ${directObject}.`
        : `One person has been emailed their ${directObject}.`;
      showSuccessMessage(message);
      onNext();
    } catch (e) {
      logger.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInSendWorkItems, editOffer, offers, onNext, taskId, workItemIds, memberIds, getObjectForTask]);

  const emailPreview = React.useMemo(() => {
    if (previewConfig) {
      return (
        <EmailPreviewer
          previewConfig={previewConfig}
          memberCount={size(memberIds)}
          memberQuery={{
            communityIds: [-999],
            includeMemberIds: memberIds,
          }}
          onBack={() => setPreviewConfig(null)}
          onConfirm={() => {
            setPreviewConfig(null);

            messageFormRef.current.sendMessage(true);
          }}
        />
      );
    }

    return null;
  }, [memberIds, previewConfig]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Fix in Node upgrade typing bash!
    <ApolloProvider client={apolloClient}>
      <STAEmailComposer
        title={contentTop}
        emailComposer={(
          <EmailComposer
            ref={messageFormRef}
            className={cx(styles.composer, {
              [styles.hide]: !!previewConfig,
            })}
            subject={emailSubject || defaultEmailSubject}
            initialMessage={initialMessage}
            onMessageSent={handleUpdateOffer}
            hasTopBorder={false}
            memberQuery={{
              communityIds: [-999],
              includeMemberIds: memberIds,
            }}
            memberCount={size(memberIds)}
            source="salestracking"
            onPreview={setPreviewConfig}
            allowSendingAsSeparate
            enableExpandingEmailEditor
          />
        )}
        emailPreview={emailPreview}
        onCancel={onCancel}
      />
    </ApolloProvider>
  );
};
