import * as React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/solid/esm';
import { Button } from '@frontend/shadcn/components/ui/button';

interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  total?: number;
  showTotal?: boolean;
}

const { useMemo } = React;

export const Pagination = ({
  page,
  setPage,
  pageSize,
  total,
  showTotal,
}: PaginationProps) => {
  const totalPages = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const itemsDisplayed = useMemo(() => {
    const firstItem = pageSize * page - pageSize + 1;
    const lastItem = pageSize * page < total ? pageSize * page : total;
    return `${firstItem}-${lastItem}`;
  }, [page, pageSize, total]);

  return (
    <div className="flex gap-2 items-center">
      {
        showTotal && <span>{`${itemsDisplayed} of ${total}`}</span>
      }
      <div className="flex gap-2">
        <Button
          variant="ghost"
          className="w-8 h-8"
          size="icon"
          disabled={page === 1}
          onClick={handlePrev}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          variant="ghost"
          className="w-8 h-8"
          size="icon"
          disabled={totalPages <= page}
          onClick={handleNext}
        >
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};
