import { MutationHookOptions, useMutation } from '@apollo/client';
import { REMOVE_TAGS_FROM_PRODUCTS } from '@frontend/applications/ProductFulfillmentApp/queries';
import { RemoveTagsFromProducts, RemoveTagsFromProductsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/RemoveTagsFromProducts';

type IOptions = MutationHookOptions<RemoveTagsFromProducts, RemoveTagsFromProductsVariables>;

export const useRemoveTagsFromProducts = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<RemoveTagsFromProducts, RemoveTagsFromProductsVariables>(
    REMOVE_TAGS_FROM_PRODUCTS, options,
);

  return {
    removeTags: mutate,
    isRemovingTags: loading,
  };
};
