import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import {
  includes, lowerCase, join, omit,
} from 'lodash';

import {
  endpoints, IPostCardSocialPost, Select, PostCard, SearchIcon, Input, LoadSpinner,
} from '@components';
import { 
  useGetAllProjectsQuery, useGetCurrentClient, useCommunitiesQuery,
} from '@frontend/app/hooks';
import { useAnalyze } from '../useAnalyze';
import { useFetchSummaryData } from '../useFetchAnalyzeData';

import styles from './PostsView.scss';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

enum SortFields {
  postDate = 'Post Date',
  totalMediaValue = 'Total Media Value',
}

const SORT_FIELD_OPTIONS = [
  {
    label: SortFields.postDate,
    value: 'postDate',
  },
  {
    label: SortFields.totalMediaValue,
    value: 'totalMediaValue',
  },
];

const SORT_DIRECTION_OPTIONS = [
  {
    label: 'Descending',
    value: 'descending',
  },
  {
    label: 'Ascending',
    value: 'ascending',
  },
];

type PostsRequest = { data: IPostCardSocialPost[] };

/**
 * @type {React.FunctionComponent}
 */
const PostsView: React.FunctionComponent<{ className?: string }> = React.memo((props) => {
  const {
    filters,
    apiEndpoint,
    isQa,
    showCostMetrics,
  } = useAnalyze();
  const LOAD_INCREMENT = 5;

  const [isFiltering, setIsFiltering] = useState(false);
  const [posts, setPosts] = useState<IPostCardSocialPost[]>([]);

  const [searchString, setSearchString] = useState('');
  const [sortFieldIndex, setSortFieldIndex] = useState(0);
  const [sortDirectionIndex, setSortDirectionIndex] = useState(0);
  const [renderIndex, setRenderIndex] = useState(LOAD_INCREMENT);

  const { data: requestData, loading: loadingPostData } = useFetchSummaryData<PostsRequest>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/posts`,
    showCostMetrics,
    filters,
  );

  const { client: currentClient } = useGetCurrentClient();

  const igEstimatedImpressionsEnabled = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);

  const { loading: loadingPrograms, data: programsData } = useGetAllProjectsQuery({
    fetchPolicy: 'cache-only',
  });
  const { loading: loadingCommunities, data: communitiesData } = useCommunitiesQuery({
    fetchPolicy: 'cache-only',
  });

  const data = useMemo(() => {
      const programsDataDic = (programsData?.projects || []).reduce<Record<number, string>>(
      (acc, program) => {
        acc[program.id as number] = program.title as string;
        return acc;
      },
      {}
    );
    const communitiesDataDic = (communitiesData?.communities || []).reduce<Record<number, string>>(
      (acc, community) => {
        acc[community.id as number] = community.title as string;
        return acc;
      },
      {}
    );

    const data = (requestData?.data || []).map((post) => {
      post.program_names = (post.program_ids || [])
      .map((id) => programsDataDic[id])
      .filter((name): name is string => !!name);

      post.community_names = (post.community_ids || [])
      .map((id) => communitiesDataDic[id])
      .filter((name): name is string => !!name);

      return !igEstimatedImpressionsEnabled ? omit(post, 'estimated_impressions') : post;
    });
    return data
  }, [requestData, programsData, communitiesData, igEstimatedImpressionsEnabled]);

  const isLoadingData = useMemo(() => loadingPostData || loadingPrograms || loadingCommunities, [loadingPostData, loadingPrograms, loadingCommunities]);
  
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);

  const onSortFieldChange = (sortField) => {
    const newIndex = SORT_FIELD_OPTIONS.findIndex(
      (option) => option.value === sortField,
    );
    setSortFieldIndex(newIndex);
    setRenderIndex(LOAD_INCREMENT);
  };

  const onSortDirectionChange = (sortDirection) => {
    const newIndex = SORT_DIRECTION_OPTIONS.findIndex(
      (option) => option.value === sortDirection,
    );
    setSortDirectionIndex(newIndex);
    setRenderIndex(LOAD_INCREMENT);
  };

  useEffect(() => {
    if (data) {
      setIsFiltering(true);

      const newPosts = data.filter((post: IPostCardSocialPost) => {
        if (!searchString) {
          return true;
        }

        const searchTerms = lowerCase(searchString).split(/\b\s+/);

        return searchTerms.every((searchTerm) => {
          const allPostString = lowerCase(
            join(
              [
                post.social_account_name ? post.social_account_name : '',
                post.creator ? post.creator : '',
                post.post_text ? post.post_text : '',
                post.post_image_tags ? join(post.post_image_tags, ' ') : '',
              ],
              ' ',
            ),
          );

          return includes(allPostString, searchTerm);
        });
      });

      newPosts.sort((a, b) => {
        switch (SORT_FIELD_OPTIONS[sortFieldIndex].value) {
          case 'postDate':
            if (a.datetime_posted && !b.datetime_posted) {
              return -1;
            } else if (!a.datetime_posted && b.datetime_posted) {
              return 1;
            } else if (a.datetime_posted && b.datetime_posted) {
              return (new Date(a.datetime_posted) > new Date(b.datetime_posted)) ? -1 : 1;
            } else {
              return 0;
            }
          case 'totalMediaValue':
            if (a.tmv && !b.tmv) {
              return -1;
            } else if (!a.tmv && b.tmv) {
              return 1;
            } else if (a.tmv && b.tmv) {
              return parseFloat(a.tmv) > parseFloat(b.tmv) ? -1 : 1;
            } else {
              return 0;
            }
        }
      });

      if (SORT_DIRECTION_OPTIONS[sortDirectionIndex].value === 'ascending') {
        newPosts.reverse();
      }

      setPosts(newPosts);
      setIsFiltering(false);
      setRenderIndex(LOAD_INCREMENT);
    }
  }, [data, searchString, sortFieldIndex, sortDirectionIndex]);


  useEffect(() => {
    const timer = setInterval(() => {
        setRenderIndex(prevIndex => Math.min(prevIndex + LOAD_INCREMENT, posts.length));
    }, 500);

    return () => clearInterval(timer);
  }, [posts]);

  const isLoading = isLoadingData || isFiltering;

  return (
    <div className={cx(styles.PostsView, props.className)}>
      <div className={styles.postFilters}>
        <Input
          className={styles.searchInput}
          icon={<SearchIcon />}
          onChange={setSearchString}
          placeholder="Search usernames, phrases, etc..."
        />
        <Select
          className={styles.selectSort}
          options={SORT_FIELD_OPTIONS}
          onChange={onSortFieldChange}
          selectedIndex={sortFieldIndex}
          round
        />
        <Select
          className={styles.selectSort}
          options={SORT_DIRECTION_OPTIONS}
          onChange={onSortDirectionChange}
          selectedIndex={sortDirectionIndex}
          round
        />
      </div>
      {isLoading
        ? <LoadSpinner centered />
        : (
          <div className={styles.postsGrid}>
            {posts.slice(0, renderIndex).map(postData => (
                <PostCard
                    key={postData.post_id}
                    clientId={currentClient?.id}
                    isQa={isQa}
                    post={postData}
                    tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
                />
            ))}
          </div>
        )}
        {(renderIndex < posts.length) ?
          <LoadSpinner centered /> : null
        }
    </div>
  );
});

export default PostsView;
