import { useState, useEffect } from 'react';

import { logger } from '@common';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { IContract, IContractSigner } from './useFetchContracts';

export type ISendContractRequest = ICustomContractRequest | ITemplateContractRequest;

export interface IBaseContractRequest {
  signers: IContractSigner[];
  message: string;
  title: string;
  subject: string;
  client_id: string;
  completed: boolean;
}

interface ITemplateContractRequest extends IBaseContractRequest {
  template_id: string;
  member_id: string;
}

interface ICustomContractRequest extends IBaseContractRequest {
  uploaded_pdf_url: string;
  member_id: string;
}

export interface ISendBulkContractRequest extends IBaseContractRequest {
  uploaded_pdf_url: string;
  search_query: string;
  member_ids: number[];
  message: string;
}

export const sendContract = async (url: string, params: ISendContractRequest, backendServerFetchResponse: typeof fetch) =>
  saveContractData(url, params, backendServerFetchResponse);

export const sendBulkContract = async (url: string, params: ISendBulkContractRequest, backendServerFetchResponse: typeof fetch) =>
  saveContractData(url, params, backendServerFetchResponse);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveContractData = async (url: string, params: any, backendServerFetchResponse: typeof fetch) => {
  try {
    const resp = await backendServerFetchResponse(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

export function useSendContract(url: string, params: ISendContractRequest) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IContract>(null);
  const [error, setError] = useState(null);

  const { backendServerFetchResponse } = useBackendServerFetch();
  useEffect(() => {
    if (backendServerFetchResponse) {
      setLoading(true);
      saveContractData(url, params, backendServerFetchResponse)
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [url, params, backendServerFetchResponse]);

  return {
    loading,
    data,
    error,
  };
}
