import * as React from 'react';
import {
  isEmpty, isNull, isUndefined, some, startCase,
} from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { isBefore } from 'date-fns';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { MAX_ALLOWED_MEMBERS } from '@frontend/applications/AffiliatesApp/utils';
import { IAddPromoCodeMembers } from '../../types';
import { TActions, TState } from './state';
import { getSteps } from './getSteps';

import styles from '../../components/MembersWizard.scss';
import { getWorkflowPromoCodeSteps } from './getWorkflowPromoCodeSteps';

export const getPromoCodeSteps = (props: IAddPromoCodeMembers, state: TState, actions: TActions) => {
  const {
    onClose,
    isWorkflow,
    isUngrouped,
    offerSource,
    isProjects,
  } = props;

  if (isWorkflow) {
    return getWorkflowPromoCodeSteps(props, state, actions);
  }

  const {
    selectPrograms,
    selectMembers,
    selectPayoutAndDate,
    selectActiveDates,
    reviewMembers,
    completed,
    selectedMembers,
  } = getSteps(props, state, actions);
  const programLabel = isProjects ? 'Project' : 'Program';
  let nextButtonDisabled = true;
  if (!isNull(state.members) && !isEmpty(selectedMembers)) {
    nextButtonDisabled = some(selectedMembers, (m) => m.code.length < 1 || m.isDuplicate);
  }
  const selectMemberDisabled = isEmpty(selectedMembers);
  const reviewButtonDisabled = isUndefined(state.activeDates.startDate)
    || isUndefined(state.activeDates.startTime)
    || isUndefined(state.activeDates.endDate)
    || isUndefined(state.activeDates.endTime);
  const commonCompletedStep = (stepNum) => ({
    instructions: {
      description: selectedMembers.length > 500
        ? ''
        : 'Their unique codes are starting to be generated, it should only take a few minutes. You can close this window. Once all codes have been generated, you can email these members their codes using the “Email Members” button above the table on this offer page.',
      title: selectedMembers.length > 500
        ? ''
        : `Congrats! You are generating promo codes for ${selectedMembers.length.toLocaleString()} member${selectedMembers.length !== 1 ? 's' : ''}.`,
    },
    nextButtonConfig: {
      action: onClose,
      className: styles.wizardNextButton,
      disabled: false,
      showIcon: false,
      text: 'Done',
    },
    previousButtonConfig: {
      className: `${styles.wizardButton} ${styles.hidden}`,
      disabled: true,
      text: 'Review Members',
    },
    stepNum,
  });
  const isUngroupedMemberCorrect = isUngrouped ? !!selectedMembers?.filter((m) => !m.startDate || (m.startDate && m.endDate && isBefore(m.endDate, m.startDate))).length : false;
  const newSteps = [
    selectMembers({
      instructions: {
        description: 'Select the members who will receive the codes for this promotion.',
        title: 'Who will receive these codes for this offer',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: selectMemberDisabled || (offerSource === OFFER_SOURCE.SHOPIFY ? selectedMembers.length > MAX_ALLOWED_MEMBERS : false),
        text: 'Select Members',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Select the members who will receive the codes for this promotion.',
        title: state.saving ? '' : 'Who will receive these codes for this offer',
      },
      nextButtonConfig: {
        action: () => actions.save(4),
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled || isUngroupedMemberCorrect,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Back',
      },
      stepNum: 3,
    }),
    completed(commonCompletedStep(4)),
    completed(commonCompletedStep(5)),
    completed(commonCompletedStep(6)),
  ];

  if (isUngrouped) {
    let disableButtonForUngrouping = false;
    disableButtonForUngrouping = state.showEndDate && !state.bulkEndDate;
    if (state.showEndDate) {
      if (!state.bulkEndDate) disableButtonForUngrouping = true;
      if (state.showEndDate && isBefore(state.bulkEndDate, new Date())) {
        disableButtonForUngrouping = true;
      }
      if (isBefore(state.bulkEndDate, state.bulkStartDate)) {
        disableButtonForUngrouping = true;
      }
    }
    newSteps.splice(1, 0, selectPayoutAndDate({
      instructions: {
        description: 'Promo codes will be generated based on the naming strategy of the offers. The default payout option and active dates will be applied to all the members. You can also customize the promo code name, payout and code active/end date manually for each of them by clicking on Customize Per Member button.',
        title: 'Configure Payout and active dates for all selected members.',
      },
      nextButtonConfig: {
        action: () => {
          if (nextButtonDisabled) {
            actions.setCurrentStep(3);
          } else {
            actions.save(4);
          }
        },
        disabled: selectMemberDisabled || !state.bulkStartDate || disableButtonForUngrouping,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        text: 'Back',
      },
      actionButtonConfig: {
        action: () => actions.setCurrentStep(3),
        disabled: !state.bulkStartDate || disableButtonForUngrouping,
        text: 'Customize Per Member',
      },
      stepNum: 2,
    }));
  }

  const steps = [
    selectPrograms({
      instructions: {
        description: 'You can confirm which members will get these codes in the next step.',
        title: 'Select which projects you would like to be part of this offer.',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: isEmpty(state.selectedPrograms),
        text: 'Confirm Members',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    selectMembers({
      instructions: {
        description: 'These are the members who will be receiving codes for this promotion.',
        title: 'Who will receive these codes?',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Set Active Dates',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        text: `Select ${startCase(programLabel)}s`,
      },
      stepNum: 2,
    }),
    selectActiveDates({
      instructions: {
        description: 'These dates are for this set of members. You will be able to change the dates for new members you give codes to or when you replace a member\'s code.',
        title: 'Set how long these promo codes will be active.',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: reviewButtonDisabled,
        text: 'Review',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: false,
        text: 'Confirm Members',
      },
      stepNum: 3,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Make sure everything looks good before you generate the promo codes.',
        title: state.saving ? '' : 'Review your promo code details.',
      },
      nextButtonConfig: {
        action: () => actions.save(5),
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Set Active Dates',
      },
      stepNum: 4,
    }, { disableNextStep: true }),
    completed({
      instructions: {
        description: `
        Their unique codes are starting to be generated, it should only take a
        few minutes. You can close this window. Once all codes have been
        generated, you can email these members their codes using the
        “Email Members” button above the table on this offer page.
       `.trim(),
        title: `
        Congrats! You are generating promo codes for ${`${selectedMembers.length.toLocaleString()} `}
        member${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 5,
    }),
  ];

  let currentSteps = props.isNewFlow ? newSteps : steps;
  if (state.error) {
    currentSteps = currentSteps.map((currentSteps) => ({
      ...currentSteps,
      actionComponents: (
        <Notice type="error" message={state.error.message} />
      ),
    }));
  }

  return currentSteps;
};
