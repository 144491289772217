import * as React from 'react';
import { Form, Checkbox } from '@revfluence/fresh';
import styles from '../../../OfferForm.scss';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'codePrefix'> {
    onAddPrefixChange: (checked: boolean) => void;
    checked: boolean;
}

export const AddPrefix: React.FC<Readonly<IProps>> = React.memo(({
    onAddPrefixChange,
    checked,
}) => {
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        onAddPrefixChange(checked);
    };
    return (
      <>
        <Form.Item className={styles.customRadioGroup}>
          <Checkbox onChange={handleCheckboxChange} checked={checked}>
            Add Prefix
          </Checkbox>
        </Form.Item>
      </>
    );
});
AddPrefix.displayName = 'AddPrefix';
