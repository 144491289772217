import * as React from 'react';
import { Drawer } from 'antd';

import {
  IStepInfo as Step,
  WizardContainer as Wizard,
} from '@frontend/applications/Shared/Wizard/container';
import { TAddMembersProps } from '../types';
import { useAddMembers } from '../hooks';
import { ConfirmClose } from './ConfirmClose';
import { AddMembers as AddMembersIcon } from './Icons';

import styles from './MembersWizard.scss';
import { useClientFeature } from '../../../contexts/ClientFeatureContext';

const { useCallback, useEffect } = React;

export const AddMembers: React.FC<Readonly<TAddMembersProps>> = (props) => {
  const {
    fetchPrograms,
    onClose,
    visible,
    isWorkflow,
    offerSource,
  } = props;
  const { isProjects, migrateToGraphQL } = useClientFeature();
  const { actions, state, steps } = useAddMembers({ ...props, isProjects, migrateToGraphQL });

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (state.programs !== null || state.error !== null) {
      return;
    }
    fetchPrograms()
      .then(actions.populatePrograms)
      .catch(actions.setError);
  }, [
    actions.populatePrograms,
    actions.setError,
    fetchPrograms,
    state.error,
    state.programs,
    visible,
  ]);

  const resetStateOnHide = useCallback((visible: boolean) => {
    if (!visible) {
      actions.reset();
    }
  }, [actions]);

  const handleCloseClick = useCallback(() => {
    if (state.saving) {
      return;
    }
    if (!state.error && state.currentStep > 1 && state.currentStep < 5) {
      actions.showCloseConfirmation();
      return;
    }
    onClose();
  }, [
    actions,
    onClose,
    state.currentStep,
    state.error,
    state.saving,
  ]);

  let contents;
  if (state.showCloseConfirmation) {
    contents = (
      <ConfirmClose
        onConfirm={onClose}
        onDismissConfirmation={actions.dismissCloseConfirmation}
      />
    );
  } else {
    const hideNavigation = (
      !!state.error
      || (state.showNoMembersWarning)
    );
    contents = (
      <Wizard
        hideNavigation={hideNavigation}
        icon={<AddMembersIcon offerSource={offerSource} />}
        onCancel={onClose}
        onStepChange={actions.setCurrentStep}
        step={state.currentStep}
        stepsInfo={steps as Step[]}
        noHeader={isWorkflow}
        noBodyPadding={isWorkflow}
      />
    );
  }
  if (isWorkflow) {
    return <div className={styles.MembersWizard}>{contents}</div>;
  }

  return (
    <Drawer
      afterVisibleChange={resetStateOnHide}
      className={styles.MembersWizard}
      closable
      destroyOnClose
      onClose={handleCloseClick}
      placement="right"
      visible={visible}
      width={1032}
    >
      {contents}
    </Drawer>
  );
};
