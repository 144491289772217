import { ISelectedColumns } from './SelectedColumns';
import { IApplicationFormFields } from './ApplicationFormFields';
import { IOnboardingTemplateConfigProperties } from './OnboardingTemplateConfigProperties';

export interface IProgramInput {
  title?: string;
  communityId?: number;
  columns?: ISelectedColumns;
  applicationFormFields?: IApplicationFormFields;
  customLandingPagePath?: string;
  owner?: string;
  inviteEmailSubject?: string;
  inviteEmailText?: string;
  onboardingTemplateConfig?: IOnboardingTemplateConfigProperties;
  description?: string;
  splashImageUrl?: string;
  specKey?: string;
  published?: boolean;
  submittedForReview?: boolean;
  legacyLandingPage?: boolean;
  emailTemplateId?: number;
  hasUnpaidOffer?: boolean;
  gcrEnabled?: boolean;
  gcrApproverIds?: string[];
  templateName?: string;
  applicationPageTemplateName?: string;
  isFlexibleSpec?: boolean;
  workletSpecKeys?: string[];
}
