import * as React from 'react';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import cx from 'classnames';

import styles from './Notice.scss';

type INoticeType = 'info' | 'alert' | 'error' | 'success';

interface IProps {
  actions?: React.ReactElement;
  className?: string;
  message: React.ReactNode;
  type: INoticeType;
  messageClassName?: string;
  onClose?: () => void;
}

export const Notice: React.FC<Readonly<IProps>> = (props) => {
  const {
    actions,
    className,
    message,
    type,
    messageClassName,
    onClose,
  } = props;
  return (
    <div className={cx(styles.Notice, styles[type], className)}>
      <div className={styles.iconWrapper}>
        <ExclamationCircleFilled />
      </div>
      <div className={cx(styles.messageWrapper, messageClassName)}>
        {message}
      </div>
      {actions}
      {onClose && (
      <button className="pr-3" onClick={onClose} aria-label="Close Notice">
        <CloseOutlined />
      </button>
)}
    </div>
  );
};
