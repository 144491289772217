import React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { Badge, BadgeProps } from '@frontend/shadcn/components/ui/badge';
import { ArrowTrendUpIcon, ArrowTrendDownIcon } from '@revfluence/fresh-icons/solid/esm';

const PercentChangeBaseBadge = ({ className, ...props }: BadgeProps) => (
  <Badge className={cn('flex items-center leading-none px-0.5', className)} variant="outline" {...props} />
);

export const PositivePercentChangeBadge = ({ children, ...props }: BadgeProps) => (
  <PercentChangeBaseBadge className="text-green-5" {...props}>
    <ArrowTrendUpIcon className="mr-1" />
    <span>
      {children}
      %
    </span>
  </PercentChangeBaseBadge>
);
export const NegativePercentChangeBadge = ({ children, ...props }: BadgeProps) => (
  <PercentChangeBaseBadge className="text-red-5" {...props}>
    <ArrowTrendDownIcon className="mr-1" />
    <span>
      {children}
      %
    </span>
  </PercentChangeBaseBadge>
);

export const NoChangePercentChangeBadge = ({ children, ...props }: BadgeProps) => (
  <PercentChangeBaseBadge className="text-muted-foreground" {...props}>
    {children}
    %
  </PercentChangeBaseBadge>
);

export const NAPercentChangeBadge = ({ children, ...props }: BadgeProps) => (
  <PercentChangeBaseBadge className="text-muted-foreground" {...props}>
    {children}
  </PercentChangeBaseBadge>
);

type PercentChangeBadgeProps = BadgeProps & {
  percent: number | 'NA' | null | undefined;
};

const percentChangeBadgeMap: Record<string, React.ComponentType<BadgeProps>> = {
  positive: PositivePercentChangeBadge,
  negative: NegativePercentChangeBadge,
  noChange: NoChangePercentChangeBadge,
  na: NAPercentChangeBadge,
};
export const PercentChangeBadge = ({ percent, children, ...props }: PercentChangeBadgeProps) => {
  const isNA = percent === 'NA';
  const isPositive = typeof percent === 'number' && percent > 0;
  const isNegative = typeof percent === 'number' && percent < 0;
  const isNoChange = typeof percent === 'number' && percent === 0;
  const formattedPercent = typeof percent === 'number' ? percent.toFixed(0) : percent;
  const ChosenPercentChangeBadge = percentChangeBadgeMap[
      `${isPositive ? 'positive' : isNegative ? 'negative' : isNoChange ? 'noChange' : isNA ? 'na' : 'noChange'}`
    ];
  return <ChosenPercentChangeBadge {...props}>{children || formattedPercent}</ChosenPercentChangeBadge>;
};
