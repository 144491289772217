import gql from 'graphql-tag';

import { PRODUCT_FRAGMENT } from './fragments';

export const GET_PRODUCT_DETAILS_QUERY = gql`
  query GetProductDetailsQuery(
    $resourceId: Float!,
    $productId: Float!,
    $query: String,
    $cursor: String
    ) {
    productDetail: productDetailForBrand(
      resourceId: $resourceId,
      productId: $productId,
      query: $query,
      cursor: $cursor
      ) {
        id
        title
        descriptionHtml
        options {
          name
          values
        }
        variants {
          id
          product_id
          position
          title
          price
          sku
          inventory_quantity
          sellableOnlineQuantity
          inventoryPolicy
        }
        image {
          src
        }
        subtitle
    }
  }
`;

export const GET_PRODUCTS_V3_QUERY = gql`
  query GetProductsV3Query($resourceId: Float!, $query: String, $cursor: String) {
    productCollection: getProductsV3(resourceId: $resourceId, query: $query, cursor: $cursor) {
      products{
        ...ProductFragment
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_VARIANTS_BY_IDS_QUERY = gql`
  query GetVariantsByIds($resourceId: Float!, $variantIds: [Float!]!){
    variants: getVariants(resourceId: $resourceId, variantIds: $variantIds) {
      id
      title
      price
      inventory_quantity
      product_id
      product_title
      sellableOnlineQuantity
    }
  }
`;

export const GET_IMPORTED_PRODUCTS_STATS = gql`
  query GetImportedProductsStats {
    getImportedProductsStats{
      shopifyProductsCount
      shopifyVariantsCount
    }
  }
`;

export const UPDATE_IMPORTED_PRODUCT = gql`
  mutation UpdateImportedProduct($id: Int!, $input: ProductUpdateInput!) {
    updateProductById(id: $id, input: $input) {
      id
      tags
      visibility
    }
  }
`;

export const ADD_TAGS_TO_PRODUCTS = gql`
  mutation AddTagsToProducts($ids: [Int!]!, $tags: [String!]!) {
    addTagsToProducts(ids: $ids, tags: $tags) {
      id
      tags
    }
  }
`;

export const REMOVE_TAGS_FROM_PRODUCTS = gql`
  mutation RemoveTagsFromProducts($ids: [Int!]!, $tags: [String!]!) {
    removeTagsFromProducts(ids: $ids, tags: $tags) {
      id
      tags
    }
  }
`;
