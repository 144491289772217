import { H4 } from '@frontend/shadcn/components/typography/h4';
import { P } from '@frontend/shadcn/components/typography/p';
import { buttonVariants } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import React from 'react';
import { Link } from 'react-router-dom';

export const MenuHeaderLayout = ({ children }) => <div className="flex items-center">{children}</div>;
export const MenuHeader = ({ children }) => (
  <H4 className="tracking-tight text-sm font-medium text-muted-foreground mb-0">{children}</H4>
);

export const MenuList = ({ children }) => <ul className="grid gap-2 grid-cols-1">{children}</ul>;

export const MenuListItem = ({ children }) => <li>{children}</li>;

export const MenuLink = React.forwardRef<React.ElementRef<typeof Link>, React.ComponentPropsWithoutRef<typeof Link>>(
  ({ children, ...props }, ref) => (
    <Link
      ref={ref}
      className={cn(
        buttonVariants({ variant: 'ghost', size: 'sm' }),
        'flex items-center gap-2 overflow-x-hidden text-foreground hover:text-foreground group justify-start h-12 ',
      )}
      {...props}
    >
      {children}
    </Link>
  ),
);
MenuLink.displayName = 'MenuLink';

export const MenuItemContent = ({ children }) => <div className="grid gap-1 overflow-y-hidden truncate">{children}</div>;
export const MenuTitle = ({ children }) => <P fontWeight="font-medium">{children}</P>;
export const MenuCount = ({ children }) => (
  <p className="text-sm text-muted-foreground group-hover:text-foreground m-0 ml-auto">{children}</p>
);

export const MenuIconWrapper = ({ children }) => <div className="flex h-9 w-9">{children}</div>;
export const MenuLayout = ({ children }) => <div className="grid gap-6 grid-cols-1">{children}</div>;
