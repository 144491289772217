import * as React from 'react';
import {
  useParams, useHistory, useLocation,
} from 'react-router-dom';
import {
  get, isNull,
} from 'lodash';
import * as qs from 'qs';
import { useState } from 'react';

import { Modal } from '@revfluence/fresh';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';

import { LoadSpinner } from '@components';
import { useQueryParams } from '@frontend/app/hooks';

import { OfferConfirmation as OfferConfirmationComponent } from '@affiliates/components/';
import { useGetOfferByIdQuery, useUpdateOfferMutation } from '@affiliates/hooks';
import { OFFER_SOURCE, OFFER_TYPE } from '@affiliates/types/globalTypes';
import { GetOfferById } from '@affiliates/queries/types/GetOfferById';
import { Notice } from '@affiliates/AspireUI';
import { message } from '@affiliates/AspireUI';
import { SetupOffers } from '../SetupOffers/SetupOffers';

interface IParams {
  offerId: string;
}
interface IProps {
  baseUri: string;
}

export const OfferConfirmation: React.FC<Readonly<IProps>> = ({ baseUri }) => {
  const { offerId } = useParams<IParams>();

  const offerQuery = useGetOfferByIdQuery({
    variables: {
      id: Number(offerId),
    },
    skip: !offerId,
  });
  const history = useHistory();
  const location = useLocation();
  let conversionTrackingProps = null;
  let offerDetails = null;
  const query = useQueryParams();
  let programId = null;
  let taskId = null;

  if (query.has('program_id') && query.has('task_id')) {
    programId = query.get('program_id');
    taskId = query.get('task_id');
  }

  const [showSelectOffers, setShowSelectOffers] = useState<boolean>(false);
  const onChangeOffer = () => {
    setShowSelectOffers(true);
  };

  const [editOffer] = useUpdateOfferMutation({
    onCompleted() {
      message.success('Offer removed from this project!');
    },
    onError() {
      message.error('There was an issue removing the offer from this project.');
    },
  });

  const onRemoveOffer = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this offer?',
      icon: <CircleExclamationIcon />,
      content: 'Once the offer is removed, any members in the sales tracking stage must be manually moved to the next stage.'
        + ' However, this action can be undone by simply adding the offer back to the project.'
        + ' Modifying the offer for a project may impact the project-level reporting.'
        + ' Please keep this in mind before making any changes.',
      okText: 'Remove',
      okType: 'danger',
      onOk: async () => {
        await editOffer({
          variables: {
            id: offerQuery?.data?.offer?.id,
            data: {
              programId: null,
            },
          },
        });
      },
    });
  };

  const onOfferUpdate = (offerId: number) => {
    const redirectPage = {
      ...location,
      pathname: `${baseUri}/offers/${offerId}/confirmation`,
      search: qs.stringify({
        program_id: programId,
        task_id: taskId,
      }),
    };
    if (programId && taskId) {
      redirectPage.search = qs.stringify({
        program_id: programId,
        task_id: taskId,
      });
    }
    history.replace(redirectPage);
    message.success('Offer updated for this project!');
  };
  const getWorkflowParam = () => {
    if (query.has('program_id') && query.has('task_id')) {
      const programId = query.get('program_id');
      const taskId = query.get('task_id');
      return qs.stringify({
        program_id: programId,
        task_id: taskId,
      });
    }
  };
  const base_offer_url = location.pathname.split('/');
  base_offer_url.pop();
  if (offerQuery.data) {
    if (offerQuery.data.offer.links.length > 0 && !offerQuery.data.offer.isPromoLink) {
      offerDetails = {
        source: OFFER_SOURCE.TUNE,
        offer: offerQuery.data.offer,
        isOfferArchived: !isNull(offerQuery.data.offer.archivedDate),
      };
      conversionTrackingProps = {
        offerName: offerQuery.data.offer.name,
        offerTrackingType: offerQuery.data.offer.links[0].conversionTrackingType,
        ...(offerQuery.data.offer.links[0].pixelCode && { pixelCode: offerQuery.data.offer.links[0].pixelCode }),
        ...(offerQuery.data.offer.links[0].postbackUrl && { postbackUrl: offerQuery.data.offer.links[0].postbackUrl }),
      };
    }
    if (offerQuery.data.offer.promos.length > 0) {
      offerDetails = {
        source: OFFER_SOURCE.SHOPIFY,
        offer: offerQuery.data.offer,
        isOfferArchived: !isNull(offerQuery.data.offer.archivedDate),
      };
    }
  }

  const renderPageContents = (): React.ReactElement => {
    if (offerQuery.loading) {
      return <LoadSpinner />;
    }

    if (offerQuery.error) {
      throw offerQuery.error;
    }

    const offer: GetOfferById['offer'] | null = get(offerQuery, ['data', 'offer'], null);
    if (isNull(offer)) {
      return <Notice type="error" message="Unable to retrieve offer." />;
    }
    const taskId = query.get('task_id') ? query.get('task_id') : null;
    const canMigrateProgram = !!offerDetails.offer.programId;
    const onEditOffer = () => {
      const search = getWorkflowParam();
      history.push({
        ...location,
        pathname: `${base_offer_url.join('/')}/edit`,
        search,
        state: { isNewFlow: offerDetails.offer.isNewFlow, isMigrationEnabled: false },
      });
    };
    return (
      <>
        {showSelectOffers && (
          <SetupOffers
            offerType={offerDetails.source == OFFER_SOURCE.SHOPIFY ? OFFER_TYPE.PROMO_CODE : OFFER_TYPE.LINK}
            visible={showSelectOffers}
            programId={parseInt(offerDetails.offer.programId, 10)}
            setVisible={setShowSelectOffers}
            taskId={taskId}
            onOfferUpdate={onOfferUpdate}
          />
        )}
        <OfferConfirmationComponent
          onRemoveOffer={onRemoveOffer}
          offerDetails={{
            ...offerDetails,
            onClickEdit: onEditOffer,
          }}
          conversionTrackingProps={conversionTrackingProps}
          onChangeOffer={onChangeOffer}
          canMigrateProgram={canMigrateProgram}
        />
      </>
    );
  };

  return <div>{renderPageContents()}</div>;
};
