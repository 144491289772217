import React, { useEffect, useMemo, useState } from 'react';
import { tagColors, useQueryParams } from '@frontend/app/hooks';
import { pluralize } from '@frontend/app/utils/strings';
import {
  Alert,
 Button, Checkbox, Col, Empty, Input, message, Modal, Row, Skeleton, Space, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import {
 BoxesStackedIcon, MagnifyingGlassIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { isEqual, sortBy } from 'lodash';
import { useGetProductDetails } from '../hooks/useGetProductDetails';
import { useGetProductAttributesByClientId } from '../../Catalogs/hooks/useGetProductAttributesByClientId';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import { useProductDetailsDrawerContext } from './ProductDetailsDrawerContext';
import styles from './AssignTagsModal.scss';
import { getAspireTags, simpleHash, toAspireTags } from '../../utils';
import { useAddTagsToProducts } from '../hooks/useAddTagsToProducts';
import { useRemoveTagsFromProducts } from '../hooks/useRemoveTagsFromProducts';

const { Text } = Typography;

export const AssignTagsModal = () => {
  const queryParams = useQueryParams();
  const productId = queryParams.get('productId');

  const {
 isOpen, tagMode, newTags, setNewTags, selectedTags, setSelectedTags, reset, selectedProductIds,
} = useProductDetailsDrawerContext();

  const [search, setSearch] = useState('');

  const { product, loading: isProductLoading, refetch: refetchProduct } = useGetProductDetails({
    variables: {
      productId: Number(productId),
    },
    skip: !open || !productId,
    fetchPolicy: 'network-only',
  });

  const { productAttributes, loading: isAttributesLoading, refetch: refetchAttributes } = useGetProductAttributesByClientId({
    skip: !open,
    fetchPolicy: 'network-only',
  });

  const { updateProduct, isUpdatingProduct } = useUpdateProduct({
    onCompleted: () => {
      message.success('Tags updated successfully');
      refetchAttributes();
      refetchProduct();
      handleClose();
    },
    onError: (error) => {
      message.error(error.message || 'Unable to update tags');
    },
  });

  const { addTags, isAddingTags } = useAddTagsToProducts({
    onCompleted: () => {
      message.success('Tags assigned successfully');
      refetchAttributes();
      handleClose();
    },
    onError: (error) => {
      message.error(error.message || 'Unable to assign tags');
    },
  });

  const { removeTags, isRemovingTags } = useRemoveTagsFromProducts({
    onCompleted: () => {
      message.success('Tags unassigned successfully');
      refetchAttributes();
      handleClose();
    },
    onError: (error) => {
      message.error(error.message || 'Unable to unassign tags');
    },
  });

  const aspireTags = useMemo(() => [...newTags, ...(
    getAspireTags(productAttributes?.tags || [])
  )], [newTags, productAttributes?.tags]);

  const filteredTags = useMemo(() => {
    if (search) {
      return aspireTags?.filter((tag) => tag.toLowerCase().includes(search.toLowerCase()));
    }
    return aspireTags;
  }, [aspireTags, search]);

  const addNewTag = () => {
    setNewTags([search, ...newTags]);
    setSelectedTags([...selectedTags, search]);
    setSearch('');
  };

  const toggleTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleSave = () => {
    if (productId) {
      updateProduct({
        variables: {
          id: Number(productId),
          input: {
            tags: toAspireTags(selectedTags),
          },
        },
      });
    } else {
      if (tagMode === 'unassign') {
        removeTags({
          variables: {
            ids: selectedProductIds,
            tags: toAspireTags(selectedTags),
          },
        });
        return;
      }
      addTags({
        variables: {
          ids: selectedProductIds,
          tags: toAspireTags(selectedTags),
        },
      });
    }
  };

  const handleClose = () => {
    reset();
    setSearch('');
  };

  useEffect(() => {
    if (isOpen && product?.tags?.length) {
      setSelectedTags(getAspireTags(product.tags));
    }
  }, [isOpen, product.tags, setSelectedTags]);

  const hasSelectionChanged = useMemo(() => {
    if (productId) {
      return !isEqual(
        sortBy(selectedTags),
        sortBy(getAspireTags(product?.tags || [])),
      );
    }
    return selectedTags.length > 0;
  }, [productId, product?.tags, selectedTags]);

  const isUpdating = isUpdatingProduct || isAddingTags || isRemovingTags;

  if (isProductLoading || isAttributesLoading) {
    return <Skeleton active />;
  }

  return (
    <Modal
      width={600}
      title={`${tagMode === 'unassign' ? 'Unassign' : 'Assign'} Aspire Tags`}
      footer={(
        <Space>
          <Button disabled={isUpdating} onClick={handleClose}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleSave}
            loading={isUpdating}
            disabled={!hasSelectionChanged}
          >
            {tagMode === 'unassign' ? 'Unassign' : 'Assign'}
            {' '}
            Tags
          </Button>
        </Space>
      )}
      open={isOpen}
      className={styles.AssignTagsModal}
      onCancel={handleClose}
    >
      <Space direction="vertical" className={styles.fullSpace}>
        <Text type="secondary">
          {
            tagMode === 'unassign'
              ? `Unassign selected tags from ${selectedProductIds.length} selected ${pluralize(selectedProductIds.length, 'product')}`
              : `Assign selected tags to ${productId ? 'this product' : `${selectedProductIds.length} selected ${pluralize(selectedProductIds.length, 'product')}`}`
          }
        </Text>
        <Input
          prefix={<MagnifyingGlassIcon />}
          placeholder="Search tags..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {
          search && !filteredTags?.length && (
            <Alert
              message={
                tagMode === 'unassign' ? (
                  <>
                    No tags found with this name
                  </>
                ) : (
                  <>
                    No tags with this name, search again or
                    <Button type="link" onClick={addNewTag} className={styles.createTagBtn}>create this new tag</Button>
                  </>
                )
              }
            />
          )
        }
        {
          aspireTags?.length === 0 && (
            <Empty
              image={<BoxesStackedIcon />}
              description={
                tagMode === 'unassign'
                  ? <Text>No tags found.</Text>
                  : <Text>No tags found. Create new tags from the search bar.</Text>
              }
              size="small"
              style={{ margin: '32px 0' }}
            />
          )
        }
        <Space className={styles.tagRowsContainer} direction="vertical">
          {
            filteredTags?.map((tag) => (
              <Row key={tag} align="middle" justify="space-between">
                <Row align="middle" gutter={16}>
                  <Tooltip title={!selectedTags.includes(tag) && selectedTags.length >= 15 ? 'At most 15 tags can be assigned to a product' : ''}>
                    <Checkbox disabled={!selectedTags.includes(tag) && selectedTags.length >= 15} checked={selectedTags.includes(tag)} onChange={() => toggleTag(tag)} />
                  </Tooltip>
                  <Col>
                    <Tag className={styles.darkTextTag} color={tagColors[simpleHash(tag) % tagColors.length]?.backgroundColor}>{tag}</Tag>
                  </Col>
                </Row>
              </Row>
            ))
          }
        </Space>
      </Space>
    </Modal>
  );
};
