import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';

import { PageSection } from '@affiliates/AspireUI';
import {
  NeedsShopifyPermissions,
  OfferForm,
  OfferFormModes,
  OfferSummaryCard,
  TFormValues,
} from '@affiliates/components';
import { useEffect, useState } from 'react';
import { NewAffiliateOfferContainer, NewShopifyCodeContainer } from '@affiliates/containers';
import { IShopifyCredentials, useApolloClient, useRedirectOnOfferFormSubmit } from '@affiliates/hooks';
import { Typography } from '@revfluence/fresh';
import { OFFER_SOURCE, OFFER_STATUS } from '@affiliates/types/globalTypes';
import { IChildArgs } from '../containers/OfferFormContainer/types';
import { useShopifyShopQuery } from '../hooks/useShopifyShopQuery';
import { GetConnectedShopify_clientConnections } from '../queries/types/GetConnectedShopify';
import { GET_CONNECTED_SHOPIFY } from '../queries/getConnectedShopifyQuery';
import { useClientFeature } from '../contexts/ClientFeatureContext';

const { Title } = Typography;
const { useCallback, useMemo } = React;
interface IProps {
  baseUri: string;
  hasShopifyCredentials: boolean;
  shopifyCredentials: IShopifyCredentials;
  shopifyAppId: string;
  source: OFFER_SOURCE;
  isUTMEnabled: boolean;
  allowedDomains: string[];
  shopifyStoreName?: string,
}

export const NewOfferPage: React.FC<Readonly<IProps>> = ({
  baseUri,
  hasShopifyCredentials,
  shopifyAppId,
  shopifyCredentials,
  source,
  allowedDomains,
  shopifyStoreName,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { onSubmit } = useRedirectOnOfferFormSubmit(baseUri);
  const { enableMultipleShopify: isEnabledMultipleShopify, migrateToGraphQL } = useClientFeature();
  const shopifySubscriptionCheck = useShopifyShopQuery();
  const [isSubscriptionEnable, setIsSubscriptionEnable] = useState(false);
  const [connectedShopifyData, setConnectedShopifyData] = useState<GetConnectedShopify_clientConnections[]>([]);
  const staApolloClient = useApolloClient();
  const title = useMemo(() => {
    switch (source) {
      case OFFER_SOURCE.TUNE:
        return 'Create Offer Using Tracking Links';
      case OFFER_SOURCE.SHOPIFY:
        return 'Create Offer Using Promo Codes';
    }
  }, [source]);

  useEffect(() => {
    if (isEnabledMultipleShopify) {
      (async () => {
        const { data } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
        if (data?.clientConnections) {
          const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
          setConnectedShopifyData(allConnections);
        }
      })();
    }
  }, [isEnabledMultipleShopify, staApolloClient]);

  useEffect(() => {
    if (!shopifySubscriptionCheck.loading) {
      if (shopifySubscriptionCheck?.data && shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions) {
        setIsSubscriptionEnable(shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions);
      }
    }
  }, [shopifySubscriptionCheck, isSubscriptionEnable, setIsSubscriptionEnable]);
  const renderComponents = useCallback(({
    actions,
    disabledFields,
    formRef,
    initialValues,
    isSaving,
    requiredFields,
    tooltip,
    values,
    formOptionData,
    handleFormAsyncActions,
  }: IChildArgs<TFormValues>) => (
    <Row gutter={80}>
      <Col span={10}>
        <OfferForm
          disabledFields={disabledFields}
          formRef={formRef}
          hasAffiliateLink={false}
          initialValues={initialValues}
          isDeleted={false}
          isExpired={false}
          isSaving={isSaving}
          formOptionData={formOptionData}
          mode={OfferFormModes.Create}
          onDelete={actions.onDelete}
          onFieldFocused={actions.onFieldFocused}
          onFinish={actions.onFinish}
          onUnDelete={actions.onUnDelete}
          onValuesChange={actions.onChangeValues}
          requiredFields={requiredFields}
          isNewFlow={migrateToGraphQL}
          values={values}
          isSubscriptionEnable={isSubscriptionEnable}
          handleFormAsyncActions={handleFormAsyncActions}
          connectedAdvertiserForSync={connectedShopifyData.filter((c) => !c.isPrimary)}
          connectedClients={connectedShopifyData}
          initialAllowedDomains={allowedDomains}
          shopifyStoreName={shopifyStoreName}
        />
      </Col>
      <Col span={7}>
        <OfferSummaryCard
          offerFormVariant
          onChangeImage={actions.handleChangeOfferImage}
          status={OFFER_STATUS.DELETED}
          values={values}
          isExpired={false}
          isNewFlow={migrateToGraphQL}
        />
        {tooltip}
      </Col>
    </Row>
  ), [migrateToGraphQL, isSubscriptionEnable, connectedShopifyData, allowedDomains, shopifyStoreName]);

  const renderContainer = useCallback(() => {
    switch (source) {
      case OFFER_SOURCE.TUNE:
        return <NewAffiliateOfferContainer onSubmit={onSubmit}>{renderComponents}</NewAffiliateOfferContainer>;
      case OFFER_SOURCE.SHOPIFY:
        return (
          <NewShopifyCodeContainer
            shopifyCredentials={shopifyCredentials}
            onSubmit={onSubmit}
            isSubscriptionEnable={isSubscriptionEnable}
            connectedAdvertiserForSync={connectedShopifyData}
          >
            {renderComponents}
          </NewShopifyCodeContainer>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, renderComponents, source, isSubscriptionEnable]);

  if (source === OFFER_SOURCE.SHOPIFY && !hasShopifyCredentials) {
    return (
      <NeedsShopifyPermissions
        onClick={() => {
          history.push({
            ...location,
            pathname: `/settings/${encodeURIComponent(shopifyAppId)}`,
          });
        }}
      />
    );
  }

  return (
    <div style={{ padding: 24 }}>
      <PageSection>
        <Title level={4}>{title}</Title>
      </PageSection>
      <PageSection>{renderContainer()}</PageSection>
    </div>
  );
};

NewOfferPage.displayName = 'NewOfferPage';
