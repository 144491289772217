import * as React from 'react';
import { map } from 'lodash';

import { logger } from '@common';
import { ArtifactAssignmentForm, IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';

import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { RequirementAssignmentForm } from '@frontend/applications/Shared/components/RequirementAssignmentForm';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { updateContentData } from '../saveLicensedContent';
import { saveContentAsDeliverable } from '../saveLicensedContentToDeliverable';
import { ILicensedContent } from '../saveLicensedContent';

const { useState } = React;

interface IProps {
  onFlowComplete(): void;
  contents: ILicensedContent[];
}

export const ContentAssignment: React.FunctionComponent<IProps> = (props) => {
  const { onFlowComplete, contents } = props;

  const { backendServerApiEndpoint, memberId, clientId } = useApplication();
  const [saving, setSaving] = useState<boolean>(false);
  const [showRequirements, setShowRequirements] = useState<boolean>(false);
  const [loadingArtifactAssignment, setLoadingArtifactAssignment] = useState<boolean>(false);
  const { backendServerFetchResponse } = useBackendServerFetch();

  const onError = () => {
    // ERROR_TODO: Handle errors
  };

  const onAddToRequirement = (requirementId: number, markComplete: boolean) => {
    if (!requirementId) {
      onFlowComplete();
      return;
    }

    setSaving(true);
    const params = {
      client_id: clientId,
      content_ids: map(contents, (content) => content.id),
      requirement_id: requirementId,
      mark_complete: markComplete,
    };
    const url = `${backendServerApiEndpoint}/licensed_content_add_deliverable`;

    saveContentAsDeliverable(url, params, backendServerFetchResponse).then(onFlowComplete).catch(onError).finally(() => setSaving(false));
  };

  const onArtifactAssignmentSkip = () => {
    onArtifactAssignmentFlowComplete();
  };

  const onArtifactAssignmentFlowComplete = () => {
    // After artifact assignment, move to requirement assignment if it has not been done
    // Else skip to end (no re-assignment of requirements because it will create dupe deliverables)

    // Here is a trick. If there are multiple contents (>1), then all contents must be new
    const canAssignRequirements = contents.length > 1 || !contents[0].requirements_assigned;
    if (canAssignRequirements) {
      setShowRequirements(true);
    } else {
      // Can't assign requirements so jump to the end
      onFlowComplete();
    }
  };

  const onArtifactAssignmentSave = (assignment: IAssignmentAPIPayload) => {
    setLoadingArtifactAssignment(true);
    const contentUrl = `${backendServerApiEndpoint}/licensed_content`;

    const params = {
      client_id: clientId,
      content_ids: map(contents, 'id'),
      content: map(contents, (content) => ({
          ...assignment,
          id: content.id,
        })),
    };

    updateContentData(contentUrl, params, backendServerFetchResponse).then(() => {
      setLoadingArtifactAssignment(false);
      onArtifactAssignmentFlowComplete();
    }).catch(logger.error);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formParams: any = {};
  return (
    <div>
      {!showRequirements
        ? (
          <ArtifactAssignmentForm
            {...formParams}
            artifact={contents.length === 1 ? contents[0] : null}
            artifactName="content"
            secondaryArtifactName="post and content"
            onSave={onArtifactAssignmentSave}
            onSkip={onArtifactAssignmentSkip}
            loading={loadingArtifactAssignment}
          />
) : (
  <RequirementAssignmentForm
    memberId={Number(memberId)}
    artifactName="Content"
    onAddToRequirement={onAddToRequirement}
    saving={saving}
  />
      )}
    </div>
  );
};
