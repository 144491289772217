/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';
import {
  isEmpty,
  isNil,
  orderBy,
  size,
  isObject,
  filter,
  map,
  toString,
  toLower,
  first,
  isUndefined,
  isArray,
  includes,
  keyBy,
  isNumber,
  get,
  find,
} from 'lodash';
import numeral from 'numeral';
import { TWorklet, TWorkItem } from '@frontend/app/containers/Projects/types';
import {
  ITableColumn,
  Button,
  Tag,
  TagGroup,
  Tooltip,
  Modal,
} from '@revfluence/fresh';
import { PlusIcon, UserIcon } from '@revfluence/fresh-icons/regular/esm';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import { EllipsisLabel } from '@frontend/app/components';
import { GetAllCompletedMembersQuery_members } from '@frontend/app/queries/types/GetAllCompletedMembersQuery';
import {
  ColumnKey,
} from '@frontend/app/containers/Projects/constants';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import {
  SocialProfileCell,
} from '@frontend/app/components/MemberTable/CustomCells/SocialProfileCell';
import { TNetworkIdentifier } from '@components';
import { NameCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/NameCell';
import { LastMessageCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/LastMessageCell';
import { OwnersCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/OwnersCell';
import { hasError, hasNotification } from '@frontend/app/utils/task';
import { useMessagingContext } from '@frontend/hooks';
import {
  IMember,
  IThread,
  useClientFeatureEnabled,
  useRemoveMembersFromTagsMutation,
  useModifyCommunityMembersMutation,
  useModifyProgramMembersMutation,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import {
  IField,
} from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { MemberSearchQuery_members_owners as IOwners } from '@frontend/app/queries/types/MemberSearchQuery';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';
import {
  ICounts,
} from '@frontend/app/containers/Projects/hooks';
import { CustomFieldType } from '@frontend/app/types/globalTypes';
import { useMembershipSources } from '@frontend/app/containers/Projects/hooks';
import { pfaV2Routes } from '@frontend/app/containers/Settings/ProductFulfillment/routes';
import { useMemberColumns } from '../../MemberTable/hooks';
import { sortStr, formatDate } from '../util';
import { CellModalType, MSG_DURATION } from '../MembersTable';
import styles from '../MembersTable.scss';

const { useMemo, useCallback } = React;

export type MemberTableRow = GetAllCompletedMembersQuery_members & {
  id: string;
  key: string;
  name: string;
  member: any;
  fields: any;
  email: string;
  owners: IOwners[];
  [ColumnKey.DaysInStage]: number;
  [ColumnKey.CTA]: any;
  [ColumnKey.DateAdded]: number;
  [ColumnKey.DateCompleted]: number;
  [ColumnKey.TaskName]: ITask;
  [ColumnKey.WorkletName]: ITask;
  [ColumnKey.WorkletTaskName]: ITask;
  [ColumnKey.PendingContracts]?: string;
  [ColumnKey.PaypalAddress]?: string;
  [ColumnKey.LastPaymentSent]?: Date;
  [ColumnKey.Message]?: string;
  [ColumnKey.Deliverables]?: string;
  [ColumnKey.Price]?: number;
  [ColumnKey.NextDeadline]?: Date;
  [ColumnKey.OpenDeliverables]?: number;
  [ColumnKey.CompletedDeliverables]?: number;
  [ColumnKey.Address1]?: string;
  [ColumnKey.Address2]?: string;
  [ColumnKey.City]?: string;
  [ColumnKey.State]?: string;
  [ColumnKey.PostalCode]?: string;
  [ColumnKey.Country]?: string;
  [ColumnKey.OrderStatus]?: string;
  [ColumnKey.Quantity]?: string;
  [ColumnKey.Products]?: string;
  [ColumnKey.ShipmentStatus]?: string;
  [ColumnKey.TrackingNumber]?: string;
  [ColumnKey.PromoCode]?: string;
  [ColumnKey.PromoCodeStatus]?: string;
  [ColumnKey.AffiliateLink]?: string;
  [ColumnKey.ApplicantSource]?: string;
  _raw: GetAllCompletedMembersQuery_members & {
    fields: any;
  };
};

const SOCIAL_FIELD_NAMES = [
  'Instagram',
  'TikTok',
  'YouTube',
  'Pinterest',
  'Facebook',
  'Twitter',
  'Blog',
];

const TAG_COLORS = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'mauve',
  'coral',
  'apricot',
  'marigold',
  'butter',
  'apple',
  'asparagus',
  'tiffany',
  'baby',
  'carolina',
  'rose',
  'watermelon',
  '#f50',
  '#2db7f5',
  '#87d068',
  '#108ee9',
];

export const useRenderSelectedColumns = ({
    selectedColumns,
    cellActionsByColumn,
    counts,
    worklets,
    onTagRemoved,
    onGroupRemoved,
    onProjectRemoved,
    onCellModalOpen,
    projectId,
  }: {
    worklets: TWorklet[];
    counts: ICounts;
    cellActionsByColumn?: {
      name: (memberId: IMember['id']) => void;
      lastMessage: (threadId: IThread['id']) => void;
      cta: (workItem: TWorkItem) => void;
      task: THandleTaskSelected;
    };
    projectId?: number;
    selectedColumns: IField[];
    onTagRemoved: (tagId: number, memberId: number) => void;
    onGroupRemoved: (groupId: number, memberId: number) => void;
    onProjectRemoved: (projectId: number, memberId: number) => void;
    onCellModalOpen: (cellModalType: CellModalType, member: IMember) => void;
  }) => {
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);
  const [removeMemberFromTag] = useRemoveMembersFromTagsMutation();
  const [removeMemberFromCommunity] = useModifyCommunityMembersMutation('remove');
  const [removeMemberFromProgram] = useModifyProgramMembersMutation('remove');
  const memberColumns = useMemberColumns();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();
  const history = useHistory();

  const schemasByName = React.useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );

  const socialSchemaIds = useMemo(() => new Set<string>(
    map(
      filter(memberColumns, (column) => SOCIAL_FIELD_NAMES.includes(column.headerName)),
      (column) => toString(column.field),
    ),
  ), [memberColumns]);

  const deleteTagFromMember = useCallback(async (tagId: number, memberId: number) => {
    try {
      await removeMemberFromTag({
        variables: {
          memberIds: [memberId],
          tagIds: [tagId],
        },
      });
      onTagRemoved(tagId, memberId);
      showSuccessMessage('Tag removed from member', MSG_DURATION);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    showSuccessMessage,
    showErrorMessage,
    removeMemberFromTag,
    onTagRemoved,
  ]);

  const deleteProjectFromMember = useCallback(async (programId: number, memberId: number) => {
    try {
      await removeMemberFromProgram({
        variables: {
          memberIds: [memberId],
          programIds: [programId],
        },
      });
      onProjectRemoved(programId, memberId);
      showSuccessMessage('Member removed from project', MSG_DURATION);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    removeMemberFromProgram,
    onProjectRemoved,
    showSuccessMessage,
    showErrorMessage,
  ]);

  const deleteGroupFromMember = useCallback(async (groupId: number, memberId: number) => {
    try {
      await removeMemberFromCommunity({
        variables: {
          memberIds: [memberId],
          communityIds: [groupId],
        },
      });
      onGroupRemoved(groupId, memberId);
      showSuccessMessage('Member removed from group', MSG_DURATION);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    removeMemberFromCommunity,
    onGroupRemoved,
    showSuccessMessage,
    showErrorMessage,
  ]);

  const handleProjectClick = useCallback((projectId) => {
    history.push(`/projects/${projectId}`);
  }, [history]);

  const handleGroupClick = useCallback((groupId) => {
    history.push({
      pathname: '/member_table',
      search: qs.stringify({
        communityId: groupId,
      }),
    });
  }, [history]);

  const { sourceConfigs } = useMembershipSources();

  const specialRenders = useCallback((col: IField) => {
    if (col?.field === ColumnKey.Name) {
      return {
        sorter: (a, b) => sortStr(a?.name, b?.name),
        render: (member) => (
          <NameCell
            imageUrl={member?.profilePicture}
            name={member?.name}
            onClick={() => { cellActionsByColumn.name?.(member.id); }}
          />
        ),
      };
    }
    if (col?.field === 'appliedDate') {
      return {
        sorter: (a, b) => {
          const membershipDataA = find(a.programMembershipsLogs, (d) => (
            d.status === 'new' && d.programId === projectId
          ));
          if (!membershipDataA?.createdDate) return 1;
          const membershipDataB = find(b.programMembershipsLogs, (d) => (
            d.status === 'new' && d.programId === projectId
          ));
          if (!membershipDataB?.createdDate) return -1;
          return Date.parse(membershipDataA?.createdDate) - Date.parse(membershipDataB?.createdDate);
        },
        render: (member) => {
          const membershipData = find(member.programMembershipsLogs, (d) => (
            d.status === 'new' && d.programId === projectId
          ));
          if (!membershipData?.createdDate) {
            return null;
          }
          return formatDate(membershipData.createdDate);
        },
      };
    }
    if (col?.field === ColumnKey.LastMessage) {
      return {
        sorter: (a, b) => (
          (a.member?.lastMessage?.internalDate || 0) - (b.member?.lastMessage?.internalDate || 0)
        ),
        render: (memberData) => (
          <div className={styles.fixedCell}>
            <LastMessageCell
              messageData={memberData.lastMessage}
              member={memberData}
              onClick={() => {
                cellActionsByColumn.lastMessage?.(memberData.lastMessage.threadId);
              }}
            />
          </div>
        ),
      };
    }
    if (col?.field === ColumnKey.CTA) {
      return {
        render: (data) => (
          <div className={styles.fixedCell}>
            <Button
              type="link"
              onClick={() => { cellActionsByColumn.cta?.(data?.workItem); }}
              style={{ padding: 0 }}
            >
              {data?.taskMetaData?.singleCTAText || 'Mark as Done'}
            </Button>
          </div>
        ),
      };
    }
    if (col?.field === ColumnKey.WorkletTaskName) {
      return {
        sorter: (a, b) => sortStr(a[ColumnKey.WorkletTaskName]?.taskName, b[ColumnKey.WorkletTaskName]?.taskName),
        render: (task) => {
          if (isNil(task?.taskName)) return null;

          const error = hasError(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
          const notification = hasNotification(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
          let color = 'processing'; // Blue
          if (error) {
            color = 'error'; // red
          } else if (notification) {
            color = 'warning'; // Yellow
          }

          return (
            <div className={styles.fixedCell}>
              <Tag
                onClick={() => cellActionsByColumn.task?.(task.workletSpecUri, task.taskId)}
                className={color === 'processing' ? cx(styles.tag, styles.defaultTag) : styles.tag}
                color={color}
              >
                {task.taskName}
              </Tag>
            </div>
          );
        },
      };
    }
    if (col?.field === ColumnKey.WorkletName) {
      return {
        sorter: (a, b) => sortStr(a[ColumnKey.WorkletName]?.taskName, b[ColumnKey.WorkletName]?.taskName),
        render: (data) => (
          <Button
            type="link"
            onClick={() => { cellActionsByColumn.task?.(data?.workletSpecUri, data.taskId); }}
            style={{ padding: 0 }}
          >
            {getWorkletNameBySpecURI(data?.workletSpecUri, worklets)}
          </Button>
        ),
      };
    }
    if (col?.field === ColumnKey.Owners) {
      return {
        sorter: (a, b) => {
          const aOwners = orderBy(a?.owners, (owner) => owner.name, 'asc');
          const bOwners = orderBy(b?.owners, (owner) => owner.name, 'asc');
          const compare = sortStr(aOwners[0]?.name, bOwners[0]?.name);
          if (size(aOwners) <= 1 || size(bOwners) <= 1) {
            if (compare === 0 && size(aOwners) < size(bOwners)) {
              return -1;
            }
            if (compare === 0 && size(bOwners) < size(aOwners)) {
              return 1;
            }
            return compare;
          }

          if (compare !== 0) return compare;
          return sortStr(aOwners[1]?.name, bOwners[1]?.name);
        },
        render: (member) => (
          <div className={styles.fixedCell}>
            <OwnersCell owners={member?.owners} />
          </div>
        ),
      };
    }
    if (col?.field === ColumnKey.Tags) {
      return {
        sorter: (a, b) => {
          const aTags = a?.[ColumnKey.Tags];
          const bTags = b?.[ColumnKey.Tags];
          const compare = sortStr(aTags[0]?.name, bTags[0]?.name);
          if (size(aTags) <= 1 || size(bTags) <= 1) {
            if (compare === 0 && size(aTags) < size(bTags)) {
              return -1;
            }
            if (compare === 0 && size(bTags) < size(aTags)) {
              return 1;
            }
            return compare;
          }

          if (compare !== 0) return compare;
          return sortStr(aTags[1]?.name, bTags[1]?.name);
        },
        render: (member) => {
          const tags = member?.[ColumnKey.Tags];
          const max = size(tags[0]?.name) + size((tags[1]?.name));
          return (
            <div className={styles.tagsCell}>
              <TagGroup maxCharCount={max}>
                {map(
                  tags,
                  (tag) => {
                    const color = TAG_COLORS[tag.id % size(TAG_COLORS)];
                    return (
                      <Tag
                        onClose={() => deleteTagFromMember(Number(tag.id), Number(member.id))}
                        closable
                        color={color}
                      >
                        {tag[col?.itemProp]}
                      </Tag>
                    );
                  },
                )}
              </TagGroup>
              <Tooltip title="Add Tag">
                <Button
                  className={styles.addTagIcon}
                  icon={<PlusIcon />}
                  size="small"
                  onClick={() => {
                    onCellModalOpen('tags', member);
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      };
    }
    if (col?.field === ColumnKey.Programs) {
      return {
        sorter: (a, b) => {
          const aProjects = a?.[ColumnKey.Programs];
          const bProjects = b?.[ColumnKey.Programs];
          const compare = sortStr(aProjects[0]?.title, bProjects[0]?.title);
          if (size(aProjects) <= 1 || size(bProjects) <= 1) {
            if (compare === 0 && size(aProjects) < size(bProjects)) {
              return -1;
            }
            if (compare === 0 && size(bProjects) < size(aProjects)) {
              return 1;
            }
            return compare;
          }

          if (compare !== 0) return compare;
          return sortStr(aProjects[1]?.title, bProjects[1]?.title);
        },
        render: (member) => {
          const projects = member?.[ColumnKey.Programs];
          const max = size(projects[0]?.title);
          return (
            <div className={styles.tagsCell}>
              <TagGroup maxCharCount={max}>
                {map(
                  projects,
                  (project) => (
                    <Tag
                      color="processing"
                      className={styles.clickableTag}
                      onClose={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                          open: false,
                          title: 'Remove Member from Project?',
                          onOk: () => deleteProjectFromMember(Number(project.id), Number(member.id)),
                          okText: 'Remove',
                          okButtonProps: { danger: true },
                          cancelText: 'Cancel',
                        });
                      }}
                      onClick={() => handleProjectClick(project.id)}
                      closable
                    >
                      {project[col?.itemProp]}
                    </Tag>
                    ),
                )}
              </TagGroup>
              <Tooltip title="Add to Project">
                <Button
                  className={styles.addTagIcon}
                  icon={<PlusIcon />}
                  size="small"
                  onClick={() => {
                    onCellModalOpen('projects', member);
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      };
    }
    if (col?.field === ColumnKey.Communities) {
      return {
        sorter: (a, b) => {
          const aGroups = a?.[ColumnKey.Communities];
          const bGroups = b?.[ColumnKey.Communities];
          const compare = sortStr(aGroups[0]?.title, bGroups[0]?.title);
          if (size(aGroups) <= 1 || size(bGroups) <= 1) {
            if (compare === 0 && size(aGroups) < size(bGroups)) {
              return -1;
            }
            if (compare === 0 && size(bGroups) < size(aGroups)) {
              return 1;
            }
            return compare;
          }

          if (compare !== 0) return compare;
          return sortStr(aGroups[1]?.title, bGroups[1]?.title);
        },
        render: (member) => {
          const groups = member?.[ColumnKey.Communities];
          const max = size(groups[0]?.title);
          return (
            <div className={styles.tagsCell}>
              <TagGroup maxCharCount={max}>
                {map(
                  groups,
                  (group) => (
                    <Tag
                      color="processing"
                      className={styles.clickableTag}
                      onClose={() => deleteGroupFromMember(Number(group.id), Number(member.id))}
                      onClick={() => handleGroupClick(group.id)}
                      closable
                    >
                      {group[col?.itemProp]}
                    </Tag>
                    ),
                )}
              </TagGroup>
              <Tooltip title="Add to Group">
                <Button
                  className={styles.addTagIcon}
                  icon={<PlusIcon />}
                  size="small"
                  onClick={() => {
                    onCellModalOpen('groups', member);
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      };
    }
    if (col?.field === ColumnKey.OrderFulfillmentStatus) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          const aFulfillmentStatus = first(aProgramOrders).fulfillmentStatus;
          const bFulfillmentStatus = first(bProgramOrders).fulfillmentStatus;
          return aFulfillmentStatus < bFulfillmentStatus ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;

          const programOrders = filter(orders, (order) => order.programId === projectId);

          if (isEmpty(programOrders)) return;

          const fulfillmentStatuses = map(orders, (order) => order?.fulfillmentStatus);
          const firstFulfillmentStatus = fulfillmentStatuses[0];
          return (
            <TagGroup maxCharCount={size(firstFulfillmentStatus)}>
              {
                map(
                  fulfillmentStatuses,
                  (fulfillmentStatus) => <Tag>{fulfillmentStatus}</Tag>,
                )
              }
            </TagGroup>
          );
        },
      };
    }
    if (col?.field === ColumnKey.OrderShipmentStatus) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          const aShipmentStatus = first(aProgramOrders).shipmentStatus;
          const bShipmentStatus = first(bProgramOrders).shipmentStatus;
          return aShipmentStatus < bShipmentStatus ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;

          const programOrders = filter(orders, (order) => order.programId === projectId);

          if (isEmpty(programOrders)) return;

          const shipmentStatuses = map(programOrders, (order) => order?.shipmentStatus);
          const firstShipmentStatus = shipmentStatuses[0];
          return (
            <TagGroup maxCharCount={size(firstShipmentStatus)}>
              {
                map(
                  shipmentStatuses,
                  (shipmentStatus) => <Tag>{shipmentStatus}</Tag>,
                )
              }
            </TagGroup>
          );
        },
      };
    }
    if (col?.field === ColumnKey.OrderTrackingNumber) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          const aTrackingNumber = first(aProgramOrders).trackingNumber;
          const bTrackingNumber = first(bProgramOrders).trackingNumber;
          return aTrackingNumber < bTrackingNumber ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;

          const programOrders = filter(orders, (order) => order.programId === projectId);

          if (isEmpty(programOrders)) return;

          const trackingNumbers = map(programOrders, (order) => order?.trackingNumber);
          const firstTrackingNumber = trackingNumbers[0];
          return (
            <TagGroup maxCharCount={size(firstTrackingNumber)}>
              {
                map(
                  trackingNumbers,
                  (trackingNumber) => <Tag>{trackingNumber}</Tag>,
                )
              }
            </TagGroup>
          );
        },
      };
    }
    if (col?.field === ColumnKey.OrderDate) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          const aOrderDate = formatDate(first(aProgramOrders).orderDate);
          const bOrderDate = formatDate(first(bProgramOrders).orderDate);
          return aOrderDate < bOrderDate ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;

          const programOrders = filter(orders, (order) => order.programId === projectId);

          if (isEmpty(programOrders)) return;

          const orderDates = map(programOrders, (order) => order?.orderDate);
          const firstOrderDate = formatDate(orderDates[0]);
          return (
            <TagGroup maxCharCount={size(firstOrderDate)}>
              {
                map(
                  orderDates,
                  (orderDate) => <Tag>{formatDate(orderDate)}</Tag>,
                )
              }
            </TagGroup>
          );
        },
      };
    }
    if (col?.field === ColumnKey.TalentAgents) {
      return {
        sorter: (a, b) => sortStr(a?.talentAgents?.join(', '), b?.talentAgents?.join(', ')),
        render: (talentAgents) => {
          if (isEmpty(talentAgents)) return;
          const max = size(talentAgents[0]?.agentName) + size((talentAgents[1]?.agentName));
          return (
            <div className={styles.tagsCell}>
              <TagGroup maxCharCount={max}>
                {map(talentAgents,
                  (talentAgent) => (
                    <Tag
                      icon={<UserIcon />}
                    >
                      {talentAgent.agentName}
                    </Tag>
                    ))}
              </TagGroup>
            </div>
            );
        },
      };
    }
    if (col?.field === ColumnKey.OrderNumber) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          const aOrderNumber = first(aProgramOrders).orderNumber;
          const bOrderNumber = first(bProgramOrders).orderNumber;
          return aOrderNumber < bOrderNumber ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;

          const programOrders = filter(orders, (order) => order.programId === projectId);

          if (isEmpty(programOrders)) return;

          const orderNumbers = map(programOrders, (order) => order?.orderNumber);

          const firstOrderNumber = orderNumbers[0];

          return (
            <TagGroup maxCharCount={size(toString(firstOrderNumber))}>
              {
                map(
                  orderNumbers,
                  (orderNumber) => (<Tag>{toString(orderNumber)}</Tag>),
                )
              }
            </TagGroup>
          );
        },
      };
    }
    if (col?.field === ColumnKey.TotalOrderedProductsInPending) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          let aProductsCount = 0;
          aProgramOrders.forEach((order) => {
            order?.productsOrderedInPending?.forEach((product) => {
              aProductsCount += product?.quantity || 1;
            });
          });
          let bProductsCount = 0;
          bProgramOrders.forEach((order) => {
            order?.productsOrderedInPending?.forEach((product) => {
              bProductsCount += product?.quantity || 1;
            });
          });
          return aProductsCount < bProductsCount ? -1 : 1;
        },
        render: (orders) => {
          if (!orders) return;
          let productsCount = 0;
          orders.forEach((order) => {
            order?.productsOrderedInPending?.forEach((product) => {
              productsCount += product?.quantity || 1;
            });
          });
          return (<>{productsCount}</>);
        },
      };
    }
    if (col?.field === ColumnKey.Products) {
      return {
        title: 'Product Selected',
        sorter: (a, b) => sortStr(a?.products?.join(', '), b?.products?.join(', ')),
        render: (products) => (
          <EllipsisLabel
            tooltipPlacement="top"
            showTooltip
          >
            {!isEmpty(products) && products.join(', ')}
          </EllipsisLabel>
        ),
      };
    }
    if (col?.field === ColumnKey.ApplicantSource) {
      return {
        title: 'Applicant Source',
        sorter: (a, b) => {
          let aProjectMembership;
          if (projectId) {
            aProjectMembership = filter(a.programMemberships, (membership) => membership.programId === projectId)[0];
          } else {
            aProjectMembership = first(a.programMemberships);
          }
          const aSourceConfig = get(sourceConfigs, aProjectMembership?.utmSource);
          const aSourceName = aSourceConfig ? aSourceConfig.name : 'Other';

          let bProjectMembership;
          if (projectId) {
            bProjectMembership = filter(b.programMemberships, (membership) => membership.programId === projectId)[0];
          } else {
            bProjectMembership = first(b.programMemberships);
          }
          const bSourceConfig = get(sourceConfigs, bProjectMembership?.utmSource);
          const bSourceName = bSourceConfig ? bSourceConfig.name : 'Other';
          return sortStr(aSourceName, bSourceName);
        },
        render: (programMemberships) => {
          let projectMembership;
          if (projectId) {
            projectMembership = filter(programMemberships, (membership) => membership.programId === projectId)[0];
          } else {
            projectMembership = first(programMemberships);
          }
          const sourceConfig = get(sourceConfigs, projectMembership?.utmSource);
          const sourceName = sourceConfig ? sourceConfig.name : 'Other';

          return (
            <div>{sourceName}</div>
          );
        },
      };
    }
    if (col?.field === ColumnKey.TotalOrderedMarketValueInPending) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          let aTotalOrdersCost = 0;
          aProgramOrders.forEach((order) => {
            order?.productsOrderedInPending?.forEach((product) => {
              aTotalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
          });
          let bTotalOrdersCost = 0;
          bProgramOrders.forEach((order) => {
            order?.productsOrderedInPending?.forEach((product) => {
              bTotalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
          });
          return aTotalOrdersCost < bTotalOrdersCost ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;
          // TODO what if we have mutliple currencies?
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: orders[0]?.productsOrderedInPending?.price_set?.presentment_money?.currency_code || 'USD',
          });
          let totalOrdersCost = 0;
          orders.forEach((order) => {
            order?.productsOrderedInPending?.forEach((product) => {
              totalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
          });
          return (
            <>
              {formatter.format(totalOrdersCost)}
            </>
          );
        },
      };
    }

    if (col?.field === ColumnKey.TotalShippedProducts) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          let aProductsCount = 0;
          aProgramOrders.forEach((order) => {
            order?.productsOrderedShopify?.forEach((product) => {
              aProductsCount += product?.quantity || 1;
            });
            order?.productsOrdered?.forEach((product) => {
              // this might not be the right value
              aProductsCount += product?.quantity || 1;
            });
          });
          let bProductsCount = 0;
          bProgramOrders.forEach((order) => {
            order?.productsOrderedShopify?.forEach((product) => {
              bProductsCount += product?.quantity || 1;
            });
            order?.productsOrdered?.forEach((product) => {
              // this might not be the right value
              bProductsCount += product?.quantity || 1;
            });
          });
          return aProductsCount < bProductsCount ? -1 : 1;
        },
        render: (orders) => {
          if (!orders) return;
          let productsCount = 0;
          orders.forEach((order) => {
            order?.productsOrderedShopify?.forEach((product) => {
              productsCount += product?.quantity || 1;
            });
            order?.productsOrdered?.forEach((product) => {
              // this might not be the right value
              productsCount += product?.quantity || 1;
            });
          });
          return (<>{productsCount}</>);
        },
      };
    }
    if (col?.field === ColumnKey.TotalShippedMarketValue) {
      return {
        sorter: (a, b) => {
          const aProgramOrders = filter(a.orders, (order) => order.programId === projectId);
          const bProgramOrders = filter(b.orders, (order) => order.programId === projectId);

          if (size(aProgramOrders) === 0) return -1;
          if (size(bProgramOrders) === 0) return 1;

          let aTotalOrdersCost = 0;
          aProgramOrders.forEach((order) => {
            order?.productsOrderedShopify?.forEach((product) => {
              aTotalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
            order?.productsOrdered?.forEach((product) => {
              // this might not be the right value
              aTotalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
          });
          let bTotalOrdersCost = 0;
          bProgramOrders.forEach((order) => {
            order?.productsOrderedShopify?.forEach((product) => {
              bTotalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
            order?.productsOrdered?.forEach((product) => {
              // this might not be the right value
              bTotalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
          });
          return aTotalOrdersCost < bTotalOrdersCost ? -1 : 1;
        },
        render: (orders) => {
          if (isEmpty(orders)) return;
          // TODO what if we have mutliple currencies?
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: orders[0]?.productsOrderedShopify?.price_set?.presentment_money?.currency_code || 'USD',
          });
          let totalOrdersCost = 0;
          orders.forEach((order) => {
            order?.productsOrderedShopify?.forEach((product) => {
              totalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
            order?.productsOrdered?.forEach((product) => {
              // this might not be the right value
              totalOrdersCost += product?.price * (product?.quantity || 1) || 0;
            });
          });
          return (
            <>
              {formatter.format(totalOrdersCost)}
            </>
          );
        },
      };
    }
    if (col?.field === ColumnKey.Address) {
      return {
        render: (member) => {
          const address1 = schemasByName && member && member.fields[schemasByName[ColumnKey.Address1]?.schemaId];
          const address2 = schemasByName && member && member.fields[schemasByName[ColumnKey.Address2]?.schemaId];
          const city = schemasByName && member && member.fields[schemasByName[ColumnKey.City]?.schemaId];
          const state = schemasByName && member && member.fields[schemasByName[ColumnKey.State]?.schemaId];
          const postalCode = schemasByName && member && member.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
          const country = schemasByName && member && member.fields[schemasByName[ColumnKey.Country]?.schemaId];

          const combinedAddress = `${address1 || ''} ${address2 || ''} ${city || ''} ${state || ''} ${postalCode || ''} ${country || ''}`;
          return (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {combinedAddress}
            </EllipsisLabel>
          );
        },
        sorter: (a, b) => {
          const aAddress1 = schemasByName && a && a.fields[schemasByName[ColumnKey.Address1]?.schemaId];
          const aAddress2 = schemasByName && a && a.fields[schemasByName[ColumnKey.Address2]?.schemaId];
          const aCity = schemasByName && a && a.fields[schemasByName[ColumnKey.City]?.schemaId];
          const aState = schemasByName && a && a.fields[schemasByName[ColumnKey.State]?.schemaId];
          const aPostalCode = schemasByName && a && a.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
          const aCountry = schemasByName && a && a.fields[schemasByName[ColumnKey.Country]?.schemaId];

          const aCombinedAddress = `${aAddress1 || ''} ${aAddress2 || ''} ${aCity || ''} ${aState || ''} ${aPostalCode || ''} ${aCountry || ''}`;

          const bAddress1 = schemasByName && b && b.fields[schemasByName[ColumnKey.Address1]?.schemaId];
          const bAddress2 = schemasByName && b && b.fields[schemasByName[ColumnKey.Address2]?.schemaId];
          const bCity = schemasByName && b && b.fields[schemasByName[ColumnKey.City]?.schemaId];
          const bState = schemasByName && b && b.fields[schemasByName[ColumnKey.State]?.schemaId];
          const bPostalCode = schemasByName && b && b.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
          const bCountry = schemasByName && b && b.fields[schemasByName[ColumnKey.Country]?.schemaId];

          const bCombinedAddress = `${bAddress1 || ''} ${bAddress2 || ''} ${bCity || ''} ${bState || ''} ${bPostalCode || ''} ${bCountry || ''}`;
          return sortStr(aCombinedAddress, bCombinedAddress);
        },
      };
    }
    if (col?.field === ColumnKey.ShippingCountry) {
      return {
        sorter: (a, b) => sortStr(a?.country, b?.country),
        render: (country) => (
          <EllipsisLabel
            tooltipPlacement="top"
            showTooltip
          >
            {country}
          </EllipsisLabel>
        ),
      };
    }
    if (socialSchemaIds.has(col?.field)) {
      return {
        sorter: (a, b) => sortStr((a.fields?.[col?.field] || ''), (b.fields?.[col?.field] || '')),
        render: (member) => (
          <SocialProfileCell
            isEditable={false}
            isEditing={false}
            network={toLower(col?.headerName) as TNetworkIdentifier}
            onChangeValue={() => undefined}
            onToggleIsEditing={() => undefined}
            username={member?.fields[col?.field]}
            member={member}
          />
        ),
      };
    }
    if (col?.field === ColumnKey.SentCatalog) {
      return {
        sorter: (a, b) => sortStr(a?.sentCatalog?.name, b?.sentCatalog?.name),
        render: (member) => (
          <Button
            type="link"
            onClick={() => history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', member?.sentCatalog?.id))}
            className={styles.leftAlignedButton}
          >
            {member?.sentCatalog?.name}
          </Button>
        ),
      };
    }
    if (col?.field === ColumnKey.SentSelectionRule) {
      return {
        sorter: (a, b) => sortStr(a?.sentSelectionRule?.name, b?.sentSelectionRule?.name),
        render: (member) => (
          <Button
            type="link"
            onClick={() => {
              history.push({
                pathname: pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', member?.sentCatalog?.id),
                search: `?${new URLSearchParams({ screen: 'selection-criteria' }).toString()}`,
              });
            }}
            className={styles.leftAlignedButton}
          >
            {member?.sentSelectionRule?.name}
          </Button>
        ),
      };
    }
    return null;
  }, [
    onCellModalOpen,
    handleProjectClick,
    handleGroupClick,
    deleteTagFromMember,
    deleteGroupFromMember,
    deleteProjectFromMember,
    socialSchemaIds,
    counts,
    worklets,
    cellActionsByColumn,
    projectId,
    schemasByName,
    sourceConfigs,
    history,
  ]);

  const columns: ITableColumn<MemberTableRow> = useMemo(() => {
    if (!isPFAColumnsEnabled) return [];
    if (isEmpty(selectedColumns)) return [];

    const result = [];
    selectedColumns.forEach((col) => {
      let rendered;
      const props = {
        key: col?.field,
        dataIndex: col?.dataIndex,
        title: col?.headerName,
        width: col?.width,
        minWidth: col?.minWidth,
        maxWidth: col?.maxWidth,
        fixed: col?.fixed,
      };

      const specialRender = specialRenders(col);
      if (specialRender) {
        rendered = {
          ...props,
          ...specialRender,
        };
      } else {
        rendered = {
          ...props,
          ...{
            sorter: (a, b) => {
              const aVal = a?.[col.field];
              const bVal = b?.[col.field];

              if (isNumber(aVal) || isNumber(bVal)) {
                if (isNil(aVal)) return -1;
                if (isNil(bVal)) return 1;
                return aVal - bVal;
              }

              if (includes([CustomFieldType.DATE, CustomFieldType.ANNUAL], col?.type)) {
                return Date.parse(aVal) - Date.parse(bVal);
              }

              return sortStr(aVal, bVal);
            },
            render: (val) => {
              let value = val;

              if (isObject(value)) {
                if (!Number.isNaN(+col?.field)) {
                  value = (value as any)?.fields[col?.field];
                } else {
                  value = value?.[col?.field];
                }
              }

              if (col?.type === CustomFieldType.BOOLEAN) {
                if (value) {
                  value = 'YES';
                } else {
                  value = 'NO';
                }
              }

              if (!isNil(value) && col?.type === CustomFieldType.PERCENTAGE) {
                value = numeral(value).format('0.[00]%');
              }

              if (!isNil(value) && col?.type === CustomFieldType.NUMBER) {
                value = value?.toLocaleString('en-US');
              }

              if (includes([CustomFieldType.DATE, CustomFieldType.ANNUAL], col?.type)) {
                value = formatDate(value);
              }

              if (col?.type === CustomFieldType.ARRAY && isArray(value)) {
                value = value.join(', ');
              }

              if (isUndefined(value) || isObject(value) || isArray(value)) {
                value = '';
              }

              return (
                <EllipsisLabel
                  tooltipPlacement="top"
                  showTooltip
                >
                  {value}
                </EllipsisLabel>
              );
            },
          },
        };
      }

      if (rendered) {
        result.push(rendered);
      }
    });

    return result;
  }, [
    selectedColumns,
    isPFAColumnsEnabled,
    specialRenders,
  ]);

  return columns;
};
