import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
 map, size, includes, upperCase, find, isEmpty, each, first, trim,
} from 'lodash';
import { logger } from '@common';
import { Modal, Button } from '@components';
import { AccordionSection } from '@frontend/app/components/Accordion/AccordionSection';
import { EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { IEmailEditorState } from '@frontend/app/components';
import { MessageType, SendMessageInput } from '@frontend/app/types/globalTypes';
import { SHOPIFY_APP_ID } from '@frontend/app/constants/applicationIds';
import { useAddProjectEvent } from '@frontend/app/containers/Projects/hooks';
import { subjectLineForClientAndProgram } from '@frontend/applications/Shared/utils';
import { COUNTRY_DATA } from '@frontend/app/constants/countryData';
import { toLowerAndTrim } from '@frontend/utils';
import { ShopifyShippingLine } from '@frontend/applications/ShopifyApp/components/types';
import { ConfirmationPage } from './ConfirmationPage';
import { IBulkCreateOrderResult, SuccessPage } from './SuccessPage';
import { InvalidMembers } from './InvalidMembers';
import { ComposeMessage } from './ComposeMessage';
import { InvalidMemberAddress, MemberWithInvalidAddress } from './InvalidMemberAddress';

import { IResource, useGetResources, useBulkCreateOrder } from '../hooks';
import { IShippingAddress, useProductFulfillmentContext } from '../context';

import styles from './OrderCreationWizard.scss';
import { SelectProductFormV3 } from './SelectProductFormV3';
import { SaveOrderParams } from '../types/globalTypes';
import { isUSA } from '../utils';

const {
 useState, useEffect, useMemo, useImperativeHandle, useCallback,
} = React;

interface IProps {}
export interface ICreateOrderWizardRef {
  getCurrentStep(): number;
}

const DUPLICATE_ORDER_ERROR_MESSAGE = 'Duplicate create order request received';
const DEFAULT_SUBJECT = 'We’re preparing your free product!';

const OrderCreationWizard: React.ForwardRefRenderFunction<ICreateOrderWizardRef, IProps> = (_, ref) => {
  const history = useHistory();
  const { addProjectEvent } = useAddProjectEvent();

  const [currentStep, setCurrentStep] = useState(1);
  const [skipMembers, setSkipMembers] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [selectedResource, setSelectedResource] = useState<IResource>();
  const [subject, setSubject] = useState(DEFAULT_SUBJECT);
  const [additionalCc, setAdditionalCc] = useState([]);
  const [editorState, setEditorState] = useState<IEmailEditorState>(null);
  const [sendFromResourceId, setSendFromResourceId] = useState<number>();
  const [skipMessage, setSkipMessage] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [sentReceipt, setSendReceipt] = useState<boolean>(false);
  const [bulkCreateOrderResult, setBulkCreateOrderResult] = useState<IBulkCreateOrderResult>(null);

  const { closeModal, workflowActionParameters, clientName } = useApplication(); // selected member ids are in bulkActionParameters
  const {
    addAspireShippingLine,
    activeEmailResources,
    addBillingAddress,
    bypassShopifyInventory,
    getMemberShippingAddress,
    invalidMembers,
    members,
    sendBulkMessage,
    setTokenIsInvalid,
    shopifyResource,
    tokenIsInvalid,
    workItems,
    setUsePfaContextVariables,
  } = useProductFulfillmentContext();

  // For the legacy flow we need to set the usePfaContextVariables to true so that email variables come from context
  setUsePfaContextVariables(true);

  const { showErrorMessage } = useMessagingContext();
  const { resources, loading: loadingResources } = useGetResources();
  const [invalidAddresses, setInvalidAddresses] = useState<MemberWithInvalidAddress[]>([]);
  const [ignoreAddress, setIgnoreAddress] = useState<boolean>(false);
  const { bulkCreateOrder, loading: creatingOrders, error: creatingOrdersError } = useBulkCreateOrder();

  const validateAddresses = useCallback((addresses: IShippingAddress[]) => {
    const invalidAddressesTemp = [];

      addresses.forEach((address, index) => {
        const countryLowerCase = toLowerAndTrim(address.country || '');
        const stateLowerCase = toLowerAndTrim(address.province || '');
        let errorMessages = '';
        const country = isUSA(countryLowerCase)
        ? COUNTRY_DATA.find((c) => c.countryShortCode === 'US')
        : COUNTRY_DATA.find((c) => [
          toLowerAndTrim(c.countryName),
          toLowerAndTrim(c.countryShortCode)].includes(countryLowerCase));
        if (!country) {
          errorMessages += 'The country appears invalid please check again.';
        }
        if (country && !country.regions.find((s) => [
          toLowerAndTrim(s.name),
          toLowerAndTrim(s.shortCode)].includes(stateLowerCase))) {
          errorMessages += 'The state appears invalid please check again.';
        }
        if (errorMessages) {
          invalidAddressesTemp.push({
            id: members[index].id,
            name: members[index].name,
            address,
            errorMessages,
          });
        }
      });

      setInvalidAddresses(invalidAddressesTemp);
  }, [members]);

  useEffect(() => {
    validateAddresses(map(members, getMemberShippingAddress));
  }, [members, getMemberShippingAddress, validateAddresses]);

  useImperativeHandle(ref, () => ({
    getCurrentStep: () => currentStep,
  }));
  const sendFromResource = useMemo(
    () => find(activeEmailResources, (resource) => resource.id === sendFromResourceId),
    [activeEmailResources, sendFromResourceId],
  );

  useEffect(() => {
    if (!isEmpty(activeEmailResources)) {
      setSendFromResourceId(first(activeEmailResources).id);
    }
  }, [activeEmailResources]);

  useEffect(() => {
    const lineItems = map(Object.values(selectedProducts), (selectedProduct) => ({
      quantity: selectedProduct.quantity,
      variant_id: selectedProduct.variant.id,
    }));

    setLineItems(lineItems);
  }, [selectedProducts]);

  const onSkipMessage = () => {
    setSkipMessage(true);
    incrementStep();
  };
  const incrementStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const isCurrentStep = (step: number) => currentStep === step;

  useEffect(() => {
    if (workflowActionParameters?.programName && subject === DEFAULT_SUBJECT) {
      setSubject(subjectLineForClientAndProgram(clientName, workflowActionParameters.programName, 'Products'));
    }
  }, [workflowActionParameters?.programName, subject, setSubject, clientName]);

  const createOrders = async (shippingLine?: ShopifyShippingLine) => {
    const params: SaveOrderParams[] = map(members, (m) => ({
      member: {
        id: m.id,
        email: m.email,
        name: m.name,
      },
      details: {
        email: m.email,
        line_items: lineItems,
        shipping_address: getMemberShippingAddress(m),
        ...(addBillingAddress && { billing_address: getMemberShippingAddress(m) }),
        ...(bypassShopifyInventory && { inventory_behaviour: 'bypass' }),
        note: notes,
        send_receipt: sentReceipt,
        shippingLines: shippingLine ? [shippingLine] : addAspireShippingLine
          ? [{ custom: true, price: 0, title: 'Aspire Placeholder Shipping' }]
          : undefined,
      },
      workItemId: workItems?.find((wi) => wi?.data?.memberId === m.id)?.id,
      artifacts: workflowActionParameters ? { programIds: [workflowActionParameters?.programId] } : {},
    }));

    let ordersCreated = [];

    try {
      const { data } = await bulkCreateOrder({
        variables: {
          resourceId: selectedResource?.id,
          params,
        },
        context: {
          headers: {
            'x-aspirex-uuid': uuidv4(),
          },
        },
      });

      ordersCreated = data?.result.orders ?? [];
      setBulkCreateOrderResult(data?.result ?? { orders: [], errors: [] });

      let valuePerMember = 0;
      let qtyPerMember = 0;
      each(lineItems, (l) => {
        const product = selectedProducts[l.variant_id];
        valuePerMember += parseInt(product.variant.price, 10) * l.quantity;
        qtyPerMember += l.quantity;
      });

      addProjectEvent(EventName.PFAOrdersSubmitted, {
        member_count: size(members),
        value_per_member: valuePerMember,
        total_value: valuePerMember * size(ordersCreated),
        qty_per_member: qtyPerMember,
        send_receipt: sentReceipt,
      });

      incrementStep();
    } catch (err) {
      console.error(err);
      logger.error('Failed to create order', {
        err,
        memberIds: map(members, (m) => m.id).join(', '),
      });

      // skip showing error message for duplicate orders
      if (!includes(err.message, DUPLICATE_ORDER_ERROR_MESSAGE)) {
        showErrorMessage('We were unable to create order for the member(s)');
      }
    }

    if (!skipMessage && sendFromResource && !isEmpty(ordersCreated)) {
      let message = editorState?.html;
      if (sendFromResource.config.signature?.mode === 'html' && !!sendFromResource.config.signature?.fromHTML) {
        message = trim(
          `${message}<div><br></div>${sendFromResource.config.signature?.fromHTML.replace(/\n/g, '<br />')}`,
        );
      } else if (
        sendFromResource.config.signature?.mode === 'editor'
        && !!sendFromResource.config.signature?.fromEditor
      ) {
        message = trim(`${message}<div><br></div>${sendFromResource.config.signature?.fromEditor}`);
      }

      const sendMessageParams: SendMessageInput = {
        members: map(ordersCreated, (order) => ({
          id: order.member.id,
          email: order.member.email,
        })),
        additionalCc: additionalCc.map((cc) => (cc.email)),
        resourceId: sendFromResourceId,
        type: upperCase(sendFromResource.type) as MessageType,
        subject,
        message,
      };

      try {
        await sendBulkMessage({
          variables: {
            message: sendMessageParams,
          },
        });
      } catch (err) {
        console.log({ err });
        logger.error('Failed to send order email', {
          err,
          memberIds: map(ordersCreated, (order) => order.member.id).join(', '),
        });
        addProjectEvent(EventName.PFASendEmailFailed, {
          params: sendMessageParams,
          errorMessage: err?.message,
        });

        showErrorMessage('We were unable to send an email to the member(s)');
      }
    }
  };

  if (!isEmpty(invalidMembers) && !skipMembers) {
    const memberShippingAddresses = map(invalidMembers, getMemberShippingAddress);

    return (
      <InvalidMembers
        memberShippingAddresses={memberShippingAddresses}
        onContinue={() => setSkipMembers(true)}
        canContinue={!isEmpty(members)}
      />
    );
  }
  if (!isEmpty(invalidAddresses) && !ignoreAddress) {
    return (
      <InvalidMemberAddress
        memberShippingAddresses={invalidAddresses}
        onContinue={() => setIgnoreAddress(true)}
        canContinue
      />
    );
  }

  if (isCurrentStep(4)) {
    return (
      <SuccessPage
        subject={subject}
        selectedProducts={selectedProducts}
        editorState={editorState}
        closeModal={closeModal}
        skipMessage={skipMessage}
        result={bulkCreateOrderResult}
        memberCount={size(members)}
      />
    );
  }

  return (
    <div
      className={cx(styles.OrderCreationWizard, {
        [styles.disabled]: creatingOrders,
      })}
    >
      <div className={styles.container}>
        <AccordionSection
          title="1. Select Product"
          active={isCurrentStep(1)}
          onHeaderClick={() => setCurrentStep(1)}
          showHeader={currentStep > 1}
        >
          <SelectProductFormV3
            selectedResource={selectedResource}
            setSelectedResource={setSelectedResource}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            resources={resources}
            loadingResources={loadingResources}
            incrementStep={incrementStep}
            selectedItemCount={lineItems.length}
            setTokenIsInvalid={setTokenIsInvalid}
            bypassShopifyInventory={bypassShopifyInventory}
          />

        </AccordionSection>

        <AccordionSection
          title="2. Compose message (optional)"
          active={isCurrentStep(2)}
          onHeaderClick={() => setCurrentStep(2)}
          showHeader={currentStep >= 2}
        >
          <ComposeMessage
            members={members}
            resource={sendFromResource}
            setResourceId={setSendFromResourceId}
            subject={subject}
            setSubject={setSubject}
            setAdditionalCc={setAdditionalCc}
            editorState={editorState}
            setEditorState={setEditorState}
            incrementStep={incrementStep}
            onSkip={onSkipMessage}
          />
        </AccordionSection>

        <AccordionSection
          title="3. Confirm order details"
          active={isCurrentStep(3)}
          onHeaderClick={() => setCurrentStep(2)}
          showHeader={currentStep >= 3}
        >
          <ConfirmationPage
            memberCount={members?.length}
            createOrder={createOrders}
            creatingOrders={creatingOrders}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            notes={notes}
            setNotes={setNotes}
            sentReceipt={sentReceipt}
            setSendReceipt={setSendReceipt}
            error={creatingOrdersError}
          />
        </AccordionSection>
        <Modal
          show={!shopifyResource || tokenIsInvalid}
          className={styles.NoShopifyModal}
          onRequestClose={closeModal}
          title="Your Shopify account is not connected"
          footer={(
            <>
              <Button
                label="Continue"
                theme="primary"
                className={styles.button}
                onClick={() => {
                  closeModal();
                  history.push(`/settings/${encodeURIComponent(SHOPIFY_APP_ID)}`);
                }}
              />
              <Button
                label="Cancel"
                theme="light"
                className={styles.button}
                onClick={closeModal}
              />
            </>
          )}
        >
          <div>
            Please integrate your shopify account by clicking the Continue link below in order to send products to members.
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default React.forwardRef(OrderCreationWizard);
