import * as React from 'react';
import {
 map, findIndex, size, isEmpty,
} from 'lodash';
import cx from 'classnames';
import {
  useMemberProgramsAndCommunitiesQuery,
  useActivationsQuery,
  useProgramsQuery,
  useCommunitiesQuery,
  useFeatureFlagVerbiage,
  useClientFeatureEnabled,
  useConfirmOnExit,
} from '@frontend/app/hooks';

import { useState, useEffect, useMemo } from 'react';

import {
  Select, Button, SubmitButton, LoadSpinner,
} from '@components';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useEventContext } from '@frontend/app/context/EventContext';
import { IArtifact } from '@frontend/applications/Shared/context/applicationContext';

import { EventName } from '@common';
import { logger } from '@common';

import { ClientFeature } from '@frontend/app/constants';
import styles from './ArtifactAssignmentForm.scss';

interface IProps {
  onSkip?(): void;
  onSave?(assignment: IAssignmentAPIPayload): void;
  loading: boolean;
  artifact?: IArtifact;
  preview?: React.ReactNode;
  artifactName: string;
  secondaryArtifactName?: string;
  hideTitle?: boolean;
  hideActions?: boolean;
  embedded?: boolean;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  communities: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onCommunitySelected: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  programs: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onProgramSelected: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  activations: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onActivationSelected: any;
}

interface IAssignment {
  communityIds: number[];
  programIds: number[];
  programNames: string[];
  activationIds: number[];
  activationNames: string[];
}

export interface IAssignmentAPIPayload {
  community_ids: number[];
  program_ids: number[];
  program_names: string[];
  activation_ids: number[];
  activation_names: string[];
  assigned: boolean;
  project_id?: number;
  assign_payment_to_brief?: boolean;
  note?: string;
}

export const ArtifactAssignmentForm: React.FunctionComponent<IProps> = (props) => {
  const { memberId } = useApplication();
  const addEvent = useEventContext();

  // TODO - the PFA using proxy so need to pass via context
  const isPFA = !!useProductFulfillmentContext();
  const pfa_verbiage = useProductFulfillmentContext()?.verbiage;
  const pfa_workflowEnabled = useProductFulfillmentContext()?.workflowEnabled;

  const verbiage_query = useFeatureFlagVerbiage({ skip: isPFA });
  const workflowEnabled_query = useClientFeatureEnabled(ClientFeature.WORKFLOW, { skip: isPFA });

  const verbiage = pfa_verbiage || verbiage_query;
  const workflowEnabled = pfa_workflowEnabled || workflowEnabled_query;

  const EMPTY_STATE = {
    communityIds: [],
    programIds: [],
    programNames: [],
    activationIds: [],
    activationNames: [],
  };

  const {
 onSave, onSkip, loading, artifact, preview, artifactName, hideTitle, hideActions, embedded, secondaryArtifactName,
} = props;

  const [assignment, setAssignment] = useState<IAssignment>(EMPTY_STATE);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { data: memberData, loading: memberLoading } = useMemberProgramsAndCommunitiesQuery(memberId ? Number(memberId) : null, {
    skip: !memberId,
  });

  useEffect(() => {
    if (artifact && artifact.assigned) {
      setAssignment({
        communityIds: artifact.community_ids,
        programIds: artifact.program_ids,
        programNames: artifact.program_names,
        activationIds: artifact.activation_ids,
        activationNames: artifact.activation_names,
      });
    } else if (memberData) {
      const member = memberData.member;
      setAssignment({
        communityIds: size(member.communities) === 1 ? map(member.communities, 'id') : [],
        programIds: size(member.programs) === 1 ? map(member.programs, 'id') : [],
        programNames: size(member.programs) === 1 ? map(member.programs, 'title') : [],
        activationIds: [],
        activationNames: [],
      });
    }
  }, [memberData, artifact]);

  const {
    loading: communityLoading,
    data: communityData,
  } = useCommunitiesQuery({ skip: embedded });

  const {
    loading: programLoading,
    data: programData,
  } = useProgramsQuery({ skip: embedded });

  const {
    loading: activationsLoading,
    activations,
  } = useActivationsQuery({ skip: embedded });

  const communityOptions = useMemo(() => map((communityData && communityData.communities) || props.communities, (community) => ({
        value: community.id,
        label: community.title,
      })), [communityData, props.communities]);

  const programOptions = useMemo(() => map((programData && programData.programs) || props.programs, (program) => ({
        value: program.id,
        label: program.title,
      })), [programData, props.programs]);

  const activationOptions = useMemo(() => map(activations || props.activations, (activation) => ({
        value: activation.id,
        label: activation.name,
      })), [activations, props.activations]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onSelected = (identifier: string, value: any) => {
    setAssignment({
      ...assignment,
      [identifier]: [value],
    });
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onCommunitySelected = (value: any) => {
    onSelected('communityIds', value);
    props.onCommunitySelected && props.onCommunitySelected(value);
    setHasUnsavedChanges(true);
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onProgramSelected = (value: any, index: number) => {
    onSelected('programIds', value);

    const name = programOptions[index].label;
    const newValue = {
      ...assignment,
      programIds: [value],
      programNames: [name],
    };
    setAssignment(newValue);
    props.onProgramSelected && props.onProgramSelected(value);
    setHasUnsavedChanges(true);
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onActivationSelected = (value: any, index: number) => {
    // must handle this independent of onSelected function because
    // cannot update state twice since it won't update immediately
    const name = activationOptions[index].label;
    const newValue = {
      ...assignment,
      activationIds: [value],
      activationNames: [name],
    };
    setAssignment(newValue);
    props.onActivationSelected && props.onActivationSelected(newValue);
    setHasUnsavedChanges(true);
  };

  const apiPayload = (): IAssignmentAPIPayload => ({
      community_ids: assignment.communityIds,
      program_ids: assignment.programIds,
      activation_ids: assignment.activationIds,
      activation_names: assignment.activationNames,
      program_names: assignment.programNames,
      assigned: true,
    });

  const saveAssigments = () => {
    onSave(apiPayload());
    try {
      addEvent(
        EventName.SocialPostAssigned,
        {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          tags: (artifact as any).tags,
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          member_id: memberId as any,
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          community_ids: assignment.communityIds as any,
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          program_ids: assignment.programIds as any,
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          activation_ids: assignment.activationIds as any,
        },
      );
    } catch (error) {
      logger.error(error);
    }
    setHasUnsavedChanges(false);
  };

  const communitySelectedIndex = useMemo(() => (isEmpty(assignment.communityIds) ? null : findIndex(communityOptions, {
      value: assignment.communityIds[0],
    })), [assignment, communityOptions]);

  const programSelectedIndex = useMemo(() => (isEmpty(assignment.programIds) ? null : findIndex(programOptions, {
      value: assignment.programIds[0],
    })), [assignment, programOptions]);

  const activationSelectedIndex = useMemo(() => (isEmpty(assignment.activationIds) ? null : findIndex(activationOptions, {
      value: assignment.activationIds[0],
    })), [assignment, activationOptions]);

  const dataLoading = memberLoading || communityLoading || activationsLoading || programLoading;

  const saveDisabled = useMemo(
    () =>
      loading
        || (isEmpty(assignment.communityIds) && isEmpty(assignment.programIds) && isEmpty(assignment.activationIds)),
    [assignment.activationIds, assignment.communityIds, assignment.programIds, loading],
  );

  useConfirmOnExit({
    show: hasUnsavedChanges,
    okText: `Continue without assigning ${artifactName}`,
    cancelText: 'Go Back',
    confirmMessage: 'Assignments to a Project or Group will not apply unless saved. Do you want to continue without saving saving assignments?',
    customModalProps: {
      okButtonProps: {
        type: 'primary',
      },
    },
  });

  return (
    <div className={cx([styles.ArtifactAssignmentForm, { [styles.embedded]: embedded }])}>
      {dataLoading ? <LoadSpinner /> : (
        <div>
          <div className={cx([styles.artifact_assign_header, , { [styles.embedded]: embedded }])}>
            {!hideTitle && (
            <h1>
              Assign your
              {' '}
              {artifactName}
            </h1>
)}
            <p>
              Set assignments for your
              {' '}
              {artifactName}
              .
              {secondaryArtifactName && (
                <span>
                  {' '}
                  Associated
                  {' '}
                  {secondaryArtifactName}
                  {' '}
                  will share the same assignment as this
                  {' '}
                  {artifactName}
                  .
                </span>
              )}
            </p>
          </div>
          <div className={cx([styles.form_wrap, , { [styles.embedded]: embedded }])}>
            <section className={styles.standard_fieldset}>
              <span className={styles.standard_label}>
                Assign
                {' '}
                {verbiage.Community}
                :
              </span>
              <Select
                hintText={`Select ${verbiage.Community}`}
                options={communityOptions}
                selectedIndex={communitySelectedIndex}
                onChange={onCommunitySelected}
              />
            </section>
            <section className={styles.standard_fieldset}>
              <span className={styles.standard_label}>
                Assign
                {' '}
                {verbiage.Program}
                :
              </span>
              <Select
                hintText={`Select ${verbiage.Program}`}
                selectedIndex={programSelectedIndex}
                onChange={onProgramSelected}
                options={programOptions}
              />
            </section>
            {workflowEnabled === false && (
              <section className={styles.standard_fieldset}>
                <span className={styles.standard_label}>Assign Activation:</span>
                <Select
                  hintText="Select Activation"
                  selectedIndex={activationSelectedIndex}
                  onChange={onActivationSelected}
                  options={activationOptions}
                />
              </section>
            )}
            {preview}
          </div>
          {
            !hideActions
            && (
            <div className={styles.actions_wrap}>
              <SubmitButton
                label="Save Assignments"
                submittingLabel="Saving..."
                isSubmitting={loading}
                disabled={saveDisabled}
                className={styles.submit_button}
                onClick={saveAssigments}
              />
              {onSkip && (
              <Button
                label="Skip"
                onClick={props.onSkip}
                theme="light"
              />
              )}
            </div>
)
          }
        </div>
      )}
    </div>
  );
};
