import React from 'react';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { CardStat, CardStatLabel, CardStatRow } from '../../card-stat';
import { PercentChangeBadge } from '../../percent-change-badge';
import { formatNumber } from '../../../utils/formatNumber';

const currentIntervalFragment = graphql(`
  fragment Impressions_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalImpressions
  }
`);

const previousIntervalFragment = graphql(`
  fragment Impressions_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalImpressions
  }
`);

type ImpressionsProps = {
  currentInterval: FragmentType<typeof currentIntervalFragment> | null | undefined;
  previousInterval: FragmentType<typeof previousIntervalFragment> | null | undefined;
};

export const Impressions = ({ currentInterval, previousInterval }: ImpressionsProps) => {
  const currentIntervalData = useFragment(currentIntervalFragment, currentInterval);
  const previousIntervalData = useFragment(previousIntervalFragment, previousInterval);

  const currentIntervalImpressions = currentIntervalData?.totalImpressions;
  const previousIntervalImpressions = previousIntervalData?.totalImpressions;

  const percentChange = previousIntervalImpressions && currentIntervalImpressions
      ? ((currentIntervalImpressions - previousIntervalImpressions) / previousIntervalImpressions) * 100
      : 'NA';

  return (
    <div className="col-span-1 items-center">
      <CardStatLabel>Impressions</CardStatLabel>
      <CardStatRow>
        <CardStat className="text-xl leading-none">{formatNumber(currentIntervalImpressions)}</CardStat>
        <PercentChangeBadge percent={percentChange} />
      </CardStatRow>
    </div>
  );
};
