import * as React from 'react';
import { Table, ITableColumnConfig, IRowData } from '@components';
import { isNaN } from 'lodash';
import { onFinish } from '@frontend/applications/PaymentsApp/utils/updateAmountToPay';
import { useClientFeature } from '@frontend/applications/AffiliatesApp/contexts/ClientFeatureContext';
import styles from './BulkPaymentsTable.scss';

export interface BulkPaymentsTableProps {
  selectable: boolean;
  data: IRowData[];
  extraColumns?: ITableColumnConfig[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectedData?: (selectedData: any[]) => void;
  showAmountDue: boolean;
  forInvoice?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdatedData?: (selectedData: any[]) => void;
}
export const BulkPaymentsTable: React.FC<Readonly<BulkPaymentsTableProps>> = (props) => {
  const alwaysFirstColumns: ITableColumnConfig[] = [{
    headerName: 'Member',
    field: 'memberName',
  }];

  const { migrateToGraphQL } = useClientFeature();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderPaypalEmail = (paypalEmail: string, context: any) => {
    if (context?.rowData?._raw?.memberIsDeleted) {
      return (
        <div className={styles.paypalEmailErrorField}>
          Member is deleted
        </div>
      );
    }
    if (paypalEmail) {
      return paypalEmail;
    }
    return (
      <div className={styles.paypalEmailErrorField}>
        PayPal email required
      </div>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataSource, setDataSource] = React.useState(props.data);
  const alwaysLastColumns: ITableColumnConfig[] = props.forInvoice
    ? [
      {
        headerName: 'Amt Paid',
        field: 'amountPaid',
        cellType: 'numeric',
        isPrice: true,
        width: 120,
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 120,
      },
      {
        headerName: 'Paypal Email',
        field: 'paypalEmail',
        width: 240,
        render: renderPaypalEmail,
      },
    ]
    : [
      {
        headerName: 'Amt to Pay',
        field: 'amountToPay',
        cellType: 'numeric',
        isPrice: true,
        width: 160,
        editable: migrateToGraphQL,
        maxValue: 9999.99,
        minValue: 0,
      },
      {
        headerName: 'Paypal Email',
        field: 'paypalEmail',
        width: 240,
        render: renderPaypalEmail,
      },
    ];

  if (props.showAmountDue) {
    alwaysLastColumns.unshift({
      headerName: 'Amt Due',
      field: 'amountDue',
      cellType: 'numeric',
      isPrice: true,
      width: 120,
    });
  }

  const columnConfigs = [
    ...alwaysFirstColumns,
    ...props.extraColumns,
    ...alwaysLastColumns,
  ];
  const onChangeCellValue = (rowId, _, updatedAmountToPay, amountToPay, rowData) => {
    let newAmountToPay = Math.abs(isNaN(updatedAmountToPay) ? 0 : Number(updatedAmountToPay));
    if (newAmountToPay > (rowData?.amountDue || 0)) {
      newAmountToPay = (rowData.amountDue || 0).toFixed(3);
    }
    onFinish(rowId, newAmountToPay, amountToPay, dataSource, setDataSource, props.onUpdatedData);
  };

  return (
    <div>
      <Table
        config={{
          rowHeight: 80,
          allowSearch: false,
          selectable: props.selectable,
          configurableColumns: false,
          striped: false,
          rowBorder: true,
        }}
        columns={columnConfigs}
        data={dataSource}
        onSelectedDataChange={props.onSelectedData}
        onChangeCellValue={onChangeCellValue}
      />
    </div>
  );
};

BulkPaymentsTable.defaultProps = {
  forInvoice: false,
  extraColumns: [],
};
