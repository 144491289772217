import * as React from 'react';
import cx from 'classnames';

import { Form, Select } from '@revfluence/fresh';
import { useState } from 'react';
import { IShopifyFormElementProps, OfferFormModes, PrefixTypeStrategy } from '../../../types';

import styles from '../../../OfferForm.scss';

const { Option } = Select;

interface IProps extends IShopifyFormElementProps<'prefixType'> {
  halfRow: boolean;
  mode: OfferFormModes;
}

export const OfferCodeOption: React.FC<Readonly<IProps>> = React.memo(({
  halfRow,
  name,
  mode,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const handleSelectChange = () => {
    // Show warning only when mode is 'Edit' and dropdown value changes
    if (mode === OfferFormModes.Edit) {
      setShowWarning(true);
    }
  };
  const rules = [
    { required: true, message: 'Please select a unique prefix option!' },
  ];
  return (
    <>
      <Form.Item
        className={cx('inline', {
        [styles.halfRow]: halfRow,
      })}
        label={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Naming Strategy
          </div>
      )}
        name={name}
        rules={rules}
      >
        <Select placeholder="Select" size="large" onChange={handleSelectChange}>
          <Option value={PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY}>Use First Initial + Last Name</Option>
          <Option value={PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY}>Use First Name + Last Initial</Option>
          <Option value={PrefixTypeStrategy.FULL_NAME_ONLY}>Use Full Name</Option>
          <Option value={PrefixTypeStrategy.INSTAGRAM_ONLY}>Use Instagram Username</Option>
        </Select>
      </Form.Item>
      {showWarning && (
      <div className="mt-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2">
        <span className="text-sm">
          Editing the naming strategy will not reflect on existing promo codes.
        </span>
      </div>
    )}
    </>
  );
});
