import { useQuery } from '@apollo/client';
import { GET_TAGS } from '@frontend/app/queries';
import { GetTagsQuery, GetTagsQueryVariables } from '@frontend/app/queries/types/GetTagsQuery';

export function useFetchTags(options = {}) {
  return useQuery<GetTagsQuery, GetTagsQueryVariables>(
    GET_TAGS,
    options,
  );
}
