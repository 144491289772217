import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetImportedCollectionsByClientId } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedCollectionsByClientId';
import { GET_IMPORTED_COLLECTIONS_BY_CLIENT_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { useMemo } from 'react';

type IOptions = QueryHookOptions<GetImportedCollectionsByClientId>;

export const useGetImportedCollections = (options: IOptions = {}) => {
    const {
   loading, data, error, refetch,
  } = useQuery(GET_IMPORTED_COLLECTIONS_BY_CLIENT_ID_QUERY, options);

  return {
    loading,
    collections: useMemo(() => data?.getImportedCollections ?? [], [data?.getImportedCollections]),
    error,
    refetch,
  };
};
