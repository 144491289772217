import * as React from 'react';
import {
  Dropdown, Input, Button,
} from '@revfluence/fresh';
import { ArrowRotateRightIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  SlidersIcon,
  MagnifyingGlassIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { SearchFilterOverlay } from '@frontend/app/containers/MessagingApp/SearchFilter/SearchFilterOverlay';
import { useReactiveVar } from '@apollo/client';
import {
  messageThreadTypes,
  useMessagingContext,
} from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { useFetchUsers, useSyncMessages } from '@frontend/app/hooks';
import {
  getParsedFullSearch,
  getStrippedFullSearch,
  initSearchFilter,
  searchFilterReactiveVars,
} from '@frontend/app/containers/MessagingApp/reativeVars/searchFilter';
import { filter, sortBy } from 'lodash';
import { message } from 'antd';
import styles from './SearchFilter.scss';

const { useState, useMemo } = React;

export const SearchFilter = () => {
  const { syncMessages, loading: syncing } = useSyncMessages();
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const searchFilter = useReactiveVar(searchFilterReactiveVars);
  const {
    resourceEmail,
    currentFolderLabel,
    refetchAndUpdateThreads,
    applications,
    setResetFilter,
    setNoMoreThreads,
    resetMessagingState,
    loadingThreads,
  } = useMessagingContext();

  const onRefreshMessages = () => {
    syncMessages({
      variables: {
        resourceId: undefined,
        count: 1000,
      },
    }).then(resetMessagingState).catch(() => {
      message.error('There\'s an error when trying to update the messages.');
    });
  };

  const { users } = useFetchUsers();

  const title = resourceEmail || currentFolderLabel;

  const appNotificationThreads = useMemo(
    () => filter(
      sortBy(applications, ['name']),
      (a) => (a.visibility.showOnMember || a.visibility.showOnNav) && !messageThreadTypes.includes(a.name),
    ),
    [applications],
  );

  const updateFullSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchFilterReactiveVars({
      ...searchFilter,
      fullSearch: e.target.value,
    });
  };

  const updateSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchFilterReactiveVars({
      ...searchFilter,
      parsedFullSearch: e.target.value,
    });
  };

  const onFieldChange = (fieldName: string, newValue: string[]) => {
    searchFilterReactiveVars({
      ...searchFilter,
      [fieldName]: newValue,
    });
  };

  const onSearch = async () => {
    await resetMessagingState();
    await searchFilterReactiveVars({
      ...searchFilter,
      parsedFullSearch: getParsedFullSearch(searchFilter, users, appNotificationThreads),
    });
    await refetchAndUpdateThreads();
    setIsDropdownVisible(false);
  };

  const onCancel = () => {
    searchFilterReactiveVars(initSearchFilter);
    setResetFilter(true);
    setIsDropdownVisible(false);
  };

  const onKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setNoMoreThreads(false);
      searchFilterReactiveVars({
        ...searchFilter,
        fullSearch: getStrippedFullSearch(searchFilter.parsedFullSearch),
        parsedFullSearch: getParsedFullSearch(searchFilter, users, appNotificationThreads, false),
      });
      await refetchAndUpdateThreads(0, 20, false);
      setIsDropdownVisible(false);
    }
  };

  return (
    <div className={styles.SearchFilter}>
      <div className={styles.title}>{title}</div>
      <div className={styles.filterInput}>
        <Button
          size="middle"
          type="text"
          icon={<ArrowRotateRightIcon />}
          loading={loadingThreads || syncing}
          disabled={loadingThreads || syncing}
          onClick={onRefreshMessages}
        />
        <Input
          value={searchFilter.parsedFullSearch}
          prefix={<MagnifyingGlassIcon />}
          placeholder={`Search subject line in ${title}`}
          className={styles.searchFilterInput}
          onKeyPress={onKeyPress}
          onChange={updateSearchInput}
        />
        <Dropdown
          overlay={(
            <SearchFilterOverlay
              onFieldChange={onFieldChange}
              updateFullSearchText={updateFullSearchText}
              onSearch={onSearch}
              onCancel={onCancel}
              users={users}
              appNotificationThreads={appNotificationThreads}
            />
          )}
          trigger={['click']}
          visible={isDropdownVisible}
          onVisibleChange={setIsDropdownVisible}
        >
          <div className={styles.filterDropdownIcon}>
            <SlidersIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
