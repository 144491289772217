import * as React from 'react';
import cx from 'classnames';
import {
 toUpper, isFunction, isEmpty, map,
} from 'lodash';

import { useLocation } from 'react-router-dom';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import {
  Editor as DraftEditor,
  EditorState,
  ContentState,
  Modifier,
  RichUtils,
  KeyBindingUtil,
  convertToRaw,
  DraftHandleValue,
  RawDraftContentState,
  getDefaultKeyBinding,
  DraftEditorCommand,
  EditorChangeType,
  SelectionState,
  DraftDecorator,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { logger } from '@common';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { IMember, IMessageTemplate } from '@frontend/app/hooks';
import { Button, Space, Tooltip } from '@revfluence/fresh';
import { TrashIcon } from '@revfluence/fresh-icons/regular/esm';
import { IAttachment } from '@frontend/app/components';
import { StyleControls } from './StyleControls';
import { getDecorator } from './decorator';
import {
 editorStateFromValue, toggleLinkState, getSelectedEntityType, exportHTML,
} from './utils';

export interface IEditorState {
  raw: RawDraftContentState;
  html: string;
}
interface IProps {
  plaintextOnly?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  // initial value
  initialValue?: string | RawDraftContentState;
  currentValue?: string | RawDraftContentState;
  signaturePreview?: string;
  convertFromHTML?: boolean;
  onChange?(result: IEditorState): void;
  attachments?: IAttachment[];
  resourceType?: ResourceType;
  onDeleteAttachment?(id: string): void;
  onFilesSelected?(files: FileList): void;
  showProgramVariables?: boolean;
  enableVariableReplacement?: boolean;
  enableEmailTemplate?: boolean;
  replaceTemplateVariables?: boolean;
  members?: IMember[];
  invalidMemberIdsForField?: {
    [field: string]: number[];
  };
  additionalVariables?: { [key: string]: { label: string } };
  canSend?: boolean;
  errorMessage?: string;
  actions?: JSX.Element[];
  enableMessageTemplate?: boolean;

  errorHeader?: React.ReactNode;

  decorators?: DraftDecorator[];
  className?: string;
  hasFixedHeight?: boolean;
  actionsDisabled?: boolean;
  onClose?: () => void;
  onTemplateSelected?: (template: IMessageTemplate) => void;
}
interface IInsertEntityParams {
  type: string;
  label?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any;
}
export interface IEditor {
  focus(): void;
  reset(): void;
  getValue(): IEditorState;
  setValue(value: string | RawDraftContentState): void;
  insertText(text: string): void;
  insertEntity(params: IInsertEntityParams): void;
}
type TCustomKeybindingType =
  | 'unstyled'
  | 'header-one'
  | 'header-two'
  | 'header-three'
  | 'header-four'
  | 'header-five'
  | 'header-six'
  | 'ordered-list-item'
  | 'unordered-list-item'
  | 'link'
  | DraftEditorCommand;
const KEYCODE_TO_BLOCK_TYPE: {
  [keyCode: number]: TCustomKeybindingType;
} = {
  48: 'unstyled',
  49: 'header-one',
  50: 'header-two',
  51: 'header-three',
  52: 'header-four',
  53: 'header-five',
  54: 'header-six',
  55: 'ordered-list-item',
  56: 'unordered-list-item',
  75: 'link',
};

const { useState, useEffect, useImperativeHandle } = React;
const { hasCommandModifier } = KeyBindingUtil;

import styles from './Editor.module.scss';
import { AttachmentItem } from '../MessageComposer';

/**
 * @type {React.FC}
 */
const EditorComponent: React.RefForwardingComponent<IEditor, IProps> = (props, ref) => {
  const addEvent = useEventContext();
  const location = useLocation();

  const [blockStyle, setBlockStyle] = useState<string>();

  const onEditorStateChange = (newState: EditorState) => {
    if (newState.getCurrentContent() !== editorState.getCurrentContent() && isFunction(props.onChange)) {
      props.onChange({
        raw: convertToRaw(newState.getCurrentContent()),
        html: exportHTML(newState),
      });
    }

    setEditorState(newState);
  };
  const updateEntityData = (newContentState: ContentState, changeType: EditorChangeType = 'apply-entity') => {
    const newEditorState = EditorState.push(editorState, newContentState, changeType);

    onEditorStateChange(newEditorState);
  };
  const [editorDisabled, setEditorDisabled] = useState(false);
  const decorator = getDecorator({
    decorators: props.decorators,
    props: {
      updateEntityData,
      setEditorDisabled,
    },
  });
  const [editorState, setEditorState] = useState<EditorState>(
    editorStateFromValue({
      value: props.currentValue || props.initialValue,
      decorator,
      isHtml: props.convertFromHTML,
    }),
  );

  useEffect(() => {
    if (!isEmpty(props.initialValue) && isFunction(props.onChange)) {
      props.onChange({
        raw: convertToRaw(editorState.getCurrentContent()),
        html: exportHTML(editorState),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  const insertText = (text: string) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(contentState, selectionState, text);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    onEditorStateChange(newEditorState);
  };

  const onEditorFocused = () => {
    addEvent(EventName.EmailEditorFocused, {
      pathname: location.pathname,
    });
  };

  const focusEditor = () => {
    // @ts-expect-error
    ref?.current?.focus();
  };
  const resetEditor = () => onEditorStateChange(EditorState.createEmpty(decorator));
  useImperativeHandle(ref, () => ({
    focus: () => {},
    reset: resetEditor,
    getValue: (): IEditorState => ({
      raw: convertToRaw(editorState.getCurrentContent()),
      html: exportHTML(editorState),
    }),
    setValue: (value) => {
      const newEditorState = editorStateFromValue({
        value,
        decorator,
        isHtml: props.convertFromHTML,
      });

      setEditorState(newEditorState);
      onEditorStateChange(newEditorState);
    },
    insertText,
    insertEntity: ({ label, data, type }) => {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', { data });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = Modifier.insertText(contentStateWithEntity, selectionState, label, null, entityKey);
      updateEntityData(newContentState, 'insert-characters');

      setTimeout(() => focusEditor(), 0);
    },
  }));

  const toggleLink = () => {
    toggleLinkState(editorState)
      .then((linkEditorState) => {
        const selection = linkEditorState.getSelection();
        const blockKey = selection.getStartKey();
        const end = selection.getEndOffset();
        const newEditorState = EditorState.forceSelection(
          linkEditorState,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          new SelectionState({
            anchorKey: blockKey,
            focusKey: blockKey,
            anchorOffset: end,
            focusOffset: end,
          }),
        );

        onEditorStateChange(newEditorState);
      })
      .catch(logger.error);
  };
  const keyBindingFn = (event: React.KeyboardEvent): TCustomKeybindingType => {
    const { keyCode } = event;

    if (hasCommandModifier(event) && KEYCODE_TO_BLOCK_TYPE[keyCode]) {
      return KEYCODE_TO_BLOCK_TYPE[keyCode];
    }

    return getDefaultKeyBinding(event);
  };
  const handleKeyCommand = (command: TCustomKeybindingType): DraftHandleValue => {
    switch (command) {
      case 'bold':
      case 'italic':
      case 'underline': {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, toUpper(command)));
        return 'handled';
      }

      case 'unstyled':
      case 'header-one':
      case 'header-two':
      case 'header-three':
      case 'header-four':
      case 'header-five':
      case 'header-six':
      case 'ordered-list-item':
      case 'unordered-list-item': {
        onEditorStateChange(RichUtils.toggleBlockType(editorState, command));
        return 'handled';
      }

      case 'link': {
        toggleLink();
        return 'handled';
      }

      default: {
        return 'not-handled';
      }
    }
  };

  const openFileSelector = (inputRef) => {
    const fileInput = inputRef.current;

    fileInput.click();
  };

  const handleDeleteContent = () => {
    resetEditor();
    props.attachments?.forEach((a) => {
      props.onDeleteAttachment?.(a.id);
    });
    props.onClose?.();
  };

  const editorHasContent = React.useMemo(
    () => editorState.getCurrentContent().hasText() || Boolean(props.attachments?.length),
    [editorState, props.attachments],
  );

  const actionsRef = React.useRef();

  /**
   * TODO<developer>: this is a hacky way to disable action button
   * Action buttons should be build as a separate component, and accept props for 'disabled' state
   * Right now actions live 3 components above in hierarchy and there is no way to control it's state
   */
  useEffect(() => {
    if (!editorHasContent || props.errorHeader) {
      // @ts-expect-error fix ref current
      actionsRef?.current?.querySelector('button')?.setAttribute('disabled', true);
    } else {
      // @ts-expect-error fix ref current
      actionsRef?.current?.querySelector('button')?.removeAttribute('disabled');
    }
  }, [editorHasContent, props.errorHeader]);

  return (
    <div
      className={cx(styles.Editor, props.className, {
        [styles.disabled]: props.disabled,
      })}
      onClick={focusEditor}
    >
      <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }} className={styles.editor}>
        <div className={styles.editorContainer}>
          {!!props.errorHeader && <>{props.errorHeader}</>}
          <DraftEditor
            readOnly={editorDisabled || props.readOnly}
            editorState={editorState}
            placeholder={!blockStyle && (props.placeholder || 'Enter your message...')}
            onChange={onEditorStateChange}
            onFocus={onEditorFocused}
            keyBindingFn={keyBindingFn}
            handleKeyCommand={handleKeyCommand}
            // @ts-expect-error
            ref={ref}
            spellCheck={false}
          />
        </div>

        {!!props.signaturePreview && (
          <div
            style={{ wordBreak: 'break-word', whiteSpace: 'pre-line', paddingTop: '1rem' }}
            className={styles.signature}
            dangerouslySetInnerHTML={{
              __html: props.signaturePreview,
            }}
          />
        )}
      </div>
      {!isEmpty(props.attachments) && (
        <div className={styles.attachments}>
          {map(props.attachments, (attachment) => (
            <AttachmentItem
              key={attachment.id}
              className={styles.item}
              content={attachment}
              resourceType={props.resourceType}
              onDelete={props.onDeleteAttachment?.bind(this, attachment.id)}
            />
          ))}
        </div>
      )}
      {!props.readOnly && !props.plaintextOnly && (
        <Space className={styles.editorSpace} direction="horizontal">
          <StyleControls
            editorState={editorState}
            getSelectedEntityType={() => getSelectedEntityType(editorState)}
            toggleBlockType={(type) => {
              onEditorStateChange(RichUtils.toggleBlockType(editorState, type));
              setBlockStyle((prevState) => (prevState === type ? undefined : type));
            }}
            toggleInlineStyle={(style) => {
              onEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));
            }}
            toggleMediaType={(type, ref) => {
              if (type === 'link') {
                toggleLink();
              } else if (type === 'attachment') {
                openFileSelector(ref);
              }
            }}
            onFilesSelected={props.onFilesSelected}
            ref={ref}
            showProgramVariables={props.showProgramVariables}
            enableVariableReplacement={props.enableVariableReplacement}
            enableEmailTemplate={props.enableEmailTemplate}
            invalidMemberIdsForField={props.invalidMemberIdsForField}
            additionalVariables={props.additionalVariables}
            onTemplateSelected={props.onTemplateSelected}
            enableMessageTemplate={props.enableMessageTemplate}
          />
          <Space>
            <Button className={styles.trashButton} onClick={handleDeleteContent}>
              <TrashIcon />
            </Button>
            <Tooltip
              overlayStyle={{
                display: !props.canSend ? undefined : 'none',
                zIndex: 9999,
              }}
              title={props.errorMessage}
            >
              <div ref={actionsRef}>{React.Children.toArray(props.actions)}</div>
            </Tooltip>
          </Space>
        </Space>
      )}
    </div>
  );
};

export const Editor = React.forwardRef(EditorComponent);
