import * as React from 'react';

import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { RawOfferSummaryCard } from './RawOfferSummaryCard';
import { IFormProps } from './types';

export const OfferFormSummaryCard: React.FC<Readonly<IFormProps>> = (props) => {
  switch (props.values.source) {
    case OFFER_SOURCE.SHOPIFY:
      return (
        <RawOfferSummaryCard
          description={props.values.description}
          expired={props.isExpired}
          flatPayout={Number(props.values.flatPayout)}
          imageUrl={props.values.imageUrl}
          offerFormVariant
          onChangeImage={props.onChangeImage}
          payoutType={props.values.payoutType}
          percentPayout={Number(props.values.percentPayout)}
          priceRuleAmount={Number(props.values.priceRuleAmount)}
          priceRuleType={props.values.priceRuleType}
          source={OFFER_SOURCE.SHOPIFY}
          status={props.values.status}
          title={props.values.name}
          offerForm
          startDate={(props.values.activeDate as unknown) as Date}
          endDate={(props.values.endDate as unknown) as Date}
          isNewFlow={props.isNewFlow}
          payoutOptions={props.values.payoutOptions}
          isOfferArchived={props.isOfferArchived}
          isReadOnly={props.values.isReadOnly}
        />
      );
    case OFFER_SOURCE.TUNE:
      return (
        <RawOfferSummaryCard
          conversionTrackingType={props.values.conversionTrackingType}
          conversionType={props.values.conversionType}
          description={props.values.description}
          expirationDate={(props.values.expirationDate as unknown) as Date}
          expired={props.isExpired}
          flatPayout={Number(props.values.flatPayout)}
          imageUrl={props.values.imageUrl}
          offerFormVariant
          onChangeImage={props.onChangeImage}
          payoutType={props.values.payoutType}
          percentPayout={Number(props.values.percentPayout)}
          pixelCode={null}
          postbackUrl={null}
          source={OFFER_SOURCE.TUNE}
          status={props.values.status}
          title={props.values.name}
          url={props.values.url}
          utmSource={props.values.utmSource}
          utmMedium={props.values.utmMedium}
          utmCampaign={props.values.utmCampaign}
          utmContent={props.values.utmContent}
          utmTerm={props.values.utmTerm}
          customUTMParameters={props.values.customUTMParameters}
          isAdvanceUrlEnable={props.values.isAdvanceUrlEnable}
          offerForm
          isNewFlow={props.isNewFlow}
          payoutOptions={props.values.payoutOptions}
          isOfferArchived={props.isOfferArchived}
        />
      );
  }
};
