import React from 'react';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { CardStat, CardStatLabel, CardStatRow } from '../../card-stat';
import { PercentChangeBadge } from '../../percent-change-badge';
import { formatNumber } from '../../../utils/formatNumber';

const currentIntervalFragment = graphql(`
  fragment Reach_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalReach
  }
`);

const previousIntervalFragment = graphql(`
  fragment Reach_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalReach
  }
`);

type ReachProps = {
  currentInterval: FragmentType<typeof currentIntervalFragment> | null | undefined;
  previousInterval: FragmentType<typeof previousIntervalFragment> | null | undefined;
};

export const Reach = ({ currentInterval, previousInterval }: ReachProps) => {
  const currentIntervalData = useFragment(currentIntervalFragment, currentInterval);
  const previousIntervalData = useFragment(previousIntervalFragment, previousInterval);

  const currentIntervalReach = currentIntervalData?.totalReach;
  const previousIntervalReach = previousIntervalData?.totalReach;

  const percentChange = previousIntervalReach && currentIntervalReach
      ? ((currentIntervalReach - previousIntervalReach) / previousIntervalReach) * 100
      : 'NA';

  return (
    <div className="col-span-1 items-center">
      <CardStatLabel>Reach</CardStatLabel>
      <CardStatRow>
        <CardStat className="text-xl leading-none">{formatNumber(currentIntervalReach)}</CardStat>
        <PercentChangeBadge percent={percentChange} />
      </CardStatRow>
    </div>
  );
};
