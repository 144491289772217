/* eslint-disable quote-props */
import React from 'react';
import {
 LineChart, Line, XAxis, YAxis, CartesianGrid,
} from 'recharts';
import { FragmentType, graphql, useFragment as getFragmentData } from '@frontend/gql/social';
import {
 ChartConfig, ChartContainer, ChartTooltip, ChartLegendContent, ChartLegend,
} from '@frontend/shadcn/components/ui/chart';
import { formatNumber } from '../../utils/formatNumber';
import { ChartTooltipContent } from '../chart';

const RoasOverTimeChartFragment = graphql(`
  fragment RoasOverTimeChart_MetaInsights on MetaInsights {
    interval {
      date
    }
    roas
  }
`);

type RoasOverTimeChartProps = {
  currentIntervalsMetaInsights: (FragmentType<typeof RoasOverTimeChartFragment> | null | undefined)[] | null | undefined;
  currentIntervalsMetaInsightsNotAspireSourced: (FragmentType<typeof RoasOverTimeChartFragment> | null | undefined)[] | null | undefined;
};

const RoasOverTimeChart = ({ currentIntervalsMetaInsights, currentIntervalsMetaInsightsNotAspireSourced }: RoasOverTimeChartProps) => {
  const currentIntervalData = currentIntervalsMetaInsights?.map((interval) =>
    getFragmentData(RoasOverTimeChartFragment, interval)) || [];

  const currentIntervalNotAspireSourcedData = currentIntervalsMetaInsightsNotAspireSourced?.map((interval) =>
    getFragmentData(RoasOverTimeChartFragment, interval)) || [];

  // Transform data for the chart
  const data = currentIntervalData.map((interval, index) => {
    const notAspireSourced = currentIntervalNotAspireSourcedData[index];
    const date = interval?.interval?.date ? new Date(interval.interval.date) : new Date();
    return {
      month: date.toLocaleString('default', { month: 'short', day: 'numeric' }),
      'Attributed to Aspire': interval?.roas || 0,
      'Not Attributed to Aspire': notAspireSourced?.roas || 0,
    };
  });

  const chartConfig = {
    'Attributed to Aspire': {
      label: 'Attributed to Aspire',
      color: 'hsl(var(--refresh-purple-4))',
    },
    'Not Attributed to Aspire': {
      label: 'Not Attributed to Aspire',
      color: 'hsl(var(--refresh-purple-3))',
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer className="h-[300px]" config={chartConfig}>
      <LineChart data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={(value: number) => formatNumber(value).toString()}
        />
        <ChartTooltip
          content={
            (
              <ChartTooltipContent valueFormatter={(item) => `${formatNumber(Number(item.value)).toString()}x`} />
            )
          }
        />
        <ChartLegend content={<ChartLegendContent />} />
        <Line
          type="linear"
          dataKey="Attributed to Aspire"
          stroke="hsl(var(--refresh-purple-4))"
          strokeWidth={2}
          dot={false}
        />
        <Line
          type="linear"
          dataKey="Not Attributed to Aspire"
          stroke="hsl(var(--refresh-purple-3))"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ChartContainer>
  );
};

export default RoasOverTimeChart;
