import * as querystring from 'querystring';
import { TNetworkIdentifier } from '@components';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

export interface ISocialAccount {
  id: number;
  username: string;
  has_social_listening_api_access: boolean;
  has_creator_marketplace_api_access: boolean;
  has_messaging_api_access: boolean;
  has_analytics_api_access: boolean;
  network_identifier: TNetworkIdentifier;
  has_branded_content_tag_access: boolean;
  has_partnership_ads_access: boolean;
  has_messaging_toggle_enabled: boolean;
}

// Base interface with common properties
interface IUseFetchSocialAccountDataBase {
  url: string;
  clientId: string;
  network?: TNetworkIdentifier;
}

// Username-specific interface
interface IUseFetchSocialAccountDataByUsername extends IUseFetchSocialAccountDataBase {
  username: string;
  fetchAll?: never;
}

// FetchAll-specific interface
interface IUseFetchSocialAccountDataFetchAll extends IUseFetchSocialAccountDataBase {
  username?: never;
  fetchAll: true;
}

// Combined union type
type IUseFetchSocialAccountDataOptions = IUseFetchSocialAccountDataByUsername | IUseFetchSocialAccountDataFetchAll;

export function useFetchSocialAccountData(options: IUseFetchSocialAccountDataOptions) {
  const {
 url: baseUrl, clientId, username, fetchAll, network,
} = options;

  const filterString = querystring.stringify({
    member_username: username,
    client_id: clientId,
    network,
  });
  const url = `${baseUrl}?${filterString}`;

  const {
 data, isLoading: loading, error, refetch,
} = useBackendServerQuery<ISocialAccount[]>({
    fetchParams: {
      input: url,
    },
    useQueryParams: {
      options: {
        enabled: !!(username || fetchAll),
      },
    },
  });

  return {
    loading,
    data,
    error,
    refetch,
  };
}
