import React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { CardHeader, CardTitle, CardContent } from '@frontend/shadcn/components/ui/card';
import { CardStat, CardStatLabel, CardStatRow } from '../card-stat';

export const AdHighlightCardHeader = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardHeader>>(
  ({ className, ...props }, ref) => (
    <CardHeader ref={ref} className={cn('rounded-t-2xl p-4 pb-0', className)} {...props} />
  ),
);
AdHighlightCardHeader.displayName = 'AdHighlightCardHeader';

export const AdHighlightCardTitle = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardTitle>>(
  ({ className, ...props }, ref) => (
    <CardTitle ref={ref} className={cn('text-primary-foreground flex items-center', className)} {...props} />
  ),
);
AdHighlightCardTitle.displayName = 'AdHighlightCardTitle';

export const AdHighlightCardContent = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardContent>>(
  ({ className, ...props }, ref) => <CardContent ref={ref} className={cn('p-2 px-4', className)} {...props} />,
);
AdHighlightCardContent.displayName = 'AdHighlightCardContent';

export const AdHighlightCardContentRow = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-end flex-wrap gap-2 justify-between', className)} {...props} />
  ),
);
AdHighlightCardContentRow.displayName = 'AdHighlightCardContentRow';

export const AdHighlightCardContentColumn = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex flex-col items-start', className)} {...props} />,
);
AdHighlightCardContentColumn.displayName = 'AdHighlightCardContentColumn';

export const AdHighlightCardStatRow = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardStatRow>>(
  ({ className, ...props }, ref) => <CardStatRow ref={ref} className={className} {...props} />,
);
AdHighlightCardStatRow.displayName = 'AdHighlightCardStatRow';

export const AdHighlightCardStat = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardStat>>(
  ({ className, ...props }, ref) => <CardStat ref={ref} className={cn('text-xl', className)} {...props} />,
);
AdHighlightCardStat.displayName = 'AdHighlightCardStat';

export const AdHighlightCardStatLabel = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardStatLabel>>(
  ({ className, ...props }, ref) => <CardStatLabel ref={ref} className={className} {...props} />,
);
AdHighlightCardStatLabel.displayName = 'AdHighlightCardStatLabel';
