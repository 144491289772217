import * as React from 'react';
import cx from 'classnames';

import {
 map, isEmpty, orderBy, isArray,
} from 'lodash';
import {
 Alert, Button, Space, Typography,
} from '@revfluence/fresh';
import { LoadSpinner } from '@components';

import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { IDesignatedContractSigner } from '../DesignatedContractSigner';
import { NewSignerModal } from '../components/NewSignerModal';
import { AuthorizedSignerCell } from '../components/AuthorizedSignerCell';

import styles from './ContractSignersPage.scss';

const { useEffect, useMemo, useState } = React;
const { Text, Title, Paragraph } = Typography;

interface IProps {
  showNav?: boolean;
  classes?: string[];
  title?: string;
}

const ContractSignersPage: React.FunctionComponent<IProps> = (props) => {
  const { backendServerApiEndpoint, clientId } = useApplication();
  const [showNewSignerModal, setShowNewSignerModal] = useState<boolean>(false);
  const [newCount, setNewCount] = useState<number>(0);

  const finalUrl = `${backendServerApiEndpoint}/contract_signer?client_id=${clientId}`;
  const { isLoading: loading, data: signers, refetch } = useBackendServerQuery<IDesignatedContractSigner[]>({
    fetchParams: { input: finalUrl },
  });

  useEffect(() => {
    refetch();
  }, [clientId, newCount, refetch]);

  const classNames = useMemo(() => {
    let pageClassName = styles.SettingsLayout;
    let settingDetailClassName = styles.setting_detail;
    if (isArray(props.classes)) {
      if (props.classes.length > 0) {
        pageClassName = cx(pageClassName, props.classes[0]);
      }
      if (props.classes.length > 1) {
        settingDetailClassName = cx(settingDetailClassName, props.classes[1]);
      }
    }
    return { pageClassName, settingDetailClassName };
  }, [props.classes]);

  const addSigner = () => {
    setShowNewSignerModal(true);
  };

  const onNewSignerModalRequestClose = () => {
    setShowNewSignerModal(false);

    // Force refetch by indicating that there is additional signer
    setNewCount(newCount + 1);
  };

  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <Space direction="vertical" size="large" className={classNames.pageClassName}>
      {props.showNav && (
        <div className={styles.setting_nav}>
          <ul>
            <li>
              <a href="#" className={styles.settings_nav_active}>
                Authorized Signers
              </a>
            </li>
          </ul>
        </div>
      )}

      <Space direction="vertical" size="middle">
        {props.title && <Title level={3}>{props.title}</Title>}
        <Title level={4}>Signers</Title>
        <Paragraph>
          Add the contact information of the people on your team that are authorized to sign contracts
        </Paragraph>
        <Text strong>Your authorized signers for your organization:</Text>
      </Space>
      {map(orderBy(signers, 'date_created', 'desc'), (signer, index) => (
        <AuthorizedSignerCell signer={signer} key={index} />
      ))}

      {isEmpty(signers) && (
        <Alert
          type="info"
          message="You haven't added any authorized signers yet"
        />
      )}
      <Button type="primary" onClick={addSigner}>
        Add Signer
      </Button>

      <NewSignerModal show={showNewSignerModal} onRequestClose={onNewSignerModalRequestClose} />
    </Space>
  );
};

ContractSignersPage.defaultProps = {
  showNav: true,
};

export default ContractSignersPage;
