import * as React from 'react';

import { pick, map } from 'lodash';
import { logger } from '@common';
import { SimpleForm, SimpleField } from '@frontend/app/components';
import {
  Select,
  Textarea,
  SubmitButton,
  TokenInput,
  Notice,
  TagIcon,
  LoadSpinner,
  IToastRefHandles,
} from '@components';

import { useAuth } from '@frontend/context/authContext';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { IContent, useGetMemberQuery, useClientFeatureEnabled } from '@frontend/app/hooks';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { ClientFeature } from '@frontend/app/constants';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { ContentRightsType } from '../saveLicensedContent';
import { saveContentData, ISendLicensedContentRequest } from '../saveLicensedContent';
import { ContentReducerActionType } from '../pages/NewContent';

import styles from './NewContentSetup.scss';

const { useState, useRef } = React;

interface IProps {
  dispatch(action): void;
  uploadedContent: IContent[];
  memberId: string;
  toastRef?: React.MutableRefObject<IToastRefHandles>;
}

const selectOptions = [
  {
    value: ContentRightsType.PERMISSION_LEVEL_NONE,
    label: 'No permissions',
  },
  {
    value: ContentRightsType.PERMISSION_LEVEL_CUSTOM,
    label: 'Limited permissions',
  },
  {
    value: ContentRightsType.PERMISSION_LEVEL_BROAD,
    label: 'Broad permissions',
  },
];

export const NewContentSetup: React.FunctionComponent<IProps> = (props) => {
  const { dispatch, uploadedContent, memberId } = props;
  const [saving, setSaving] = useState<boolean>(false);
  const { user } = useAuth();
  const { backendServerApiEndpoint, clientId } = useApplication();
  const [submitError, setSubmitError] = useState<string>(null);
  const { backendServerFetchResponse } = useBackendServerFetch();
  const addEvent = useEventContext();
  const isProjects = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const EMPTY_INPUT = {
    permission_level: null,
    permission_notes: '',
    sku: '',
    tags: [],
  };

  // Step 1 is get member information
  const {
    loading: memberLoading,
    data: memberData,
    error: memberError,
  } = useGetMemberQuery(Number(memberId));

  const [input, setInput] = useState(EMPTY_INPUT);

  const formRef = useRef<SimpleForm>();

  const formValues = pick(input, ['permission_notes', 'sku']);

  const handleClickSave = () => {
    formRef.current.submit();
    // Show content submitted message
    props.toastRef.current.showMessage({
      content: 'Content Submitted',
      type: 'success',
      duration: 5000,
    });
  };

  const handleChangeFields = (fieldName: string, value: string) => {
    setInput((i) => ({ ...i, [fieldName]: value }));
  };

  const handleError = () => {
    // Todo
  };

  const handleSubmit = () => {
    // Manual form validation for custom elements
    if (!input.permission_level) {
      setSubmitError('Please select a choice for content license');
      return;
    }

    // Save licensed contents to server
    setSaving(true);
    const url = `${backendServerApiEndpoint}/licensed_content`;
    const content = map(uploadedContent, (content) => ({
        media: [{
          media_type: content.type,
          url: content.fileUrl,
        }],
        member_id: Number(memberId),
        client_id: clientId,
        permission_level: input.permission_level,
        permission_notes: formValues.permission_notes,
        sku: formValues.sku,
        tags: input.tags,
        content_type: content.type,
        source: 'ax_upload',
      }));

    const params: ISendLicensedContentRequest = {
      content,
      member: {
        name: memberData.member.name,
        email: memberData.member.email,
        id: memberData.member.id,
        profile_picture: null,
      },
      user: {
        name: user.name,
        email: user.email,
        profile_picture: user.picture,
      },
      client_id: clientId,
    };

    saveContentData(url, params, backendServerFetchResponse).then(({ data: contents }) => {
      dispatch({
        type: ContentReducerActionType.SetContent,
        value: contents,
      });
    }).then(() => {
      try {
        addEvent(
          EventName.ContentCreated,
          {
            has_sku: !((formValues.sku == '' || formValues.sku == null)),
            member_id: Number(memberId),
          },
        );
      } catch (error) {
        logger.error({ message: error });
      }
    }).catch(handleError)
.finally(() => setSaving(false));
  };

  const setLicenseRights = (value) => {
    setInput({
      ...input,
      permission_level: value,
    });
    setSubmitError(null);
  };

  const handleChangeTags = (tags: string[]) => {
    setInput({
      ...input,
      tags,
    });
  };

  return (
    <div className={styles.NewContentSetup}>
      {
        memberLoading ? <LoadSpinner />
          : memberError ? (
            <Notice type="error">
              Sorry, we encountered an unexpected error! Please email
              help@aspireiq.com if this error continues.
            </Notice>
) : (
  <div>
    <h1 className={styles.main_title}>Content Details</h1>
    {!isProjects
              && <p className={styles.main_descrip}>Set assignments for your contract so that you can keep it organized. Make sure it belongs to the right activation, community and programs.</p>}
    {isProjects
                && <p className={styles.main_descrip}>Set assignments for your contract so that you can keep it organized. Make sure it belongs to the right group and project.</p>}
    <SimpleForm
      ref={formRef}
      values={formValues}
      onChange={handleChangeFields}
      onSubmit={handleSubmit}
    >

      <label className={styles.standard_label}>Select what best describes your license permissions to this content:</label>
      <div className={styles.form_wrap}>
        <fieldset>
          <Select options={selectOptions} onChange={setLicenseRights} />
        </fieldset>
        <fieldset>
          <label className={styles.standard_label}>License Rights:</label>
          <SimpleField
            name="permission_notes"
            required={false}
            type="string"
          >
            <Textarea onChange={null} />
          </SimpleField>
        </fieldset>
        <fieldset>
          <label className={styles.standard_label}>Tags:</label>
          <TokenInput
            onChange={handleChangeTags}
            placeholder="Enter a tag..."
            icon={<TagIcon size={18} />}
            hidePopover
          />
        </fieldset>
      </div>
    </SimpleForm>
    <div>
      <SubmitButton
        label="Save"
        isSubmitting={saving}
        disabled={saving}
        submittingLabel="Saving..."
        onClick={handleClickSave}
      />
    </div>
    {submitError
                && <Notice type="error">{submitError}</Notice>}
    {/* <MediaPreview mediaUrls={mediaUrls} /> */}
  </div>
          )
}
    </div>
  );
};
