import * as React from 'react';
import cx from 'classnames';
import { each, map } from 'lodash';

import { IContentReview } from '@components';
import { SubmittedContent, TContent } from './SubmittedContent';

import styles from './PreReviewPanel.scss';

interface IProps {
  review: IContentReview;
  programId: number;
  onRequestClose(): void;
  onSendToGCR(): void;
  onRequestChanges(comment: string, checkOffs?: boolean[]): void;

  loading?: boolean;
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const PreReviewPanel: React.FC<IProps> = React.memo(({
  review, onRequestClose, onSendToGCR, onRequestChanges, loading, className, programId,
}) => {
  const previewContents = useMemo(() => {
    const mediaList = review.content.media || review.content.saved_media_list;
    const previewMap = review.content.preview_map || {};
    const contentList = [];

    if (review.content.content_class === 'YoutubeVideoContent') {
      contentList.push({
        type: 'youtube',
        src: review.content.link,
        downloadableUrl: review.content.link,
        previewUrl: review.content.image_link,
        thumbnailUrl: review.content.image_link,
        text: review.content.caption || '',
        imageLink: review.content.image_link,
      });
    } else {
      each(mediaList, (media) => {
        if (
          !['aiq_preview_resolution_original', 'aiq_preview_resolution'].includes(media.resolution)
        ) {
          contentList.push({
            type: media.media_type,
            src: media.url,
            downloadableUrl: media.url,
            previewUrl: (previewMap[media.url] || {}).aiq_preview_resolution_original || media.url,
            thumbnailUrl: (previewMap[media.url] || {}).aiq_preview_resolution || media.url,
            text: review.content.caption || '',
          });
        }
      });
    }

    return contentList;
  }, [review]);
  const contentItems: TContent = useMemo(() => ({
    issues: map(review.product.checkoff_guidelines, (guideline, index) => ({
      id: `${index}`,
      description: guideline,
    })),
    previews: previewContents,
    caption: review.content?.caption,
    programId,
    contentFieldValues: review?.content_field_values,
    onClose: onRequestClose,
    onSendToGCR,
    onRequestChanges,
    loading,
    acceptedPostTypes: review?.accepted_post_types,
  }), [review, previewContents, onRequestClose, onSendToGCR, onRequestChanges, loading, programId]);
  return (
    <div className={cx(styles.PreReviewPanel, className)}>
      <SubmittedContent {...contentItems} />
    </div>
  );
});

PreReviewPanel.defaultProps = {
  className: null,
};

PreReviewPanel.displayName = 'PreReviewPanel';
