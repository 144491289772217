import {
 Row, Col, Switch, Typography, Input, Form, Checkbox, Button,
} from '@revfluence/fresh';
import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import {
 FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions, OfferFormModes,
} from '../../../types';
import styles from '../../../OfferForm.scss';
import { UtmFields } from '../../AffiliateLinkSection/FormElements/UtmFields';
import { UtmCustomFields } from '../../AffiliateLinkSection/FormElements/UtmCustomFields';

const { Title, Text } = Typography;
interface IProps {
  formValues: IShopifyPromoCodeFormValues;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  shopifyStoreName: string;
  redirectError?: boolean;
  setRedirectError?: (value: boolean) => void;
  secureCodes?: boolean;
  hasMembers?: boolean;
  isOfferPromoLink?: boolean;
  mode?: OfferFormModes;
  isUtmSupport?: boolean;
}
export const LinksForPromos: React.FC<Readonly<IProps>> = ({
  formValues: values,
  handleFormAsyncActions,
  shopifyStoreName,
  setRedirectError,
  secureCodes,
  mode,
  isOfferPromoLink,
  hasMembers,
  isUtmSupport,
}) => {
  const handleUtmParametersToggle = (event) => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isAdvancedUrlEnabled', value: event.target.checked },
    });
};

  const handleSwitch = () => {
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'isPromoLink', value: !values.isPromoLink },
    });
  };

  const handleUtmFieldChange = (key: string, value: string) => {
    // For standard UTM fields, use the UpdateFieldAsyncActionPayload
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: {
        key,
        value,
      },
    });
  };

  useEffect(() => {
    if (!values.shopifyRedirectUrl || isEmpty(values.shopifyRedirectUrl)) {
      setRedirectError(false);
    }
  }, [values, setRedirectError]);

  // const isProjectNameExistsInUtm = useCallback(() => {
  //   const keysToCheck = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'];
  //   return keysToCheck.some((key) => values[key] === '{project_name}');
  // }, [values])();

  const handleSecureCodes = useCallback(
    (event) => {
      handleFormAsyncActions({
        action: OfferFormAsyncActions.UPDATE_FIELD,
        payload: { key: 'isSecureCodes', value: event.target.checked },
      });
      if (event) {
        handleFormAsyncActions({
          action: OfferFormAsyncActions.UPDATE_FIELD,
          payload: { key: 'isMultipleShopifySyncEnabled', value: false },
        });
      }
    },
    [handleFormAsyncActions],
  );

  const handleRedirectUrl = useCallback((event) => {
    try {
      const url = new URL(event.target.value);
      if (url.hostname !== shopifyStoreName) {
        setRedirectError(true);
      } else {
        setRedirectError(false);
      }
    } catch {
      setRedirectError(true);
    }
    handleFormAsyncActions({
      action: OfferFormAsyncActions.UPDATE_FIELD,
      payload: { key: 'shopifyRedirectUrl', value: event.target.value },
    });
  }, [shopifyStoreName, setRedirectError, handleFormAsyncActions]);

  // Generate UTM events that match the expected type
  const generateUtmEvents = (key: string) => ({
    onBlur: () => {},
    onFocus: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onChange: (value: string) => handleUtmFieldChange(key, value),
  });

  return (
    <>
      <div>
        <Row gutter={8}>
          <Col>
            {/* change logic for disable again to hasMembers instead of edit. */}
            <Switch size="default" checked={values.isPromoLink} onChange={handleSwitch} disabled={isOfferPromoLink} />
          </Col>
          <Col>
            <Title level={5}>Generate shareable links for promo codes</Title>
          </Col>
        </Row>
      </div>
      <div>
        <Text className={styles.descTextColor}>
          Enabling this will generate unique links for each promo code assigned to each member, allowing them to use
          these links to promote your offer more effectively.
        </Text>
      </div>
      {values.isPromoLink && (
        <Col style={{ padding: 0, paddingTop: '8px' }}>
          <Row style={{ marginBottom: '5px' }}>
            Enter the redirect URL
            {' '}
            <Text style={{ color: 'grey' }}> (optional)</Text>
            {' '}
          </Row>
          <Row style={{ paddingLeft: 0, marginBottom: '5px' }}>
            <Input
              size="large"
              placeholder="Enter the Redirect URL for links"
              value={values.shopifyRedirectUrl}
              onChange={handleRedirectUrl}
              disabled={values.isSecureCodes}
            />
          </Row>
          <Row style={{ marginBottom: '5px' }}>
            <Text style={{ color: 'grey' }}>By default, user will be redirected to your website homepage.</Text>
          </Row>
          {isUtmSupport && (
            <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Checkbox
                checked={values.isAdvancedUrlEnabled}
                onChange={handleUtmParametersToggle}
                disabled={hasMembers}
              >
                <strong>UTM Parameters Settings</strong>
              </Checkbox>
            </Row>
          )}
          {values.isAdvancedUrlEnabled && (
            <div className={styles.urlwrapper}>
              <UtmFields
                name="advanceUrlSettingType"
                label="UTM Source"
                keyName="utmSource"
                events={generateUtmEvents('utmSource')}
              />
              <UtmFields
                name="advanceUrlSettingType"
                label="UTM Medium"
                keyName="utmMedium"
                events={generateUtmEvents('utmMedium')}
              />
              <UtmFields
                name="advanceUrlSettingType"
                label="UTM Campaign"
                keyName="utmCampaign"
                events={generateUtmEvents('utmCampaign')}
              />
              <UtmFields
                name="advanceUrlSettingType"
                label="UTM Content"
                keyName="utmContent"
                events={generateUtmEvents('utmContent')}
              />
              <UtmFields
                name="advanceUrlSettingType"
                label="UTM Term"
                keyName="utmTerm"
                events={generateUtmEvents('utmTerm')}
              />
              <div className="ant-form-item-label">
                <label>Custom Parameters</label>
              </div>
              <Form.List name="customUTMParameters">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, index) => (
                      <UtmCustomFields
                        name="advanceUrlSettingType"
                        key={key}
                        customName={name}
                        disabled={hasMembers}
                        events={null}
                        index={index}
                        remove={remove}
                      />
                    ))}
                    {fields.length < 3 ? (
                      <Form.Item name="button">
                        <Button size="large" onClick={() => add({ key: '', value: '' })} disabled={hasMembers}>
                          <PlusOutlined />
                          Add Parameter
                        </Button>
                      </Form.Item>
                    ) : (
                      <div className={styles.customText}>You can add up to 3 custom parameters only</div>
                    )}
                  </>
                )}
              </Form.List>
            </div>
          )}
          {secureCodes && (
            <Row style={{ marginTop: '20px' }}>
              <Col>
                <Form.Item name="isSecureCodes">
                  <Checkbox
                    checked={values.isSecureCodes}
                    onChange={handleSecureCodes}
                    disabled={mode !== OfferFormModes.Create}
                  >
                    <Text strong>Enable Secure Codes Generation</Text>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </>
  );
};
