/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_e7jyh_157 {
  display: flex;
}

._justify-content-space-between_e7jyh_161 {
  justify-content: space-between;
}

._tabular-nums_e7jyh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_e7jyh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MessageTemplatesPage_e7jyh_178 ._header_e7jyh_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._action_e7jyh_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188 ._label_e7jyh_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_e7jyh_157 {
  display: flex;
}

._justify-content-space-between_e7jyh_161 {
  justify-content: space-between;
}

._tabular-nums_e7jyh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_e7jyh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MessageTemplatesPage_e7jyh_178 ._header_e7jyh_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._action_e7jyh_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188 ._label_e7jyh_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MessageTemplatesPage_e7jyh_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 3rem 0;
  overflow: hidden;
  overflow-x: auto;
}
._MessageTemplatesPage_e7jyh_178 ._pageHeader_e7jyh_423 {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #dadcde;
}
._MessageTemplatesPage_e7jyh_178 ._pageHeader_e7jyh_423 ._backButton_e7jyh_430 {
  height: 3rem;
  margin: 0 0.75rem 0 -0.75rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
}
._MessageTemplatesPage_e7jyh_178 ._pageHeader_e7jyh_423 ._leftArrowIcon_e7jyh_436 {
  color: #1a1818;
  font-size: 1.5rem;
}
._MessageTemplatesPage_e7jyh_178 ._pageHeader_e7jyh_423 ._image_e7jyh_440 {
  object-fit: contain;
  width: 3rem;
  height: 3rem;
}
._MessageTemplatesPage_e7jyh_178 ._pageHeader_e7jyh_423 ._title_e7jyh_188 {
  margin: 0 3rem 0 1rem;
  font-weight: 600;
  font-size: 1.5rem;
}
._MessageTemplatesPage_e7jyh_178 ._header_e7jyh_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
._MessageTemplatesPage_e7jyh_178 ._searchInput_e7jyh_459 {
  padding: 0.75rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 3.75rem;
  min-width: 46.5625rem;
  flex: 1;
  overflow: hidden;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 {
  flex-shrink: 0;
  width: 18.75rem;
  margin-right: 2.8125rem;
  overflow: hidden;
  overflow-y: auto;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border-bottom: 0.0625rem solid #dadcde;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478._selected_e7jyh_483 {
  background-color: #eff5f9;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478:hover {
  background-color: #e6edf2;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 ._label_e7jyh_198 {
  display: inline-block;
  margin-top: 0.625rem;
  margin-right: 0.625rem;
  align-items: center;
  padding: 0.375rem 0.5rem;
  background: #eff5f9;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: #3996e0;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 ._label_e7jyh_198._selected_e7jyh_483 {
  background: #fdfdfd;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 ._program_e7jyh_504 {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  color: #8f8d91;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 ._program_e7jyh_504 ._programIcon_e7jyh_511 {
  margin-right: 0.25rem;
  vertical-align: middle;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 ._title_e7jyh_188 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._left_e7jyh_436 ._templateRow_e7jyh_478 ._subject_e7jyh_520 span {
  color: #8f8d91;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  min-width: 25rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 h1 {
  font-size: 2em !important;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188 {
  padding: 1rem 1.5rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188:not(._actions_e7jyh_539) {
  border-bottom: 0.0625rem solid #dadcde;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._actions_e7jyh_539 {
  border-top: 0.0625rem solid #dadcde;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 {
  display: flex;
  align-items: center;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._title_e7jyh_188 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._edit_e7jyh_554 {
  display: flex;
  align-items: center;
  color: #3996e0;
  cursor: pointer;
  margin-left: 0.75rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._action_e7jyh_188 {
  margin-left: 1.25rem;
  color: #3996e0;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._action_e7jyh_188:hover {
  color: #4fa7ee;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._titleSection_e7jyh_188 ._action_e7jyh_188._cancel_e7jyh_572 {
  color: #da3737;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._actions_e7jyh_539 {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
  text-align: right;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._actions_e7jyh_539 ._primaryActions_e7jyh_581 {
  display: flex;
  margin-left: auto;
  margin-bottom: 1rem;
  padding-left: 0;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._actions_e7jyh_539 ._primaryActions_e7jyh_581 > div {
  white-space: nowrap;
  margin-left: 1rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188._actions_e7jyh_539 ._deleteButton_e7jyh_591 {
  justify-self: flex-start;
  margin-bottom: 1rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._section_e7jyh_188 ._label_e7jyh_198 {
  margin-right: 0.75rem;
  color: #8f8d91;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._EmailComposer_e7jyh_188 ._subject_e7jyh_520 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.0625rem solid #dadcde;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._signature_e7jyh_605 {
  margin-top: 1.25rem;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._signature_e7jyh_605 a {
  text-decoration: underline;
}
._MessageTemplatesPage_e7jyh_178 ._container_e7jyh_188 ._right_e7jyh_188 ._signature_e7jyh_605 a ._externalLinkIcon_e7jyh_611 {
  vertical-align: text-bottom;
  margin-left: 0.375rem;
}
._MessageTemplatesPage_e7jyh_178 ._loadSpinner_e7jyh_615 {
  margin-top: 20%;
}