import gql from 'graphql-tag';

export const CREATE_TAG = gql`
  mutation CreateTag($entityType: TagEntityType!, $name: String!) {
    tag: createTag(entityType: $entityType, name: $name) {
      id
      name
      clientId
      createdDate
      deletedDate
      entityType
      updatedDate
    }
  }
`;

export const GET_TAGS = gql`
  query GetTagsQuery($entityType: TagEntityType, $includeDeleted: Boolean, $name: String) {
    tags: getTags(entityType: $entityType, includeDeleted: $includeDeleted, name: $name) {
      id
      name
      clientId
      createdDate
      deletedDate
      entityType
      updatedDate
    }
  }
`;

export const ASSIGN_UNASSIGN_TAG = gql`
  mutation AssignUnassignTagMutation(
    $tagIds: [Int!]!
    $entityIds: [BigInt!]!
    $entityType: TagEntityType!
    $action: TagAction!
  ) {
    assignUnassignTag: updateTagEntityAssignment(
      tagIds: $tagIds
      entityIds: $entityIds
      entityType: $entityType
      action: $action
    )
  }
`;

export const GET_TAGS_BY_ENTITY_ID_AND_TYPE = gql`
  query GetTagsByEntityIdAndTypeQuery($entityIds: [BigInt!]!, $entityType: TagEntityType!) {
    tags: getTagsByEntityIdAndType(entityIds: $entityIds, entityType: $entityType) {
      id
      name
      clientId
      createdDate
      deletedDate
      entityType
      updatedDate
    }
  }
`;
