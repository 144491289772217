import * as React from 'react';
import * as qs from 'querystring';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';
import { isEmpty } from 'lodash';
import { IPaymentSources } from './models';

const {
 useMemo,
} = React;

interface IPaymentSourceFetchParams {
  client_id: string;
}

interface UseFetchPaymentSourceDataParams {
  backendServerApiEndpoint?: string;
  clientId?: string;
}

export function useFetchPaymentSourceData({
  backendServerApiEndpoint: paramBackendServerApiEndpoint,
  clientId: paramClientId,
}: UseFetchPaymentSourceDataParams = {}) {
  // Provide default values if useApplication returns null
  const { backendServerApiEndpoint: contextBackendServerApiEndpoint, clientId: contextClientId } = useApplication() || {};

  const backendServerApiEndpoint = paramBackendServerApiEndpoint || contextBackendServerApiEndpoint;
  const clientId = paramClientId || contextClientId;

  const finalUrl = useMemo(() => {
    if (!backendServerApiEndpoint || !clientId) return null;

    const url = `${backendServerApiEndpoint}/payment_source`;

    const params: IPaymentSourceFetchParams = {
      client_id: clientId,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterString = qs.stringify(params as any);

    return `${url}?${filterString}`;
  }, [backendServerApiEndpoint, clientId]);

  const {
 isLoading: loading, error, data, refetch,
} = useBackendServerQuery<IPaymentSources>({
    fetchParams: { input: finalUrl },
    useQueryParams: { options: { enabled: !isEmpty(finalUrl) } },
  });
  return {
    loading,
    data,
    error,
    refetch,
  };
}
