import * as qs from 'qs';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

import { endpoints, ISocialAccount } from '@components';

export enum DetailedSocialAccountCaller {
  CREATOR_SEARCH = 'creator_search',
  SOCIAL_PROFILE = 'social_profile',
}

interface IProps {
  apiEndpoint: string;
  id: ISocialAccount['id'];
  brandInstagramUsername?: string;
  clientId?: string;
  caller?: DetailedSocialAccountCaller;
}

/**
 * Fetch detailed social account
 */
export const useDetailedSocialAccount = (props: IProps) => {
  const {
 apiEndpoint, id, brandInstagramUsername, clientId, caller,
} = props;

  const url = apiEndpoint && id
      ? `${apiEndpoint}/${endpoints.socialAccountEndpoint}${qs.stringify(
          {
            social_account_id: id,
            brand_instagram_username: brandInstagramUsername || undefined,
            client_id: clientId,
            include_social_profile_data: true,
            caller,
          },
          { addQueryPrefix: true },
        )}`
      : null;

  const { data: socialAccountData, isFetching, error } = useBackendServerQuery<{ data: ISocialAccount[] }>({
    fetchParams: {
      input: url || '',
    },
    useQueryParams: {
      options: {
        enabled: !!url,
      },
    },
  });

  const data = socialAccountData?.data;
  const detailedSocialAccount = Array.isArray(data) && data.length > 0
    ? Object.freeze(data[0])
    : null;

  return {
    detailedSocialAccount,
    isFetching,
    error,
  };
};
