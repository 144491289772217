import { combineReducers } from 'redux';
import { IConnectSearchPage } from '../models';
import { imageSearchReducer } from './imageSearchReducer';
import { externalVarReducer } from './externalVarReducer';
import { searchResultsReducer } from './searchResultsReducer';
import { textSearchReducer } from './textSearchReducer';
import { savedSearchesReducer } from './savedSearchesReducer';
import { authReducer } from './authReducer';

export default combineReducers<IConnectSearchPage>({
  auth: authReducer,
  external: externalVarReducer,
  imageSearch: imageSearchReducer,
  searchResults: searchResultsReducer,
  textSearch: textSearchReducer,
  savedSearches: savedSearchesReducer as any,
});
