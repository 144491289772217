import gql from 'graphql-tag';

export const GET_OFFERS_QUERY = gql`
  query GetOffers {
    offers {
      createdDate
      id
      imageUrl
      expired
      links {
        status
      }
      name
      promos {
        status
      }
      programId
      type
      archivedDate
      isPromoLink
    }
  }
`;
