import { FulfillmentStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';

export enum ProductFulfillmentDashboardTabEnum {
  Analytics = 'Analytics',
  Orders = 'Orders',
}

export const fulfillmentStatusLabels = {
  [FulfillmentStatus.CANCELLED]: 'Cancelled',
  [FulfillmentStatus.ERROR]: 'Error',
  [FulfillmentStatus.FAILURE]: 'Failure',
  [FulfillmentStatus.FULFILLED]: 'Fulfilled',
  [FulfillmentStatus.OPEN]: 'Open',
  [FulfillmentStatus.PARTIAL]: 'Partial',
  [FulfillmentStatus.PENDING]: 'Pending',
  [FulfillmentStatus.SUCCESS]: 'Success',
};
