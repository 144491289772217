import * as React from 'react';
import {
  Alert,
  Avatar,
  Button, Card, Col, IColumnsType, Input, message, Modal, Row, Skeleton,
  Tag,
} from '@revfluence/fresh';
import {
 ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon,
 TrashIcon,
 TriangleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { FileCsvIcon } from '@revfluence/fresh-icons/solid/esm';
import AffiliateTableSTA from '@frontend/applications/AffiliatesApp/components/AffiliateTableSTA/AffiliateTableSTA';
import { TableHeader } from '@frontend/applications/AffiliatesApp/components';
import {
  filter, includes, isEmpty, isNil, isNull, isString, map, size,
} from 'lodash';
import { IHeader, useExportToCsv } from '@frontend/applications/AffiliatesApp/hooks';
import { useHistory } from 'react-router-dom';
import { ImportStatus, InventorySource, Source } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GetImportedCollectionsByClientId_getImportedCollections } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedCollectionsByClientId';
import { ExportButton } from '@frontend/applications/AffiliatesApp/components/MemberTable/Buttons';
import { format } from 'date-fns';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { useUpdateImportSyncSettings } from '@frontend/applications/ProductFulfillmentApp/hooks/useUpdateImportSyncSettings';
import { useCreateInventorySettings } from '@frontend/applications/ProductFulfillmentApp/hooks/useCreateInventorySettings';
import { GET_IMPORTED_COLLECTIONS_BY_CLIENT_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { TabContainer } from '../TabContainer/TabContainer';
import styles from './Collections.scss';
import { ICollectionRow } from '../types';
import { useGetImportedCollections } from '../hooks/useGetImportedCollections';
import { EmptyScreen } from '../../components/EmptyScreen/EmptyScreen';
import { NameWithImageRenderer } from '../../components/NameWithImageRenderer/NameWithImageRenderer';
import { ImportStatusRenderer } from '../ShopifyImportModal/ImportStatusRenderer';
import { pfaV2Routes } from '../../routes';
import { StatsRenderer } from '../../components/StatsRenderer/StatsRenderer';
import { useGetAllCollectionStats } from '../hooks/useGetAllCollectionStats';
import { useDeleteImportedCollectionById } from '../hooks/useDeleteImportedCollectionById';

const PAGE_SIZE = 10;

const AllCollections = () => {
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const { clientConfig, loading: isClientConfigLoading } = useGetClientConfig();

  const { deleteImportedCollectionById, isDeletingImportedCollectionById } = useDeleteImportedCollectionById({
    refetchQueries: [GET_IMPORTED_COLLECTIONS_BY_CLIENT_ID_QUERY],
    onCompleted: (data) => {
      if (data?.deleteImportedCollectionById) {
        message.success('Collection deleted successfully');
      } else {
        message.error('Failed to delete collection');
      }
    },
    onError: (error) => {
      message.error(error?.message || 'Failed to delete collection');
    },
  });

  const columnConfig = React.useMemo<IColumnsType<ICollectionRow>>(
    () => [
      {
        title: 'Collections',
        dataIndex: '_raw',
        key: 'title',
        width: 288,
        align: 'left',
        ellipsis: {
          showTitle: false,
        },
        render: (collection: GetImportedCollectionsByClientId_getImportedCollections) =>
          NameWithImageRenderer({
            title: collection.collection?.name,
            image:
              collection.collection?.images?.[0]
              || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png',
          }),
      },
      {
        title: 'Products',
        dataIndex: 'productsCount',
        key: 'productsCount',
        align: 'left',
        width: 184,
      },
      // {
      //   title: 'Variants',
      //   dataIndex: 'variantsCount',
      //   key: 'variantsCount',
      //   width: 184,
      //   align: 'left',
      // },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        align: 'left',
        width: 92,
        render: (source) => {
          if (source === Source.CSV) {
            return <FileCsvIcon />;
          }
          return <ShopifyIcon fontSize={16} fill="--var(gray-9)" />;
        },
      },
      {
        title: 'Import Status',
        dataIndex: '_raw',
        key: 'importStatus',
        align: 'left',
        width: 184,
        render: (collection: GetImportedCollectionsByClientId_getImportedCollections) => (
          <ImportStatusRenderer
            collection={{
              id: collection.collection.externalId,
              image: collection.collection?.images?.[0],
              title: collection.collection?.name,
              productsCount: collection.collection?.productCount,
              currentStatus: collection.status,
              handle: collection.collection?.metadata?.handle,
            }}
            lastSyncDate={collection.collection?.lastSyncDate}
            statusInfo={{
              id: collection.collection.id,
              externalId: collection.collection.externalId,
              status: collection.status,
            }}
          />
        ),
      },
      {
        title: 'Last Synced',
        dataIndex: 'lastSynced',
        key: 'lastSynced',
        align: 'left',
        width: 176,
        render: (lastSynced) => (lastSynced ? <Tag>{format(new Date(lastSynced), "MMM dd, yyyy 'at' h:mmaaaaa'm'")}</Tag> : '-'),
      },
      {
        title: 'Imported By',
        dataIndex: 'importedBy',
        key: 'importedBy',
        align: 'left',
        width: 100,
        render: (importedBy: string) => {
          const nameInitials = importedBy?.split(' ').map((name) => name[0]).join('');
          return <Avatar className={styles.importedByAvatar}>{nameInitials}</Avatar>;
        },
      },
      {
        title: '',
        dataIndex: '_raw',
        key: 'actions',
        align: 'left',
        width: 80,
        render: (collection: GetImportedCollectionsByClientId_getImportedCollections) => (
          <Button
            danger
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                title: 'Are you sure you want to delete this collection?',
                icon: <TriangleExclamationIcon />,
                okText: 'Yes',
                okType: 'danger',
                onOk: () => {
                  deleteImportedCollectionById({ variables: { collectionId: collection.collectionId } });
                },
              });
            }}
            loading={isDeletingImportedCollectionById}
            icon={<TrashIcon />}
            size="small"
          />
        ),
      },
    ],
    [deleteImportedCollectionById, isDeletingImportedCollectionById],
  );

  const { collections } = useGetImportedCollections();
  const { stats, loading: isStatsLoading } = useGetAllCollectionStats();

  const pagination = {
    total: collections.length,
    pageSize: PAGE_SIZE,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    itemRender: (_current, type, originalElement) => {
      if (type === 'prev') {
        return <Button icon={<ChevronLeftIcon fontSize={24} />} />;
      }
      if (type === 'next') {
        return <Button icon={<ChevronRightIcon fontSize={24} />} />;
      }
      return originalElement;
    },
    showLessItems: true,
    className: 'customPagination',
  };

  const headerActions = React.useMemo(
    () => (
      <TableHeader
        renderCount={() => {
          const count = size(collections);
          return `${count} Collection${count !== 1 ? 's' : ''}`;
        }}
      />
    ),
    [collections],
  );

  const searchBox = () => (
    <Row gutter={9}>
      <Col>
        <Input
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          prefix={<MagnifyingGlassIcon />}
        />
      </Col>
      <Col>
        <ExportButton disabled={isEmpty(collections)} onClick={handleClickExport} showText={false} />
      </Col>
    </Row>
  );

  const tableData = React.useMemo<ICollectionRow[]>(
    () =>
      collections.map((collection) => ({
        key: String(collection.collectionId),
        id: String(collection.collectionId),
        title: collection.collection?.name,
        productsCount: collection.collection?.metadata?.importedProductCount || 0,
        source: collection.collection?.source,
        importStatus: collection.status,
        importedBy: collection.userInfo?.name,
        image: collection.collection?.images?.[0],
        lastSynced: collection.collection?.lastSyncDate,
        _raw: collection,
      })),
    [collections],
  );

  const exportToCsv = useExportToCsv();
  const handleClickExport = React.useCallback(() => {
    const csvColumnConfig = map(columnConfig, (config): IHeader | null => {
      if (!isString(config.title)) {
        return null;
      }
      return {
        headerName: config.title,
        field: config.key.toString(),
      };
    }).filter((c) => !isNull(c));
    const selectedCollections = isEmpty(selectedRowKeys)
      ? tableData
      : tableData.filter((collection) => selectedRowKeys.includes(collection.id));
    if (!isEmpty(tableData)) {
      exportToCsv(`collections-${Date.now()}`, csvColumnConfig, selectedCollections);
    }
  }, [columnConfig, exportToCsv, selectedRowKeys, tableData]);

  const filteredData = React.useMemo(() => {
    if (!searchText) {
      return tableData;
    }
    return filter(tableData, (item: ICollectionRow) => includes(item.title.toLowerCase(), searchText.toLowerCase()));
  }, [tableData, searchText]);

  const totalErrors = React.useMemo(() => tableData.filter((item) => item.importStatus === ImportStatus.FAILED).length, [tableData]);

  const onSelectionChange = React.useCallback((keys) => {
    setSelectedRowKeys(keys);
  }, []);

  const history = useHistory();

  const isLoading = isClientConfigLoading || isStatsLoading;

  const importLimit = clientConfig?.importSyncSettings?.productsImportLimit || 50_000;

  return (
    <>
      {totalErrors > 0 ? (
        <Alert
          message={`Failed to import ${totalErrors} collections from Shopify`}
          type="error"
          action={(
            <Button size="small" type="ghost" onClick={() => history.push(pfaV2Routes.settings.importLogs)}>
              View Import History
            </Button>
            )}
          closable
          style={{ marginBottom: '24px' }}
        />
      ) : null}
      <div className={styles.Collections}>
        <Card>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Row gutter={[16, 16]} className={styles.metricsRow}>
              <Col flex={1}>
                <StatsRenderer title="Collections" total={stats.shopifyCollectionCount} />
              </Col>
              <Col flex={1}>
                <StatsRenderer title="Total Unique Products" total={stats.shopifyProductsCount} outOff={importLimit} />
              </Col>
              <Col flex={1}>
                <StatsRenderer title="Total Unique Variants" total={stats.shopifyVariantsCount} />
              </Col>
            </Row>
          )}
          <AffiliateTableSTA<ICollectionRow>
            dataSource={filteredData}
            columns={columnConfig}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys,
              onChange: onSelectionChange,
            }}
            pagination={pagination}
            headerActions={headerActions}
            searchBox={searchBox()}
            searchText={searchText}
            sortField="name"
            className="offerTable"
            enableEditColumn
            onRow={(record) => ({
              onClick: () => history.push(pfaV2Routes.settings.collectionDetails.replace(':collectionId', record.id)), // click row
            })}
          />
        </Card>
      </div>
    </>
  );
};

export const Collections = () => {
  const { loading, collections } = useGetImportedCollections();

  const { clientConfig, loading: isClientConfigLoading, refetch } = useGetClientConfig();

  const { updateImportSyncSettings } = useUpdateImportSyncSettings();

  const { createInventorySetting } = useCreateInventorySettings();

  // Set default values for autoSyncFromShopify and stockAvailabilityCheck
  React.useEffect(() => {
    const updateConfig = async () => {
      if (isClientConfigLoading) return;
      if (isNil(clientConfig?.importSyncSettings?.autoSyncFromShopify)) {
        await updateImportSyncSettings({
          variables: {
            importSyncSettings: {
              autoSyncFromShopify: true,
              productsImportLimit: clientConfig?.importSyncSettings?.productsImportLimit,
            },
          },
        });
      }
      if (isNil(clientConfig?.inventorySettings?.stockAvailabilityCheck)) {
        await createInventorySetting({
          variables: {
            inventorySettings: {
              stockAvailabilityCheck: true,
              inventorySource: clientConfig?.inventorySettings?.inventorySource || InventorySource.ONLINE_SELLABLE_INVENTORY,
              maintainMinimumInventory: clientConfig?.inventorySettings?.maintainMinimumInventory,
              minimumStockLevel: clientConfig?.inventorySettings?.minimumStockLevel,
              inventoryLocations: clientConfig?.inventorySettings?.inventoryLocations || [],
              reserveStockForPendingOrders: clientConfig?.inventorySettings?.reserveStockForPendingOrders,
            },
          },
        });
      }
      refetch();
    };
    updateConfig();
  }, [clientConfig?.importSyncSettings, clientConfig?.inventorySettings, createInventorySetting, isClientConfigLoading, refetch, updateImportSyncSettings]);

  if (loading) {
    return (
      <TabContainer>
        <Skeleton />
      </TabContainer>
    );
  }

  return <TabContainer>{isEmpty(collections) ? <EmptyScreen /> : <AllCollections />}</TabContainer>;
};
