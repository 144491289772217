import { size } from 'lodash';
import * as React from 'react';

import { Button } from '@frontend/shadcn/components/ui/button';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import {
  ModifyTagMembersModal,
  TagsIcon,
} from '@frontend/app/components';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { cn } from '@frontend/shadcn/lib/utils';
import {
 DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import {
 Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';

const { useState } = React;

interface IProps {
  className?: string;
  disabled?: boolean;
  memberCount?: number;
  memberIds?: number[]
  searchQuery?: IMemberSearchQuery;
}

type TModalMode = 'add' | 'remove' | null;

export const AddTags: React.FunctionComponent<IProps> = React.memo(({
  className,
  disabled,
  memberCount,
  memberIds,
  searchQuery,
}) => {
  const [shownModal, setShownModal] = useState<TModalMode>();

  const addEvent = useEventContext();

  const handleMenuItemClick = (type) => {
    addEvent(
      EventName.AttemptBulkAction,
      {
        action: type === 'add' ? 'add_to_tag' : (type === 'remove' ? 'remove_from_tag' : null),
        member_count: size(memberIds) > 0 ? size(memberIds) : memberCount,
      },
    );
    setShownModal(type);
  };

  const onCloseModal = () => {
    setShownModal(null);
  };

  const renderButton = () => (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <Button
            disabled={disabled}
            size="icon"
            variant="ghost"
            className={cn(className)}
          >
            <TagsIcon size={16} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Tags</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
  const renderContent = () => (
    <>
      <DropdownMenuItem
        onClick={() => handleMenuItemClick('add')}
      >
        <span>Assign Tags</span>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => handleMenuItemClick('remove')}
      >
        <span>Unassign Tags</span>
      </DropdownMenuItem>
    </>
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={disabled}>
          {renderButton()}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {renderContent()}
        </DropdownMenuContent>
      </DropdownMenu>
      {shownModal && (
        <ModifyTagMembersModal
          memberCount={memberCount}
          memberIds={memberIds}
          onRequestClose={onCloseModal}
          query={searchQuery}
          show={(
            shownModal === 'add'
            || shownModal === 'remove'
          )}
          type={shownModal}
          showNewTagButton
        />
      )}
    </>
  );
});

AddTags.displayName = 'AddTags';
