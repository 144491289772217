import cx from 'classnames';
import {
  debounce,
  filter,
  get,
  keyBy,
  map,
  some,
  isEmpty,
  isNumber,
  isString,
  includes,
  toLower,
  toString,
  reduce,
  find,
  first,
  forEach,
} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { PredefinedSegmentsForProgramQuery_segments } from '@frontend/app/queries/types/PredefinedSegmentsForProgramQuery';
import { ProjectsPageState } from '@frontend/app/containers/Projects/constants';
import {
  ColumnKey,
  AllInProgressStageTitle,
  InStageTitle,
} from '@frontend/app/containers/Projects/constants';
import { ClientFeature, TABLE_PAGE_SIZE } from '@frontend/app/constants';
import {
  IRowData,
  ITableProps,
  ITableRefHandles,
  IProgram as IProject,
} from '@components';
import {
  getColumnWidth,
} from '@frontend/app/components/MemberTable/hooks';
import {
  LoadSpinner,
  ModifyTagMembersModal,
  ModifyProgramMembersModal,
  ModifyCommunityMembersModal,
} from '@frontend/app/components';
import {
  useMemberFieldsWithSources,
} from '@frontend/app/hooks/memberList';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import {
  Col, Row, Space,
} from '@revfluence/fresh';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import {
  MemberSearchQuery_members as IMember,
  MemberSearchQueryVariables,
  MemberSearchQuery_members_owners as IOwners,
} from '@frontend/app/queries/types/MemberSearchQuery';
import {
  ITag,
  IThread,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';
import {
  MemberSearchQuery_members_programs as IProgram,
} from '@frontend/app/queries/types/MemberSearchQuery';
import {
  ICounts,
} from '@frontend/app/containers/Projects/hooks';
import { useAuth } from '@frontend/context/authContext';
import { useMemberColumns } from '@frontend/app/components/MemberTable/hooks';
import {
  IField,
} from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { TWorklet, TWorkItem, TTask } from '@frontend/app/containers/Projects/types';
import { DataTable } from '@frontend/app/refresh-components';
import { Button } from '@frontend/shadcn/components/ui/button';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { Input } from '@frontend/shadcn/components/ui/input';
import { ColumnDef } from '@tanstack/react-table';
import { MemberTableRow } from './hooks/useMembersTableColumns';

import { EditProjectColumns } from './EditProjectColumns';

import styles from './MembersTable.scss';
import { FlexMemberTableRow, useFlexMembersTableColumns } from './hooks/useFlexMembersTableColumns';
import { Pagination } from './Pagination';

import { useFlexTableSelectedColumns } from './hooks/useFlexTableSelectedColumns';

const {
  useEffect,
  useMemo,
  useState,
  useCallback,
} = React;

export enum MembersTableSelectStatus {
  All = 'All',
  None = 'None',
  Partial = 'Partial',
}

export const MSG_DURATION = 3000;

export const OWNED_BY_ME_FILTER_VALUE = 'ownedByMe';
export const LAST_MENTION_DATE_7DAYS_FILTER_VALUE = 'lastMentionDate7Days';
export const GROUP_CONTENT_REVIEW_FILTER_VALUE = 'groupContentReview';

export interface IMembersTableSearchQuery extends Omit<MemberSearchQueryVariables, 'query'> {
  query?: IMemberSearchQuery;
  selectStatus?: MembersTableSelectStatus;
}

export interface IMembersTableProps extends Partial<ITableProps> {
  className?: string;
  data?: IRowData[];
  containerHeight: number;
  onSelectedDataChange: (selectedMembers: IMember[]) => void;
  selectedMembers?: IMember[];
  renderHeaderActions?: () => React.ReactElement;
  searchQuery: IMembersTableSearchQuery;
  isLoading?: boolean;
  setDisplayedTotalRowCount?: (value: number) => void;
  selectedQuickFilter: string;
  renderEmptyPlaceholder: (searchText?: string) => React.ReactElement;
  cellActionsByColumn?: {
    name: (memberId: IMember['id']) => void;
    lastMessage: (threadId: IThread['id']) => void;
    cta: (workItem: TWorkItem) => Promise<void>;
    task: THandleTaskSelected;
  };
  visibleColumnIds: string[];
  worklets: TWorklet[];
  counts: ICounts;
  task?: TTask;
  fetchMembers: (queryVariables: IMembersTableSearchQuery) => Promise<void>;
  members: IMember[];
  pageId?: string;
  workletSpecUri?: string;
  project?: IProject;
  projectSpecKey?: string;
  savedColumns: PredefinedSegmentsForProgramQuery_segments;
  defaultSavedColumns: PredefinedSegmentsForProgramQuery_segments;
  isLoadingPredefinedProgramSegments: boolean;
  refetchCounts: () => Promise<void>;
  onRefetchSegments: (projectId: number) => void;
}

export interface IMemberTableRefHandles extends ITableRefHandles {
  // @TODO: Revisit any type and MembersTable/Table widget implementation
  // eslint-disable-next-line
  data: any[];
  memberById: { [id: string]: IMember };
  refetch: (variables?: IMembersTableSearchQuery) => Promise<void | IMember[]>;
  selectedMembers: IMember[];
}

const TABLE_HEADER_HEIGHT = 111;
const FIXED_FIELDS = ['name'];

export type CellModalType = 'tags' | 'projects' | 'groups';

export const FlexMembersTable = React.forwardRef<IMemberTableRefHandles, IMembersTableProps>((
  {
    className,
    data,
    containerHeight,
    renderHeaderActions,
    searchQuery,
    isLoading,
    setDisplayedTotalRowCount,
    selectedQuickFilter,
    renderEmptyPlaceholder,
    cellActionsByColumn,
    visibleColumnIds,
    worklets,
    counts,
    task,
    fetchMembers,
    members,
    pageId,
    workletSpecUri,
    project,
    projectSpecKey,
    savedColumns,
    defaultSavedColumns,
    isLoadingPredefinedProgramSegments,
    refetchCounts,
    onRefetchSegments,
  },
) => {
  const { user } = useAuth();
  const memberColumns = useMemberColumns();
  const schemasByName = useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);
  const lastMentionDateKey = useMemo(() => get(schemasByName, 'Last Mention Date')?.schemaId, [schemasByName]);
  const { fields, appsWithFields } = useMemberFieldsWithSources(null, project?.id, true);
  const [columnsUpdated, setColumnsUpdated] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<IField[]>();
  const [tableData, setTableData] = useState<MemberTableRow[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [xScroll, setXScroll] = useState<number>(0);
  const [cellModalType, setCellModalType] = useState<CellModalType>();
  const [activeCellMember, setActiveCellMember] = useState<IMember>(null);
  const [currentMembers, setCurrentMembers] = useState<IMember[]>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    setCurrentMembers(members);
  }, [members]);
  const filteredFields = useMemo(() => {
    if (task) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName, ColumnKey.WorkletTaskName],
        f.field,
      ));
    }

    if (workletSpecUri) {
      return filter(fields, (f) => !includes(
        [ColumnKey.DateCompleted, ColumnKey.WorkletName, ColumnKey.CTA],
        f.field,
      ));
    }

    if (pageId !== ProjectsPageState.Completed) {
      return filter(
        fields, (f) =>
        !includes([ColumnKey.DateCompleted, ColumnKey.CTA], f.field),
      );
    }

    if (pageId === ProjectsPageState.Completed) {
      return filter(
        fields, (f) =>
        !includes([ColumnKey.WorkletName, ColumnKey.WorkletTaskName], f.field),
      );
    }

    return filter(fields, (f) => !includes([ColumnKey.CTA], f.field));
  }, [fields, task, workletSpecUri, pageId]);

  const fieldById = useMemo(
    () => reduce(
      filteredFields,
      (acc, f) => acc.set(f.field, f), new Map<IField['field'], IField>(),
    ),
    [filteredFields],
  );

  useEffect(() => {
    if (!savedColumns) return;

    const fixed = map(FIXED_FIELDS, (f) => fieldById.get(f));
    const selected = map(
      (savedColumns as IPredefinedSegment).columns?.order,
      (o) => o.dbColumn || toString(o.memberFieldSchemaId) || o.projectColumn,
    );
    const filtered = filter(selected, (f) => !includes(FIXED_FIELDS, f));
    const merged = [
      ...fixed,
      ...map(filtered, (f) => fieldById.get(f)).filter(Boolean),
    ];
    setSelectedColumns(merged);
  }, [savedColumns, fieldById, setSelectedColumns]);

  const defaultColumns = useMemo(() => {
    if (!defaultSavedColumns) return [];

    const fixed = map(FIXED_FIELDS, (f) => fieldById.get(f));
    const selected = map(
      (defaultSavedColumns as IPredefinedSegment).columns?.order,
      (o) => o.dbColumn || toString(o.memberFieldSchemaId) || o.projectColumn,
    );
    const filtered = filter(selected, (f) => !includes(FIXED_FIELDS, f));
    return [
      ...fixed,
      ...map(filtered, (f) => fieldById.get(f)).filter(Boolean),
    ];
  }, [defaultSavedColumns, fieldById]);

  useEffect(() => {
    const widths = map(selectedColumns, (column) => {
      if (column?.width) return column.width;
      return getColumnWidth(column?.headerName).width;
    });
    const total = reduce(widths, (acc, w) => acc + w);
    setXScroll(total);
  }, [selectedColumns]);

  const predefinedSegmentId: string = useMemo(() => {
    if (task) {
      return `${task.taskId}+${task.workletSpecKey}_${project?.id}`;
    }

    if (workletSpecUri) {
      return `${InStageTitle}_${workletSpecUri}_${project?.id}`;
    }

    return `${AllInProgressStageTitle}_${projectSpecKey}_${project?.id}`;
  }, [
    task,
    workletSpecUri,
    project?.id,
    projectSpecKey,
  ]);

  const updateTargetMember = useCallback((targetMember: IMember) => {
    setCurrentMembers((currentMembers) => reduce(
      currentMembers,
      (acc, member) => {
        if (member.id !== targetMember.id) {
          acc.push(member);
        } else {
          acc.push(targetMember);
        }
        return acc;
      },
      [],
    ));
  }, [
    setCurrentMembers,
  ]);

  const onRelationshipAdded = useCallback((
    relationship: 'tags' | 'communities' | 'programs',
    items: ITag[] | ICommunity[] | IProgram[],
    memberIds?: number[],
  ) => {
    const memberId = first(memberIds);
    const targetMember = find(currentMembers, (member) => member.id === memberId);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const existingIds = new Set<number>(map(targetMember[relationship], (item) => (item as any).id));
    const updatedRelationship = [...(targetMember[relationship] || [])];
    forEach(items, (item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!existingIds.has((item as any).id)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedRelationship.push(item as any);
      }
    });
    updateTargetMember({
      ...targetMember,
      [relationship]: updatedRelationship,
    });
  }, [currentMembers, updateTargetMember]);

  const onTagsAdded = useCallback((tags: ITag[], memberIds?: number[]) => {
    onRelationshipAdded('tags', tags, memberIds);
  }, [onRelationshipAdded]);

  const onGroupAdded = useCallback((communities: ICommunity[], memberIds?: number[]) => {
    onRelationshipAdded('communities', communities, memberIds);
  }, [onRelationshipAdded]);

  const onProjectAdded = useCallback((projects: IProgram[], memberIds?: number[]) => {
    onRelationshipAdded('programs', projects, memberIds);
  }, [onRelationshipAdded]);

  const onTagRemoved = useCallback((tagId: number, memberId: number) => {
    const targetMember = find(currentMembers, (member) => member.id === memberId);
    const updatedTags = filter(targetMember.tags, (tag) => tag.id !== tagId);
    updateTargetMember({
      ...targetMember,
      tags: updatedTags,
    });
  }, [currentMembers, updateTargetMember]);

  const onGroupRemoved = useCallback((groupId: number, memberId: number) => {
    const targetMember = find(currentMembers, (member) => member.id === memberId);
    const updatedCommunities = filter(targetMember.communities, (community) => community.id !== groupId);
    updateTargetMember({
      ...targetMember,
      communities: updatedCommunities,
    });
  }, [currentMembers, updateTargetMember]);

  const onProjectRemoved = useCallback((projectId: number, memberId: number) => {
    if (project?.id !== projectId) {
      // user removed member from a project different from current one, update projects for member
      const targetMember = find(currentMembers, (member) => member.id === memberId);
      const updatedProjects = filter(targetMember.programs, (program) => program.id !== projectId);
      updateTargetMember({
        ...targetMember,
        programs: updatedProjects,
      });
    } else {
      // user removed member from current project, filter member out from table
      setCurrentMembers((currentMembers) => filter(
        currentMembers,
        (member) => member.id !== memberId,
      ));
      refetchCounts();
    }
  }, [
    project?.id,
    currentMembers,
    setCurrentMembers,
    updateTargetMember,
    refetchCounts,
  ]);

  const onCellModalOpen = useCallback((cellModalType: CellModalType, member: IMember) => {
    setActiveCellMember(member);
    setCellModalType(cellModalType);
  }, [setActiveCellMember, setCellModalType]);

  const columns = useFlexMembersTableColumns({
    worklets,
    counts,
    cellActionsByColumn,
    columnsToDisplay: visibleColumnIds,
    task,
  });

  const dynamicColumns = useFlexTableSelectedColumns({
    worklets,
    selectedColumns,
    counts,
    cellActionsByColumn,
    projectId: project?.id,
    onTagRemoved,
    onGroupRemoved,
    onProjectRemoved,
    onCellModalOpen,
  });

  // When navigating to another page, clear search
  useEffect(() => {
    setSearchText('');
  }, [pageId, workletSpecUri]);

  const handleSearchChange = (event) => {
    const updatedSearchText = event.target.value;
    debounce((text: string) => {
      setSearchText(text);
      setPage(1);
    }, 300)(updatedSearchText);
  };

  // Refetch when searchQuery updates
  useEffect(
    () => {
      setTableData([]);
      setTotal(0);
      setPage(1);
      fetchMembers(searchQuery);
    },
    [
      setTableData,
      setTotal,
      setPage,
      fetchMembers,
      searchQuery,
    ],
  );

  const filterData = useCallback((unfilterData: MemberTableRow[], selectedFilter) => {
    switch (selectedFilter) {
      case OWNED_BY_ME_FILTER_VALUE:
        return filter(
          unfilterData,
          (member: MemberTableRow) => (
            some(member.owners, (owner: IOwners) => toLower(owner.email) === toLower(user.email))
          ),
        );
      case LAST_MENTION_DATE_7DAYS_FILTER_VALUE:
        return filter(
          unfilterData,
          (member: MemberTableRow) => (!isEmpty(member[lastMentionDateKey])
            ? moment().diff(moment(member[lastMentionDateKey]), 'days') < 7
            : false),
        );
      default:
        return unfilterData;
    }
  }, [user, lastMentionDateKey]);

  /**
   * Merge member data with provided data by id
   */
  const dataById = useMemo(
    () => keyBy(data, (d) => d.id),
    [data],
  );

  useEffect(
    () => {
      const tableData = map(currentMembers, (member) => {
        const rowData = ({
          ...member,
          ...get(member, 'fields', {}),
          ...get(dataById, toString(member.id), {}),
        });

        return {
          ...rowData,
          id: toString(member.id),
          key: toString(member.id),
          member,
          _raw: rowData,
        };
      });
      const filteredData = filterData(tableData, selectedQuickFilter);
      setDisplayedTotalRowCount(filteredData.length);
      setTotal(filteredData.length);
      setTableData(filteredData);
    },
    [
      dataById,
      currentMembers,
      page,
      selectedQuickFilter,
      filterData,
      setDisplayedTotalRowCount,
    ],
  );

  const updatedColumns: ColumnDef<FlexMemberTableRow, unknown>[] = useMemo(() => (
    isPFAColumnsEnabled ? dynamicColumns : columns
  ), [
    isPFAColumnsEnabled,
    dynamicColumns,
    columns,
  ]);

  console.log({
    updatedColumns,
    columns,
    dynamicColumns,
    isPFAColumnsEnabled,
    savedColumns,
    defaultSavedColumns,
  });

  const displayTableData = useMemo(() => {
    const start = TABLE_PAGE_SIZE * (page - 1);
    const end = TABLE_PAGE_SIZE * page;
    const lowerSearchText = toLower(searchText);
    const columnKeys = map(updatedColumns, (column) => column.id);

    const filtered = tableData.filter((row: MemberTableRow) => {
      if (searchText) {
        return !!columnKeys.find((key) => {
          if (isString(row[key]) || isNumber(row[key])) {
            const value = toLower(toString(row[key]));
            return includes(value, lowerSearchText);
          }

          if (key === 'member') {
            const value = toLower(row[key].name);
            if (includes(value, lowerSearchText)) return true;
          }

          if (key === ColumnKey.TalentAgents) {
            const searchProps = ['agentName', 'agentEmail'];
            const match = some(
              searchProps,
              (prop) => some(
                row[key],
                (agent) => includes(toLower(agent[prop]), lowerSearchText),
              ),
            );
            if (match) return true;
          }

          return false;
        });
      }

      return true;
    });

    const paginatedData = filtered.slice(start, end);
    setTotal(filtered.length);
    setDisplayedTotalRowCount(paginatedData.length);
    return filtered; // let antd handle the pagination
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableData,
    page,
    searchText,
  ]);

  const flexTableData = useMemo(() => {
    const start = TABLE_PAGE_SIZE * (page - 1);
    const end = TABLE_PAGE_SIZE * page;
    const lowerSearchText = toLower(searchText);
    const columnKeys = map(updatedColumns, (column) => column.id);

    const filtered = tableData.filter((row: MemberTableRow) => {
      if (searchText) {
        return !!columnKeys.find((key) => {
          if (isString(row[key]) || isNumber(row[key])) {
            const value = toLower(toString(row[key]));
            return includes(value, lowerSearchText);
          }

          if (key === 'member') {
            const value = toLower(row[key].name);
            if (includes(value, lowerSearchText)) return true;
          }

          if (key === ColumnKey.TalentAgents) {
            const searchProps = ['agentName', 'agentEmail'];
            const match = some(
              searchProps,
              (prop) => some(
                row[key],
                (agent) => includes(toLower(agent[prop]), lowerSearchText),
              ),
            );
            if (match) return true;
          }

          return false;
        });
      }

      return true;
    });

    const paginatedData = filtered.slice(start, end);
    setTotal(filtered.length);
    setDisplayedTotalRowCount(paginatedData.length);
    return filtered; // let antd handle the pagination
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableData,
    page,
    searchText,
  ]);

  const isLoadingData = useMemo(() => (
    isLoading || (isEmpty(displayTableData) && isEmpty(searchText) && isEmpty(selectedQuickFilter) && !isEmpty(currentMembers))
  ), [isLoading, currentMembers, displayTableData, selectedQuickFilter, searchText]);

  useEffect(() => {
    if (isLoadingData || isEmpty(displayTableData) || !containerHeight) return;

    const tableBodySelector = 'ant-table-body';
    const tableBodyContainer = document.getElementsByClassName(tableBodySelector)[0] as HTMLElement;
    if (tableBodyContainer) {
      tableBodyContainer.removeAttribute('style');
      tableBodyContainer.style.overflow = 'auto';
      tableBodyContainer.style.height = `${containerHeight - TABLE_HEADER_HEIGHT}px`;
    }
  }, [
    containerHeight,
    displayTableData,
    isLoadingData,
  ]);

  useEffect(() => {
    const refetch = async () => {
      if (columnsUpdated) {
        await onRefetchSegments(project?.id);
        setColumnsUpdated(false);
      }
    };
    refetch();
  }, [
    columnsUpdated,
    project?.id,
    onRefetchSegments,
    setColumnsUpdated,
  ]);

  return (
    <div className={cx(className)}>
      {(isLoadingData || xScroll === 0) && <LoadSpinner className={styles.loadingOverlaySpinner} />}
      <div className={styles.MembersTable}>
        <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
          <Row
            className={styles.tableHeader}
            justify="space-between"
          >
            <Col>{renderHeaderActions()}</Col>
            <Col>
              <Space>
                {isSearchOpen ? (
                  <div className="relative">
                    <Input
                      placeholder="Search..."
                      onChange={handleSearchChange}
                      style={{ width: 200 }}
                      autoFocus
                      className="pr-6"
                    />
                    <span className="absolute top-1/2 -translate-y-1/2 right-2">
                      <MagnifyingGlassIcon />
                    </span>
                  </div>
                ) : (
                  <Button size="headerIcon" variant="ghost" onClick={() => setIsSearchOpen(true)}>
                    <MagnifyingGlassIcon />
                  </Button>
                )}
                {isPFAColumnsEnabled && (
                <EditProjectColumns
                  fields={filteredFields}
                  fieldById={fieldById}
                  appsWithFields={appsWithFields}
                  predefinedSegmentId={predefinedSegmentId}
                  initialSelectedColumns={selectedColumns}
                  defaultColumns={defaultColumns}
                  onColumnsUpdated={setColumnsUpdated}
                  isLoadingSegments={isLoadingPredefinedProgramSegments}
                  isFlexExpandableColumns
                />
                    )}
                <Pagination
                  total={total}
                  page={page}
                  setPage={setPage}
                  pageSize={TABLE_PAGE_SIZE}
                  showTotal
                />
              </Space>
            </Col>
          </Row>
          {isEmpty(displayTableData)
                ? (renderEmptyPlaceholder(searchText))
                : (
                  <div className={styles.membersTableContainer}>

                    <DataTable<FlexMemberTableRow, unknown>
                      columns={updatedColumns}
                      data={flexTableData}
                      bordered
                      selectable
                      onSelectionChange={(selectedRows) => console.log(selectedRows)}
                    />
                    <ModifyTagMembersModal
                      show={cellModalType === 'tags'}
                      onRequestClose={() => {
                        setCellModalType(null);
                        setActiveCellMember(null);
                      }}
                      type="add"
                      memberIds={[Number(activeCellMember?.id)]}
                      memberCount={1}
                      showNewTagButton
                      closeModalOnSave
                      onModifyComplete={onTagsAdded}
                    />
                    <ModifyProgramMembersModal
                      show={cellModalType === 'projects'}
                      onRequestClose={() => {
                        setCellModalType(null);
                        setActiveCellMember(null);
                      }}
                      type="add"
                      memberIds={[Number(activeCellMember?.id)]}
                      memberCount={1}
                      onModifyComplete={onProjectAdded}
                    />
                    <ModifyCommunityMembersModal
                      show={cellModalType === 'groups'}
                      onRequestClose={() => {
                        setCellModalType(null);
                        setActiveCellMember(null);
                      }}
                      type="add"
                      memberIds={[Number(activeCellMember?.id)]}
                      memberCount={1}
                      onModifyComplete={onGroupAdded}
                    />
                  </div>
                )}
        </Space>
      </div>
    </div>
  );
});
