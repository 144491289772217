import * as React from 'react';
import { merge, identity } from 'lodash';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';

import { useAuth } from '@frontend/context/authContext';

import ContentPageInternal from './ContentPage';
import { configureStore, CPThunkDispatch } from './redux/store';
import { IStore } from './redux/models';
import {
  changeFilters,
  fetchCampaigns,
  fetchContent,
  selectContent,
  toggleLikedContent,
  createContent,
  toggleSelectAllContent,
  saveTags,
  savePermissions,
  removeTag,
  deleteContent,
  downloadContent,
  changeSort,
  fetchNextContentPage,
  searchSimilarContent,
  visitProfilePage,
  visitManagePage,
  visitAdminPage,
  viewContent,
} from './redux/actions';

export interface IContentPageProps extends Partial<IStore> {
  className?: string;
}

const { useMemo, useEffect } = React;

const mapStateToProps = identity;

const mapDispatchToProps = {
  onChangeFilters: changeFilters,
  onSelectedContentChange: selectContent,
  onToggleLikedContent: toggleLikedContent,
  onConfirmContentUpload: createContent,
  onToggleSelectAllContent: toggleSelectAllContent,
  onSaveTags: saveTags,
  onRemoveTag: removeTag,
  onSavePermissions: savePermissions,
  onDeleteContent: deleteContent,
  onDownloadContent: downloadContent,
  onChangeSort: changeSort,
  onLoadNextPage: fetchNextContentPage,
  onSearchClick: searchSimilarContent,
  onVisitProfileClick: visitProfilePage,
  onVisitManageClick: visitManagePage,
  onVisitAdminClick: visitAdminPage,
  onToggleDetailView: viewContent,
};

const ConnectedContentPage = connect<IStore, any, any>(mapStateToProps, mapDispatchToProps)(ContentPageInternal);

const initialState: IStore = {
  $state: null,
  apiEndpoint: null,
  activeBrand: null,
  organization: null,
  clientId: null,
  campaigns: [],
  filters: {
    query: '',
    selectedCampaignId: null,
    selectedMediaType: null,
    selectedNetworkId: null,
    selectedPermissionLevel: null,
    selectedTags: [],
    sku: '',
    memberId: null,
    activationId: null,
    programId: null,
    communityId: null,
    contentListIds: [],
    syncStatus: null,
    useLegacySearch: false,
  },
  content: [],
  sort: 'date',
  isLoading: true,
  hasNext: false,
  cursor: null,
  selectedContent: {},
  isAllSelected: false,
  likedContent: {},
  contentUploadPostEndpoint: null,
  contentUploadFolder: null,
  isQA: false,
  hideFilters: false,
  showVisitManageLink: false,
  showContentDetail: false,
  contentDetailIndex: null,
  additionalFilterOptions: null,
};

export const ContentPage: React.FunctionComponent<IContentPageProps> = (props) => {
  const { className, ...rest } = props;

  const { token } = useAuth();
  const state = { ...initialState, auth: { token } };
  const store = useMemo(() => configureStore(merge({}, state, rest)), []);

  const fetchAIQData = !!props.organization;

  useEffect(() => {
    const dispatch: CPThunkDispatch = store.dispatch;

    // Only fetch campaigns if we have organization data and this is being used in AIQ (vs AX) context
    if (fetchAIQData) {
      dispatch(fetchCampaigns());
    }
    dispatch(fetchContent());
  }, [store.dispatch, fetchAIQData]);

  return (
    <Provider store={store}>
      <ConnectedContentPage className={className} />
    </Provider>
  );
};
