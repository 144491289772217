import React from 'react';
import { useFragment, graphql, FragmentType } from '@frontend/gql/social';
import { PercentChangeBadge } from '../../percent-change-badge';
import { formatNumber } from '../../../utils/formatNumber';
import { CardStat, CardStatLabel, CardStatRow } from '../../card-stat';

const currentIntervalFragment = graphql(`
  fragment Posts_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalPosts
  }
`);

const previousIntervalFragment = graphql(`
  fragment Posts_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalPosts
  }
`);

type PostsProps = {
  currentInterval: FragmentType<typeof currentIntervalFragment> | null | undefined;
  previousInterval: FragmentType<typeof previousIntervalFragment> | null | undefined;
};

export const Posts = ({ currentInterval, previousInterval }: PostsProps) => {
  const currentIntervalData = useFragment(currentIntervalFragment, currentInterval);
  const previousIntervalData = useFragment(previousIntervalFragment, previousInterval);

  const currentPosts = currentIntervalData?.totalPosts ?? 0;
  const previousPosts = previousIntervalData?.totalPosts ?? 0;
  const percentChange = previousPosts ? ((currentPosts - previousPosts) / previousPosts) * 100 : 'NA';

  return (
    <div className="col-span-1 items-center">
      <CardStatLabel>Posts</CardStatLabel>
      <CardStatRow>
        <CardStat className="text-xl leading-none">{formatNumber(currentPosts)}</CardStat>
        <PercentChangeBadge percent={percentChange} />
      </CardStatRow>
    </div>
  );
};
