import gql from 'graphql-tag';
import { PROGRAM_FRAGMENT } from './fragments/ProgramFragment';

export const GET_PROGRAM_BY_ID = gql`
  query ProgramByIdQuery($id: Int!) {
    program: programById(id: $id) {
      ...ProgramFragment
      specKey
      isFlexibleSpec
      workletSpecKeys
    }
  }
  ${PROGRAM_FRAGMENT}
`;
