import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

export interface ProductDetailsDrawerContextProps {
  isOpen: boolean;
  selectedTags: string[];
  newTags: string[];
  selectedProductIds: number[];
  tagMode: 'assign' | 'unassign' | 'both';
  setIsOpen: (isOpen: boolean, tagsMode: ProductDetailsDrawerContextProps['tagMode']) => void;
  setSelectedTags: (tags: string[]) => void;
  setNewTags: (tags: string[]) => void;
  setSelectedProductIds: (productIds: number[]) => void;
  reset: () => void;
}
export type ProductDetailsDrawerContextState = Omit<
ProductDetailsDrawerContextProps,
  'setIsOpen' | 'setSelectedTags' | 'setNewTags' | 'reset' | 'setSelectedProductIds'
>;

const initialState: ProductDetailsDrawerContextState = {
  isOpen: false,
  selectedTags: [],
  newTags: [],
  selectedProductIds: [],
  tagMode: 'both',
};

export const ProductDetailsDrawerContext = createContext<ProductDetailsDrawerContextProps>({
  ...initialState,
  setIsOpen: () => {},
  setSelectedTags: () => {},
  setNewTags: () => {},
  setSelectedProductIds: () => {},
  reset: () => {},
});

export enum ActionType {
  SetIsOpen = 'SET_IS_OPEN',
  SetSelectedTags = 'SET_SELECTED_TAGS',
  SetNewTags = 'SET_NEW_TAGS',
  SetSelectedProductIds = 'SET_SELECTED_PRODUCT_IDS',
  Reset = 'RESET',
}

export type Action = {
  type: ActionType.SetIsOpen;
  payload: { isOpen: boolean, tagsMode: ProductDetailsDrawerContextProps['tagMode'] };
} | {
  type: ActionType.SetSelectedTags;
  payload: string[];
} | {
  type: ActionType.SetNewTags;
  payload: string[];
} | {
  type: ActionType.Reset;
} | {
  type: ActionType.SetSelectedProductIds;
  payload: number[];
};

export const ProductDetailsDrawerProvider: React.FC = ({ children }) => {
  const reducer = (state: ProductDetailsDrawerContextState, action: Action): ProductDetailsDrawerContextState => {
    switch (action.type) {
      case ActionType.SetIsOpen:
        return {
          ...state,
          isOpen: action.payload.isOpen,
          tagMode: action.payload.tagsMode,
        };
      case ActionType.SetSelectedTags:
        return {
          ...state,
          selectedTags: action.payload,
        };
      case ActionType.SetNewTags:
        return {
          ...state,
          newTags: action.payload,
        };
      case ActionType.SetSelectedProductIds:
        return {
          ...state,
          selectedProductIds: action.payload,
        };
      case ActionType.Reset:
        return initialState;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(() => ({
    setIsOpen: (isOpen: boolean, tagsMode: ProductDetailsDrawerContextState['tagMode']) => {
      dispatch({ type: ActionType.SetIsOpen, payload: { isOpen, tagsMode } });
    },
    setSelectedTags: (tags: string[]) => {
      dispatch({ type: ActionType.SetSelectedTags, payload: tags });
    },
    setNewTags: (tags: string[]) => {
      dispatch({ type: ActionType.SetNewTags, payload: tags });
    },
    setSelectedProductIds: (productIds: number[]) => {
      dispatch({ type: ActionType.SetSelectedProductIds, payload: productIds });
    },
    reset: () => {
      dispatch({ type: ActionType.Reset });
    },
  }), []);

  const memoizedValue = useMemo<ProductDetailsDrawerContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <ProductDetailsDrawerContext.Provider value={memoizedValue}>{children}</ProductDetailsDrawerContext.Provider>;
};

export const useProductDetailsDrawerContext = () => useContext(ProductDetailsDrawerContext);
