import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_FLEX_COMPLETED_MEMBER_IDS } from '@frontend/app/queries';
import { GetFlexCompletedMemberIdsQuery, GetFlexCompletedMemberIdsQueryVariables } from '../queries/types/GetFlexCompletedMemberIdsQuery';

type IOptions = QueryHookOptions<GetFlexCompletedMemberIdsQuery, GetFlexCompletedMemberIdsQueryVariables>;

export const useGetFlexCompletedMemberIdsQuery = (options: IOptions = {}) => (
  useQuery<GetFlexCompletedMemberIdsQuery, GetFlexCompletedMemberIdsQueryVariables>(GET_FLEX_COMPLETED_MEMBER_IDS, options)
);
