import { ClientFeature } from '@frontend/app/constants';
import { ClientFeatureResponse, useProcessedFeatureFlags } from '@frontend/app/hooks/useAllClientFeatures';
import React, { createContext, useContext, ReactNode } from 'react';

interface IClientFeatureContext {
  [key: string]: boolean | undefined;
}

// Generate default context dynamically from ClientFeature enum
const defaultContext: IClientFeatureContext = Object.values(ClientFeature).reduce(
  (acc, feature) => ({
    ...acc,
    [feature]: false,
  }),
  {},
);

const ClientFeatureContext = createContext<IClientFeatureContext>(defaultContext);

interface IProps {
  children: ReactNode;
  clientFeatures: ClientFeatureResponse[];
}

export const ClientFeatureProvider: React.FC<IProps> = ({ children, clientFeatures }) => {
  const featureFlags = useProcessedFeatureFlags(clientFeatures);

  return (
    <ClientFeatureContext.Provider
      value={featureFlags}
    >
      {children}
    </ClientFeatureContext.Provider>
  );
};

export const useClientFeature = () => useContext(ClientFeatureContext);
