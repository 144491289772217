import * as React from 'react';
import { Provider } from 'react-redux';
import { Spinner } from '@revfluence/fresh';

import { logger } from '@common';
import { TProgram, InviteContextProvider } from '@components';
import { INetworkSearchVersion } from '@frontend/applications/SocialDiscoveryApp/pages/InfluencerSearchPage';
import { useAuth } from '@frontend/context/authContext';
import { ConnectedSearchPage } from './connected/ConnectedSearchPage';
import { IExternalVariables, IImageSearchUpload, ISearchResultsProps, CreatorSearchVersion } from './models';
import { configureStore } from './redux/store';
import { fetchFeaturedCreators } from './redux/searchResultsActions';
import { getInitialImageSearchFilters, getInitialTextSearchFilters } from './utils/initialFilters';

const { useMemo, useEffect } = React;

type TEmailType = 'gmail' | 'outlook';

export interface IConnectSearchPageProps extends ISearchResultsProps, IExternalVariables {
  className?: string;
  networkTypes: string[];
  acceptedPlaceIds: any[];
  acceptedCountryCodes: string[];
  acceptedLanguageCodes: string[];
  useImageSearchUpload?: IImageSearchUpload;
  defaultImageSearchUrl?: string;
  onRequestAddEmail?(type: TEmailType);
  isWorkflowEnabled?: boolean;
  initialSelectedProjectId?: TProgram['id'];
  resultsClass?: string;
  versionByNetwork?: INetworkSearchVersion;
  version?: CreatorSearchVersion;
}

export const ConnectSearchPage: React.FunctionComponent<IConnectSearchPageProps> = (props) => {
  const {
    $state,
    sendBulkOffer,
    exportCsv,
    reportAsIncorrect,
    className,
    onCreatorSelected,
    selectedSocialAccountId,
    networkTypes,
    acceptedPlaceIds,
    acceptedCountryCodes,
    acceptedLanguageCodes,
    useImageSearchUpload,
    initialSelectedProjectId,
    version = CreatorSearchVersion.v1,
    versionByNetwork,
    ...external
  } = props;

  const { token } = useAuth();

  const store = useMemo(() => {
    const { campaign } = external;

    if (!external.clientId) {
      logger.error('[Creator Search] Missing clientId, by-passing the store creation.');
      return;
    }

    let options;
    if (campaign) {
      options = {
        networkTypes: campaign.network_types,
        placesIds: campaign.accepted_place_ids,
        acceptedCountryCodes: campaign.accepted_country_codes,
        acceptedLanguageCodes: campaign.accepted_language_codes,
      };
    } else {
      options = {
        networkTypes,
        placesIds: acceptedPlaceIds,
        acceptedCountryCodes,
        acceptedLanguageCodes,
        version,
      };
    }
    const initialTextSearchFilters = getInitialTextSearchFilters(options);
    const initialImageSearchFilters = getInitialImageSearchFilters(options.acceptedCountryCodes);
    initialImageSearchFilters.useImageSearchUpload = useImageSearchUpload;
    return configureStore({
      auth: { token },
      external: {
        ...external,
        version,
        versionByNetwork,
      },
      textSearch: initialTextSearchFilters,
      imageSearch: initialImageSearchFilters,
    });
  }, [
    external,
    acceptedCountryCodes,
    networkTypes,
    acceptedPlaceIds,
    acceptedLanguageCodes,
    useImageSearchUpload,
    version,
    versionByNetwork,
    token,
  ]);

  useEffect(() => {
    if (store) {
      store.dispatch(fetchFeaturedCreators(version));
    }
  }, [store, version]);

  if (!external.clientId) {
    return <Spinner />
  }

  return (
    <Provider store={store}>
      <InviteContextProvider
        apiEndpoint={props.apiEndpoint}
        userId={props.userId}
        clientId={props.clientId}
        isWorkflowEnabled={props.isWorkflowEnabled}
        onRequestAddEmail={props.onRequestAddEmail}
        initialSelectedProjectId={initialSelectedProjectId}
        version={version}
        versionByNetwork={versionByNetwork}
      >
        <ConnectedSearchPage
          className={className}
          resultsClass={props.resultsClass}
          $state={$state}
          sendBulkOffer={sendBulkOffer}
          exportCsv={exportCsv}
          reportAsIncorrect={reportAsIncorrect}
          onCreatorSelected={onCreatorSelected}
          selectedSocialAccountId={selectedSocialAccountId}
        />
      </InviteContextProvider>
    </Provider>
  );
};
