import React from 'react';
import { FragmentType, graphql, useFragment } from '@frontend/gql/social';
import { CardStat, CardStatLabel, CardStatRow } from '../../card-stat';
import { PercentChangeBadge } from '../../percent-change-badge';
import { formatNumber } from '../../../utils/formatNumber';

const currentIntervalFragment = graphql(`
  fragment Clicks_CurrentIntervalMetaSumInsights on MetaSumInsights {
    totalClicks
  }
`);

const previousIntervalFragment = graphql(`
  fragment Clicks_PreviousIntervalMetaSumInsights on MetaSumInsights {
    totalClicks
  }
`);

type ClicksProps = {
  currentInterval: FragmentType<typeof currentIntervalFragment> | null | undefined;
  previousInterval: FragmentType<typeof previousIntervalFragment> | null | undefined;
};

export const Clicks = ({ currentInterval, previousInterval }: ClicksProps) => {
  const currentIntervalData = useFragment(currentIntervalFragment, currentInterval);
  const previousIntervalData = useFragment(previousIntervalFragment, previousInterval);

  const currentIntervalClicks = currentIntervalData?.totalClicks;
  const previousIntervalClicks = previousIntervalData?.totalClicks;

  const percentChange = previousIntervalClicks && currentIntervalClicks
      ? ((currentIntervalClicks - previousIntervalClicks) / previousIntervalClicks) * 100
      : 'NA';

  return (
    <div className="col-span-1 items-center">
      <CardStatLabel>Clicks</CardStatLabel>
      <CardStatRow>
        <CardStat className="text-xl leading-none">{formatNumber(currentIntervalClicks)}</CardStat>
        <PercentChangeBadge percent={percentChange} />
      </CardStatRow>
    </div>
  );
};
