import React from 'react';
import { useUpdateMemberMutation } from '@frontend/app/hooks';
import { logger } from '@common';
import { find, isEqual, lowerCase } from 'lodash';
import { Button, message } from '@revfluence/fresh';
import { BadgeCheckIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { CircleCheckIcon } from '@revfluence/fresh-icons/solid/esm';
import styles from './StatusAction.scss';

const StatusAction = ({
  member, fields, refetchMemberQuery, setBrandApprovalError, isContentApprover,
}) => {
  const [memberMutation] = useUpdateMemberMutation({
    onError(error) {
      message.error('Status not updated');
      logger.error(`Error logging ${error}, error`);
    },
  });
  const brandApprovalField = find(fields, (field) => lowerCase(field.name) === 'brand approval');
  const updateMemberStatus = async (value: string) => {
    try {
      if (!brandApprovalField) {
        setBrandApprovalError(true);
        throw new Error('Brand Approval field not configured');
      }
      await memberMutation({
        variables: {
          id: member.id,
          member: {
            fields: {
              [brandApprovalField.id]: value,
            },
          },
        },
        onCompleted() {
          switch (value) {
            case 'Maybe':
              message.warning('Applicant moved to maybe');
              break;
            case 'Approved':
              message.success('Applicant approved');
              break;
            case 'Rejected':
              message.error('Applicant rejected');
              break;
          }
        },
      });
    } catch (error) {
      logger.error(`Error logging in updating member field ${error}, error`);
    }
    refetchMemberQuery();
  };

  return (
    <div className={styles.statusButtons}>
      <Button className={styles.reject} onClick={() => updateMemberStatus('Rejected')} icon={<XmarkIcon />} disabled={!isContentApprover}>
        Reject
      </Button>
      {!isEqual(member?.metaFields.brand_approval, 'Maybe') && (
        <Button className={styles.maybe} onClick={() => updateMemberStatus('Maybe')} icon={<BadgeCheckIcon />} disabled={!isContentApprover}>
          {' '}
          Maybe
        </Button>
      )}
      <Button className={styles.accept} onClick={() => updateMemberStatus('Approved')} icon={<CircleCheckIcon />} disabled={!isContentApprover}>
        Approve
      </Button>
    </div>
  );
};

export default StatusAction;
