import { useMemo } from 'react';
import { ClientFeature } from '../constants';

export interface ClientFeatureResponse {
  id: number;
  name: string;
  state: string;
  __typename?: string;
}

export const useProcessedFeatureFlags = (clientFeatures: ClientFeatureResponse[] | undefined) => useMemo(() => {
      const flags: Record<string, boolean> = {};

      // Initialize all known ClientFeature keys to false
      Object.values(ClientFeature).forEach((featureName) => {
        flags[featureName] = false;
      });

      // Set true for any feature that appears in API response
      clientFeatures?.forEach((feature) => {
        if (feature.state === 'disabled') {
          flags[feature.name] = false;
        } else {
          flags[feature.name] = true;
        }
      });

      return flags;
    }, [clientFeatures]);
