import * as React from 'react';
import {
  first, isEmpty, isNull, sortBy,
} from 'lodash';
import moment from 'moment';
import { CustomUTMField, IAffiliateLinksFormValues, IPayoutVariant } from '@affiliates/components';
import { GetOfferById } from '@affiliates/queries/types/GetOfferById';
import { OFFER_SOURCE, OFFER_STATUS } from '@affiliates/types/globalTypes';
import { OfferFormContainer } from './OfferFormContainer';
import { IBaseProps, IChildArgs } from './types';

interface IProps extends IBaseProps<IAffiliateLinksFormValues> {
  offer: GetOfferById['offer'];
  allowedDomains: string[];
}
export const EditAffiliateOfferContainer: React.FC<Readonly<IProps>> = (props) => {
  const { offer, ...remainingProps } = props;
  const link = first(offer.links);
  const customUTMParameters: CustomUTMField[] = [];

  if (!isNull(link.utmFields)) {
    Object.entries(link.utmFields).map(([key, value]) => {
      if (
        key != 'utm_source'
        && key != 'utm_medium'
        && key != 'utm_campaign'
        && key != 'utm_term'
        && key != 'utm_content'
      ) {
        customUTMParameters.push({ key, value: value as string });
        return;
      }
      return null;
    });
  }
  const isAdvanceUrlEnable: boolean = !(isEmpty(link.utmFields?.utm_source) && isEmpty(link.utmFields?.utm_medium) && isEmpty(link.utmFields?.utm_campaign) && isEmpty(link.utmFields?.utm_content) && isEmpty(link.utmFields?.utm_term) && isEmpty(customUTMParameters));
  let payoutOptions: IPayoutVariant[] = [];
  if (link?.defaultPayoutId) {
    offer.payouts.forEach((payoutOption) => {
      if (payoutOption.id == link.defaultPayoutId) {
        payoutOptions.push({ ...payoutOption, isDefault: true });
      } else {
        payoutOptions.push({ ...payoutOption, isDefault: false });
      }
    });
  }
  payoutOptions = sortBy(payoutOptions, (obj) => moment(obj.createdDate));
  const initialValues: IChildArgs<IAffiliateLinksFormValues>['initialValues'] = {
    conversionTrackingType: link?.conversionTrackingType,
    conversionType: link.conversionType,
    description: offer.description,
    expirationDate: moment(offer.expirationDate),
    flatPayout: isNull(link.flatPayout) ? null : link.flatPayout.toString(),
    imageUrl: offer.imageUrl,
    name: offer.name,
    payoutOptions,
    payoutType: link.payoutType,
    percentPayout: isNull(link.percentPayout) ? null : link.percentPayout.toString(),
    source: OFFER_SOURCE.TUNE,
    status: link.status,
    url: link.url,
    isAdvanceUrlEnable,
    utmSource: link.utmFields?.utm_source || '',
    utmMedium: link.utmFields?.utm_medium || '',
    utmCampaign: link.utmFields?.utm_campaign || '',
    utmContent: link.utmFields?.utm_content || '',
    utmTerm: link.utmFields?.utm_term || '',
    customUTMParameters,
    multipleDomain: link.domains || [],
    creatorDeeplink: link.creatorDeeplink,
    allowMultipleConversions: link?.allowMultipleConversions,
    thirdPartyTracking: link?.thirdPartyTracking,
    isThirdPartyIntegration: link?.isThirdPartyIntegration,
  };

  const offerDetails: IChildArgs<IAffiliateLinksFormValues>['offerDetails'] = {
    hasMembers: link.affiliateOfferLinkGenerated,
    id: Number(offer.id),
    imageUrl: offer.imageUrl,
    isDeleted: initialValues.status === OFFER_STATUS.DELETED,
    isExpired: moment(initialValues.expirationDate) < moment(),
    linkedId: link.id,
    canEditConversionType: link.canEditConversionType,
    isOfferArchived: !isNull(offer.archivedDate),

  };

  return (
    <OfferFormContainer
      initialValues={initialValues}
      offerDetails={offerDetails}
      {...remainingProps}
    />
  );
};
