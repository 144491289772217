/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bghol_157 {
  display: flex;
}

._justify-content-space-between_bghol_161 {
  justify-content: space-between;
}

._tabular-nums_bghol_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bghol_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferSummaryCard_bghol_178 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  color: #8f8d91;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  position: relative;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 {
  display: flex;
  flex: 1 1 auto;
  line-height: 1.125rem;
  min-height: 0;
  padding: 0.5rem;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._offerImageContainer_bghol_194 {
  flex: 0 0 6rem;
  margin-right: 0.5rem;
  height: 6rem;
  position: relative;
  border-radius: inherit;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._offerImageContainer_bghol_194 img {
  width: 6rem;
  height: 6rem;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 0;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._titleWrapper_bghol_212 {
  align-items: center;
  display: flex;
  min-width: 0.0625rem;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._titleWrapper_bghol_212 ._title_bghol_212 {
  color: #1a1818;
  flex: 1 1 auto;
  font-size: 1rem;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._titleWrapper_bghol_212 ._editBtn_bghol_226 {
  flex: 0 0 auto;
  margin-left: 0.5rem;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._titleWrapper_bghol_212 ._editBtn_bghol_226 ._editIcon_bghol_230 {
  color: #3996e0;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._titleWrapper_bghol_212 ._editBtn_bghol_226 ._editIcon_bghol_230 .anticon {
  display: block;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._descriptionWrapper_bghol_236 {
  flex: 0 1 auto;
  min-width: 0.0625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 ._url_bghol_248 {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 ._url_bghol_248 .anticon {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 ._url_bghol_248 span {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 ._instructionsWrapper_bghol_265 {
  flex: 0 0 auto;
}
._OfferSummaryCard_bghol_178 ._body_bghol_187 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 ._instructionsWrapper_bghol_265 ._divider_bghol_268 {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 {
  align-items: center;
  border-top: 0.0625rem solid #dadcde;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 0.75rem;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._payout_bghol_282 {
  flex: 1 1 auto;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._expirationDate_bghol_285 {
  flex: 1 1 auto;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._tag_bghol_288 {
  flex: 0 0 auto;
  padding: 0.125rem 0.375rem;
  border-radius: 6.25rem;
  color: #fdfdfd;
  font-weight: 600;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0.078125rem;
  margin-left: auto;
  text-transform: uppercase;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._tag_bghol_288._active_bghol_300 {
  background-color: #4eb468;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._tag_bghol_288._expired_bghol_303 {
  background-color: #f1515f;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._tag_bghol_288._draft_bghol_306 {
  background-color: #bdbdbd;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._tag_bghol_288._paused_bghol_309 {
  background-color: #eacd60;
}
._OfferSummaryCard_bghol_178 ._footer_bghol_273 ._tag_bghol_288._archived_bghol_312 {
  background-color: #FFFBE6;
  color: #d48806;
}
._OfferSummaryCard_bghol_178._offerFormVariant_bghol_316 {
  position: fixed;
  top: 12.8125rem;
  width: 30.75rem;
}
._OfferSummaryCard_bghol_178._offerFormVariant_bghol_316 ._body_bghol_187 ._offerImageContainer_bghol_194 {
  flex: 0 0 6rem;
}
._OfferSummaryCard_bghol_178._offerFormVariant_bghol_316 ._body_bghol_187 ._bodyText_bghol_205 ._descriptionWrapper_bghol_236 {
  white-space: normal;
}
._OfferSummaryCard_bghol_178._offerFormVariant_bghol_316 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 {
  position: absolute;
  top: 10.25rem;
  left: 0;
  right: 0;
  width: 100%;
}
._OfferSummaryCard_bghol_178._offerFormVariant_bghol_316 ._bodyText_bghol_205 ._additionalDetailsWrapper_bghol_243 ._instructionsWrapper_bghol_265 {
  display: none;
}
._OfferSummaryCard_bghol_178 ._linkIcon_bghol_337 {
  transform: rotate(-45deg);
}

._addndetails_bghol_341 {
  position: fixed;
  top: 23.125rem;
}

._tag_bghol_288 {
  flex: 0 0 auto;
  padding: 0.125rem 0.375rem;
  border-radius: 6.25rem;
  color: #fdfdfd;
  font-weight: 600;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0.078125rem;
  margin-left: auto;
  text-transform: uppercase;
}
._tag_bghol_288._active_bghol_300 {
  background-color: #4eb468;
}
._tag_bghol_288._expired_bghol_303 {
  background-color: #f1515f;
}
._tag_bghol_288._draft_bghol_306 {
  background-color: #bdbdbd;
}
._tag_bghol_288._paused_bghol_309 {
  background-color: #eacd60;
}
._tag_bghol_288._archived_bghol_312 {
  background-color: #FFFBE6;
  color: #d48806;
}
._tag_bghol_288._mPayout_bghol_374 {
  float: right;
  text-transform: none;
  border-radius: 0.3125rem;
  padding: 0.25rem 0.625rem;
}

._multiplePayoutSummaryCard_bghol_381 {
  min-height: 210px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #F0F0F0;
  padding: 16px;
  z-index: 100;
}
._multiplePayoutSummaryCard_bghol_381 ._imageContainer_bghol_389 {
  height: 80px;
  width: 80px;
  display: flex;
  position: relative;
  flex-direction: column;
}
._multiplePayoutSummaryCard_bghol_381 ._offerName_bghol_396 {
  font-size: 14px;
  display: block;
  font-weight: 600;
  color: #8C8C8C;
}
._multiplePayoutSummaryCard_bghol_381 ._offerTag_bghol_402 {
  white-space: nowrap;
}
._multiplePayoutSummaryCard_bghol_381 ._offerExpirationContainer_bghol_405 {
  display: flex;
  align-items: center;
}
._multiplePayoutSummaryCard_bghol_381 ._offerExpirationContainer_bghol_405 ._offerLabel_bghol_409 {
  font-weight: 600;
}
._multiplePayoutSummaryCard_bghol_381 ._offerExpirationContainer_bghol_405 ._offerValue_bghol_412 {
  margin-left: 5px;
  font-size: 13px;
}
._multiplePayoutSummaryCard_bghol_381 ._offerDiscountConatiner_bghol_416 {
  margin: 5px 0 0;
  font-size: 20px;
  display: block;
  font-weight: 600;
}
._multiplePayoutSummaryCard_bghol_381 ._offerDiscount_bghol_416 {
  margin: 0 0 8px 0;
  font-size: 20px;
  display: block;
}
._multiplePayoutSummaryCard_bghol_381 ._offerDescription_bghol_427 {
  font-size: 14px;
  color: #8C8C8C;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 8px;
}
._multiplePayoutSummaryCard_bghol_381 ._payoutsContainer_bghol_434 {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
._multiplePayoutSummaryCard_bghol_381 ._payoutsContainer_bghol_434 ._payoutsLabel_bghol_439 {
  font-weight: 600;
  margin-bottom: 8px;
}
._multiplePayoutSummaryCard_bghol_381 ._payoutsContainer_bghol_434 ._payoutsValueContainer_bghol_443 {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
}
._multiplePayoutSummaryCard_bghol_381 ._payoutsContainer_bghol_434 ._payoutsValueContainer_bghol_443 ._payoutsValue_bghol_443 {
  padding: 4px 8px;
  background-color: #E6F7FF;
  border-radius: 4px;
  font-size: 12px;
}