import React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';

export const CardStatRow = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center flex-wrap', className)} {...props} />
  ),
);
CardStatRow.displayName = 'CardStatRow';

export const CardStat = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-normal font-bold mr-2 leading-none', className)} {...props} />
  ),
);
CardStat.displayName = 'CardStat';

export const CardStatLabel = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-xs text-muted-foreground', className)} {...props} />
  ),
);
CardStatLabel.displayName = 'CardStatLabel';
