/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  message,
} from '@revfluence/fresh';
import { Modal as AntdModal } from 'antd';
import cx from 'classnames';
import {
  isEmpty,
  isFunction,
  map,
  find,
  lowerCase,
  size,
} from 'lodash';
import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import {
  logger,
} from '@common';
import {
  EllipsisIcon,
  EllipsisLabel,
} from '@frontend/app/components';
import {
  TCondition,
  TMember,
  TProject,
  TTask,
  TWorkItem,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import { useSkipWorkItemsMutation } from '@frontend/app/hooks/useSkipWorkItemsMutation';
import { useBulkMemberApplicantOperationMutation } from '@frontend/app/hooks';

import {
  useFeatureFlagVerbiage,
  useModifyProgramMembersMutation,
  useBulkMoveToMutation,
} from '@frontend/app/hooks';

import { useMessagingContext } from '@frontend/hooks';

import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { useCancelBriefsMutation } from '@frontend/app/hooks/useCancelBriefsMutation';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button as ShadCnButton } from '@frontend/shadcn/components/ui/button';
import {
 DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, DropdownMenuSub, DropdownMenuSubTrigger, DropdownMenuSubContent, DropdownMenuSeparator,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { ProjectsPageState, Task } from '../../constants';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';

const { useMemo, useCallback } = React;
const { confirm } = AntdModal;

enum CTAActions {
  Skip = 'Skip',
  Restart = 'Restart',
  ManageOffer = 'ManageOffer',
  CancelBrief = 'CancelBrief',
  CancelOrderRequest = 'CancelOrderRequest',
}
interface IProps {
  disabled?: boolean;
  showBulkCTA?: boolean;
  onCheckIn?: () => void;
  onOpenApplication: (workItem: TWorkItem) => void;
  onSkip: () => void;
  onMoveStage?: () => void;
  pageState?: ProjectsPageState;
  project: TProject;
  refetchData: () => Promise<unknown> | void;
  selectedMemberIds?: TMember['id'][];
  selectedWorkItems?: TWorkItem[];
  selectedWorkItemIds?: TWorkItem['id'][];
  task: TTask;
  worklets?: TWorklet[];
  conditions?: TCondition[];
  openAddToCollectionModal(modalType: ModalType): void;
  showSelectOffer?: (value: boolean) => void;
  migrateToGraphQL?: boolean;
  offers?: GetOfferById_offer[];
  onMemberApplicantOperationSuccess?: (memberIds?: IMember['id'][], operation?: MemberApplicantOperation) => void;
}

const MSG_DURATION = 3000;

export const CTAMenu: React.FC<IProps> = React.memo(({
  onSkip,
  onMoveStage,
  pageState,
  refetchData,
  selectedWorkItems,
  selectedWorkItemIds,
  task = {},
  project,
  worklets,
  conditions,
  selectedMemberIds,
  openAddToCollectionModal,
  showSelectOffer,
  onMemberApplicantOperationSuccess,
  migrateToGraphQL,
}) => {
  const {
    showErrorMessage,
    showSuccessMessage,
    showGenericErrorMessage,
    showMessage,
    showError,
  } = useMessagingContext();
  const [
    bulkMemberApplicantOperation,
  ] = useBulkMemberApplicantOperationMutation();

  const verbiage = useFeatureFlagVerbiage();
  const { clientInfo } = useAuth();
  const hasMembersSelected = useMemo(
    () => !isEmpty(selectedMemberIds),
    [selectedMemberIds],
  );

  const [cancelBriefs] = useCancelBriefsMutation();

  // Add to program
  const [
    addMembersToPrograms,
  ] = useModifyProgramMembersMutation('add', {
    onCompleted: () => {
      showMessage({
        type: 'success',
        content: `Members added to ${verbiage.programs}`,
      });
    },
    onError: (error) => {
      showError(error);
    },
  });

  /**
   * Skip Stage
   */
  const [
    skipWorkItems,
  ] = useSkipWorkItemsMutation();

  const [bulkkMoveTo] = useBulkMoveToMutation();

  const [removeMembersFromPrograms] = useModifyProgramMembersMutation('remove', {
    onCompleted: async () => {
      showMessage({
        type: 'success',
        content: `${selectedWorkItemIds.length} members removed from ${verbiage.programs}`,
      });
      await refetchData();
    },
    onError: () => {
      showGenericErrorMessage();
    },
  });

  const handleSkip = useCallback(async () => {
    try {
      logger.debug('Skipping work items:', selectedWorkItemIds);
      const result = await skipWorkItems({
        variables: {
          workItemIds: selectedWorkItemIds,
        },
      });
      if (!result?.data?.success) {
        throw new Error('Unable to skip work items');
      }
      logger.debug('Successfully skipped work items');
      if (isFunction(onSkip)) {
        await onSkip();
      }
    } catch (error) {
      logger.error(error);
      showErrorMessage('Unable to skip members as they are no longer in this stage. Please try refreshing the page.');
    }
  }, [onSkip, selectedWorkItemIds, showErrorMessage, skipWorkItems]);

  const handleMoveToStage = (worklet: TWorklet) => {
    async function moveToStage(workletArgument: TWorklet) {
      try {
        logger.debug('Moving to stage:', workletArgument.specTitle);
        showMessage({
          type: 'info',
          content: 'Moving members to new stage. This may take up to a few minutes.',
        });
        const condition = find(conditions, { workletSpecKey: workletArgument.specKey });
        const result = await bulkkMoveTo({
          variables: {
            workItemIds: selectedWorkItemIds,
            conditionId: condition.conditionId,
          },
        });

        if (!result?.data?.success) {
          throw new Error(`Unable to move work items to stage: ${workletArgument.specTitle}`);
        }
        logger.debug(`Successfully moved work items to stage: ${workletArgument.specTitle}`);
        await refetchData();
        if (isFunction(onMoveStage)) {
          await onMoveStage();
        }
      } catch (error) {
        logger.error(error);
        showErrorMessage(`Unable to move to stage: ${workletArgument.specTitle}`);
      }
    }

    moveToStage(worklet);
  };

  const cancelOrderRequest = async (worklet: TWorklet) => {
      try {
        logger.debug('Canceling & Moving to stage:', worklet.specTitle);
        showMessage({
          type: 'info',
          content: 'Cancelling the order request and moving members to previous stage. This may take up to a few minutes.',
        });
        const condition = find(conditions, { workletSpecKey: worklet.specKey });
        const result = await bulkkMoveTo({
          variables: {
            workItemIds: selectedWorkItemIds,
            conditionId: condition.conditionId,
            isCancelOrderRequest: true,
          },
        });

        if (!result?.data?.success) {
          throw new Error(`Unable to move work items to stage: ${worklet.specTitle}`);
        }
        logger.debug(`Successfully moved work items to stage: ${worklet.specTitle}`);
        await refetchData();
        if (isFunction(onMoveStage)) {
          await onMoveStage();
        }
      } catch (error) {
        logger.error('Error in cancel order request function', error);
        showErrorMessage(`Unable to move to stage: ${worklet.specTitle}`);
      }
  };

  const handleApproveRejectedApplicants = useCallback(async () => {
    try {
      await bulkMemberApplicantOperation({
        variables: {
          memberIds: selectedMemberIds,
          projectId: project.id,
          operation: MemberApplicantOperation.Approve,
        },
      });
      onMemberApplicantOperationSuccess(selectedMemberIds, MemberApplicantOperation.Approve);
      const approvedCount = size(selectedMemberIds);
      showSuccessMessage(
        `Approved ${approvedCount} rejected applicant${approvedCount > 1 ? 's' : ''}`,
        MSG_DURATION,
      );
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    selectedMemberIds,
    project?.id,
    bulkMemberApplicantOperation,
    showSuccessMessage,
    showErrorMessage,
  ]);

  const handleRestartProgram = useCallback(
    async () => {
      await addMembersToPrograms({
        variables: {
          memberIds: selectedMemberIds,
          programIds: [project.id],
          status: 'approved',
          clientId: clientInfo.id,
        },
      });
    }, [project, selectedMemberIds, addMembersToPrograms],
  );

  const handleCancelProject = useCallback(
    async () => {
      const briefVerbiage = selectedWorkItems.length > 1
        ? `${selectedWorkItems.length} briefs`
        : '1 brief';
      const hideLoadingMessage = message.loading(
        `Canceling ${briefVerbiage}. This may take up to a few minutes`,
        0,
      );

      const projectIds = map(selectedWorkItems, 'data.sending_terms_task_data.post_project_id');
      cancelBriefs({
        variables: {
          axProjectIds: projectIds,
        },
        onCompleted: (data) => {
          hideLoadingMessage();
          if (data.results.successes > 0) {
            showMessage({
              type: 'success',
              content: `${data.results.successes} successfully canceled`,
            });
          }
          if (data.results.failures > 0) {
            showMessage({
              type: 'error',
              content: `Unable to cancel ${data.results.failures} briefs. Please try again or contact support for assistance`,
            });
          }
        },
        onError: () => {
          hideLoadingMessage();
          // should never get to here as the errors should be covered above
          showGenericErrorMessage();
        },
      });
    }, [project, selectedMemberIds, addMembersToPrograms],
  );

  const showCancelOrderRequest = task && (task.taskId === Task.WaitingForOrderRequestTask || task.taskId === Task.PFAV2WaitingForOrderRequestTask);
  /**
   * Menu
   */
  const handleMenuClick = useCallback(({ key }) => {
    if (key === ModalType.RemoveFromProject) {
      confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to remove selected members from project?',
        okText: 'Remove',
        cancelText: 'Cancel',
        onOk: () => {
          removeMembersFromPrograms({
            variables: {
              memberIds: selectedMemberIds,
              programIds: [project.id],
            },
          });
        },
      });
    } else if (key === CTAActions.Skip) {
      const peopleVerbiage = selectedMemberIds.length > 1
        ? `these ${selectedMemberIds.length} members`
        : 'this member';
      confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: (
          `Are you sure you want to skip this stage for ${peopleVerbiage}? `
          + 'Their progress in the current stage may be lost.'
        ),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: handleSkip,
      });
    } else if (key === CTAActions.Restart) {
      const peopleVerbiage = selectedMemberIds.length > 1
        ? `these ${selectedMemberIds.length} members`
        : 'this member';
      confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: (
          `Are you sure you want to restart this project for ${peopleVerbiage}? `
          + 'They will rejoin the program at the first "In Progress" stage'
        ),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: handleRestartProgram,
      });
    } else if (key === CTAActions.ManageOffer) {
      showSelectOffer(true);
    } else if (key === CTAActions.CancelBrief) {
      const briefVerbiage = selectedMemberIds.length > 1
        ? `${selectedMemberIds.length} briefs`
        : 'this brief';
      const peopleVerbiage = selectedMemberIds.length > 1
        ? 'These members'
        : 'This member';
      confirm({
        title: `Are you sure you want to cancel ${briefVerbiage}?`,
        icon: (
          <TriangleExclamationIcon
            className={cx('anticon')}
          />
        ),
        content: (
          <>
            {peopleVerbiage}
            {' '}
            will move back to the
            {' '}
            <strong>Send Briefs</strong>
            {' '}
            status and will no longer
            have access to the brief. If you would still like to collaborate with
            {' '}
            {lowerCase(peopleVerbiage)}
            {' '}
            you will need to send them a new brief.
            {' '}
            <strong>This action cannot be undone.</strong>
          </>
        ),
        okText: 'Confirm',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: handleCancelProject,
      });
    } else if (key === CTAActions.CancelOrderRequest) {
      const worklet = worklets.find((worklet) => worklet.specKey === task.workletSpecKey);
      if (worklet) {
        cancelOrderRequest(worklet);
      }
    } else {
      const worklet = find(worklets, { specKey: key });

      if (worklet) {
        confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: (
            `Are you sure you want to move work items to stage ${worklet.specTitle}? `
            + 'Their progress in the current stage may be lost.'
          ),
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: () => handleMoveToStage(worklet),
        });
      } else {
        openAddToCollectionModal(key);
      }
    }
  }, [
    project?.id,
    selectedMemberIds,
    worklets,
  ]);

  const menuElem = useMemo(
    () => {
      /**
       * All in Progress
       */
      if (pageState === ProjectsPageState.AllInProgress) {
        return (
          <>
            {migrateToGraphQL && (
              <DropdownMenuItem
                disabled={!hasMembersSelected}
                key={CTAActions.ManageOffer}
                onClick={() => handleMenuClick({ key: CTAActions.ManageOffer })}
              >
                Manage Offers
              </DropdownMenuItem>
            )}
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <span>Add people to this project...</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  key={ModalType.AddFromGroup}
                  onClick={() => handleMenuClick({ key: ModalType.AddFromGroup })}
                >
                  Add from your groups
                </DropdownMenuItem>
                {/* <Menu.Item key={ModalType.InviteFromGroup}>Invite from your groups...</Menu.Item> */}
                <DropdownMenuItem
                  key={ModalType.AddFromProject}
                  onClick={() => handleMenuClick({ key: ModalType.AddFromProject })}
                >
                  Add from other projects
                </DropdownMenuItem>
                {/* <Menu.Item key={ModalType.InviteFromProject}>Invite from other projects...</Menu.Item> */}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger disabled={!hasMembersSelected} className={!hasMembersSelected ? 'opacity-50' : ''}>
                <span>Move to stage...</span>
              </DropdownMenuSubTrigger>
              {map(worklets, (worklet) => (
                <DropdownMenuItem key={worklet.specKey} onClick={() => handleMenuClick({ key: worklet.specKey })}>
                  <EllipsisLabel
                    className="stageTitle"
                    tooltipPlacement="right"
                  >
                    {worklet.specTitle}
                  </EllipsisLabel>
                </DropdownMenuItem>
              ))}
            </DropdownMenuSub>
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.RemoveFromProject}
              onClick={() => handleMenuClick({ key: ModalType.RemoveFromProject })}
            >
              Remove from this project
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.AddToGroup}
              onClick={() => handleMenuClick({ key: ModalType.AddToGroup })}
            >
              Add people to group
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.AddToAnotherProject}
              onClick={() => handleMenuClick({ key: ModalType.AddToAnotherProject })}
            >
              Add to another project
            </DropdownMenuItem>
          </>
        );
      }

      if ([
        ProjectsPageState.Invited,
        ProjectsPageState.Applicants,
        ProjectsPageState.Rejected,
      ].includes(pageState)) {
        return (
          <>
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.AddToGroup}
              onClick={() => handleMenuClick({ key: ModalType.AddToGroup })}
            >
              Add people to group
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.AddToAnotherProject}
              onClick={() => handleMenuClick({ key: ModalType.AddToAnotherProject })}
            >
              Add to another project
            </DropdownMenuItem>
            {pageState === ProjectsPageState.Rejected && (
              <DropdownMenuSeparator />
            )}
            {pageState === ProjectsPageState.Rejected && (
              <DropdownMenuItem
                key="approveRejectedApplicants"
                disabled={!hasMembersSelected}
                onClick={handleApproveRejectedApplicants}
              >
                Approve rejected applicants
              </DropdownMenuItem>
            )}
          </>
        );
      }

      /**
       * Tasks
       */
      const showCancelBriefs = task && (task.taskId === 'review_changes_task' || task.taskId === 'waiting_for_agreement_task');
      if (pageState === ProjectsPageState.Task || pageState === ProjectsPageState.Worklet) {
        return (
          <>
            {showCancelBriefs && (
              <>
                <DropdownMenuItem
                  disabled={!hasMembersSelected}
                  key={CTAActions.CancelBrief}
                  onClick={() => handleMenuClick({ key: CTAActions.CancelBrief })}
                >
                  Cancel Briefs
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}
            {migrateToGraphQL && (
              <DropdownMenuItem
                disabled={!hasMembersSelected}
                key={CTAActions.ManageOffer}
                onClick={() => handleMenuClick({ key: CTAActions.ManageOffer })}
              >
                Manage Offers
              </DropdownMenuItem>
            )}

            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={CTAActions.Skip}
              onClick={() => handleMenuClick({ key: CTAActions.Skip })}
            >
              Skip this stage
            </DropdownMenuItem>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger disabled={!hasMembersSelected} className={!hasMembersSelected ? 'opacity-50' : ''}>
                <span>Move to stage...</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {map(worklets, (worklet) => (
                  <DropdownMenuItem key={worklet.specKey} onClick={() => handleMenuClick({ key: worklet.specKey })}>
                    <EllipsisLabel
                      className="stageTitle"
                      tooltipPlacement="right"
                    >
                      {worklet.specTitle}
                    </EllipsisLabel>
                  </DropdownMenuItem>
              ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            {
              showCancelOrderRequest && (
              <DropdownMenuItem
                disabled={!hasMembersSelected}
                key={CTAActions.CancelOrderRequest}
                style={hasMembersSelected ? { color: 'red' } : {}}
                onClick={() => handleMenuClick({ key: CTAActions.CancelOrderRequest })}
              >
                Cancel Order Request
              </DropdownMenuItem>
              )
            }
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.RemoveFromProject}
              onClick={() => handleMenuClick({ key: ModalType.RemoveFromProject })}
            >
              Remove from this project
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.AddToGroup}
              onClick={() => handleMenuClick({ key: ModalType.AddToGroup })}
            >
              Add people to group
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={!hasMembersSelected}
              key={ModalType.AddToAnotherProject}
              onClick={() => handleMenuClick({ key: ModalType.AddToAnotherProject })}
            >
              Add to another project
            </DropdownMenuItem>
          </>
        );
      }

      /**
       * Completed
       * - menu should be disabled when there are no members selected
       */
      if (pageState === ProjectsPageState.Completed) {
        return (
          <>
            <DropdownMenuItem key={CTAActions.Restart} onClick={() => handleMenuClick({ key: CTAActions.Restart })}>
              Restart project
            </DropdownMenuItem>
            <DropdownMenuItem key={ModalType.AddToGroup} onClick={() => handleMenuClick({ key: ModalType.AddToGroup })}>
              Add people to group
            </DropdownMenuItem>
            <DropdownMenuItem key={ModalType.AddToAnotherProject} onClick={() => handleMenuClick({ key: ModalType.AddToAnotherProject })}>
              Add to another project
            </DropdownMenuItem>
          </>
        );
      }

      return null;
    },
    [handleMenuClick, hasMembersSelected, pageState],
  );

  return (
    <>
      {menuElem && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ShadCnButton variant="ghost" size="headerIcon">
              <EllipsisIcon size={16} />
            </ShadCnButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {menuElem}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
});
