import React, { useCallback, useMemo, useState } from 'react';
import {
 Drawer, Input, Button, List,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
 isNull, isString, map, trim,
} from 'lodash';
import { format } from 'date-fns';
import { IHeader } from '../../hooks';
import {
  MemberStatus,
  OfferMemberStatus,
  TMemberTableCSVRow,
  TMemberTableRow,
  TOfferMember,
} from '../MemberTable/types';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { abbreviateAffiliateLink, defaultAffiliateSort } from '../../utils';
import { composePromoCodeStatus } from '../MemberTable/components/composePromoCodeStatus';
import { composeLinkStatus } from '../MemberTable/components/composeLinkStatus';
import { GetOfferById_offer } from '../../queries/types/GetOfferById';
import { GetOfferAffliatesStatsMultipleShopify_affiliatesStats } from '../../queries/types/GetOfferAffliatesStatsMultipleShopify';

interface DeletedMembersDrawerProps {
  isVisible: boolean;
  onClose: () => void;
  deletedMembers: readonly TOfferMember[];
  buttonActions: {
    export: (columns: IHeader[], data: readonly TMemberTableCSVRow[]) => void;
  };
  columns: IHeader[];
  payoutConversionText: (payoutId: string) => string;
  toggleCurrency: boolean;
  offer: GetOfferById_offer;
  missingShopifyCredentials: boolean;
  showStoreLevelAffiliateStats: boolean;
  shopifyStoreName: string;
  affiliatesStats: GetOfferAffliatesStatsMultipleShopify_affiliatesStats[];
  //   onClickExportDeletedMembers: () => void;
}

const DeletedMembersDrawer: React.FC<DeletedMembersDrawerProps> = ({
  isVisible,
  onClose,
  deletedMembers,
  buttonActions,
  columns,
  payoutConversionText,
  toggleCurrency,
  offer,
  missingShopifyCredentials,
  showStoreLevelAffiliateStats,
  shopifyStoreName,
  affiliatesStats,
  //   onClickExportDeletedMembers,
}) => {
  const [searchDeletedMember, setSearchDeletedMember] = useState<string>('');
  const payouts = useMemo(() => offer?.payouts ?? [], [offer]);
  const data = useMemo(() => {
    const sortedMembers = [...deletedMembers].sort(defaultAffiliateSort);
    return map(
      sortedMembers,
      (member: TOfferMember): TMemberTableRow => {
        let linkStatus = member.status;
        if (missingShopifyCredentials) {
          linkStatus = OfferMemberStatus.UNKNOWN;
        }
        const commonFields: TMemberTableRow = {
          _sortableFields: {
            affiliateName: trim(member.name).toLowerCase(),
          },
          key: member.memberId.toLocaleString(),
          affiliateName: {
            name: member.name,
            imageUrl: member.imageUrl,
            memberId: member.memberId,
            disabled: member.memberStatus === MemberStatus.DELETED,
            disabledReason:
              member.memberStatus === MemberStatus.DELETED ? 'This member has been deleted in the Members app' : null,
          },
          payoutId: member.payoutId,
          avgSale: member.avgSale,
          conversions: member.conversions,
          email: member.email,
          endDate: null,
          id: String(member.memberId),
          linkStatus,
          payoutEarned: member.payoutEarned,
          payoutMade: member.payoutMade,
          sales: member.sales,
          startDate: null,
          disableSelection: member.memberStatus === MemberStatus.DELETED,
          disabledReason:
            member.memberStatus === MemberStatus.DELETED ? 'This member has been deleted in the Members app' : null,
          name: member.name,
          affiliateOffer: {
            id: member.affiliateOfferId,
            affiliateId: member.affiliateId,
          },
          createdDate: member.createdDate,
        };
        switch (member.source) {
          case OFFER_SOURCE.SHOPIFY:
            return {
              ...commonFields,
              ...(toggleCurrency
                ? {
                    avgSale: {
                      valueBase: member.avgSaleBase,
                      valueUsd: member.avgSale,
                      currency: member.currencies[0],
                    },
                    sales: {
                      valueBase: member.salesBase,
                      valueUsd: member.sales,
                      currency: member.currencies[0],
                    },
                    payoutEarned: {
                      valueBase: member.payoutEarnedBase,
                      valueUsd: member.payoutEarned,
                      currency: member.currencies[0],
                    },
                  }
                : {}),
              externalCode: member.code,
              selectedAffliate: {
                affiliateOfferId: member.affiliateOfferId,
                memberAffiliateOfferId: member.affiliateOfferId,
                name: member.name,
                source: member.source,
                imageUrl: member.imageUrl,
                isMultipleShopifyOffer: !!offer.promos[0]?.connectedClientMetadata?.length,
                status: composePromoCodeStatus(
                  member.status,
                  member.codeStartDate,
                  member.codeEndDate,
                  offer.promos[0],
                  offer.isNewFlow,
                  offer.archivedDate,
                  member.deletedDate,
                ),
                payout: member?.payoutId ? payouts.find((p) => p.id === member.payoutId) : null,
                code: member.code,
                affliateLevelStats: {
                  conversions: member.conversions,
                  sales: member.sales,
                  salesBase: member.salesBase,
                  avgSale: member.avgSale,
                  avgSaleBase: member.avgSaleBase,
                  payoutEarned: member.payoutEarned,
                  payoutEarnedBase: member.payoutEarnedBase,
                  payoutMade: member.payoutMade,
                  payOutDue: member.payoutEarned - member.payoutMade,
                  clicks: null,
                },
                externalDiscountCodeGid: member.externalDiscountCodeGid,
                providerMetadata: member.providerMetadata,
              },
              affliateDetailedStats:
                (showStoreLevelAffiliateStats
                  && affiliatesStats?.find((a) => a.affiliateId === member.affiliateId)?.storeStats)
                || [],
              endDate: member.codeEndDate,
              startDate: member.codeStartDate,
              linkStatus: composePromoCodeStatus(
                member.status,
                member.codeStartDate,
                member.codeEndDate,
                offer.promos[0],
                offer.isNewFlow,
                offer.archivedDate,
                member.deletedDate,
              ),
              linkCreationDate: null,
              shopifyShowData: {
                shopifyStoreName,
                selectedPromo: member,
              },
            };
          case OFFER_SOURCE.TUNE:
            return {
              ...commonFields,
              affiliateLink: member.affiliateLink,
              affiliateShortLink: member.affiliateShortLink,
              affiliateLinkAbbrev: {
                shortAffiliateLinkAbbrev: member.affiliateShortLink || abbreviateAffiliateLink(member.affiliateLink),
                shortLink: member.affiliateShortLink,
                defaultDeeplink: member.defaultDeeplink,
              },
              clicks: member.clicks,
              linkStatus: composeLinkStatus(offer, member.status, member.deletedDate),
              linkStatusError: member.linkStatusError,
              linkCreationDate: member.linkCreationDate,
              additionalDeepLinks: member.additionalDeepLinks,
              selectedAffliate: {
                affiliateOfferId: member.affiliateOfferId,
                memberAffiliateOfferId: member.memberAffiliateOfferId,
                name: member.name,
                source: member.source,
                imageUrl: member.imageUrl,
                isMultipleShopifyOffer: !!offer.promos[0]?.connectedClientMetadata?.length,
                status: member.status,
                payout: member?.payoutId ? payouts.find((p) => p.id === member.payoutId) : null,
                uniqueLink: member.affiliateShortLink,
                affliateLevelStats: {
                  conversions: member.conversions,
                  sales: member.sales,
                  avgSale: member.avgSale,
                  payoutEarned: member.payoutEarned,
                  payoutMade: member.payoutMade,
                  payOutDue: member.payoutEarned - member.payoutMade,
                  clicks: member.clicks,
                },
              },
            };
        }
      },
    );
  }, [
    deletedMembers,
    affiliatesStats,
    showStoreLevelAffiliateStats,
    missingShopifyCredentials,
    toggleCurrency,
    payouts,
    offer,
    shopifyStoreName,
  ]);

  const deletedMemberList = data.filter((row) => deletedMembers.some((member) => member.affiliateId === row.affiliateOffer.affiliateId));
  const { csvDeletedColumnConfig, csvDeletedData } = useMemo(
    () => ({
      csvDeletedColumnConfig: columns
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((config: any): IHeader | null => {
          if (!isString(config.title)) {
            return null;
          }
          const field = config.key.toString();
          switch (config.key) {
            case 'affiliateLinkAbbrev':
              return null;
            case 'selectedAffliate':
              return null;
          }
          return {
            headerName: config.title,
            field,
          };
        })
        .concat({
          headerName: 'Email',
          field: 'email',
        })
        .concat({
          headerName: 'Primary Link',
          field: 'affiliateLink',
        })
        .concat({
          headerName: 'Shortened Link',
          field: 'affiliateShortLink',
        })
        .filter((c) => !isNull(c))
        .filter((c) => c.headerName !== 'Shopify'),
      csvDeletedData: map(
        deletedMemberList,
        (row): TMemberTableCSVRow => {
          const { shopifyShowData, ...rest } = row;
          return {
            ...rest,
            activeCode: row.externalCode || '',
            affiliateName: row.name,
            email: row.email,
            conversions: row.conversions,
            startDate: format(new Date(row.linkCreationDate), 'MM/dd/yyyy, H:mm'),
            endDate: row.endDate ? format(new Date(row.endDate), 'MM/dd/yyyy, H:mm') : '',
            linkCreationDate: format(new Date(row.linkCreationDate), 'MM/dd/yyyy, H:mm'),
            payoutId: payoutConversionText(String(row.payoutId)),
          };
        },
      ),
    }),
    [columns, payoutConversionText, deletedMemberList],
  );

  const onClickExportDeletedMembers = useCallback(() => {
    buttonActions.export(csvDeletedColumnConfig, csvDeletedData);
  }, [buttonActions, csvDeletedColumnConfig, csvDeletedData]);

  const filteredDeletedMembers = useMemo(() => deletedMembers
      .filter((member) => member.name)
      .filter((member) => member.name.toLowerCase().includes(searchDeletedMember.toLowerCase())), [deletedMembers, searchDeletedMember]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <Drawer title="Removed Members List" placement="right" onClose={onClose} open={isVisible}>
      <Input
        placeholder="Search Removed Members"
        value={searchDeletedMember}
        onChange={(e) => setSearchDeletedMember(e.target.value)}
        style={{ marginBottom: '16px' }}
        prefix={<SearchOutlined />}
      />

      {/* Export Deleted Members Button */}
      <div className="flex justify-between items-center">
        {deletedMembers.length > 0 && (
          <>
            <span className="text-md font-medium">
              {deletedMembers.length}
              {' '}
              Members
            </span>
            <Button
              type="default"
              onClick={onClickExportDeletedMembers}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            >
              Export CSV
            </Button>
          </>
        )}
      </div>

      {/* Deleted Members List */}
      <List
        dataSource={filteredDeletedMembers}
        renderItem={(member) => {
          const name = member.name; // Fallback to affiliate.name if affiliateName doesn't exist
          const randomColor = getRandomColor();
          return (
            <List.Item>
              <div className="flex items-center">
                {/* If image is present, show image, otherwise show initials */}
                {member.imageUrl ? (
                  <img src={member.imageUrl} alt={name} className="w-10 h-10 rounded-full object-cover mr-3" />
                ) : (
                  <div
                    className="w-10 h-10 rounded-full flex items-center justify-center text-white font-bold mr-3"
                    style={{ backgroundColor: randomColor }} // Apply random color
                  >
                    {name
                      .split(' ')
                      .map((word) => word[0].toUpperCase())
                      .join('')}
                  </div>
                )}

                <span className="text-lg font-semibold">{name}</span>
              </div>
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
};

export default DeletedMembersDrawer;
