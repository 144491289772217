import React from 'react';
import { TabsList, TabsTrigger } from '@frontend/shadcn/components/ui/tabs';
import { cn } from '@frontend/shadcn/lib/utils';
import { H1 } from '@frontend/shadcn/components/typography/h1';

export const Header = ({ children }: { children: React.ReactNode }) => <div className="bg-primary">{children}</div>;
export const HeaderLayout = ({ children }: { children: React.ReactNode }) => <div className="flex justify-between items-center">{children}</div>;
export const HeaderMainContent = ({ children }: { children: React.ReactNode }) => <div className="flex items-center space-x-2 p-4 pb-1">{children}</div>;
export const HeaderActions = ({ children }: { children: React.ReactNode }) => <div className="flex space-x-4 p-4">{children}</div>;
export const HeaderTabs = ({ children }: { children: React.ReactNode }) => <div className="flex items-center px-4 pt-1">{children}</div>;

export const HeaderTabsList = React.forwardRef<
  React.ElementRef<typeof TabsList>,
  React.ComponentPropsWithoutRef<typeof TabsList>
>(({ className, ...props }, ref) => (
  <TabsList
    ref={ref}
    className={cn(
      'inline-flex h-auto items-center justify-center rounded-none rounded-t-lg bg-transparant p-0 space-x-2 text-primaryMuted-foreground',
      className,
    )}
    {...props}
  />
));
HeaderTabsList.displayName = TabsList.displayName;

export const HeaderH1 = ({ children }: { children: React.ReactNode }) => <H1 className="text-primary-foreground m-0 font-inter font-medium">{children}</H1>;

export const HeaderTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsTrigger>,
  React.ComponentPropsWithoutRef<typeof TabsTrigger>
>(({ className, ...props }, ref) => (
  <TabsTrigger
    ref={ref}
    className={cn(
      'bg-primaryMuted text-primaryMuted-foreground py-2 px-4 rounded-none rounded-t-lg data-[state=active]:text-primary data-[state=active]:shadow-none font-semibold',
      className,
    )}
    {...props}
  />
));
HeaderTabsTrigger.displayName = TabsTrigger.displayName;
