import * as React from 'react';
import { useHistory } from 'react-router-dom';

const { useState, useEffect } = React;

import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import { useFetchSocialAccountData } from '@frontend/applications/SocialPostApp/useFetchSocialAccountData';
import { useAuth } from '@frontend/context/authContext';

import { BaseMethod } from './BaseMethod';

const socialReachIcon = require('@frontend/app/assets/svgs/social_reach.svg');

export const SocialListeningMethod: React.FC = React.memo(() => {
  const history = useHistory();
  const [hasIGAccount, setHasIGAccount] = useState(false);
  const { clientInfo } = useAuth();

  const { loading, data: socialAccounts } = useFetchSocialAccountData({
    url: `${backendServerApiEndpoint()}/social_account`,
    clientId: clientInfo.id,
    fetchAll: true,
  });

  useEffect(() => {
    setHasIGAccount(
      !!(socialAccounts && socialAccounts.filter((account) => account.network_identifier === 'instagram').length > 0),
    );
  }, [socialAccounts]);

  const handleClick = () => {
    let url: string = '';

    if (!hasIGAccount) {
      // Go to settings if no connected account.
      url = `/settings/${SOCIAL_POST_APP_ID}`;
    } else {
      // ...Otherwise go to Social Listening.
      const paramsObj = {
        filters: JSON.stringify({
          segmentId: 'segmentId',
          value: [],
        }),
        sourcingId: 'social listening',
        switchSourcing: 'true',
      };
      const searchParams = new URLSearchParams(paramsObj);
      url = `/member_table?${searchParams}`;
    }

    history.push(url);
  };

  return (
    <BaseMethod
      loading={loading}
      icon={socialReachIcon}
      iconColor="#AC839E"
      title="Social Listening"
      description="See Instagram mentions"
      handleClick={handleClick}
    />
  );
});
