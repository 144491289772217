import React from 'react';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
  import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { AtIcon } from '@revfluence/fresh-icons/regular/esm';
import { SecondaryNavProps } from '../../Layout';
import {
  MenuHeader,
  MenuHeaderLayout,
  MenuIconWrapper,
  MenuItemContent,
  MenuLayout,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuTitle,
} from '../menuLayout';
import {
    SOCIAL_DISCOVERY_APP_ID,
  } from '@/app/constants/applicationIds';

export const SecondaryRecruitNav = ({ onItemSelected }: SecondaryNavProps) => (
  <MenuLayout>
    <MenuHeaderLayout>
      <MenuHeader>Recruit</MenuHeader>
    </MenuHeaderLayout>

    <MenuList>
      <MenuListItem>
        <MenuLink onClick={onItemSelected} to={`/app/${SOCIAL_DISCOVERY_APP_ID}`}>
          <MenuIconWrapper>
            <MagnifyingGlassIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Creator Search</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink
          onClick={onItemSelected}
          to={'/member_table?filters={"segmentId":"source_shopify","value":[]}&sourcingId=shopify&switchSourcing=true'}
        >
          <MenuIconWrapper>
            <ShopifyIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Customers</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
      <MenuListItem>
        <MenuLink
          onClick={onItemSelected}
          to={
            '/member_table?filters={"segmentId":"source_social listening","value":[]}&sourcingId=social listening&switchSourcing=true'
          }
        >
          <MenuIconWrapper>
            <AtIcon className="h-4 w-4 m-auto" />
          </MenuIconWrapper>
          <MenuItemContent>
            <MenuTitle>Mentions</MenuTitle>
          </MenuItemContent>
        </MenuLink>
      </MenuListItem>
    </MenuList>
  </MenuLayout>
);
