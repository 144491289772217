import * as React from 'react';
import { find, gt } from 'lodash';

import {
  useSkipWorkletsWithTrackerMutation,
  useJobStatusQuery,
  useGetAllWorkletsForParentSpecQuery,
  useGetCountsForProjectQuery,
  useGetAllTasksQuery,
  useGetTermsSettingsForProgram,
  useDeleteMessageTemplateMutation,
  useDeleteProgramTermsTemplateMutation,
} from '@frontend/app/hooks';
import { useDeleteWorkletFromSpecification } from '@frontend/app/hooks/useDeleteWorkletFromSpecification';
import { JobStatusQuery_status } from '@frontend/app/queries/types/JobStatusQuery';
import { WorkletSpecKey } from '@frontend/app/containers/Projects/utils';

const {
 useReducer, useCallback, useRef, useEffect, useMemo,
} = React;

const JOB_STATUS_POLLING_INTERVAL = 3000;
const JOB_FAILURE_TIMEOUT = 30000;

type AlertHandler = (msg: string) => void;

export type DeleteStageStatus = 'none' | 'progress' | 'failed' | 'success';

interface DeleteStageState {
  modalVisible: boolean;
  status: DeleteStageStatus;
  workItemCount: number;
  jobId: string;
  workletSpecKey: string;
  completedCount: number;
  jobStatus: JobStatusQuery_status[];
}

const initialState: DeleteStageState = {
  modalVisible: false,
  status: 'none',
  workItemCount: 0,
  jobId: '',
  workletSpecKey: '',
  completedCount: 0,
  jobStatus: [],
};

type DeleteStageAction =
  | { type: 'ShowModal'; workletSpecKey: string; workItemCount: number }
  | { type: 'HideModal' }
  | { type: 'StartChecking'; jobId: string }
  | { type: 'SetCompletedCount', count: number }
  | { type: 'Success' }
  | { type: 'Failed' }
  | { type: 'Progress' }
  | { type: 'UpdateJobStatus', status: JobStatusQuery_status[] };

const deleteStageReducer = (
  state: Readonly<DeleteStageState>,
  action: DeleteStageAction,
): DeleteStageState => {
  switch (action.type) {
    case 'ShowModal':
      return {
        ...state,
        modalVisible: true,
        workItemCount: action.workItemCount,
        workletSpecKey: action.workletSpecKey,
        status: 'none',
        completedCount: 0,
      };

    case 'HideModal':
      return {
        ...state,
        modalVisible: false,
        status:
          state.status === 'failed' || state.status === 'success'
            ? 'none'
            : state.status,
      };

    case 'StartChecking':
      return {
        ...state,
        jobId: action.jobId,
        completedCount: 0,
      };

    case 'SetCompletedCount':
      return {
        ...state,
        completedCount: action.count,
      };

    case 'Success':
      return {
        ...state,
        status: 'success',
      };

    case 'Failed':
      return {
        ...state,
        status: 'failed',
      };

    case 'Progress':
      return {
        ...state,
        status: 'progress',
        completedCount: 0,
        jobStatus: [],
      };

    case 'UpdateJobStatus':
      return {
        ...state,
        jobStatus: action.status,
      };

    default:
      return state;
  }
};

export const useDeleteStage = (
  specKey: string,
  projectId: number,
  showSuccessMessage: AlertHandler,
  showErrorMessage: AlertHandler,
) => {
  const [
    {
      modalVisible,
      status,
      workItemCount,
      jobId,
      jobStatus,
      workletSpecKey,
      completedCount,
    },
    dispatch,
  ] = useReducer(deleteStageReducer, initialState);
  const jobTimerRef = useRef<NodeJS.Timeout>(null);
  const failureTimerRef = useRef<NodeJS.Timeout>(null);
  const completedPercent = useMemo(() => (gt(workItemCount, 0)
      ? Math.floor((completedCount * 95) / workItemCount) + 5
      : 5), [completedCount, workItemCount]);

  const [skipWorkletsWithTracker] = useSkipWorkletsWithTrackerMutation();
  const [deleteWorkletFromSpecification] = useDeleteWorkletFromSpecification();
  const [deleteTemplate] = useDeleteMessageTemplateMutation();
  const [deleteProgramTermsTemplate] = useDeleteProgramTermsTemplateMutation();

  const {
    data: { status: jobStatusQueryResult } = {},
    refetch: refetchJobStatus,
  } = useJobStatusQuery(jobId, {
    skip: !jobId,
  });
  const { refetch: refetchWorklets } = useGetAllWorkletsForParentSpecQuery({
    variables: {
      specKey,
    },
    skip: !specKey,
  });
  const { refetch: refetchCounts } = useGetCountsForProjectQuery({
    variables: { projectId },
    skip: !projectId,
  });
  const { refetch: refetchTasks } = useGetAllTasksQuery({
    variables: {
      programId: projectId,
    },
    skip: !projectId,
  });
  const {
    refetch: refetchTermsSettingsForProgram,
  } = useGetTermsSettingsForProgram({
    variables: {
      programId: projectId,
    },
    skip: true,
  });

  const hideDeleteStageModal = () => {
    dispatch({ type: 'HideModal' });
  };

  const startCheckingJob = useCallback(() => {
    if (jobTimerRef.current) {
      clearInterval(jobTimerRef.current);
    }

    jobTimerRef.current = setInterval(async () => {
      await refetchJobStatus();
    }, JOB_STATUS_POLLING_INTERVAL);
  }, [refetchJobStatus]);

  const stopCheckingJob = useCallback(() => {
    if (jobTimerRef.current) {
      clearInterval(jobTimerRef.current);
      jobTimerRef.current = null;
    }
  }, []);

  const handleRemoveExistingSendTermsWorklet = useCallback(
    async () => {
      const { data: { termsTemplate } } = await refetchTermsSettingsForProgram({ programId: projectId });
      if (termsTemplate?.id) {
        await deleteProgramTermsTemplate({
          variables: { programId: projectId },
        });
      }
      if (termsTemplate?.emailTemplate?.id) {
        await deleteTemplate({
          variables: { id: termsTemplate?.emailTemplate?.id },
        });
      }
    },
    [projectId, refetchTermsSettingsForProgram, deleteProgramTermsTemplate, deleteTemplate],
  );

  const skipWorklet = useCallback(async () => {
    dispatch({ type: 'Progress' });

    try {
      const {
        data: { parentJobId },
      } = await skipWorkletsWithTracker({
        variables: {
          specKey,
          workletSpecKey,
        },
      });

      if (WorkletSpecKey.SendTerms === workletSpecKey) {
        handleRemoveExistingSendTermsWorklet();
      }

      dispatch({ type: 'StartChecking', jobId: parentJobId });

      startCheckingJob();
    } catch (e) {
      dispatch({ type: 'Failed' });
    }
  },
  [
    specKey,
    workletSpecKey,
    startCheckingJob,
    skipWorkletsWithTracker,
    handleRemoveExistingSendTermsWorklet,
  ]);

  const refreshAfterDeletion = useCallback(async () => {
    await refetchWorklets({ specKey });
    await refetchCounts({ projectId });
    await refetchTasks({ programId: projectId });
  },
  [
    specKey,
    projectId,
    refetchWorklets,
    refetchCounts,
    refetchTasks,
  ]);

  const deleteStage = useCallback(
    async (
      workletSpecKey: string,
      specKey: string,
      options?: { showToast?: boolean, onFinish?: () => void },
    ) => {
      const {
        showToast = true,
        onFinish,
      } = options || {};

      try {
        await deleteWorkletFromSpecification({
          variables: {
            workletSpecKey,
            specKey,
          },
        });

        if (WorkletSpecKey.SendTerms === workletSpecKey) {
          handleRemoveExistingSendTermsWorklet();
        }

        if (showToast) {
          showSuccessMessage('Successfully removed the stage');
        }
        await refreshAfterDeletion();
        onFinish && onFinish();
      } catch (e) {
        if (showToast) {
          showErrorMessage(
            "We're sorry, there was an unexpected error! Please reach out to us at help@aspireiq.com for help",
          );
        }
      }
    },
    [
      showSuccessMessage,
      showErrorMessage,
      deleteWorkletFromSpecification,
      refreshAfterDeletion,
      handleRemoveExistingSendTermsWorklet,
    ],
  );

  const handleRemoveExistingWorklet = useCallback(
    async (workletSpecKey: string, workItemCount: number) => {
      if (!gt(workItemCount, 0)) {
        await deleteStage(workletSpecKey, specKey);
      } else {
        dispatch({ type: 'ShowModal', workletSpecKey, workItemCount });
      }
    },
    [specKey, deleteStage],
  );

  /**
   * Update progress
   */
  useEffect(() => {
    const stopFailureTimer = () => {
      if (failureTimerRef.current) {
        clearTimeout(failureTimerRef.current);
        failureTimerRef.current = null;
      }
    };

    if (status !== 'progress' || !workItemCount) {
      return stopFailureTimer;
    }

    const successStatus = find(jobStatus, { success: true });
    const successCount = successStatus?.count || 0;

    // If completed job count is changed/increased
    if (completedCount < successCount) {
      dispatch({ type: 'SetCompletedCount', count: successCount });

      // If all jobs are completed, delete worklet
      if (successCount === workItemCount) {
        stopCheckingJob();
        deleteStage(workletSpecKey, specKey, {
          showToast: false,
          onFinish: () => {
            dispatch({ type: 'Success' });
          },
        });

        return stopFailureTimer;
      }
    }

    // If progress has been updated or failureTimer has not been started, start failureTimer
    if (completedCount < successCount || !failureTimerRef.current) {
      failureTimerRef.current = setTimeout(() => {
        stopCheckingJob();
        dispatch({ type: 'Failed' });
      }, JOB_FAILURE_TIMEOUT);
    }

    return stopFailureTimer;
  }, [
    jobStatus,
    status,
    workItemCount,
    workletSpecKey,
    specKey,
    completedCount,
    stopCheckingJob,
    deleteStage,
  ]);

  useEffect(() => {
    if (!modalVisible) {
      stopCheckingJob();
    }
  }, [modalVisible, stopCheckingJob]);

  useEffect(() => {
    dispatch({ type: 'UpdateJobStatus', status: jobStatusQueryResult || [] });
  }, [jobStatusQueryResult]);

  return {
    modalVisible,
    status,
    completedPercent,
    totalCount: workItemCount,
    handleRemoveExistingWorklet,
    hideDeleteStageModal,
    skipWorklet,
  };
};
