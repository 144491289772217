import { MutationHookOptions, useMutation } from '@apollo/client';
import { UPDATE_CATALOG_COLLECTION_RANKS_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { UpdateCatalogCollectionRanks, UpdateCatalogCollectionRanksVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/UpdateCatalogCollectionRanks';

type IOptions = MutationHookOptions<UpdateCatalogCollectionRanks, UpdateCatalogCollectionRanksVariables>;

export const useUpdateCatalogCollectionRanks = (options: IOptions = {}) => {
  const [updateCollectionRanks, { loading, error }] = useMutation(UPDATE_CATALOG_COLLECTION_RANKS_MUTATION, options);

  return {
    loading,
    error,
    updateCollectionRanks,
  };
};
