import * as React from 'react';
import { FormItemProps } from 'antd/lib/form';

import { Form, Input } from '@revfluence/fresh';
import { IShopifyFormElementProps, IShopifyPromoCodeFormValues, PrefixTypeStrategy } from '../../../types';

const { useCallback } = React;
interface IProps extends IShopifyFormElementProps<'codeSuffix'> {
  help?: FormItemProps['extra'];
  prefixType?: IShopifyPromoCodeFormValues['prefixType'],
  required?: boolean,
}

export const OfferCodeSuffix: React.FC<Readonly<IProps>> = React.memo(({
  // disabled,
  help,
  name,
  prefixType,
  required,
}) => {
  const requiredMsg = required ? 'Please input your Suffix' : 'Please input a valid suffix or change the prefix type.';
  const isRequired = useCallback((prefixType) => {
    if (required) {
      return true;
    }
    switch (prefixType) {
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_PLUS_SUFFIX:
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_PLUS_SUFFIX:
      case PrefixTypeStrategy.FULL_NAME_PLUS_SUFFIX:
      case PrefixTypeStrategy.INSTAGRAM_PLUS_SUFFIX:
        return true;
      default:
        return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixType]);
  return (
    <Form.Item
      extra={help}
      label="Promo Code Unique Naming Suffix"
      name={name}
      rules={[{
        message: requiredMsg,
        required: isRequired(prefixType),
      }]}
    >
      <Input
        // disabled={disabled}
        placeholder="e.g. summer20"
        size="large"
      />
    </Form.Item>
  );
});

OfferCodeSuffix.displayName = 'OfferCodeSuffix';
