/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Menu,
  message,
} from '@revfluence/fresh';
import { Modal as AntdModal } from 'antd';
import cx from 'classnames';
import {
  isEmpty,
  isFunction,
  map,
  find,
  lowerCase,
  size,
  isNil,
} from 'lodash';
import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import {
  EventName,
  logger,
} from '@common';
import {
  LightningFilledIcon,
  EllipsisIcon,
  EllipsisLabel,
} from '@frontend/app/components';
import {
  TCondition,
  TMember,
  TProject,
  TTask,
  TWorkItem,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import {
  useCustomCTA,
} from '@frontend/app/containers/Projects/hooks';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import { useEventContext } from '@frontend/app/context';
import { useSkipWorkItemsMutation } from '@frontend/app/hooks/useSkipWorkItemsMutation';
import { useBulkMemberApplicantOperationMutation, useGetInstalledApplicationIds } from '@frontend/app/hooks';

import {
  useCheckInWorkItemsMutation,
  useFeatureFlagVerbiage,
  useModifyProgramMembersMutation,
  useBulkMoveToMutation,
} from '@frontend/app/hooks';

import { useMessagingContext } from '@frontend/hooks';

import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { useCancelBriefsMutation } from '@frontend/app/hooks/useCancelBriefsMutation';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { OFFER_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  CONTRACT_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { ProjectsPageState, Task } from '../../constants';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';

import styles from './CTAButton.scss';

const { SubMenu } = Menu;
const { useMemo, useCallback, useState } = React;
const { confirm } = AntdModal;

enum CTAActions {
  Skip = 'Skip',
  Restart = 'Restart',
  ManageOffer = 'ManageOffer',
  CancelBrief = 'CancelBrief',
  CancelOrderRequest = 'CancelOrderRequest',
}
const messageContainerFixed = {
  position: 'fixed',
  top: '10px',
  left: '0',
  right: '0',
  margin: 'auto',
};
interface IProps {
  disabled?: boolean;
  showBulkCTA?: boolean;
  onCheckIn?: () => void;
  onOpenApplication: (workItem: TWorkItem) => void;
  onSkip: () => void;
  onMoveStage?: () => void;
  pageState?: ProjectsPageState;
  project: TProject;
  refetchData: () => Promise<unknown> | void;
  selectedMemberIds?: TMember['id'][];
  selectedWorkItems?: TWorkItem[];
  selectedWorkItemIds?: TWorkItem['id'][];
  task: TTask;
  worklets?: TWorklet[];
  conditions?: TCondition[];
  openAddToCollectionModal(modalType: ModalType): void;
  showSelectOffer?: (value: boolean) => void;
  migrateToGraphQL?: boolean;
  offers?: GetOfferById_offer[];
  onMemberApplicantOperationSuccess?: (memberIds?: IMember['id'][], operation?: MemberApplicantOperation) => void;
}

const MSG_DURATION = 3000;

export const CTAButton: React.FC<IProps> = React.memo(({
  disabled,
  onCheckIn,
  onOpenApplication,
  onSkip,
  onMoveStage,
  pageState,
  refetchData,
  selectedWorkItems,
  selectedWorkItemIds,
  task = {},
  showBulkCTA,
  project,
  worklets,
  conditions,
  selectedMemberIds,
  openAddToCollectionModal,
  showSelectOffer,
  onMemberApplicantOperationSuccess,
  migrateToGraphQL,
  offers,
}) => {
  const addEvent = useEventContext();
  const {
    showErrorMessage,
    showSuccessMessage,
    showGenericErrorMessage,
    showMessage,
    showError,
  } = useMessagingContext();
  const [
    bulkMemberApplicantOperation,
  ] = useBulkMemberApplicantOperationMutation();
  const installedApps = useGetInstalledApplicationIds();

  const verbiage = useFeatureFlagVerbiage();
  const { clientInfo } = useAuth();
  const hasMembersSelected = useMemo(
    () => !isEmpty(selectedMemberIds),
    [selectedMemberIds],
  );
  const { taskMetaData: metadata = undefined } = task;

  /**
   * Check in
   */
  const [
    checkInWorkItems,
    { loading: isCheckingIn },
  ] = useCheckInWorkItemsMutation();

  const customCTAAction = useCustomCTA(project?.id, isEmpty(task) ? undefined : task as TTask);
  const [isLoadingCustomBulkCTA, setIsLoadingCustomBulkCTA] = useState(false);

  const [cancelBriefs] = useCancelBriefsMutation();

  // Add to program
  const [
    addMembersToPrograms,
  ] = useModifyProgramMembersMutation('add', {
    onCompleted: () => {
      showMessage({
        type: 'success',
        content: `Members added to ${verbiage.programs}`,
      });
    },
    onError: (error) => {
      showError(error);
    },
  });

  /**
   * Skip Stage
   */
  const [
    skipWorkItems,
    { loading: isSkipping },
  ] = useSkipWorkItemsMutation();

  const [bulkkMoveTo] = useBulkMoveToMutation();

  const isMarkAsDone = (
    metadata
    && !metadata.serviceId
    && !metadata.singleCTAText
    && !metadata.bulkCTAText
  );

  const handleCtaButtonClicked = async () => {
    if (metadata?.bulkCTAText === 'Create Promo Codes' && migrateToGraphQL) {
      for (const offer of offers) {
        if (offer.promos.length && (!offer.isNewFlow || !offer.promos[0].defaultPayoutId)) {
          message.info({
            content: 'Please upgrade your connected promo offer via Sales Tracking to continue generating promo codes.',
            style: messageContainerFixed,
          }, 5);
          return;
        }
      }
    }
    if (metadata?.bulkCTAText === 'Create Sales Links' && migrateToGraphQL) {
      for (const offer of offers) {
        if (!offer.isPromoLink && offer.links.length && !offer.links[0].defaultPayoutId) {
          message.info({
            content: 'Please upgrade your connected offer through Sales Tracking to continue creating links.',
            style: messageContainerFixed,
          }, 5);
          return;
        }
      }
    }
    if (
      (metadata?.bulkCTAText === 'Create Promo Codes' && !isNil(offers.find((offer) => offer.type === OFFER_TYPE.PROMO_CODE)?.archivedDate))
      || (metadata?.bulkCTAText === 'Create Sales Links' && !isNil(offers.find((offer) => offer.type === OFFER_TYPE.LINK)?.archivedDate))
    ) {
      message.info({
        content: `The connected offer is Archived. Please change the offer to generate ${metadata?.bulkCTAText === 'Create Promo Codes' ? 'promo codes' : 'sales links'}.`,
        style: messageContainerFixed,
      }, 5);
      return;
    }
    if (metadata?.bulkCTAText === 'Send Contracts' && !installedApps[CONTRACT_APP_ID]) {
      message.info({
        content: 'Contract app is not installed on your account. Please contact support to get started.',
        style: messageContainerFixed,
      }, 5);
      return;
    }
    if (isEmpty(selectedWorkItemIds) || isEmpty(selectedWorkItems)) {
      logger.warn('No selected workItems');
      return;
    } if (isEmpty(task)) {
      logger.warn('No task');
      return;
    }
    if (!isMarkAsDone) {
      if (isFunction(customCTAAction)) {
        try {
          setIsLoadingCustomBulkCTA(true);
          await customCTAAction(selectedWorkItems);
        } catch (error) {
          logger.error('Error calling custom action', { error });
          showErrorMessage('There was an error when triggering the CTA. Please reach out to support.');
        } finally {
          setIsLoadingCustomBulkCTA(false);
        }
        await refetchData();
        return;
      }
      onOpenApplication(null);
    } else {
      logger.debug('Marking as done', { selectedWorkItemIds });
      await checkInWorkItems({
        variables: {
          workItemIds: selectedWorkItemIds,
          outputDataJSON: {
            [`${task.taskId}_data`]: {},
          },
        },
      });
      logger.debug('Successfully marked as done', { selectedWorkItemIds });
      addEvent(
        EventName.MovedCaseStage,
        {
          action: 'mark_done',
          case_count: selectedWorkItemIds.length,
          from_task_id: task.taskId,
        },
      );
      await refetchData();
      if (isFunction(onCheckIn)) {
        onCheckIn();
      }
    }
  };

  const [removeMembersFromPrograms] = useModifyProgramMembersMutation('remove', {
    onCompleted: async () => {
      showMessage({
        type: 'success',
        content: `${selectedWorkItemIds.length} members removed from ${verbiage.programs}`,
      });
      await refetchData();
    },
    onError: () => {
      showGenericErrorMessage();
    },
  });

  const handleSkip = useCallback(async () => {
    try {
      logger.debug('Skipping work items:', selectedWorkItemIds);
      const result = await skipWorkItems({
        variables: {
          workItemIds: selectedWorkItemIds,
        },
      });
      if (!result?.data?.success) {
        throw new Error('Unable to skip work items');
      }
      logger.debug('Successfully skipped work items');
      if (isFunction(onSkip)) {
        await onSkip();
      }
    } catch (error) {
      logger.error(error);
      showErrorMessage('Unable to skip members as they are no longer in this stage. Please try refreshing the page.');
    }
  }, [onSkip, selectedWorkItemIds, showErrorMessage, skipWorkItems]);

  const handleMoveToStage = (worklet: TWorklet) => {
    async function moveToStage(workletArgument: TWorklet) {
      try {
        logger.debug('Moving to stage:', workletArgument.specTitle);
        showMessage({
          type: 'info',
          content: 'Moving members to new stage. This may take up to a few minutes.',
        });
        const condition = find(conditions, { workletSpecKey: workletArgument.specKey });
        const result = await bulkkMoveTo({
          variables: {
            workItemIds: selectedWorkItemIds,
            conditionId: condition.conditionId,
          },
        });

        if (!result?.data?.success) {
          throw new Error(`Unable to move work items to stage: ${workletArgument.specTitle}`);
        }
        logger.debug(`Successfully moved work items to stage: ${workletArgument.specTitle}`);
        await refetchData();
        if (isFunction(onMoveStage)) {
          await onMoveStage();
        }
      } catch (error) {
        logger.error(error);
        showErrorMessage(`Unable to move to stage: ${workletArgument.specTitle}`);
      }
    }

    moveToStage(worklet);
  };

  const cancelOrderRequest = async (worklet: TWorklet) => {
      try {
        logger.debug('Canceling & Moving to stage:', worklet.specTitle);
        showMessage({
          type: 'info',
          content: 'Cancelling the order request and moving members to previous stage. This may take up to a few minutes.',
        });
        const condition = find(conditions, { workletSpecKey: worklet.specKey });
        const result = await bulkkMoveTo({
          variables: {
            workItemIds: selectedWorkItemIds,
            conditionId: condition.conditionId,
            isCancelOrderRequest: true,
          },
        });

        if (!result?.data?.success) {
          throw new Error(`Unable to move work items to stage: ${worklet.specTitle}`);
        }
        logger.debug(`Successfully moved work items to stage: ${worklet.specTitle}`);
        await refetchData();
        if (isFunction(onMoveStage)) {
          await onMoveStage();
        }
      } catch (error) {
        logger.error('Error in cancel order request function', error);
        showErrorMessage(`Unable to move to stage: ${worklet.specTitle}`);
      }
  };

  const handleApproveRejectedApplicants = useCallback(async () => {
    try {
      await bulkMemberApplicantOperation({
        variables: {
          memberIds: selectedMemberIds,
          projectId: project.id,
          operation: MemberApplicantOperation.Approve,
        },
      });
      onMemberApplicantOperationSuccess(selectedMemberIds, MemberApplicantOperation.Approve);
      const approvedCount = size(selectedMemberIds);
      showSuccessMessage(
        `Approved ${approvedCount} rejected applicant${approvedCount > 1 ? 's' : ''}`,
        MSG_DURATION,
      );
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    selectedMemberIds,
    project?.id,
    bulkMemberApplicantOperation,
    showSuccessMessage,
    showErrorMessage,
  ]);

  const handleRestartProgram = useCallback(
    async () => {
      await addMembersToPrograms({
        variables: {
          memberIds: selectedMemberIds,
          programIds: [project.id],
          status: 'approved',
          clientId: clientInfo.id,
        },
      });
    }, [project, selectedMemberIds, addMembersToPrograms],
  );

  const handleCancelProject = useCallback(
    async () => {
      const briefVerbiage = selectedWorkItems.length > 1
        ? `${selectedWorkItems.length} briefs`
        : '1 brief';
      const hideLoadingMessage = message.loading(
        `Canceling ${briefVerbiage}. This may take up to a few minutes`,
        0,
      );

      const projectIds = map(selectedWorkItems, 'data.sending_terms_task_data.post_project_id');
      cancelBriefs({
        variables: {
          axProjectIds: projectIds,
        },
        onCompleted: (data) => {
          hideLoadingMessage();
          if (data.results.successes > 0) {
            showMessage({
              type: 'success',
              content: `${data.results.successes} successfully canceled`,
            });
          }
          if (data.results.failures > 0) {
            showMessage({
              type: 'error',
              content: `Unable to cancel ${data.results.failures} briefs. Please try again or contact support for assistance`,
            });
          }
        },
        onError: () => {
          hideLoadingMessage();
          // should never get to here as the errors should be covered above
          showGenericErrorMessage();
        },
      });
    }, [project, selectedMemberIds, addMembersToPrograms],
  );

  const showCancelOrderRequest = task && (task.taskId === Task.WaitingForOrderRequestTask || task.taskId === Task.PFAV2WaitingForOrderRequestTask);
  /**
   * Menu
   */
  const handleMenuClick = useCallback(({ key }) => {
    if (key === ModalType.RemoveFromProject) {
      confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to remove selected members from project?',
        okText: 'Remove',
        cancelText: 'Cancel',
        onOk: () => {
          removeMembersFromPrograms({
            variables: {
              memberIds: selectedMemberIds,
              programIds: [project.id],
            },
          });
        },
      });
    } else if (key === CTAActions.Skip) {
      const peopleVerbiage = selectedMemberIds.length > 1
        ? `these ${selectedMemberIds.length} members`
        : 'this member';
      confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: (
          `Are you sure you want to skip this stage for ${peopleVerbiage}? `
          + 'Their progress in the current stage may be lost.'
        ),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: handleSkip,
      });
    } else if (key === CTAActions.Restart) {
      const peopleVerbiage = selectedMemberIds.length > 1
        ? `these ${selectedMemberIds.length} members`
        : 'this member';
      confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: (
          `Are you sure you want to restart this project for ${peopleVerbiage}? `
          + 'They will rejoin the program at the first "In Progress" stage'
        ),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: handleRestartProgram,
      });
    } else if (key === CTAActions.ManageOffer) {
      showSelectOffer(true);
    } else if (key === CTAActions.CancelBrief) {
      const briefVerbiage = selectedMemberIds.length > 1
        ? `${selectedMemberIds.length} briefs`
        : 'this brief';
      const peopleVerbiage = selectedMemberIds.length > 1
        ? 'These members'
        : 'This member';
      confirm({
        title: `Are you sure you want to cancel ${briefVerbiage}?`,
        icon: (
          <TriangleExclamationIcon
            className={cx(
              'anticon',
              styles.alertIcon,
            )}
          />
        ),
        content: (
          <>
            {peopleVerbiage}
            {' '}
            will move back to the
            {' '}
            <strong>Send Briefs</strong>
            {' '}
            status and will no longer
            have access to the brief. If you would still like to collaborate with
            {' '}
            {lowerCase(peopleVerbiage)}
            {' '}
            you will need to send them a new brief.
            {' '}
            <strong>This action cannot be undone.</strong>
          </>
        ),
        okText: 'Confirm',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: handleCancelProject,
      });
    } else if (key === CTAActions.CancelOrderRequest) {
      const worklet = worklets.find((worklet) => worklet.specKey === task.workletSpecKey);
      if (worklet) {
        cancelOrderRequest(worklet);
      }
    } else {
      const worklet = find(worklets, { specKey: key });

      if (worklet) {
        confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: (
            `Are you sure you want to move work items to stage ${worklet.specTitle}? `
            + 'Their progress in the current stage may be lost.'
          ),
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: () => handleMoveToStage(worklet),
        });
      } else {
        openAddToCollectionModal(key);
      }
    }
  }, [
    project?.id,
    selectedMemberIds,
    worklets,
  ]);

  const menuElem = useMemo(
    () => {
      /**
       * All in Progress
       */
      if (pageState === ProjectsPageState.AllInProgress) {
        return (
          <Menu onClick={handleMenuClick} className={styles.menu}>
            {migrateToGraphQL && (
              <Menu.Item
                disabled={!hasMembersSelected}
                key={CTAActions.ManageOffer}
              >
                Manage Offers
              </Menu.Item>
            )}
            <SubMenu title="Add people to this project...">
              <Menu.Item
                key={ModalType.AddFromGroup}
                className={styles.menuItem}
              >
                Add from your groups
              </Menu.Item>
              {/* <Menu.Item key={ModalType.InviteFromGroup}>Invite from your groups...</Menu.Item> */}
              <Menu.Item
                key={ModalType.AddFromProject}
                className={styles.menuItem}
              >
                Add from other projects
              </Menu.Item>
              {/* <Menu.Item key={ModalType.InviteFromProject}>Invite from other projects...</Menu.Item> */}
            </SubMenu>
            <SubMenu title="Move to stage..." disabled={!hasMembersSelected || project.isFlexibleSpec} className={styles.menu}>
              {map(worklets, (worklet) => (
                <Menu.Item key={worklet.specKey}>
                  <EllipsisLabel
                    className="stageTitle"
                    tooltipPlacement="right"
                  >
                    {worklet.specTitle}
                  </EllipsisLabel>
                </Menu.Item>
              ))}
            </SubMenu>
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.RemoveFromProject}
            >
              Remove from this project
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.AddToGroup}
            >
              Add people to group
            </Menu.Item>
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.AddToAnotherProject}
            >
              Add to another project
            </Menu.Item>
          </Menu>
        );
      }

      if ([
        ProjectsPageState.Invited,
        ProjectsPageState.Applicants,
        ProjectsPageState.Rejected,
      ].includes(pageState)) {
        return (
          <Menu onClick={handleMenuClick} className={styles.menu}>
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.AddToGroup}
            >
              Add people to group
            </Menu.Item>
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.AddToAnotherProject}
            >
              Add to another project
            </Menu.Item>
            {pageState === ProjectsPageState.Rejected && (
              <Menu.Divider />
            )}
            {pageState === ProjectsPageState.Rejected && (
              <Menu.Item
                key="approveRejectedApplicants"
                disabled={!hasMembersSelected}
                onClick={handleApproveRejectedApplicants}
              >
                Approve rejected applicants
              </Menu.Item>
            )}
          </Menu>
        );
      }

      /**
       * Tasks
       */
      const showCancelBriefs = task && (task.taskId === 'review_changes_task' || task.taskId === 'waiting_for_agreement_task');
      if (pageState === ProjectsPageState.Task || pageState === ProjectsPageState.Worklet) {
        return (
          <Menu onClick={handleMenuClick} className={styles.menu}>
            {showCancelBriefs && (
              <>
                <Menu.Item
                  disabled={!hasMembersSelected}
                  key={CTAActions.CancelBrief}
                >
                  Cancel Briefs
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            {migrateToGraphQL && (
              <Menu.Item
                disabled={!hasMembersSelected}
                key={CTAActions.ManageOffer}
              >
                Manage Offers
              </Menu.Item>
            )}

            <Menu.Item
              disabled={!hasMembersSelected}
              key={CTAActions.Skip}
            >
              Skip this stage
            </Menu.Item>
            <SubMenu title="Move to stage..." disabled={!hasMembersSelected || project.isFlexibleSpec} className={styles.menu}>
              {map(worklets, (worklet) => (
                <Menu.Item key={worklet.specKey}>
                  <EllipsisLabel
                    className="stageTitle"
                    tooltipPlacement="right"
                  >
                    {worklet.specTitle}
                  </EllipsisLabel>
                </Menu.Item>
              ))}
            </SubMenu>
            {
              showCancelOrderRequest && (
              <Menu.Item
                disabled={!hasMembersSelected}
                key={CTAActions.CancelOrderRequest}
                style={hasMembersSelected ? { color: 'red' } : {}}
              >
                Cancel Order Request
              </Menu.Item>
              )
            }
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.RemoveFromProject}
            >
              Remove from this project
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.AddToGroup}
            >
              Add people to group
            </Menu.Item>
            <Menu.Item
              disabled={!hasMembersSelected}
              key={ModalType.AddToAnotherProject}
            >
              Add to another project
            </Menu.Item>
          </Menu>
        );
      }

      /**
       * Completed
       * - menu should be disabled when there are no members selected
       */
      if (pageState === ProjectsPageState.Completed) {
        return (
          <Menu onClick={handleMenuClick} className={styles.menu}>
            <Menu.Item key={CTAActions.Restart}>
              Restart project
            </Menu.Item>
            <Menu.Item key={ModalType.AddToGroup}>
              Add people to group
            </Menu.Item>
            <Menu.Item key={ModalType.AddToAnotherProject}>
              Add to another project
            </Menu.Item>
          </Menu>
        );
      }

      return null;
    },
    [handleMenuClick, hasMembersSelected, pageState],
  );

  /**
   * Button states
   */
  const isBulkCTA = (
    metadata
    && metadata.serviceId
    && metadata.bulkCTAText
  );

  const isPrimary = (
    isBulkCTA
    || isMarkAsDone
  );
  const isLoading = (
    isCheckingIn
    || isSkipping
    || isLoadingCustomBulkCTA
  );
  const isPrimaryButtonDisabled = (
    disabled
    || isLoading
  );
  const isDropdownDisabled = (
    disabled
    || isLoading
    || (pageState === ProjectsPageState.Completed && !hasMembersSelected)
    || isEmpty(menuElem)
  );

  return menuElem && (
    <>
      <Dropdown
        disabled={isDropdownDisabled}
        overlay={menuElem}
        placement={isPrimary ? 'bottomRight' : 'bottomLeft'}
        trigger={['click']}
      >
        <Button
          className={cx(
            styles.button,
          )}
          icon={<EllipsisIcon className={styles.icon} size={24} />}
        />
      </Dropdown>
      {isPrimary && showBulkCTA && (
        <Button
          className={cx(styles.primaryCTA, {
            [styles.disabled]: isPrimaryButtonDisabled || !hasMembersSelected,
          })}
          icon={(
            <LightningFilledIcon
              className={styles.icon}
              size={16}
            />
          )}
          onClick={handleCtaButtonClicked}
          type="primary"
        >
          {metadata?.bulkCTAText || 'Mark as Done'}
        </Button>
      )}
    </>
  );
});
