import * as React from 'react';
import {
 Col, Divider, Drawer, Row, Typography,
} from '@revfluence/fresh';
import { TMemberTableRow } from '../MemberTable/types';
import styles from './DeeplinkDrawer.scss';
import { GetOfferById_offer } from '../../queries/types/GetOfferById';
import { CustomDeepLinks } from './CustomDeeplink';
import { OfferDetailCard } from '../AffiliatesApp/components/OfferDetailCard';
import { NameCell } from '../MemberTable/NameCell';

const { Title } = Typography;

interface IProps {
  memberData: TMemberTableRow;
  setDeeplinkDrawerVisible: (value: boolean) => void;
  isDeeplinkDrawerVisibile: boolean;
  offer: GetOfferById_offer;
  refresh?: () => void;
}
export const DeeplinkDrawer: React.FC<Readonly<IProps>> = (props: IProps) => {
  const {
 memberData, setDeeplinkDrawerVisible, isDeeplinkDrawerVisibile, offer, refresh,
} = props;
  const header = (
    <div>
      <Row justify="center" className={styles.deeplinkDrawerTitle}>
        <Title level={4}>Member Links</Title>
      </Row>
    </div>
  );
  return (
    <>
      <Drawer
        key={memberData.id}
        onClose={() => setDeeplinkDrawerVisible(false)}
        open={isDeeplinkDrawerVisibile}
        title={header}
        width={572}
        destroyOnClose
      >
        <Row justify="space-between" align="middle">
          <Col>
            <NameCell
              name={memberData.affiliateName.name}
              imageUrl={memberData.affiliateName.imageUrl}
              memberId={memberData.affiliateName.memberId}
            />
          </Col>
          <Col>
            <OfferDetailCard
              showCta={false}
              infoButtonClassName
              offerData={offer}
              hideOfferName
              isWorkFlow
            />
          </Col>
        </Row>
        <Divider orientation="center" plain type="horizontal" />
        {/** custom deeplinks */}
        <CustomDeepLinks
          memberData={memberData}
          offer={offer}
          refresh={refresh}
          setDeeplinkDrawerVisible={setDeeplinkDrawerVisible}
        />
      </Drawer>
    </>
  );
};
