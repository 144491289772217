import {
  first, isEmpty, isNull, keys, map, reduce, some,
} from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  IDashboardOffer,
  IOfferTableCsvRow,
  TOnClickAddOffer,
} from '@affiliates/components';
import { useSTAPaymentAppContext } from '@affiliates/contexts';
import {
  IHeader,
  useAdvertiserStatDetailQuery,
  useCreateAdvertiserMutation,
  useExportToCsv,
  useGetAdvertiserSignupDate,
  useGetDashboardStatsQuery,
} from '@affiliates/hooks';
import { AdvertiserStatDetail_offers } from '@affiliates/queries/types/AdvertiserStatDetail';
import { GetOffers_offers } from '@affiliates/queries/types/GetOffers';
import { GetAdvertiserSignupDate } from '@affiliates/queries/types/GetAdvertiserSignupDate';
import { GetDashboardStatistics } from '@affiliates/queries/types/GetDashboardStatistics';
import { IEnabledOfferSources } from '@affiliates/types';
import { roundToNearestCent } from '@affiliates/utils';
import { OFFER_SOURCE, OFFER_STATUS, STATS_OFFER_TYPE } from '@affiliates/types/globalTypes';
import { EventName, logger } from '@common';
import { IDateRangeSettings } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context';
import { useMapDateRangeToVariables } from '@frontend/app/hooks';
import { useGetOffersQuery } from './useGetOffersQuery';
import { useAdvertiserShopifyScopeCheck } from './useAdvertiserShopifyScopeCheck';

export enum DashboardQueriesStatus {
  Failed,
  Loading,
  Ready,
}

interface IDashboardQueriesLoading {
  status: DashboardQueriesStatus.Loading;
}

interface IDashboardQueriesFailed {
  error: Error;
  status: DashboardQueriesStatus.Failed;
}

interface IDashboardQueriesReady {
  advertiser: GetAdvertiserSignupDate['advertiser'];
  missingShopifyCredentials: boolean;
  offerActions: {
    onClickAddOffer: TOnClickAddOffer;
    onClickExport: (columns: IHeader[], data: IOfferTableCsvRow[]) => void;
    onOfferClicked: (offerId: number) => void;
  };
  offers: readonly IDashboardOffer[];
  stats: GetDashboardStatistics['summary'];
  status: DashboardQueriesStatus.Ready;
}

type TDashboardQueries = Readonly<
  | IDashboardQueriesFailed
  | IDashboardQueriesLoading
  | IDashboardQueriesReady
>;
export const useDashboardQueries = (
  baseUri: string,
  clientName: string,
  dateRangeSettings: IDateRangeSettings,
  enabledSources: IEnabledOfferSources,
  offerType: STATS_OFFER_TYPE,
  isArchiveEnabled: boolean,
): TDashboardQueries => {
  const {
    dateFilter,
    setOffers,
    setPaymentsDue,
    setRefreshCurrentDashboard,
    setRefreshCurrentTable,
  } = useSTAPaymentAppContext();

  const advertiserSignupQuery = useGetAdvertiserSignupDate();
  const missingAdvertiser = (
    advertiserSignupQuery.loading
    || !!advertiserSignupQuery.error
    || !advertiserSignupQuery.data
  );
  const paymentDateRangeVariables = useMapDateRangeToVariables(dateFilter.dateRange);
  const dateRangeVariables = useMapDateRangeToVariables(dateRangeSettings.dateRange);

  const statsVariables = useMemo(() => ({
    ...dateRangeVariables,
    offerType,
    isArchiveEnabled,
  }), [dateRangeVariables, offerType, isArchiveEnabled]);

  const statsQuery = useGetDashboardStatsQuery({
    fetchPolicy: 'cache-and-network',
    skip: missingAdvertiser,
    variables: statsVariables,
  });
  const paymentStatsQuery = useGetDashboardStatsQuery({
    fetchPolicy: 'cache-and-network',
    skip: missingAdvertiser,
    variables: paymentDateRangeVariables,
  });
  const offerStatsQuery = useAdvertiserStatDetailQuery({
    fetchPolicy: 'cache-and-network',
    skip: missingAdvertiser,
    variables: dateRangeVariables,
  });
  const offersQuery = useGetOffersQuery({
    fetchPolicy: 'cache-and-network',
    skip: missingAdvertiser,
  });

  const advertiserShopifyScopeCheck = useAdvertiserShopifyScopeCheck({
    skip: (
      offersQuery.loading
      || !!offersQuery.error
      || !some(offersQuery.data?.offers, (offer) => !isEmpty(offer.promos))
    ),
  });
  let missingShopifyCredentials = false;
  if (!advertiserShopifyScopeCheck.loading && !advertiserShopifyScopeCheck.error && advertiserShopifyScopeCheck.data) {
    missingShopifyCredentials = !(advertiserShopifyScopeCheck.data?.advertiser?.hasShopifyScopes);
  }

  const offers = useMemo((): readonly IDashboardOffer[] => {
    if (
      missingAdvertiser
      || offersQuery.loading
      || offerStatsQuery.loading
      || offersQuery.error
      || offerStatsQuery.error
      || !offersQuery.data?.offers
      || !offerStatsQuery.data?.offers
    ) {
      return [];
    }
    const offerMap = reduce(
      offersQuery.data.offers,
      (prev, next) => {
        prev[next.id.toString()] = next;
        return prev;
      },
      {} as { [id: string]: GetOffers_offers },
    );
    const offerStatsMap = reduce(
      offerStatsQuery.data.offers,
      (prev, next) => {
        prev[next.offerId.toString()] = next;
        return prev;
      },
      {} as { [id: string]: AdvertiserStatDetail_offers },
    );
    return reduce(
      keys(offerMap),
      (prev, next) => {
        if (!(next in offerMap) || !(next in offerStatsMap)) {
          return prev;
        }
        const offer = offerMap[next];
        const offerStats = offerStatsMap[next];
        let source = OFFER_SOURCE.SHOPIFY;
        let status = OFFER_STATUS.ACTIVE;
        if (!isEmpty(offer.links) && !offer.isPromoLink) {
          source = OFFER_SOURCE.TUNE;
          if (!enabledSources[OFFER_SOURCE.TUNE]) {
            return prev;
          }
          status = first(offer.links).status;
        } else if (!isEmpty(offer.promos)) {
          if (!enabledSources[OFFER_SOURCE.SHOPIFY]) {
            return prev;
          }
          if (missingShopifyCredentials) {
            status = OFFER_STATUS.PAUSED;
          } else {
            status = first(offer.promos).status;
          }
        } else {
          return prev;
        }
        const dashboardOffer: IDashboardOffer = {
          avgSale: offerStats.avgSale,
          clicks: offerStats.clicks,
          conversions: offerStats.conversions,
          createdDate: offer.createdDate,
          members: offerStats.members,
          offerId: offer.id,
          offerImageUrl: offer.imageUrl,
          offerName: offer.name,
          payoutEarned: offerStats.payoutEarned,
          payoutMade: offerStats.payoutMade,
          salesAmount: offerStats.salesAmount,
          source,
          status,
          url: offerStats.url,
          expired: offer.expired,
          programId: offer.programId,
          offerType: offer.type,
          archivedDate: offer.archivedDate,
        };
        prev.push(dashboardOffer);
        return prev;
      },
      [] as IDashboardOffer[],
    ).sort((a, b) => {
      if (a.createdDate < b.createdDate) {
        return 1;
      } else if (a.createdDate > b.createdDate) {
        return -1;
      }
      return 0;
    });
  }, [
    enabledSources,
    missingAdvertiser,
    missingShopifyCredentials,
    offersQuery,
    offerStatsQuery,
  ]);

  useEffect(() => {
    const newRefreshCurrentDashboard = () => {
      statsQuery.refetch(statsVariables);
      paymentStatsQuery.refetch(paymentDateRangeVariables);
    };
    setRefreshCurrentDashboard(() => newRefreshCurrentDashboard);
  }, [
    statsVariables,
    dateRangeVariables,
    offerType,
    paymentDateRangeVariables,
    paymentStatsQuery,
    statsQuery,
    setRefreshCurrentDashboard,
  ]);
  useEffect(() => {
    if (paymentStatsQuery.loading || isEmpty(paymentStatsQuery.data)) {
      setPaymentsDue({ state: 'loading' });
      return;
    }
    if (paymentStatsQuery.error) {
      setPaymentsDue({
        errorMessage: paymentStatsQuery.error.message,
        state: 'failed',
      });
      return;
    }
    const { summary } = paymentStatsQuery.data;
    setPaymentsDue({
      paymentsDue: Math.max(0, roundToNearestCent(
        summary.payoutEarned - summary.payoutMade,
      )),
      state: 'ready',
    });
  }, [paymentStatsQuery, setPaymentsDue]);
  useEffect(() => {
    setRefreshCurrentTable(() => offersQuery.refetch);
    if (isEmpty(offers)) {
      return;
    }
    const contextOffers = map(offers, (offer) => ({
      id: offer.offerId,
      name: offer.offerName,
      source: offer.source,
    }));
    setOffers(contextOffers);
  }, [
    offers,
    offersQuery.refetch,
    setOffers,
    setRefreshCurrentTable,
  ]);

  const history = useHistory();
  const location = useLocation();
  const handleOfferClicked = useCallback((offerId: number) => {
    history.push({ ...location, pathname: `${baseUri}/offers/${offerId}/details` });
  }, [baseUri, location, history]);
  // redirect the user based on which type of offer source
  const onClickAddOffer = useMemo((): TOnClickAddOffer => ({
    [OFFER_SOURCE.TUNE]: () => {
      history.push({ ...location, pathname: `${baseUri}/offers/newAffiliateLink` });
    },
    [OFFER_SOURCE.SHOPIFY]: () => {
      history.push({ ...location, pathname: `${baseUri}/offers/newShopifyPromo` });
    },
  }), [baseUri, history, location]);

  const [createAdvertiser] = useCreateAdvertiserMutation({
    onCompleted: advertiserSignupQuery.refetch,
  });

  const addEvent = useEventContext();
  const exportToCsv = useExportToCsv();
  const handleClickExport = useCallback((columnConfig: IHeader[], data: IOfferTableCsvRow[]) => {
    addEvent(EventName.SalesTrackingTableExport, {
      exportDate: new Date().toISOString(),
      exportedTable: 'offers',
    });
    exportToCsv(`affiliates-${Date.now()}`, columnConfig, data);
  }, [addEvent, exportToCsv]);

  useEffect(() => {
    if (
      advertiserSignupQuery.loading
      || advertiserSignupQuery.error
      || !isNull(advertiserSignupQuery.data.advertiser)
    ) {
      return;
    }
    // create the advertiser for the first time
    createAdvertiser({
      variables: {
        data: {
          name: clientName,
        },
      },
    });
  }, [
    advertiserSignupQuery,
    clientName,
    createAdvertiser,
  ]);

  if (
    advertiserSignupQuery.loading
    || statsQuery.loading
    || offersQuery.loading
    || offerStatsQuery.loading
    || (!advertiserSignupQuery.error && !advertiserSignupQuery.data?.advertiser)
  ) {
    return {
      status: DashboardQueriesStatus.Loading,
    };
  }

  if (advertiserSignupQuery.error || statsQuery.error || offersQuery.error || offerStatsQuery.error) {
    if (advertiserSignupQuery.error) {
      logger.error(advertiserSignupQuery.error);
    }
    if (statsQuery.error) {
      logger.error(statsQuery.error);
    }
    if (offersQuery.error) {
      logger.error(offersQuery.error);
    }
    if (offerStatsQuery.error) {
      logger.error(offersQuery.error);
    }
    return {
      error: new Error('Oops! Something went wrong. Please refresh or try again later.'),
      status: DashboardQueriesStatus.Failed,
    };
  }

  return {
    advertiser: advertiserSignupQuery.data.advertiser,
    missingShopifyCredentials,
    offerActions: {
      onClickAddOffer,
      onClickExport: handleClickExport,
      onOfferClicked: handleOfferClicked,
    },
    offers,
    stats: statsQuery.data.summary,
    status: DashboardQueriesStatus.Ready,
  };
};
