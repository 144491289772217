import * as React from 'react';
import { IOfferDetailsProps } from '@affiliates/components/OfferSummaryCard/types';
import { OfferSummaryCard } from '@affiliates/components';
import styles from './OfferConfirmation.scss';
import { OfferAdjustmentButtons } from './OfferAdjustmentButtons';

interface IProps {
  onRemoveOffer: () => void;
  onChangeOffer: () => void;
  canMigrateProgram?: boolean;
  offerDetails: IOfferDetailsProps;
}

export const OfferSummarySection: React.FC<Readonly<IProps>> = ({
  onRemoveOffer,
  onChangeOffer,
  canMigrateProgram,
  offerDetails,
}) => (
  <div className={`${styles.summaryCard} w-4/5`}>
    <OfferSummaryCard {...offerDetails} />
    {canMigrateProgram
      && (
      <OfferAdjustmentButtons
        onChangeOffer={onChangeOffer}
        onRemoveOffer={onRemoveOffer}
      />
)}
  </div>
);
