import * as React from 'react';
import {
    Col, Row, Typography,
} from '@revfluence/fresh';
import styles from '../../../OfferForm.scss';
import { OfferActiveDate, OfferEndDateCheckbox, OfferStatus } from '../FormElements';
import { IShopifyPromoCodeFormValues } from '../../../types';

const { Text } = Typography;
interface IProps {
    values: IShopifyPromoCodeFormValues,
    offerEndDate: boolean,
    dateErrorMsg: string,
    endDate: JSX.Element,
    setOfferEndDate: React.Dispatch<React.SetStateAction<boolean>>,
}

export const OldOfferActiveDate: React.FC<Readonly<IProps>> = React.memo((props) => {
    const {
 values, offerEndDate, dateErrorMsg, endDate, setOfferEndDate,
} = props;
    return (
      <>
        <OfferStatus disabled={false} name="status" />
        <div className={styles.CustomTextColor}>
          <Text>Active Date</Text>
        </div>
        <Row>
          <Col span={24}>
            <OfferActiveDate disabled={false} name="activeDate" endDate={values.endDate} />
          </Col>
        </Row>
        <OfferEndDateCheckbox disabled={false} setOfferEndDate={setOfferEndDate} checked={offerEndDate} name="isEndDateEnable" />
        {endDate}
        <span style={{ color: '#FF4D4F' }}>
          {dateErrorMsg}
        </span>
      </>
    );
});
OldOfferActiveDate.displayName = 'OldOfferActiveDate';
