import gql from 'graphql-tag';

export const GET_ALL_COMPLETED_MEMBERS_QUERY = gql`
  query GetAllCompletedMembersQuery($programId: Int!) {
    members: getAllCompletedMembers(programId: $programId) {
      data
      dateCompleted
    }
  }
`;
