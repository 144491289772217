import * as React from 'react';
import { isEmpty, first } from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { LoadingOutlined } from '@ant-design/icons';
import { TOfferMember, OfferMemberStatus } from './types';
import { OFFER_SOURCE } from '../../types/globalTypes';

import styles from './PendingMembers.scss';
import { GetOfferById_offer } from '../../queries/types/GetOfferById';

export enum PendingAction {
  Deactivating = 'Deactivating',
  Generating = 'Generating',
  Mixed = 'Working on',
  Refreshing = 'Refreshing',
  Deleting = 'Removing',
}

interface IProps {
  pendingMembers: ReadonlyArray<TOfferMember>;
  offerSource: OFFER_SOURCE;
  offer?: GetOfferById_offer;
  isDeletedAction: boolean;
}

export const getPendingAction = (members: ReadonlyArray<TOfferMember>): PendingAction => {
  if (isEmpty(members)) {
    return PendingAction.Mixed;
  }

  const memberStatus = first(members).status;
  for (let i = 1; i < members.length; i++) {
    if (members[i].status !== memberStatus) {
      return PendingAction.Mixed;
    }
  }
  switch (memberStatus) {
    case OfferMemberStatus.PENDING:
      return PendingAction.Generating;
    case OfferMemberStatus.PENDING_DELETION:
      return PendingAction.Deactivating;
    case OfferMemberStatus.PENDING_UPDATE:
      return PendingAction.Refreshing;
    case OfferMemberStatus.DELETING:
      return PendingAction.Deleting;
    default:
      return PendingAction.Mixed;
  }
};

  // Helper function to check if members are in a pending operation state
 export const isOperationPending = (members: ReadonlyArray<TOfferMember>): boolean => members.some((member) =>
      member.status === OfferMemberStatus.PENDING
      || member.status === OfferMemberStatus.PENDING_DELETION
      || member.status === OfferMemberStatus.PENDING_UPDATE
      || member.status === OfferMemberStatus.DELETING);

export const getPendingActionFromMembers = (members: ReadonlyArray<TOfferMember>): PendingAction => {
  if (isEmpty(members)) {
    return PendingAction.Mixed;
  }

  const memberStatus = first(members).status;
  for (let i = 1; i < members.length; i++) {
    if (members[i].status !== memberStatus) {
      return PendingAction.Mixed;
    }
  }

  switch (memberStatus) {
    case OfferMemberStatus.PENDING:
      return PendingAction.Generating;
    case OfferMemberStatus.PENDING_DELETION:
      return PendingAction.Deactivating;
    case OfferMemberStatus.PENDING_UPDATE:
      return PendingAction.Refreshing;
    case OfferMemberStatus.DELETING:
      return PendingAction.Deleting;
    default:
      return PendingAction.Mixed;
  }
};

export const PendingMembers: React.FC<Readonly<IProps>> = (props) => {
  const {
 pendingMembers, offerSource, offer, isDeletedAction,
} = props;

  const pendingLinks = React.useMemo((): boolean => {
    if (offer.isPromoLink) {
      if (offer.links.length && offer.promos.length) {
        return first(offer.promos).affiliates.length === first(offer.links).affiliates.length ? offer.links[0].affiliateStats.some((member) => member.linkStatus === 'Pending') : true;
      }
    }
    return false;
  }, [offer]);

  if (isEmpty(pendingMembers) && !pendingLinks) {
    return null;
  }

  let action = getPendingAction(pendingMembers);
  if (isDeletedAction && action === PendingAction.Deactivating) {
    action = PendingAction.Deleting;
  }
  const count = pendingMembers.length;
  const type = offerSource === OFFER_SOURCE.SHOPIFY ? 'codes' : 'links';
  return (
    <Notice
      type="info"
      className={styles.PendingMembers}
      message={
        pendingMembers.length ? (
          <>
            <LoadingOutlined className={styles.icon} />
            {action}
            {' '}
            {type}
            {' '}
            for
            {' '}
            {count.toLocaleString()}
            {' '}
            member
            {count === 1 ? '' : 's'}
            &hellip;
          </>
        ) : (
          pendingLinks && (
            <>
              <LoadingOutlined className={styles.icon} />
              Working on links for some members
              &hellip;
            </>
          )
        )
      }
    />
  );
};
