import { isNaN } from 'lodash';

import { endpoints, IPublisher } from '@components';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

interface IProps {
  apiEndpoint: string;
  publisherId?: number;
}

/**
 * Fetch other social accounts for the given publisher id
 */
export const useFetchPublisherSocialAccounts = (props: IProps) => {
  const {
    apiEndpoint,
    publisherId,
  } = props;

  const url = apiEndpoint && !isNaN(Number(publisherId))
    ? `${apiEndpoint}/${endpoints.publisherEndpoint}/${publisherId}`
    : '';

  const {
    isFetching,
    data,
    error,
  } = useBackendServerQuery<IPublisher>({
    fetchParams: {
      input: url,
    },
    useQueryParams: {
      options: {
        enabled: !!url,
      },
    },
  });

  return {
    socialAccounts: data?.social_accounts,
    isFetching,
    error,
  };
};
