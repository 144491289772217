import * as React from 'react';
import { Moment } from 'moment';
import {
  useMessagingContext,
} from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { isEmpty } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import {
  Checkbox,
  Button,
  DatePicker,
  Input,
} from '@revfluence/fresh';
import {
  searchFilterReactiveVars,
} from '@frontend/app/containers/MessagingApp/reativeVars/searchFilter';
import { useFetchMembers } from '@frontend/app/containers/MessagingApp/hooks/useFetchMembers';
import MultiSelection, { TApplication } from '@frontend/app/containers/MessagingApp/SearchFilter/MultiSelection';
import styles from '@frontend/app/containers/MessagingApp/SearchFilter/SearchFilter.scss';
import { useResourceContext } from '@frontend/app/context';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { emailRegex } from '@components';

const {
  useState,
} = React;
const { RangePicker } = DatePicker;

type TProps = {
  onFieldChange: (fieldName: string, value: string[]) => void;
  updateFullSearchText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
  onCancel: () => void;
  users: GetAllUsersQuery_users[];
  appNotificationThreads: TApplication[];
};

export type TSugguestMember = {
  label: string;
  value: string;
  id: string | number;
};

export const SearchFilterOverlay = React.memo((props: TProps) => {
  const {
    onFieldChange,
    updateFullSearchText,
    onCancel,
    onSearch,
    users,
    appNotificationThreads,
  } = props;
  const [senders, setSenders] = useState<TSugguestMember[]>([]);
  const [recipients, setRecipients] = useState<TSugguestMember[]>([]);
  const searchFilter = useReactiveVar(searchFilterReactiveVars);
  const { refetchMembers } = useFetchMembers();

  const {
    assigneeType,
  } = useMessagingContext();

  const {
    activeMessageResources,
  } = useResourceContext();

  const getActiveResources = (newValue: string) => activeMessageResources.map((resource) => {
      const userExternalDisplayId = resource.authProvider.userExternalDisplayId;
      return {
        label: resource.config.displayName,
        value: userExternalDisplayId,
        id: resource.id,
      };
    }).filter((resource) => resource.value.toLowerCase().includes(newValue.toLowerCase()));

  const handleSearch = (setSugguestedMembers: (members: TSugguestMember[]) => void) => async (newValue: string) => {
    const members = await refetchMembers(newValue);
    const resources = getActiveResources(newValue);
    if (isEmpty(members) && isEmpty(resources)) {
      if (newValue.startsWith('@') || emailRegex.test(newValue)) {
        setSugguestedMembers([{
          label: newValue,
          value: newValue.startsWith('@') ? newValue.slice(1) : newValue,
          id: newValue,
        }]);
      }
    } else {
      setSugguestedMembers(resources.concat(members));
    }
  };

  const onMessageTypeChange = (newValue: string[]) => {
    onFieldChange('selectedMessageTypes', newValue);
  };

  const onReadUnreadChange = (newValue: string[]) => {
    onFieldChange('readUnread', newValue);
  };

  const onDatesChange = (
    value: Moment | [Moment | null, Moment | null] | null,
  ) => {
    searchFilterReactiveVars({
      ...searchFilter,
      startDate: isEmpty(value) ? null : value[0],
      endDate: isEmpty(value) ? null : value[1],
    });
  };

  return (
    <div className={styles.searchFilterDropdown}>
      <div className={styles.searchFilterGrid}>
        {assigneeType !== 'appNotification' && (
          <>
            <MultiSelection
              title="From"
              name="selectedSenders"
              value={searchFilter.selectedSenders}
              onChange={onFieldChange}
              onSearch={handleSearch(setSenders)}
              options={senders}
              placeholder="Select name, email, or IG handle"
              optionLabelProp="value"
              filterOption={false}
            />
            <MultiSelection
              title="To"
              name="selectedRecipients"
              value={searchFilter.selectedRecipients}
              onChange={onFieldChange}
              onSearch={handleSearch(setRecipients)}
              options={recipients}
              placeholder="Select name, email, or IG handle"
              optionLabelProp="value"
              filterOption={false}
            />
          </>
        )}
        <MultiSelection
          title="Assigned to"
          name="selectedAssignees"
          value={searchFilter.selectedAssignees}
          onChange={onFieldChange}
          options={users}
          placeholder="Select team members"
          optionLabelProp="label"
          filterOption
        />
        <div>Subject</div>
        <Input
          value={searchFilter.fullSearch}
          onChange={updateFullSearchText}
        />
        {assigneeType === 'appNotification' && (
          <MultiSelection
            title="Thread"
            name="selectedThreads"
            value={searchFilter.selectedThreads}
            onChange={onFieldChange}
            options={appNotificationThreads}
            placeholder="Select thread type"
            optionLabelProp="label"
            filterOption
          />
        )}
        <div>Date</div>
        <RangePicker
          value={[searchFilter.startDate, searchFilter.endDate]}
          style={{ width: '300px' }}
          mode={['date', 'date']}
          format="MMM D, YYYY"
          onChange={onDatesChange}
        />
        {assigneeType !== 'appNotification' && (
          <>
            <div>Message Type</div>
            <Checkbox.Group
              value={searchFilter.selectedMessageTypes}
              onChange={onMessageTypeChange}
              options={['Email', 'IGDM']}
            />
          </>
        )}
        <>
          <div>Read/Unread</div>
          <Checkbox.Group
            value={searchFilter.readUnread}
            onChange={onReadUnreadChange}
            options={['Read', 'Unread']}
          />
        </>
      </div>
      <div className={styles.actionButtons}>
        <Button onClick={onCancel}>Clear</Button>
        <Button type="primary" onClick={onSearch}>Search</Button>
      </div>
    </div>
  );
});

SearchFilterOverlay.displayName = 'SearchFilterOverlay';
