import React from 'react';
import { useState } from 'react';
import {
 includes, map, reject, slice,
} from 'lodash';

import {
 useCommunitiesQuery, useGetAllProjectsQuery,
} from '@frontend/app/hooks';
import { GetKlaviyoSyncSettings_getSyncSettings_config } from '@frontend/app/queries/types/GetKlaviyoSyncSettings';
import { ColumnDef } from '@tanstack/react-table';
import { Switch } from '@frontend/shadcn/components/ui/switch';
import { TABLE_PAGE_SIZE } from '@frontend/app/constants';
import { GetAllProjectsQuery_projects } from '@frontend/app/queries/types/GetAllProjectsQuery';
import { GetCommunitiesQuery_communities } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';

export type ISyncSettings = Omit<GetKlaviyoSyncSettings_getSyncSettings_config, '__typename'>;
export const useGetTableData = ({
  startSync,
  config,
  updateSettingsMutation,
  isSettingsLoading,
}: {
  startSync: (projects: number[], groups: number[]) => void,
  config: ISyncSettings,
  updateSettingsMutation,
  isSettingsLoading: boolean,
}) => {
  const [currentGroupPage, setCurrentGroupPage] = useState(1);
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const {
    loading: isCommunitiesLoading,
      data: {
          communities = null,
      } = {},
  } = useCommunitiesQuery();

  const {
      data: {
      projects = undefined,
      } = {},
      loading: isProjectsLoading,
  } = useGetAllProjectsQuery({
  variables: {
    withSpecsOnly: true,
  },
  });
  if (isProjectsLoading || isCommunitiesLoading || isSettingsLoading) {
    return {
      loading: true,
      rows: null,
    };
  }
  const groupRows = _groupsToRows(communities, config);
  const projectRows = _projectsToRows(projects, config);
  const syncProject = (projectId: number) => {
    startSync([projectId], []);
  };
  const syncGroup = (groupId: number) => {
    startSync([], [groupId]);
  };
  const syncSelectedProjects = () => {
    startSync(selectedProjects, []);
  };
  const syncSelectedGroups = () => {
    startSync([], selectedGroups);
  };
  const addProject = (projectId: number) => {
    const projects = [
      ...config.projects,
      projectId,
    ];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects,
},
},
    });
  };
  const removeProject = (projectId: number) => {
    const projects = reject(config.projects, (id) => id === projectId);
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects,
},
},
    });
  };
  const addGroup = (groupId: number) => {
    const groups = [
      ...config.groups,
      groupId,
    ];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, projects: config.projects, groups,
},
},
    });
  };
  const removeGroup = (groupId: number) => {
    const groups = reject(config.groups, (id) => id === groupId);
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, projects: config.projects, groups,
},
},
    });
  };
  const toggleSelectAllGroups = (selectAll: boolean) => {
    const groups = selectAll ? map(communities, 'id') : [];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, projects: config.projects, groups,
},
},
    });
  };
  const toggleSelectAllProjects = (selectAll: boolean) => {
    const selectedProjects = selectAll ? map(projects, 'id') : [];
    updateSettingsMutation({
      variables: {
 config: {
 syncAllMembers: config.syncAllMembers, automatedSync: config.automatedSync, groups: config.groups, projects: selectedProjects,
},
},
    });
  };
  const syncSelectedItems = (type: string) => (type === 'projects' ? syncSelectedProjects() : syncSelectedGroups());
  return {
    selectedProjects,
    selectedGroups,
    syncSelectedItems,
    loading: false,
    groupRows: slice(groupRows, (currentGroupPage - 1) * TABLE_PAGE_SIZE, Math.min(currentGroupPage * TABLE_PAGE_SIZE, groupRows.length)),
    projectRows: slice(projectRows, (currentProjectPage - 1) * TABLE_PAGE_SIZE, Math.min(currentProjectPage * TABLE_PAGE_SIZE, projectRows.length)),
    totalProjectCount: projectRows.length,
    totalGroupCount: groupRows.length,
    currentGroupPage,
    setCurrentGroupPage,
    pageSize: TABLE_PAGE_SIZE,
    currentProjectPage,
    setCurrentProjectPage,
    projectColumnDef: getTableColumnDef({
      addToCollection: addProject,
      removeFromCollection: removeProject,
      addToChecked: (projectId: number) => setSelectedProjects([...selectedProjects, projectId]),
      removeFromChecked: (projectId: number) => setSelectedProjects(reject(selectedProjects, (id: number) => id === projectId)),
      toggleSelectAll: toggleSelectAllProjects,
      toggleCheckAll: (checked: boolean) => (checked ? setSelectedProjects(map(projects, 'id')) : setSelectedProjects([])),
      checkedItems: selectedProjects,
      allItemsChecked: selectedProjects.length === projects.length,
      allItemsSelected: config.projects.length === projects.length,
      syncSingleItem: syncProject,
      typeLabel: 'Project',
    }),
    groupColumnDef: getTableColumnDef({
      addToCollection: addGroup,
      removeFromCollection: removeGroup,
      addToChecked: (group: number) => setSelectedGroups([...selectedGroups, group]),
      removeFromChecked: (groupId: number) => setSelectedGroups(reject(selectedGroups, (id: number) => id === groupId)),
      toggleSelectAll: toggleSelectAllGroups,
      toggleCheckAll: (checked: boolean) => (checked ? setSelectedGroups(map(communities, 'id')) : setSelectedGroups([])),
      checkedItems: selectedGroups,
      allItemsChecked: selectedGroups.length === communities.length,
      allItemsSelected: config.groups.length === communities.length,
      syncSingleItem: syncGroup,
      typeLabel: 'Group',
    }),
  };
};

const _projectsToRows = (projects: GetAllProjectsQuery_projects[], config: ISyncSettings) => map(projects, (project) => ({
      name: project.title,
      id: project.id,
      keepSynced: includes(config.projects, project.id),
    }));

const _groupsToRows = (groups: GetCommunitiesQuery_communities[], config: ISyncSettings) => map(groups, (group) => ({
      name: group.title,
      id: group.id,
      keepSynced: includes(config.groups, group.id),
    }));

    const getTableColumnDef = ({
      addToCollection,
      removeFromCollection,
      addToChecked,
      removeFromChecked,
      toggleSelectAll,
      toggleCheckAll,
      checkedItems,
      allItemsSelected,
      allItemsChecked,
      syncSingleItem,
      typeLabel,
    }: {
      addToCollection: (id: number) => void;
      removeFromCollection: (id: number) => void;
      addToChecked: (id: number) => void;
      removeFromChecked: (id: number) => void;
      toggleSelectAll: (selected: boolean) => void;
      toggleCheckAll: (selected: boolean) => void;
      checkedItems: number[];
      allItemsSelected: boolean;
      allItemsChecked: boolean;
      syncSingleItem: (itemId: number) => void;
      typeLabel: string;
    }): ColumnDef<{ name: string, id: number, keepSynced: boolean }>[] => [
    {
      accessorKey: 'checked',
      header: () => <Checkbox checked={allItemsChecked} onCheckedChange={toggleCheckAll} />,
      enableSorting: false,
      cell: ({ row }) => {
        const id = row.original.id;
        const isChecked = checkedItems.includes(id);
        const checkChanged = (checkValue: boolean) => (checkValue ? addToChecked(id) : removeFromChecked(id));
        return (
          <div className="px-2"><Checkbox checked={isChecked} onCheckedChange={checkChanged} /></div>
        );
      },
      size: 40,
    },
    {
      accessorKey: 'name',
      header: () => <div className="font-semibold text-[#1F1F21]">{typeLabel}</div>,
      cell: ({ row }) => (
        <div className="flex gap-1 items-center px-2">
          {row.original.name}
        </div>
        ),
      size: 400,
    },
    {
      accessorKey: 'keepSynced',
      enableSorting: false,
      header: () => (
        <div className="flex gap-2">
          <Switch
            checked={allItemsSelected}
            onCheckedChange={toggleSelectAll}
          />
          <div className="font-semibold text-[#1F1F21] px-2">Keep Synced</div>
        </div>
),
      cell: ({ row }) => {
        const rowData = row.original;
        const isSyncing = rowData.keepSynced;
        const id = rowData.id;
        const switchChanged = (switchValue: boolean) => (switchValue ? addToCollection(id) : removeFromCollection(id));
        return (
          <div className="flex gap-1 items-center px-2">
            <Switch
              checked={isSyncing}
              onCheckedChange={switchChanged}
            />
          </div>
        );
      },
      size: 50,
    },
    {
      accessorKey: 'id',
      header: () => <div className="font-semibold text-[#1F1F21]">Actions</div>,
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex gap-1 items-center">
          <Button
            className="underline"
            variant="ghost"
            onClick={() => syncSingleItem(row.original.id)}
          >
            Sync
          </Button>
        </div>
        ),
      size: 50,
    },
  ];
