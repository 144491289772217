import gql from 'graphql-tag';

import { ORDER_FRAGMENT, ORDER_SHIPMENT_TRACKING_FRAGMENT } from './fragments';

export const GET_ORDERS_QUERY = gql`
  query GetOrdersQuery($limit: Int, $offset: Int) {
    orders: getOrders(limit: $limit, offset: $offset) {
      ...OrderFragment
      shipmentTracking {
        ...OrderShipmentTrackingFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
  ${ORDER_SHIPMENT_TRACKING_FRAGMENT}
`;

export const GET_ORDER_SUMMARY_QUERY = gql`
  query GetOrderSummaryQuery {
    summary: getOrderSummary
  }
`;

export const BULK_CREATE_ORDER_MUTATION = gql`
  mutation BulkCreateOrderMutation($resourceId: Float!, $params: [SaveOrderParams!]!) {
    result: bulkCreateOrder(resourceId: $resourceId, params: $params)
  }
`;

export const SYNC_ORDERS_MUTATION = gql`
  mutation SyncOrdersMutation {
    succeeded: syncOrders
  }
`;

export const GET_ORDER_BY_ID_QUERY = gql`
  query GetOrderById($id: String!) {
    order: orderById(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;
export const GET_ORDERS_BY_IDS_QUERY = gql`
  query GetOrdersByIds($ids: [String!]!) {
    orders: ordersByIds(ids: $ids) {
      ...OrderFragment
      workItemId
    }
  }
  ${ORDER_FRAGMENT}
`;

export const BULK_FULFILL_ORDER_MUTATION = gql`
  mutation BulkFulfillOrderMutation($params: IBulkFulfillOrder!) {
    result: bulkFulfillOrder(params: $params) {
      id
    }
  }
`;

export const GET_STATS_FOR_ALL_PROJECTS_QUERY = gql`
  query GetStatsForAllProjects($dashboardFilter: DashboardFilterInput) {
    stats: getStatsForAllProjects(dashboardFilter: $dashboardFilter) {
      projectName
      projectId
      projectImage
      userId
      userName
      totalInvited
      directGifting
      waitingCreator
      submitted
      accepted
      pending
      waitingFulfillment
      inTransit
      delivered
    }
  }
`;

export const GET_ORDERS_TYPE_BREAKDOWN_QUERY = gql`
  query GetOrdersTypeBreakdown($dashboardFilter: DashboardFilterInput) {
    breakdown: getOrdersTypeBreakdown(dashboardFilter: $dashboardFilter)
  }
`;

export const GET_ORDERS_COST_SUMMARY_QUERY = gql`
  query GetOrdersCostSummary($dashboardFilter: DashboardFilterInput) {
    costSummary: getOrdersCostSummary(dashboardFilter: $dashboardFilter) {
      totalOrderValue
      totalOrderCost
      averageOrderCost
      totalOrderCount
      totalProductUnits
      maxOrderValue
      minOrderValue
      orderIntervalGraphs {
        start
        end
        data
      }
      totalOrderValueTrend
      totalOrderCostTrend
      averageOrderCostTrend
    }
  }
`;

export const GET_TOP_PRODUCTS_QUERY = gql`
  query GetTopProducts($dashboardFilter: DashboardFilterInput, $resourceId: Int) {
    products: getTopProducts(dashboardFilter: $dashboardFilter, resourceId: $resourceId) {
      name
      count
      cost
      imgUrl
    }
  }
`;

export const GET_TOP_CREATORS_QUERY = gql`
  query GetTopCreators($dashboardFilter: DashboardFilterInput) {
    creators: getTopCreators(dashboardFilter: $dashboardFilter) {
      name
      count
      cost
      imgUrl
    }
  }
`;

export const GET_CREATOR_TYPE_ORDER_COST_QUERY = gql`
  query GetCreatorTypeOrderCost($dashboardFilter: DashboardFilterInput!) {
    creatorTypeOrderCost: getCreatorTypeOrderCost(dashboardFilter: $dashboardFilter) {
      creatorType
      orders
      spend
    }
  }
`;

export const GET_DEMOGRAPHIC_REGION_ORDERS_QUERY = gql`
  query GetDemographicRegionOrdersSummary($dashboardFilter: DashboardFilterInput!) {
    getDemographicRegionOrdersSummary(dashboardFilter: $dashboardFilter) {
      orderCountry
      orderProvince
      orderCountPerCountry
      totalAmountSpent
      averageOrderCost
    }
  }
`;

export const GET_CREATOR_ORDERS_SUMMARY_QUERY = gql`
  query GetCreatorOrdersSummary($dashboardFilter: DashboardFilterInput) {
    summary: getCreatorOrdersSummary(dashboardFilter: $dashboardFilter) {
      totalMembersCount
      totalMembersCountTrend
      avgOrdersPerMember
      avgOrdersPerMemberTrend
      avgCostPerMember
      avgCostPerMemberTrend
      avgProductCountPerMember
      avgProductCountPerMemberTrend
    }
  }
`;

export const GET_CATALOG_INSIGHTS_SUMMARY_QUERY = gql`
  query GetCatalogInsightsSummary($dashboardFilter: DashboardFilterInput) {
    summary: getCatalogInsightsSummary(dashboardFilter: $dashboardFilter) {
      totalCatalogs
      totalCollections
      totalProducts
      totalVariants
    }
  }
`;

export const GET_BRAND_CATALOG_DASHBOARD_STATS_QUERY = gql`
  query GetBrandCatalogDashboardStats($dashboardFilter: DashboardFilterInput) {
    catalogStats: getBrandCatalogDashboardStats(dashboardFilter: $dashboardFilter) {
      catalogId
      catalogName
      programNames
      sentToMembers
      ordersCreated
      totalSpent
      totalCollections
      totalProducts
      totalVariants
    }
  }
`;

export const GET_ORDER_SUMMARY_V2_QUERY = gql`
  query GetOrderSummaryV2($dashboardFilter: DashboardFilterInput!) {
    summary: getOrderSummaryV2(dashboardFilter: $dashboardFilter)
  }
`;

export const GET_ORDERS_FOR_DASHBOARD_QUERY = gql`
  query GetOrdersForDashboard($limit: Int, $offset: Int, $orderFilters: OrderFiltersInput!) {
    getOrdersForDashboard(limit: $limit, offset: $offset, orderFilters: $orderFilters) {
      orders {
        ...OrderFragment
        shipmentTracking {
          ...OrderShipmentTrackingFragment
        }
      }
      totalOrders
    }
  }
  ${ORDER_FRAGMENT}
  ${ORDER_SHIPMENT_TRACKING_FRAGMENT}
`;

export const GET_ORDERS_FOR_DASHBOARD_CSV_QUERY = gql`
  query GetOrdersForDashboardCsv($orderFilters: OrderFiltersInput!) {
    getOrdersForDashboardCsv(orderFilters: $orderFilters)
  }
`;
