import React from 'react';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { Link } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from '@/shadcn/components/ui/tooltip';
import { Button } from '@/shadcn/components/ui/button';
import { SecondaryNavProps } from '../../Layout';
import {
  MenuHeader,
  MenuHeaderLayout,
  MenuItemContent,
  MenuLayout,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuTitle,
} from '../menuLayout';

export const SecondaryProjectsNav = ({ onItemSelected, projects }: SecondaryNavProps) => (
  <MenuLayout>
    <MenuHeaderLayout>
      <MenuHeader>Projects</MenuHeader>
      <Button
        size="xs"
        variant="outline"
        className="ml-auto text-muted-foreground  hover:text-foreground group gap-1.5"
        onClick={onItemSelected}
        asChild
      >
        <Link to="/projects/new/templates">
          <PlusIcon className="h-3 w-3 font-semibold group-hover:text-foreground" />
          <span className="text-xs font-semibold group-hover:text-foreground">New</span>
        </Link>
      </Button>
    </MenuHeaderLayout>
    <TooltipProvider>
      <MenuList>
        {projects.map((project) => (
          <MenuListItem key={project.id}>
            <Tooltip>
              <TooltipTrigger asChild>
                <MenuLink onClick={onItemSelected} to={`/projects/${project.id}`}>
                  <Avatar className="h-9 w-9 flex rounded-md">
                    <AvatarImage src={project?.splashImageUrl} alt="Avatar" className="object-cover" />
                    <AvatarFallback className="rounded-md">{project?.title?.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <MenuItemContent>
                    <MenuTitle>{project?.title}</MenuTitle>
                  </MenuItemContent>
                </MenuLink>
              </TooltipTrigger>
              <TooltipContent className="z-[190]">{project?.title}</TooltipContent>
            </Tooltip>
          </MenuListItem>
        ))}
      </MenuList>
    </TooltipProvider>
  </MenuLayout>
);
