import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { isNull, isUndefined } from 'lodash';
import {
  Route, Switch, useRouteMatch, Redirect, useHistory, useLocation,
} from 'react-router-dom';

import { LoadSpinner } from '@components';

import { AffiliatesApp } from '@affiliates/components';
import { STASendItems, WorkflowWizard } from '@affiliates/containers';
import {
  useApolloClient,
  useCheckShopifyCredentials,
  useGetStaComposerMemberFields,
} from '@affiliates/hooks';
import {
  DashboardPage,
  EditOfferPage,
  NewOfferPage,
  OfferDetailsPage,
} from '@affiliates/pages';
import { OFFER_SOURCE, OFFER_TYPE } from '@affiliates/types/globalTypes';
import { logger } from '@common';
import { DateRangeOptions } from '@frontend/app/components/DateFilter';
import { IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import {
  useClientFeatureEnabled,
  useDateFilterSettings, useGetCurrentClient, useGetCurrentClientMetadata, useGetProfile,
} from '@frontend/app/hooks';
import { OfferConfirmation } from '@affiliates/containers';
import { OfferDetailsContextProvider } from '@frontend/context/OfferDetailsContext';
import { ClientFeature } from '@frontend/app/constants';
import { useGetClientAllFeatures } from '@frontend/app/hooks/useGetClientAllFeatures';
import { ClientFeatureProvider } from './contexts/ClientFeatureContext';

export enum STA_WORKFLOW_ACTION {
  GENERATE_LINK = 'generate_link',
  GENERATE_PROMO = 'generate_promo',
  FIX_PROMO = 'fix_promo',
  SEND_LINKS = 'send_links',
  SEND_PROMOS = 'send_promos',
  SEND_ITEMS = 'send_items',
}

interface IDeepLinkParameters {
  action?: STA_WORKFLOW_ACTION;
  [key: string]: unknown;
}

interface IProps {
  clientName: string;
  clientId: string;
  shopifyAppEnabled: boolean;
  shopifyAppId: string;
  modalView: boolean;
  deepLinkParameters: IDeepLinkParameters;
  workflowActionParameters: IWorkflowActionParameters;
  closeModal?(): void;
}

export const Main: React.FC<Readonly<IProps>> = ({
  clientId,
  clientName,
  shopifyAppEnabled,
  shopifyAppId,
  modalView,
  deepLinkParameters,
  workflowActionParameters,
  closeModal,
}) => {
  // these hooks do not use the sales tracking graphql client so they need to live here
  const enabledSources = {
    [OFFER_SOURCE.TUNE]: true,
    [OFFER_SOURCE.SHOPIFY]: shopifyAppEnabled,
  };
  const shopifyCredentials = useCheckShopifyCredentials(enabledSources);
  const staMemberFieldIds = useGetStaComposerMemberFields();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const apolloClient = useApolloClient();
  const dateRangeSettings = useDateFilterSettings(DateRangeOptions.LAST_6_MONTHS);
  const memberPortalFeatureFlagEnabled = useClientFeatureEnabled(ClientFeature.MEMBER_PORTAL);
  const clientFeatures = useGetClientAllFeatures(clientId);

  const { clientMetadata: { allowedDomains = {} } = {} } = useGetCurrentClientMetadata({ fetchPolicy: 'cache-first' });
  const { profile } = useGetProfile();
  const { client } = useGetCurrentClient();
  const userProfile = {
    clientId: profile.id,
    email: profile.email,
    name: profile.name,
  };
  const brandName = client?.name;
  const renderDashboard = (routeProps) => (
    <DashboardPage
      {...routeProps}
      baseUri={match.url}
      clientName={clientName}
      dateRangeSettings={dateRangeSettings}
      shopifyAppId={shopifyAppId}
      sources={enabledSources}
    />
  );
  const renderNewOfferForm = (source: OFFER_SOURCE) => {
    const NewOfferPageComponent = (routeProps) => (
      <NewOfferPage
        {...routeProps}
        baseUri={match.url}
        hasShopifyCredentials={!!shopifyCredentials}
        shopifyCredentials={shopifyCredentials}
        shopifyAppId={shopifyAppId}
        source={source}
        allowedDomains={allowedDomains}
        shopifyStoreName={shopifyCredentials?.shopifyStoreName}
      />
    );

    NewOfferPageComponent.displayName = 'NewOfferPageComponent';
    return NewOfferPageComponent;
  };

  const renderEditOfferForm = (routeProps) => (
    <EditOfferPage
      {...routeProps}
      shopifyCredentials={shopifyCredentials}
      baseUri={match.url}
      profile={userProfile}
      allowedDomains={allowedDomains}
    />
  );
  const renderOfferConfirmation = (routeProps) => (
    <OfferConfirmation
      {...routeProps}
      baseUri={match.url}
    />
  );
  const renderOfferDetails = (routeProps) => (
    <OfferDetailsPage
      {...routeProps}
      baseUri={match.url}
      dateRangeSettings={dateRangeSettings}
      shopifyAppId={shopifyAppId}
      staComposerMemberFieldIds={staMemberFieldIds}
      profile={userProfile}
      brandName={brandName}
    />
  );

  const dashboardPath = `${match.url}/dashboard`;
  const onClickDashboardLink = () => {
    history.push({ ...location, pathname: dashboardPath });
  };

  const renderModal = () => {
    switch (deepLinkParameters?.action) {
      case STA_WORKFLOW_ACTION.GENERATE_LINK:
        return (
          <WorkflowWizard
            offerSource={OFFER_TYPE.LINK}
            programId={workflowActionParameters.programId}
            membersIds={workflowActionParameters.memberIds}
            workItems={workflowActionParameters.workItems}
            onClose={closeModal}
            staWorkflowAction={deepLinkParameters?.action}
          />
        );
      case STA_WORKFLOW_ACTION.GENERATE_PROMO:
      case STA_WORKFLOW_ACTION.FIX_PROMO:
        return (
          <WorkflowWizard
            offerSource={OFFER_TYPE.PROMO_CODE}
            membersIds={workflowActionParameters.memberIds}
            programId={workflowActionParameters.programId}
            workItems={workflowActionParameters.workItems}
            onClose={closeModal}
            staWorkflowAction={deepLinkParameters?.action}
          />
        );
      case STA_WORKFLOW_ACTION.SEND_LINKS:
      case STA_WORKFLOW_ACTION.SEND_PROMOS:
      case STA_WORKFLOW_ACTION.SEND_ITEMS:
        return (
          <STASendItems
            workflowActionParameters={workflowActionParameters}
            closeModal={closeModal}
            programId={workflowActionParameters.programId}
            staComposerMemberFieldIds={staMemberFieldIds}
            clientName={clientName}
          />
        );

      default:
        logger.error(`Do not recognize workflow action: ${deepLinkParameters?.action}`);
        return null;
    }
  };

  if (isNull(enabledSources)
    || isNull(shopifyCredentials)
    || isNull(staMemberFieldIds)
    || isUndefined(memberPortalFeatureFlagEnabled)
  ) {
    return <LoadSpinner />;
  }

  if (modalView) {
    return (
      <ApolloProvider client={apolloClient}>
        <>{renderModal()}</>
      </ApolloProvider>
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <OfferDetailsContextProvider profile={profile}>
        <ClientFeatureProvider clientFeatures={clientFeatures}>
          <AffiliatesApp
            baseUri={dashboardPath}
            clientId={clientId}
            clientName={clientName}
            dateRangeSettings={dateRangeSettings}
            onClickDashboardLink={onClickDashboardLink}
          >
            <Switch>
              <Route path={`${match.path}/dashboard`} render={renderDashboard} />
              <Route path={`${match.path}/offers/:offerId/edit`} render={renderEditOfferForm} />
              {enabledSources[OFFER_SOURCE.TUNE] && (
                <Route path={`${match.path}/offers/newAffiliateLink`} render={renderNewOfferForm(OFFER_SOURCE.TUNE)} />
              )}
              {enabledSources[OFFER_SOURCE.SHOPIFY] && (
                <Route path={`${match.path}/offers/newShopifyPromo`} render={renderNewOfferForm(OFFER_SOURCE.SHOPIFY)} />
              )}
              <Route path={`${match.path}/offers/:offerId/details`} render={renderOfferDetails} />
              <Route path={`${match.path}/offers/:offerId/confirmation`} render={renderOfferConfirmation} />
              <Redirect from={match.path} to={{ ...location, pathname: `${match.path}/dashboard` }} />
            </Switch>
          </AffiliatesApp>
        </ClientFeatureProvider>
      </OfferDetailsContextProvider>
    </ApolloProvider>
  );
};

Main.displayName = 'AffiliateAppMain';
