import * as React from 'react';
import { first, isEmpty } from 'lodash';

import { AddMembers, DeactivateMembers, RefreshMembers } from '@affiliates/components';
import { TMember, TMode } from '@affiliates/components/MembersWizard/types';
import { useUpdateOfferMembers } from '@affiliates/hooks';
import { GetOfferById_offer } from '@affiliates/queries/types/GetOfferById';

import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { OFFER_PROMO_CODE_STATUS, OFFER_SOURCE } from '../../types/globalTypes';

interface IProps {
  offerSource: OFFER_SOURCE;
  mode: TMode;
  offer: GetOfferById_offer;
  onClose: () => void;
  onComplete: () => void;
  refreshDatesOnly?: boolean;
  selectedMembers: readonly TMember[];
  visible: boolean;
  failedPromoCodeErrors: OFFER_PROMO_CODE_STATUS[];
}

export const MembersWizard: React.FC<Readonly<IProps>> = (props) => {
  const {
    mode,
    offer,
    onClose,
    onComplete,
    refreshDatesOnly,
    selectedMembers,
    visible,
    offerSource,
  } = props;
  const promo = first(offer.promos);
  const link = first(offer.links);
  const {
    fetchMembers, fetchPrograms, onSave, fetchMembersCount,
  } = useUpdateOfferMembers(
    offer.id,
    mode,
    offerSource,
    onComplete,
    promo?.defaultPayoutId ?? link?.defaultPayoutId,
  );
  const { isOldOffer } = useOfferDetailsContext();
  if (!visible) {
    return null;
  }
  switch (mode) {
    case 'add': {
      if (offerSource === OFFER_SOURCE.TUNE && !isEmpty(offer.links)) {
        const link = first(offer.links);
        return (
          <AddMembers
            offerSource={offerSource}
            payoutType={link.payoutType}
            flatPayout={link.flatPayout}
            expirationDate={offer.expirationDate}
            percentPayout={link.percentPayout}
            offerLink={link.url}
            fetchMembers={fetchMembers}
            fetchMembersCount={fetchMembersCount}
            fetchPrograms={fetchPrograms}
            offerId={offer.id}
            offerName={offer.name}
            onClose={onClose}
            onSave={onSave}
            visible={visible}
            isWorkflow={false}
            isNewFlow={!isOldOffer}
            payouts={offer.payouts}
            defaultPayoutId={link.defaultPayoutId}
            domains={link.domains}
            isCreatorDeepLinkAllowed={link.creatorDeeplink}
          />
        );
      }
      if (offerSource === OFFER_SOURCE.SHOPIFY && !isEmpty(offer.promos)) {
        const promo = first(offer.promos);
        return (
          <AddMembers
            offerSource={offerSource}
            codeGenerationStrategy={promo.prefixType}
            discountAmount={promo.priceRuleAmount || 0}
            discountType={promo.priceRuleType}
            fetchMembers={fetchMembers}
            fetchMembersCount={fetchMembersCount}
            fetchPrograms={fetchPrograms}
            offerId={offer.id}
            offerName={offer.name}
            onClose={onClose}
            onSave={onSave}
            promoCodeSuffix={promo.codeSuffix || ''}
            promoCodePrefix={promo.codePrefix || ''}
            visible={visible}
            isWorkflow={false}
            isNewFlow={offer.isNewFlow}
            payoutType={promo.payoutType}
            flatPayout={promo.flatPayout}
            percentPayout={promo.percentPayout}
            startDate={promo.startDate}
            payouts={offer.payouts}
            defaultPayoutId={promo.defaultPayoutId}
            endDate={promo.endDate}
            isUngrouped={promo.isUngrouped}
          />
        );
      }
      break;
    }
    case 'refresh': {
      if (offerSource === OFFER_SOURCE.SHOPIFY && !isEmpty(offer.promos)) {
        const promo = first(offer.promos);
        return (
          <RefreshMembers
            offerSource={offerSource}
            discountAmount={promo.priceRuleAmount || 0}
            discountType={promo.priceRuleType}
            members={selectedMembers}
            offerId={offer.id}
            offerName={offer.name}
            onClose={onClose}
            onSave={onSave}
            refreshDatesOnly={refreshDatesOnly}
            visible={visible}
            isWorkflow={false}
            isNewFlow={offer.isNewFlow}
            payoutType={promo.payoutType}
            flatPayout={promo.flatPayout}
            percentPayout={promo.percentPayout}
            startDate={promo.startDate}
            endDate={promo.endDate}
            offerStartDate={promo.startDate}
            offerEndDate={promo.endDate}
            failedPromoCodeErrors={props.failedPromoCodeErrors}
          />
        );
      }
      if (offerSource === OFFER_SOURCE.TUNE && !isEmpty(offer.links)) {
        const link = first(offer.links);
        return (
          <RefreshMembers
            offerSource={offerSource}
            members={selectedMembers}
            offerId={offer.id}
            offer={offer}
            offerName={offer.name}
            onClose={onClose}
            onSave={onSave}
            visible={visible}
            payoutType={link.payoutType}
            flatPayout={link.flatPayout}
            refreshDatesOnly={refreshDatesOnly}
            percentPayout={link.percentPayout}
            isWorkflow={false}
            isNewFlow={!isOldOffer}
          />
        );
      }
      break;
    }
    case 'deactivate': {
      if (offerSource === OFFER_SOURCE.TUNE && !isEmpty(offer.links)) {
        const link = first(offer.links);
        return (
          <DeactivateMembers
            offerSource={offerSource}
            members={selectedMembers}
            offerId={offer.id}
            offer={offer}
            offerName={offer.name}
            onClose={onClose}
            onSave={onSave}
            visible={visible}
            payoutType={link.payoutType}
            flatPayout={link.flatPayout}
            percentPayout={link.percentPayout}
            isNewFlow={!isOldOffer}
          />
        );
      }
      if (offerSource === OFFER_SOURCE.SHOPIFY && !isEmpty(offer.promos)) {
        const promo = first(offer.promos);
        return (
          <DeactivateMembers
            offerSource={offerSource}
            discountAmount={promo.priceRuleAmount || 0}
            discountType={promo.priceRuleType}
            members={selectedMembers}
            offerId={offer.id}
            offerName={offer.name}
            onClose={onClose}
            onSave={onSave}
            visible={visible}
            isNewFlow={offer.isNewFlow}
            payoutType={promo.payoutType}
            flatPayout={promo.flatPayout}
            percentPayout={promo.percentPayout}
            startDate={promo.startDate}
            endDate={promo.endDate}

          />
        );
      }
    }
  }
  return null;
};
