import gql from 'graphql-tag';

export const UPDATE_OFFER_MEMBERS_MUTATION = gql`
  mutation AppendMembers(
    $id: Int!,
    $startDate:DateTime,
    $endDate:DateTime,
    $affiliates:[AffiliateOfferPromoInput!]!
  ) {
    appendAffiliatesForPromoOffer(
      offerId:$id,
      startDate:$startDate,
      endDate:$endDate,
      affiliates:$affiliates,
    ) {
      id
    }
  }
`;

export const UPDATE_LINKS_MEMBERS_MUTATION = gql`
  mutation AppendLinksToMembers(
    $id: Int!,
    $affiliates:[AffiliateOfferLinkInput!]!
  ) {
    createBulkAffiliateOfferLinkAsync(
      offerId:$id,
      affiliates:$affiliates,
    ) {
      id
    }
  }
`;

export const REMOVE_OFFER_MEMBERS_MUTATION = gql`
  mutation RemoveMembers($id: Int!, $affiliates: [AffiliateOfferPromoDeleteInput!]!, $removeAffiliates: Boolean) {
    removeAffiliatesForPromoOffer(offerId: $id, affiliates: $affiliates, removeAffiliates: $removeAffiliates) {
      id
    }
  }
`;
