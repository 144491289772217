import * as React from 'react';
import { ConfigProvider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Form, IFormProps as FormProps, Tooltip } from '@revfluence/fresh';

import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { ShopifyInstructions } from '../ShopifyInstructions/ShopifyInstructions';
import { TuneInstructions } from '../TuneInstructions/TuneInstructions';

import {
  AdjustExpirationDateButton,
  CreateButton,
  DeleteButton,
  UndeleteButton,
  UpdateButton,
} from './FormButtons';
import {
  AffiliateLinkSection,
  CommonSection,
} from './FormSections';
import { ShopifyPromoCodeSection } from './FormSections/ShopifyPromoCodeSection';
import { ShopifyPromoCodeSectionNew } from './FormSections/ShopifyPromoCodeSectionNew';

import {
  IAffiliateLinksFormValues,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  TDisabledMap,
  TFormValues,
  TFormOptionData,
  FormAsyncAction,
} from './types';

import styles from './OfferForm.scss';
import { GetConnectedShopify_clientConnections } from '../../queries/types/GetConnectedShopify';

const { useRef } = React;

interface IProps {
  disabledFields: TDisabledMap<TFormValues>;
  formRef: FormInstance;
  hasAffiliateLink: boolean;
  initialValues: TFormValues;
  isDeleted: boolean;
  isExpired: boolean;
  isSaving: boolean;
  mode: OfferFormModes;
  onDelete: () => void;
  onFieldFocused: (type: string, selected: boolean) => void;
  onFinish: () => void;
  onUnDelete: () => void;
  onValuesChange: FormProps['onValuesChange'];
  values: TFormValues;
  requiredFields: React.ReactNode;
  formOptionData?: TFormOptionData;
  hasMembers?: boolean
  isNewFlow: boolean;
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
  isMigrationEnabled?: boolean;
  isSubscriptionEnable?: boolean;
  handleFormAsyncActions?: (value: FormAsyncAction) => void,
  isReadOnly?: boolean,
  connectedClients?: GetConnectedShopify_clientConnections[],
  isOfferArchived?: boolean;
  initialAllowedDomains?: string[];
  shopifyStoreName?: string;
}

export const OfferForm: React.FC<Readonly<IProps>> = ({
  disabledFields,
  formRef,
  hasAffiliateLink,
  initialValues,
  isDeleted,
  isExpired,
  isSaving,
  mode,
  onDelete,
  onFieldFocused,
  onFinish,
  onUnDelete,
  onValuesChange,
  requiredFields,
  values,
  formOptionData,
  connectedAdvertiserForSync,
  isMigrationEnabled,
  isSubscriptionEnable,
  handleFormAsyncActions,
  isReadOnly,
  hasMembers,
  isNewFlow,
  connectedClients,
  isOfferArchived,
  initialAllowedDomains,
  shopifyStoreName,
}) => {
  const triggerRef = useRef();
  let instructions;
  switch (values.source) {
    case OFFER_SOURCE.SHOPIFY:
      instructions = <ShopifyInstructions />;
      break;
    case OFFER_SOURCE.TUNE:
      instructions = <TuneInstructions />;
      break;
  }
  return (
    <div className={styles.OfferForm} ref={triggerRef}>
      <ConfigProvider getPopupContainer={() => triggerRef.current}>
        <Form
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          form={formRef}
          initialValues={initialValues}
          labelCol={{ span: 24 }}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          requiredMark={false}
          size="small"
        >
          <CommonSection
            instructions={instructions}
            disabled={disabledFields}
            onFieldFocused={onFieldFocused}
            showCostPerClick={values.source === OFFER_SOURCE.TUNE}
            values={values}
            isNewFlow={isNewFlow}
            mode={mode}
            formRef={formRef}
            handleFormAsyncActions={handleFormAsyncActions}
            initialValues={initialValues}
            hasMembers={hasMembers}
          />
          {values.source === OFFER_SOURCE.TUNE && (
            <AffiliateLinkSection
              disabled={disabledFields as TDisabledMap<IAffiliateLinksFormValues>}
              onFieldFocused={onFieldFocused}
              showStatus={!isDeleted}
              values={values}
              initialUrl={(initialValues as IAffiliateLinksFormValues).url}
              utmFields={(initialValues as IAffiliateLinksFormValues).utmFields}
              hasMembers={hasAffiliateLink}
              handleFormAsyncActions={handleFormAsyncActions}
              initialAllowedDomains={initialAllowedDomains}
            />
          )}
          {values.source === OFFER_SOURCE.SHOPIFY && (
            isNewFlow ? (
              <ShopifyPromoCodeSectionNew
                disabled={disabledFields as TDisabledMap<IShopifyPromoCodeFormValues>}
                onFieldFocused={onFieldFocused}
                showStatus={!isDeleted}
                values={values}
                formOptionData={formOptionData}
                isMigrationEnabled={isMigrationEnabled}
                isSubscriptionEnable={isSubscriptionEnable}
                isNewFlow={isNewFlow}
                connectedAdvertiserForSync={connectedAdvertiserForSync}
                handleFormAsyncActions={handleFormAsyncActions}
                hasMembers={hasAffiliateLink}
                mode={mode}
                formRef={formRef}
                connectedClients={connectedClients}
                shopifyStoreName={shopifyStoreName}
                isOfferPromoLink={(initialValues as IShopifyPromoCodeFormValues).isPromoLink}
              />
            ) : (
              <ShopifyPromoCodeSection
                disabled={disabledFields as TDisabledMap<IShopifyPromoCodeFormValues>}
                onFieldFocused={onFieldFocused}
                showStatus={!isDeleted}
                values={values}
                formOptionData={formOptionData}
              />
            )
          )}
          {mode === OfferFormModes.Create
            && (
              <>
                <CreateButton
                  disabled={!!requiredFields || isSaving}
                  loading={isSaving}
                  requiredFields={requiredFields}
                />
              </>
            )}
          {isExpired && <AdjustExpirationDateButton />}
          {mode === OfferFormModes.Edit && isDeleted
            && (
              <UndeleteButton
                disabled={isSaving || isExpired}
                loading={isSaving}
                onClick={onUnDelete}
              />
            )}
          {mode === OfferFormModes.Edit && !isDeleted && !isOfferArchived
            && (
              <>
                <Tooltip title={isReadOnly ? 'Cannot update offer that is read-only.' : ''}>
                  {' '}
                  <UpdateButton
                    disabled={!!requiredFields || isSaving || isExpired || isReadOnly}
                    loading={isSaving}
                    requiredFields={!isReadOnly && requiredFields}
                  />
                  {' '}

                </Tooltip>
              </>
            )}
          {mode === OfferFormModes.Edit && !isDeleted && !hasAffiliateLink && !isOfferArchived
            && (
              <DeleteButton
                disabled={isSaving}
                loading={isSaving}
                onClick={onDelete}
              />
            )}
        </Form>
      </ConfigProvider>
    </div>
  );
};
