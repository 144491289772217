import {
  Col, Collapse, Empty, Row, Tooltip, Typography,
} from '@revfluence/fresh';
import React, { memo, useMemo } from 'react';
import {
  ITableColumnConfig, Table,
} from '@components';
import { Space } from 'antd';
import { ChevronUpIcon, ChevronDownIcon } from '@revfluence/fresh-icons/solid/esm';
import { map } from 'lodash';
import getDarkHexCodeFromString from '@frontend/app/utils/getDeteministicColor';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { GetMultipleShopifyStats_storeStats } from '../../queries/types/GetMultipleShopifyStats';
import styles from './PayoutSummaryCard.scss';
import { IStatisticsItem } from '../StatisticsBlock/types';
import { StatisticsBlock } from '../StatisticsBlock';
import { useClientFeature } from '../../contexts/ClientFeatureContext';

const { Text, Link } = Typography;
const { Panel } = Collapse;
interface IProps {
  source: OFFER_SOURCE,
  blocks: IStatisticsItem[],
  storeStats: GetMultipleShopifyStats_storeStats[],
  isMemberSummary: boolean;
}

type TColumnProps = {
  value: number;
  baseValue: number;
  currency: string;
};
const arrangBlocks = (inputArray: IStatisticsItem[]): IStatisticsItem[] => {
  const orderArray: string[] = ['Members', 'Conversions', 'Clicks', 'Sales', 'Avg. Sales', 'Commission Earned', 'Payout Due'];
  const orderMap = {};
  orderArray.forEach((title, index) => {
    orderMap[title] = index;
  });
  const sortedArray = inputArray.slice().sort((a, b) => {
    const indexA = orderMap[a.title];
    const indexB = orderMap[b.title];
    if (indexA === undefined && indexB === undefined) {
      return 0;
    } else if (indexA === undefined) {
      return 1;
    } else if (indexB === undefined) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });
  return sortedArray;
};
export const PayoutSummaryCard: React.FC<Readonly<IProps>> = memo((props) => {
  const {
    source, blocks, storeStats,
  } = props;
  const { enableMultipleShopify: isEnabledMultipleShopify } = useClientFeature();
  const data = useMemo(() => map(storeStats, (store) => {
    const commonFields = {
      store: store.storeName,
      _raw: store,
      conversion: store.conversions,
      id: String(store.clientShopifyConnectionId),
      sales: {
        value: store.sales,
        baseValue: store.salesBase,
        currency: store.currency,
      },
      avgSales: {
        value: store.avgSale,
        baseValue: store.avgSaleBase,
        currency: store.currency,
      },
      commissionEarned: {
        value: store.payoutEarned,
        baseValue: store.payoutEarnedBase,
        currency: store.currency,
      },
    };
    return {
      ...commonFields,
    };
  }), [storeStats]);
  const columns: ITableColumnConfig[] = [
    {
      headerName: 'Store',
      field: 'store',
      width: 160,
      cellClassName: styles.cellItemContainer,
      render: (name) => (
        <Row className={styles.cellItemStore}>
          <Space
            style={{
              height: '8px',
              width: '8px',
              backgroundColor: getDarkHexCodeFromString(`${name}storeName`),
              borderRadius: '4px',
            }}
          >
            {' '}
          </Space>
          <Tooltip title={name}>
            <Text>
              {name.length > 10 ? `${name.substring(0, 10)}...` : name}
            </Text>
          </Tooltip>
        </Row>
      ),
    },
    {
      headerName: 'Conversions',
      field: 'conversion',
      cellClassName: styles.cellItemContainer,
    },
    {
      headerName: 'Clicks',
      field: 'clicks',
      cellClassName: styles.cellItemContainer,
    },
    {
      headerName: 'Sales',
      field: 'sales',
      cellClassName: styles.cellItemContainer,
      render: (sales: TColumnProps) => (
        <Row className={styles.cellItemStore}>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Text>
              $
              {sales.value}
            </Text>
            {sales.currency !== 'USD' && (
              <Text style={{ color: '#167CF4' }}>
                {`${sales.baseValue} ${sales.currency}`}
              </Text>
            )}
          </Space>
        </Row>
      ),
    },
    {
      headerName: 'Avg. Sales',
      field: 'avgSales',
      cellClassName: styles.cellItemContainer,
      render: (avgSales: TColumnProps) => (
        <Row className={styles.cellItemStore}>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Text>
              $
              {avgSales.value}
            </Text>
            {avgSales.currency !== 'USD' && (
              <Text style={{ color: '#167CF4' }}>
                {`${avgSales.baseValue} ${avgSales.currency}`}
              </Text>
            )}
          </Space>
        </Row>
      ),
    },
    {
      headerName: 'Commission Earned ',
      field: 'commissionEarned',
      cellClassName: styles.cellItemContainer,
      render: (commissionEarned: TColumnProps) => (
        <Row className={styles.cellItemStore}>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Text>
              $
              {commissionEarned.value}
            </Text>
            {commissionEarned.currency !== 'USD' && (
              <Text style={{ color: '#167CF4' }}>
                {`${commissionEarned.baseValue} ${commissionEarned.currency}`}
              </Text>
            )}
          </Space>
        </Row>
      ),
    },
  ];

  const arrangedBlocks: IStatisticsItem[] = arrangBlocks(blocks);
  return (
    <Col span={24} className={styles.payoutSummaryCardContainer}>
      <Row className={styles.payoutSummaryCardStats}>
        <Col span={24}>
          <StatisticsBlock statisticsItem={arrangedBlocks} />
        </Col>
      </Row>
      {
        isEnabledMultipleShopify && storeStats?.length && source === OFFER_SOURCE.SHOPIFY
          ? (
            <Row className={styles.multiStoreConversionContainer}>
              <Col span={24}>
                <Collapse
                  expandIconPosition="start"
                  bordered={false}
                  className={styles.collapseContainer}
                  style={{ padding: '0px' }}
                  expandIcon={(isActive) => (isActive ? <ChevronDownIcon color="#167CF4" /> : <ChevronUpIcon color="#167CF4" />)}
                >
                  <Panel header={<Link>Store Wise Data Conversion</Link>} key="1" style={{ padding: '0px' }}>
                    <div className={styles.tableWrapper}>
                      <Table
                        data={data}
                        columns={columns}
                        className={styles.collapseContainerTable}
                        config={{
                          configurableColumns: false,
                          resizableColumns: true,
                          reorderableColumns: true,
                          selectable: false,
                          striped: false,
                          allowSearch: false,
                          rowBorder: true,
                          pageSize: null,
                        }}
                        renderEmptyPlaceholder={() => <Empty description="No conversions yet" />}
                      />
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          ) : null
      }
    </Col>
  );
});
