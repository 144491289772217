// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import {
 InstagramIcon, PinterestIcon, TiktokIcon, YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';
import { Tooltip, Typography } from '@revfluence/fresh';
import { get, includes, map } from 'lodash';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useBackendServerFetch } from '@frontend/app/clients/backendServerClient';
import { logger } from '@common';
import { formatNumberToSuffixString, generateLinkFromUsername } from '../GcrUtils';
import styles from './SocialMetrics.scss';
import { fetchSocialDataForMember } from './useFetchSocialDataForMember';

const { Text } = Typography;

const SocialNetworks = ({ memberRecord, client }) => {
  const [youtubeSocialProfile, setYoutubeSocialProfile] = useState(null);
  const { backendServerFetchResponse } = useBackendServerFetch();
  useEffect(() => {
    if (memberRecord.metaFields.youtube) {
      fetchSocialDataForMember({
        url: `${backendServerApiEndpoint()}/social_account`,
        clientId: client.id,
        username: memberRecord.metaFields.youtube,
        network: 'youtube',
        include_social_profile_data: true,
        backendServerFetchResponse,
      })
        .then((response) => {
          setYoutubeSocialProfile(response.data);
        })
        .catch((err) => {
          logger.error(err);
        });
    }
  }, [memberRecord, client, backendServerFetchResponse]);

  const socialNetworks = [
    {
      name: 'instagram',
      icon: <InstagramIcon />,
      followers: 'instagram_followers',
    },
    {
      name: 'tiktok',
      icon: <TiktokIcon />,
      followers: 'tiktok_followers',
    },
    {
      name: 'youtube',
      icon: <YoutubeIcon />,
      followers: 'youtube_subscribers',
    },
    {
      name: 'pinterest',
      icon: <PinterestIcon />,
      followers: 'pinterest_followers',
    },
  ];
  return (
    <div className={styles.socialNetworks}>
      {map(socialNetworks, (network) => {
        const recordName = get(memberRecord.metaFields, network.name);
        const recordFollower = get(memberRecord.metaFields, network.followers);
        if (recordName) {
          if (network.name === 'youtube') {
            return (
              <Tooltip title={`Open ${network.name} account`}>
                <div>
                  <a href={youtubeSocialProfile?.[0]?.link} target="_blank" key={network.name}>
                    {network.icon}
                    <Text className={styles.name}>{youtubeSocialProfile?.[0]?.name}</Text>
                    <Text className={styles.followers}>
                      {recordFollower ? formatNumberToSuffixString(recordFollower) : '-'}
                    </Text>
                  </a>
                </div>
              </Tooltip>
            );
          }
          const modifiedName = includes(['instagram', 'tiktok'], network.name) ? `@${recordName}` : recordName;
          return (
            <Tooltip title={`Open ${network.name} account`}>
              <div>
                <a
                  href={generateLinkFromUsername(
                    network.name,
                    network.name === 'tiktok' ? `@${recordName}` : recordName,
                  )}
                  target="_blank"
                  key={network.name}
                >
                  {network.icon}
                  <Text className={styles.name}>{modifiedName}</Text>
                  <Text className={styles.followers}>
                    {recordFollower ? formatNumberToSuffixString(recordFollower) : '-'}
                  </Text>
                </a>
              </div>
            </Tooltip>
          );
        }
        return null;
      })}
    </div>
  );
};

export default SocialNetworks;
