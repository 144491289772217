import {
  RoasCard_CurrentIntervalMetaSumInsightsFragment,
  RoasCard_PreviousIntervalMetaSumInsightsFragment,
  RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment,
} from '@frontend/gql/social/graphql';

export const calculateRoasMetrics = ({
  currentInterval,
  previousInterval,
  currentIntervalNotAspireSourced,
}: {
  currentInterval: RoasCard_CurrentIntervalMetaSumInsightsFragment | null | undefined;
  previousInterval: RoasCard_PreviousIntervalMetaSumInsightsFragment | null | undefined;
  currentIntervalNotAspireSourced: RoasCard_CurrentIntervalNotAspireSourcedMetaSumInsightsFragment | null | undefined;
}): {
  formattedCurrentIntervalRoas: string | number | null | undefined;
  formattedCurrentIntervalNotAspireSourcedRoas: string | number | null | undefined;
  currentIntervalRoas: number | null | undefined;
  currentIntervalNotAspireSourcedRoas: number | null | undefined;
  percentChange: number | 'NA';
} => {
  const currentIntervalRoas = currentInterval?.totalRoas;
  const previousIntervalRoas = previousInterval?.totalRoas;
  const currentIntervalNotAspireSourcedRoas = currentIntervalNotAspireSourced?.totalRoas;

  // calculate the percent change from previous month
  const percentChange = (currentIntervalRoas
      && previousIntervalRoas
      && ((currentIntervalRoas - previousIntervalRoas) / previousIntervalRoas) * 100)
    || 'NA';

  const formattedCurrentIntervalRoas = currentIntervalRoas && `${currentIntervalRoas.toFixed(1)}x`;
  const formattedCurrentIntervalNotAspireSourcedRoas = currentIntervalNotAspireSourcedRoas && `${currentIntervalNotAspireSourcedRoas.toFixed(1)}x`;
  return {
    formattedCurrentIntervalRoas,
    formattedCurrentIntervalNotAspireSourcedRoas,
    currentIntervalRoas,
    currentIntervalNotAspireSourcedRoas,
    percentChange,
  };
};
