import * as React from 'react';
import * as qs from 'qs';
const omitDeep = require('omit-deep-lodash');

const { useEffect, useRef, useState } = React;

const fetchAnalyzeData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export function useFetchSummaryData<T extends any = any>(
  url: string,
  showCostMetrics: boolean,
  filters?: any,
  skip: boolean = false,
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);
  const loadingUrl = useRef<string>('');

  url = `${url}?${filtersToQueryString(filters)}`;

  useEffect(() => {
    if (skip) return;
    setLoading(true);
    loadingUrl.current = url;
    fetchAnalyzeData(url)
      .then((data) => {
        // check if the URL has changed
        if (url !== loadingUrl.current) {
          return;
        }
        if (!showCostMetrics) {
          data = omitDeep(data, 'roi_pct');
          data = omitDeep(data, 'impression_cpm');
          data = omitDeep(data, 'cpm');
        }
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        // check if the URL has changed
        if (url !== loadingUrl.current) {
          return;
        }
        setError(err);
        setLoading(false);
      });
  }, [url, showCostMetrics, skip]);

  return {
    loading,
    data,
    error,
  };
}
export function useFetchSummaryDataCompare<T>(
  isComparing: boolean,
  url: string,
  showCostMetrics: boolean,
  filters?: any,
  skip: boolean = false,
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);
  const loadingUrl = useRef<string>('');
  // const comparableFilters = omit(filters, ['meta']);

  url = `${url}?${filtersToQueryString(filters)}`;

  useEffect(() => {
    if (skip) return;
    if (isComparing) {
      setLoading(true);
      loadingUrl.current = url;
      fetchAnalyzeData(url)
        .then((data) => {
          // check if the URL has changed
          if (url !== loadingUrl.current) {
            return;
          }
          if (!showCostMetrics) {
            data = omitDeep(data, 'roi_pct');
            data = omitDeep(data, 'impression_cpm');
            data = omitDeep(data, 'cpm');
          }
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          // check if the URL has changed
          if (url !== loadingUrl.current) {
            return;
          }
          setError(err);
          setLoading(false);
        });
    }
  }, [url, isComparing, showCostMetrics, skip]);

  return {
    loading,
    data,
    error,
  };
}

export function useFetchBreakdownData<T>(
  url: string,
  parameters: any,
  showCostMetrics: boolean,
  filters?: any,
  contentFieldsEnabled?: boolean
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);
  const loadingUrl = useRef<string>('');

  url = `${url}?group=${parameters.groupBy}&${filtersToQueryString(filters, contentFieldsEnabled)}`;

  useEffect(() => {
    setLoading(true);
    loadingUrl.current = url;
    fetchAnalyzeData(url)
      .then((data) => {
        // check if the URL has changed
        if (url !== loadingUrl.current) {
          return;
        }
        if (!showCostMetrics) {
          data = omitDeep(data, 'roi_pct');
          data = omitDeep(data, 'impression_cpm');
          data = omitDeep(data, 'cpm');
        }
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        // check if the URL has changed
        if (url !== loadingUrl.current) {
          return;
        }
        setError(err);
        setLoading(false);
      });
  }, [url, filters, showCostMetrics]);

  return {
    loading,
    data,
    error,
  };
}

export function filtersToQueryString(filters: any, contentFieldsEnabled?: boolean) {
  let filtersForQuery = {
    networks: networksFromFilters(filters),
    brand_ids: brandsFromFilters(filters),
    campaign_ids: campaignsFromFilters(filters),
    country_codes: countriesFromFilters(filters),
    creator_ids: creatorsFromFilters(filters),
    owners: ownersFromFilters(filters),
    payment: paymentFromFilters(filters),
    product_cost: productCostFromFilters(filters),
    total_cost: totalCostFromFilters(filters),
    organization_id: filters.organizationId,
    activation_ids: activationsFromFilters(filters),
    community_ids: communitiesFromFilters(filters),
    program_ids: programsFromFilters(filters),
    client_id: filters.clientId,
    content_fields_enabled: contentFieldsEnabled,
    sources: sourcesFromFilters(filters),
    manual_assignment: manualAssignmentFromFilters(filters),
    insight_start_date: undefined,
    insight_end_date: undefined,
    post_start_date: undefined,
    post_end_date: undefined,
  };
  if (filters.isInsight) {
    filtersForQuery.insight_start_date = startDateFromFilters(filters);
    filtersForQuery.insight_end_date = endDateFromFilters(filters);
  } else {
    filtersForQuery.post_start_date = startDateFromFilters(filters);
    filtersForQuery.post_end_date = endDateFromFilters(filters);
  }
  return qs.stringify(filtersForQuery);
}

function networksFromFilters(filters: any) {
  if (!filters.networks) {
    return [];
  }
  return Object.keys(filters.networks.networks)
    .filter((key) => filters.networks.networks[key])
    .join(',');
}

function sourcesFromFilters(filters: any) {
  if (!filters.sources) {
    return [];
  }
  return Object.keys(filters.sources.sources)
    .filter((key) => filters.sources.sources[key])
    .join(',');
}

function manualAssignmentFromFilters(filters: any) {
  if (!filters.assignments) {
    return undefined;
  }

  return filters.assignments?.selected > 0 ? true : undefined;
}

function campaignsFromFilters(filters: any) {
  let campaignIds = [];
  if (!filters.brands) {
    return [];
  }

  for (const brand of filters.brands) {
    if (brand.campaigns) {
      const selectedCampaigns = brand.campaigns.filter((value) => value.selected);
      const selectedCampaignIds = selectedCampaigns.map((value) => value.id);
      campaignIds = campaignIds.concat(selectedCampaignIds);
    }
  }
  return campaignIds.join(',');
}

function brandsFromFilters(filters: any) {
  if (!filters.brands) {
    return [];
  }

  const selectedBrandIds = filters.brands
    .filter((brand) => brand.selected)
    .map((brand) => brand.id)
    .join(',');
  return selectedBrandIds;
}

function startDateFromFilters(filters: any) {
  if (!filters.datePosted) {
    return null;
  }
  const startDate = filters.datePosted.date.start;
  if (startDate) {
    return formattedStringFromDate(startDate);
  }
  return null;
}

function endDateFromFilters(filters: any) {
  if (!filters.datePosted) {
    return null;
  }
  const endDate = filters.datePosted.date.end;
  if (endDate) {
    return formattedStringFromDate(endDate);
  }
  return null;
}

function formattedStringFromDate(date: Date) {
  return (
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  );
}

function countriesFromFilters(filters: any) {
  if (!filters.countries) {
    return [];
  }
  const countries = filters.countries.countries;
  return Object.keys(countries)
    .filter((key) => countries[key].selected)
    .join(',');
}

function creatorsFromFilters(filters: any) {
  if (!filters.creators) {
    return [];
  }
  const creators = filters.creators.creators;
  return Object.keys(creators)
    .filter((key) => creators[key].selected)
    .join(',');
}

function ownersFromFilters(filters: any) {
  if (!filters.owners) {
    return [];
  }
  const owners = filters.owners.owners;
  const selectedOwners = owners.filter((value) => value.selected);
  return selectedOwners
    .map((value) => value.email)
    .join(',');
}

const paymentValuesToApiOperator = {
  lessThan: 'lt',
  greaterThan: 'gt',
  equalTo: 'eq',
  isBetween: 'ib',
};

function paymentFromFilters(filters: any) {
  const payment = filters.payment;
  return getOperation(payment);
}

function productCostFromFilters(filters: any) {
  const payment = filters.productCost;
  return getOperation(payment);
}

function totalCostFromFilters(filters: any) {
  const payment = filters.paymentAndProductCost;
  return getOperation(payment);
}

function activationsFromFilters(filters: any) {
  if (!filters.generic || !filters.generic.activationIds) {
    return [];
  }

  const selectedActivationIds = filters.generic.activationIds.join(',');
  return selectedActivationIds;
}

function communitiesFromFilters(filters: any) {
  if (!filters.generic || !filters.generic.communityIds) {
    return [];
  }

  const selectedCommunityIds = filters.generic.communityIds.join(',');
  return selectedCommunityIds;
}

function programsFromFilters(filters: any) {
  if (!filters.generic || !filters.generic.programIds) {
    return [];
  }

  const selectedProgramIds = filters.generic.programIds.join(',');
  return selectedProgramIds;
}

function getOperation(collection: any) {
  if (!collection) {
    return null;
  }
  const operator = paymentValuesToApiOperator[collection.option.label];
  if (!operator) {
    return null;
  }
  return operator === 'ib'
    ? [`gt:${collection.amountOne}`, `lt:${collection.amountTwo}`]
    : `${operator}:${collection.amountOne}`;
}
