import * as React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import { Route, useRouteMatch } from 'react-router-dom';

import { EventName } from '@common';
import { ErrorBoundary } from '@frontend/utils';
import { CommunitySwitcherProvider, useClientFeatureEnabled } from '@frontend/app/hooks';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EmailComposerModal } from '@frontend/app/components';
import { ClientFeature } from '@frontend/app/constants';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { Mail } from 'lucide-react';
import { MessagingProvider } from './context/MessagingAppContext';
import { ThreadList } from './ThreadList';
import { MessageList } from './MessageList';
import { ThreadFolderSection } from './ThreadFolderSection/ThreadFolderSection';
import { EmptyState } from './EmptyState/EmptyState';

const { useState, useEffect, useCallback } = React;

import styles from './MessagingApp.scss';
import { SearchFilter } from './SearchFilter';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const MessagingApp: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const isShowSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const addEvent = useEventContext();

  const handleWindowErrors = useCallback((e) => {
    if (e.message.includes('ResizeObserver loop')) {
      // prevent ResizeObserver loop error from crashing inbox
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleWindowErrors);
    return () => {
      window.removeEventListener('error', handleWindowErrors);
    };
  }, [handleWindowErrors]);

  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'messages' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {refreshUi && (
        <AppHeader
          title="Inbox"
          subTitle="Assigned to Me"
          actions={[
            <EmailComposerModal
              key={0}
              allowSendingAsSeparate
              label="Compose"
              hideLabel={!showSidebar}
              icon={<Mail className="w-3 h-3 mr-2" />}
              refreshUi={refreshUi}
            />,
          ]}
        />
      )}
      <div className={cx(
        styles.MessagingApp,
        props.className,
        {
          [styles.refreshHeader]: refreshUi,
        },
      )}
      >
        <Helmet title="Inbox" />
        <CommunitySwitcherProvider useQueryParams={false}>
          <MessagingProvider>
            <>
              <ThreadFolderSection
                isExpanded={showSidebar}
                onExpandToggle={() => { setShowSidebar((x) => !x); }}
              />
              <div className={styles.rightPanel}>
                {isShowSearchFilter && <SearchFilter />}
                <div className={styles.content}>
                  <ErrorBoundary>
                    <ThreadList className={styles.threadList} />
                  </ErrorBoundary>
                  <Route
                    path={match.url}
                    exact
                    render={() => <EmptyState className={styles.messageList} />}
                  />
                  <Route
                    path={`${match.url}/:threadId`}
                    render={(props) => (
                      <ErrorBoundary>
                        <MessageList {...props} className={styles.messageList} />
                      </ErrorBoundary>
                          )}
                  />
                </div>
              </div>
            </>
          </MessagingProvider>
        </CommunitySwitcherProvider>
      </div>
    </>
  );
});

MessagingApp.displayName = 'MessagingApp';

export default MessagingApp;
