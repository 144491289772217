import * as React from 'react';
import {
 toString, isEmpty, map, find, isNumber, isFunction, isUndefined,
} from 'lodash';
import { ITableColumnConfig, IRowData } from '@components';
import {
 useMemberSearchQuery, useMemberFieldSchemasQuery, useClientFeatureEnabled, IDateRangeVariables,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { PAYPAL_APP_FIELD } from '@frontend/applications/PaymentsApp/appFields';
import { ICard, IPayment, IPaymentGroup } from '@frontend/applications/PaymentsApp/models';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { logger } from '@common';
import { useState, useEffect } from 'react';
import { ClientFeature } from '@frontend/app/constants';
import { usePayments } from '@frontend/applications/PaymentsApp/hooks/usePayments';
import { AssignPaymentTo, PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import { IStepInfo, WizardContainer } from '@frontend/applications/Shared/Wizard/container';
import { Avatar } from '@revfluence/fresh';
import { DollarSignIcon } from '@revfluence/fresh-icons/regular/esm';
import { useClientFeature } from '@frontend/applications/AffiliatesApp/contexts/ClientFeatureContext';
import { IMixpanelFields } from '../../../createPaymentSentEvent';
import { BulkPaymentsApp as BulkPaymentsAppComponent } from '../../components/BulkPaymentsApp/BulkPaymentsApp';
import { useBulkPaymentSentEvent } from '../../hooks/useBulkPaymentSentEvent';
import { useCreatePaymentGroup, IPaymentGroupPayload } from '../../../useCreatePaymentGroup';
import { useFetchPaymentSourceData } from '../../../useFetchPaymentSources';
import { savePaymentData, ISendPaymentRequest } from '../../../savePayment';
import styles from './BulkPaymentsApp.scss';

export enum PaymentMethod {
  FROM_BALANCE = 'FROM_BALANCE',
}

export interface INewPaymentMetadata {
  columns?: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [key: string]: any;
  };
  externalNote?: string; // Used for notification in member activity
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

export interface INewPayment {
  amountToPay: number;
  amountDue?: number;
  memberId: number;
  metadata?: INewPaymentMetadata;
  mixpanelFields?: IMixpanelFields;
}

export interface IPaymentGroupMetadata {
  columns?: ITableColumnConfig[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

// This has to be the same as what is in backend_server ax_application.py
export enum APPLICATION_STRING {
  SALES_TRACKING = 'sales_tracking',
}

export enum CREATING_PAYMENTS_STATUS {
  NOT_STARTED,
  IN_PROGRESS,
  FINISHED,
}

export interface IBulkPaymentContainerProps {
  onCancel: () => void;
  onDone: () => void;
  clientId: string;
  clientName: string;
  newPayments: INewPayment[];
  paymentGroupName: string;
  paymentGroupMetadata?: IPaymentGroupMetadata;
  application: APPLICATION_STRING;
  showAmountDue?: boolean;
  onPaymentGroupMade: (paymentGroup: IPaymentGroup) => Promise<void>;
  onPaymentCompleted: (paymentResponse: IPayment) => Promise<void>;
  paymentPeriod?: string;
  dateFilterRange: IDateRangeVariables;
}

export const BulkPaymentsApp: React.FC<Readonly<IBulkPaymentContainerProps>> = (props) => {
  const [allTableData, setAllTableData] = useState<IRowData[]>([]);
  // TODO: selectedTableData shouldn't just be the _raw field, we should use _raw.id to find the actual IRows in allTableData
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [selectedTableData, setSelectedTableData] = useState<any[]>([]);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [updatedTableData, setUpdatedTableData] = useState<any[]>([]);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [editedTableData, setEditedTableData] = useState<any[]>([]);

  const [paymentProgress, setPaymentProgress] = useState<number>(0);
  const [creatingPaymentsStatus, setCreatingPaymentsStatus] = useState<CREATING_PAYMENTS_STATUS>(
    CREATING_PAYMENTS_STATUS.NOT_STARTED,
  );
  const [errorRows, setErrorRows] = useState<string[]>([]);
  const { refetch: searchMembers } = useMemberSearchQuery({ skip: true });

  const { refetch: fetchMemberFieldSchemas } = useMemberFieldSchemasQuery({
    skip: true,
    /**
     * Due to renaming of fields in API layer in a different query that returns the same GraphQL object
     * (memberFieldSchemasBySections) and how Apollo cache works, this query could result in data with non-DB names.
     * Since we use the DB name to find the corresponding PayPal field, always run this query with no-cache.
     */
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    (async () => {
      if (isEmpty(props.newPayments)) {
        return;
      }

      const {
        data: { schemas: memberSchemas },
      } = await fetchMemberFieldSchemas();
      const paypalField = find(memberSchemas, { name: PAYPAL_APP_FIELD });
      if (!paypalField) {
        logger.error('Unable to find paypal field.');
        return;
      }
      const paypalFieldId = paypalField.id;

      const memberIds = map(props.newPayments, 'memberId');
      const searchQuery: IMemberSearchQuery = {
        includeMemberIds: memberIds,
      };
      const {
        data: { members: membersData },
      } = await searchMembers({
        query: searchQuery,
        skip: 0,
        take: memberIds.length,
      });

      setAllTableData(
        map(
          props.newPayments,
          (newPayment, i): IRowData => {
            let memberName = newPayment.metadata.memberName || '';
            let memberEmail = newPayment.metadata.memberEmail || '';
            let paypalEmail = '';
            let memberIsDeleted = true;
            const memberData = find(membersData, (m) => m.id === newPayment.memberId);
            if (memberData) {
              memberIsDeleted = false;
              if (memberData.name) {
                memberName = memberData.name;
              }
              if (memberData.email) {
                memberEmail = memberData.email;
              }
              if (memberData.fields && memberData.fields[paypalFieldId]) {
                paypalEmail = memberData.fields[paypalFieldId];
              }
            }
            const generatedData = {
              ...newPayment,
              ...newPayment.metadata?.columns,
              memberName,
              memberEmail,
              paypalEmail,
              id: toString(i),
            };
            return {
              ...generatedData,
              disableSelection: !paypalEmail,
              _raw: {
                row_id: toString(i),
                memberIsDeleted,
                ...generatedData,
              },
            };
          },
        ),
      );
    })();
  }, [props.newPayments, searchMembers, fetchMemberFieldSchemas, setAllTableData]);

  const [amountToPay, setAmountToPay] = useState<number>(0);

  const [amountDue, setAmountDue] = useState<number>(0);

  const [availableBalance, setAvailableBalance] = useState<number>(0);
  const [selectedCard, setCard] = useState<ICard>(null);

  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const sentPaymentEventHandler = useBulkPaymentSentEvent(props.application);
  const { migrateToGraphQL } = useClientFeature();
  const { state, steps, actions } = usePayments({
    memberId: null,
    paymentCreationSource: PaymentCreationSource.SALES_TRACKING,
    applicationProps: null,
    assignPaymentTo: AssignPaymentTo.Project,
    featureFlags: {
      migrateToGraphQL,
    },
    staPaymentsOptions: {
      client_id: props.clientId,
      name: props.paymentGroupName,
      aspirex_application: props.application,
      metadata: props.paymentGroupMetadata,
      dateFilterRange: props.dateFilterRange,
      sentPaymentEventHandler,
    },
    closeModal: () => props.onCancel(),
  });

  const { data: paymentSourcesData, refetch: refetchPaymentSourcesData } = useFetchPaymentSourceData();

  useEffect(() => {
    const staPayments = allTableData.map((payments) => ({
        key: payments.id as string,
        member: payments.memberName as string,
        offerName: payments.offerName as string,
        lastPayout: payments.previousPayout as number,
        amountDue: payments.amountDue as number,
        amountToPay: (payments.amountToPay as number).toFixed(2),
        paypalEmail: payments.paypalEmail as string,
        isMemberDeleted: payments._raw.memberIsDeleted as boolean,
        isSelected: false,
        rowData: payments,
      }));
    actions.setSTAPayments(staPayments);
  }, [allTableData, actions]);

  useEffect(() => {
    if (isNumber(paymentSourcesData?.balance_in_cents)) {
      setAvailableBalance(paymentSourcesData.balance_in_cents / 100);
    }
  }, [paymentSourcesData, setAvailableBalance]);
  const [memberCount, setMemberCount] = useState<number>(selectedTableData.length);

  // backend_server api call, TODO: we should use error in a productive way.
  const { save: createPaymentGroup } = useCreatePaymentGroup();
  // when data is selected, update aggregate totals.
  useEffect(() => {
    if (!isEmpty(updatedTableData)) {
      let initialAmountToPay = 0;
      for (const selectedRow of selectedTableData) {
        const updatedRow = updatedTableData.find((item) => item.id === selectedRow.id);
        const rowAmountToPay = !isUndefined(updatedRow) ? updatedRow.amountToPay : selectedRow.amountToPay;
        initialAmountToPay += rowAmountToPay;
      }
      setAmountToPay(initialAmountToPay);
    } else {
      setAmountToPay(selectedTableData.reduce((total, member) => member.amountToPay + total, 0));
    }

    // setAmountToPay(selectedTableData.reduce((total, member) => member.amountToPay + total, 0));
    setAmountDue(selectedTableData.reduce((total, member) => member.amountDue + total, 0));
    setMemberCount(selectedTableData.length);

    const editedRowData = selectedTableData.map(
      (initialObj) => updatedTableData.find((updatedObj) => updatedObj.id === initialObj.id) || initialObj,
    );

    // Set the merged data to the editableData state variable
    setEditedTableData(editedRowData);
  }, [selectedTableData, updatedTableData, setAmountToPay, setAmountDue, setMemberCount]);

  // TODO: create state with payment details and update it here
  const onFinishPaymentMethod = () => {};
  const findAndSetCard = (last4: string) => {
    setCard(paymentSourcesData.cards.find((card) => last4 == card.last4));
  };
  /* TODO: make payment group in b/e server, make it in sta, send payment using selected

  payments */
  const onPaymentMembersConfirmed = async () => {
    setPaymentProgress(0);
    setCreatingPaymentsStatus(CREATING_PAYMENTS_STATUS.IN_PROGRESS);
    const { paymentGroupSuccess, backendServerErrorRowIds } = await createPaymentGroupAndPayments();
    if (paymentGroupSuccess === false) {
      // display error page that no payments were created
    } else if (backendServerErrorRowIds?.length > 0) {
      setErrorRows(backendServerErrorRowIds);
    }
    refetchPaymentSourcesData();
    setCreatingPaymentsStatus(CREATING_PAYMENTS_STATUS.FINISHED);
  };

  const createPaymentGroupAndPayments = async (): Promise<{
    paymentGroupSuccess: boolean;
    successRowIds?: string[];
    backendServerErrorRowIds?: string[];
    callbackErrorRowIds?: string[];
  }> => {
    const newPaymentGroup: IPaymentGroupPayload = {
      client_id: props.clientId,
      name: props.paymentGroupName,
      aspirex_application: props.application,
      metadata: props.paymentGroupMetadata,
    };

    if (selectedCard?.id) {
      newPaymentGroup.cardId = selectedCard.id;
    }

    let backendServerPaymentGroupResponse: IPaymentGroup;
    try {
      backendServerPaymentGroupResponse = await createPaymentGroup(newPaymentGroup);
    } catch (error) {
      logger.error(error);
      return { paymentGroupSuccess: false };
    }

    if (isFunction(props.onPaymentGroupMade)) {
      try {
        await props.onPaymentGroupMade(backendServerPaymentGroupResponse);
      } catch (error) {
        logger.error(error);
        return { paymentGroupSuccess: false };
      }
    }

    const successRowIds = [];
    const backendServerErrorRowIds = [];
    const callbackErrorRowIds = [];
    let paymentsMade = 0;
    const totalPayments = selectedTableData.length;
    for (const row of selectedTableData) {
      const updatedRow = updatedTableData.find((item) => item.id === row.id);
      const rowAmountToPay = !isUndefined(updatedRow) ? updatedRow.amountToPay : row.amountToPay;
      const newPayment: ISendPaymentRequest = {
        amount: rowAmountToPay,
        amount_due: row.amountDue,
        member_id: row.memberId,
        client_id: props.clientId,
        paypal_address: row.paypalEmail,
        member_name: row.memberName,
        member_email: row.memberEmail,
        metadata: {
          ...row.metadata,
          offerId: row.mixpanelFields?.offerId || '',
        },
        payment_group_id: backendServerPaymentGroupResponse.id,
        aspirex_application: props.application,
        ...(selectedCard && { card_id: selectedCard?.id }),
      };

      let backendServerPaymentResponse: IPayment;
      try {
        const url = `${backendServerApiEndpoint()}/payment`;
        backendServerPaymentResponse = await savePaymentData(
          url,
          newPayment,
          sentPaymentEventHandler,
          row.mixpanelFields,
        );
      } catch (error) {
        logger.error(error);
        backendServerErrorRowIds.push(row.id);
        paymentsMade += 1;
        setPaymentProgress((paymentsMade / totalPayments) * 100);
        continue;
      }

      if (isFunction(props.onPaymentCompleted)) {
        try {
          await props.onPaymentCompleted(backendServerPaymentResponse);
        } catch (error) {
          logger.error(error);
          callbackErrorRowIds.push(row.id);
        }
      }

      paymentsMade += 1;
      setPaymentProgress((paymentsMade / totalPayments) * 100);
      successRowIds.push(row.id);
    }

    return {
      paymentGroupSuccess: true,
      successRowIds,
      backendServerErrorRowIds,
      callbackErrorRowIds,
    };
  };
  return (
    <>
      {isBudgetAllocationEnabled ? (
        <WizardContainer
          hideNavigation={false}
          icon={<Avatar shape="square" className={styles.icon} icon={<DollarSignIcon style={{ margin: '0px' }} />} />}
          step={state.currentStep}
          stepsInfo={steps as IStepInfo[]}
          noBodyPadding
        />
      ) : (
        <BulkPaymentsAppComponent
          onCardAdded={refetchPaymentSourcesData}
          cards={paymentSourcesData ? paymentSourcesData.cards : []}
          amountToPay={amountToPay}
          amountDue={amountDue}
          showAmountDue={props.showAmountDue}
          availableBalance={availableBalance}
          memberCount={memberCount}
          paymentGroupName={props.paymentGroupName}
          paymentGroupMetadata={props.paymentGroupMetadata}
          tableData={allTableData}
          onCancel={props.onCancel}
          onDataSelection={setSelectedTableData}
          onDone={props.onDone}
          onConfirmPayments={onPaymentMembersConfirmed}
          onFinishPayment={onFinishPaymentMethod}
          selectedData={selectedTableData}
          paymentProgress={paymentProgress}
          creatingPaymentsStatus={creatingPaymentsStatus}
          errorRows={errorRows}
          onCardSelected={findAndSetCard}
          paymentPeriod={props.paymentPeriod}
          onUpdatedData={setUpdatedTableData}
          updatedData={editedTableData}
          requireHandlingFee={!paymentSourcesData?.handling_fee_disabled || false}
        />
      )}
    </>
  );
};
