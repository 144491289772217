import { logger } from '@common';
import { IDesignatedContractSigner } from './DesignatedContractSigner';

export const saveSignerData = async (url: string, params: IDesignatedContractSigner, backendServerFetchResponse: typeof fetch) => {
  try {
    const resp = await backendServerFetchResponse(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.debug({ message: err });

    throw err;
  }
};
