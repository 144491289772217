import * as React from 'react';
import { Form, Input } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'codePrefix'> {
}

export const OfferCodePrefix: React.FC<Readonly<IProps>> = React.memo(({
  // disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your Prefix' },
  ];
  return (
    <>
      <Form.Item
        label="Promo Code Unique Naming Prefix"
        name={name}
        rules={rules}
      >
        <Input
          // disabled={disabled}
          placeholder="e.g. summer20"
          size="large"
        />
      </Form.Item>
    </>
  );
});

OfferCodePrefix.displayName = 'OfferCodePrefix';
