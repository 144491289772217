import * as React from 'react';
import { isEmpty } from 'lodash';

import { OfferFormModes, TFormValues } from '@affiliates/components';
import { IShopifyCredentials } from '@affiliates/hooks';
import { GetOfferById } from '@affiliates/queries/types/GetOfferById';
import { EditAffiliateOfferContainer } from './EditAffiliateOfferContainer';
import { EditShopifyCodeContainer } from './EditShopifyCodeContainer';
import { IBaseProps } from './types';
import { UserInfoInput } from '../../types/globalTypes';

interface IProps extends IBaseProps<TFormValues> {
  offer: GetOfferById['offer'];
  shopifyCredentials: IShopifyCredentials;
  profile: UserInfoInput
  isSubscriptionEnable: boolean;
  mode:OfferFormModes;
  allowedDomains: string[];
}

export const EditOfferContainer: React.FC<Readonly<IProps>> = (props) => {
  const { offer } = props;
  if (!isEmpty(offer.links) && !offer.isPromoLink) {
    return (
      <EditAffiliateOfferContainer {...props} />
    );
  }
  if (!isEmpty(offer.promos)) {
    return (
      <EditShopifyCodeContainer {...props} />
    );
  }
  throw new Error('Links and promo codes were empty.');
};
