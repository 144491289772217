import * as React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_ALL_FEATURES } from '../queries/clientFeature';
import { ClientFeatureResponse } from './useAllClientFeatures';

const { useMemo } = React;

export const useGetClientAllFeatures = (clientId: string | undefined): ClientFeatureResponse[] | undefined => {
  const { loading, data } = useQuery(GET_CLIENT_ALL_FEATURES, {
    variables: { clientId },
    skip: !clientId,
  });

  return useMemo(() => {
    if (loading || !data) {
      return undefined;
    }
    return data.getClientAllFeatures || [];
  }, [data, loading]);
};
