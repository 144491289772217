import * as React from 'react';
import * as qs from 'querystring';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useGetBackendServerToken } from '@frontend/app/hooks/useGetBackendServerToken';
import { IPayment } from './models';

const {
  useState, useEffect, useMemo, useCallback,
} = React;

export interface IPaymentsFetchQueryParams {
  client_id: string;
  member_id?: string;
  payment_group_id?: string;
  orderBy?: string;
  orderDirection?: string;
  page?: number;
}

interface IPaymentFetchResponse {
  data: IPayment[];
  count: number;
  has_next: boolean;
}

const fetchPaymentsData = async (url: string, token: string): Promise<IPaymentFetchResponse> => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
        'authorization': `Bearer ${token}`,
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export function useFetchPaymentsData(params: IPaymentsFetchQueryParams) {
  const { backendServerApiEndpoint } = useApplication();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaymentFetchResponse>(null);
  const [error, setError] = useState(null);

  const {
    data: tokenData,
  } = useGetBackendServerToken({
    fetchPolicy: 'network-only',
  });

  const finalUrl = useMemo(() => {
    const url = `${backendServerApiEndpoint}/payment`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterString = qs.stringify(params as any);

    return `${url}?${filterString}`;
  }, [backendServerApiEndpoint, params]);

  const fetchPayments = useCallback(async () => {
    setLoading(true);

    let result;
    try {
      result = await fetchPaymentsData(finalUrl, tokenData?.token);
      setData(result);
    } catch (err) {
      setError(err);
    }

    setLoading(false);

    return result;
  }, [finalUrl, tokenData]);

  useEffect(() => {
    if (finalUrl && tokenData) {
      fetchPayments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalUrl, tokenData]);

  return {
    loading,
    data,
    error,
    refetch: fetchPayments,
  };
}
