/* eslint-disable function-paren-newline */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as qs from 'querystring';
import { first, isArray, map } from 'lodash';

import { TNetworkIdentifier, ISocialAccount } from '@components';
import { useGetCurrentClient } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useBackendServerQueries } from '@frontend/app/clients/backendServerClient';

const { useEffect, useMemo } = React;

interface IProps {
  accounts?: {
    username?: string;
    network_identifier?: TNetworkIdentifier;
  }[];
}

interface IState {
  socialAccounts?: ISocialAccount[];
  loading: boolean;
  error: any;
}

/**
 * Fetch social profile data from backend_server
 *
 * IMPORTANT: This is a platform violation, see why here:
 * https://aspireiq.atlassian.net/wiki/spaces/CEP/pages/475562033/Known+Platform+Violations
 */
export const useFetchSocialAccount = (props: IProps): IState => {
  const { accounts } = props;

  const { showError } = useMessagingContext();
  const { client } = useGetCurrentClient();
  const urls = useMemo(() => {
    if (!client?.id || !accounts) {
      return [];
    }
    return map(accounts, (account) => {
      const query = qs.stringify({
        client_id: client.id,
        member_username: account.username,
        network: account.network_identifier,
        include_social_profile_data: true,
      });
      return `${backendServerApiEndpoint()}/social_account?${query}`;
    });
  }, [client, accounts]);

  const params = useMemo(
    () =>
      urls.map((url) => ({
        fetchParams: { input: url },
      })),
    [urls],
  );

  const queries = useBackendServerQueries<ISocialAccount[]>(params);
  const loading = queries.some((query) => query.isLoading);
  const error = queries.find((query) => query.isError)?.error;

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  // Memoize the socialAccounts computation
  const socialAccounts = useMemo(
    () =>
      queries
        .map((query) => query.data)
        .reduce<ISocialAccount[]>((accounts, account) => {
          const accountDetails = isArray(account) ? first(account) : account;
          if (accountDetails) {
            accounts.push(accountDetails);
          }
          return accounts;
        }, []),
    [queries],
  ); // Only recompute when queries change

  return { loading, error, socialAccounts };
};
