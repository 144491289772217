import React, { useEffect, useState } from 'react';
import { LoadSpinner } from '@components';
import { Empty, Modal } from '@frontend/app/refresh-components';
import { P } from '@frontend/shadcn/components/typography/p';
import { Input } from '@frontend/shadcn/components/ui/input';
import { MagnifyingGlassIcon, TagsIcon } from '@revfluence/fresh-icons/regular/esm';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { useCreateTag } from '@frontend/app/hooks';
import { TagAction, TagEntityType } from '@frontend/app/types/globalTypes';
import { useAssignUnassignTag, useFetchTags, useFetchTagsByEntityIdAndType } from '@frontend/app/hooks/tag';
import { logger } from '@common';
import { cn } from '@frontend/shadcn/lib/utils';
import { getUniqueRefreshColor } from '@frontend/utils';
import { useToast } from '@/shadcn/hooks/use-toast';

interface IPaymentTagDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPaymentIds: number[];
  action: TagAction;
}
interface ITag {
  id: number;
  name: string;
  isSelected: boolean;
}
export const PaymentTagDialog: React.FC<IPaymentTagDialogProps> = (props) => {
  const {
    isOpen,
    onClose,
    selectedPaymentIds,
    action,
   } = props;

  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
  const [searchTag, setSearchTag] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const [createTag] = useCreateTag();
  const [assignUnassignTag] = useAssignUnassignTag();
  const { loading: loadingGetTags, data: tags } = useFetchTags({
    variables: {
      entityType: TagEntityType.PAYMENT,
      name: searchTag,
    },
    fetchPolicy: 'no-cache',
  });

  const { loading: loadingGetTagsByPaymentIds, data: assignedTags } = useFetchTagsByEntityIdAndType({
    variables: {
      entityType: TagEntityType.PAYMENT,
      entityIds: selectedPaymentIds,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loadingGetTags && tags?.tags?.length) {
      const tag = tags?.tags.map((tag) => ({
        id: tag.id,
        name: tag.name,
        isSelected: false,
      }));
      setFilteredTags(tag);
    } else {
      setFilteredTags([]);
    }
  }, [loadingGetTags, tags]);

  useEffect(() => {
    if (assignedTags?.tags?.length) {
      for (let i = 0; i < assignedTags?.tags.length; i++) {
        const tag = assignedTags?.tags[i];
        setFilteredTags((prev) =>
          prev.map((t) => {
            if (t.id === tag.id) {
              return { ...t, isSelected: true };
            }
            return t;
          }));
      }
    }
  }, [assignedTags, tags]);
  const onToggle = (id: number) => {
    setFilteredTags((prev) =>
      prev.map((tag) => {
        if (tag.id === id) {
          return { ...tag, isSelected: !tag.isSelected };
        }
        return tag;
      }));
  };

  const onCreated = () => {
    if (!searchTag) {
      return;
    }
    createTag({
      variables: {
        entityType: TagEntityType.PAYMENT,
        name: searchTag,
      },
    }).then(() => {
      setSearchTag('');
    }).catch((err) => {
      logger.error({ message: err });
    });
  };

  const onConfirm = () => {
    const selectedTagIds = filteredTags.filter((tag) => tag.isSelected).map((tag) => tag.id);

    assignUnassignTag({
      variables: {
        entityType: TagEntityType.PAYMENT,
        entityIds: selectedPaymentIds,
        tagIds: selectedTagIds,
        action,
      },
    }).then(() => {
      toast({
        variant: 'success',
        title: `Successfully ${action === TagAction.ASSIGN ? 'Assigned' : 'Unassigned'} Tags`,
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
      onClose();
    }).catch((err) => {
      toast({
        variant: 'error',
        title: `Failed ${action === TagAction.ASSIGN ? 'Assigning' : 'Unassigning'} Tags`,
        duration: 3000,
        className: cn('top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4'),
      });
      logger.error({ message: err });
    });
  };
  const loadingContent = (
    <div className="p-8 bg-secondary h-full flex">
      <LoadSpinner />
    </div>
  );
  const mainContent = (
    <div>
      {!filteredTags.length ? (
        <div className="py-2">
          <Empty
            icon={TagsIcon}
            title="No tags"
            description="Modify your search or create a new tag."
            primaryCtaProps={{
              text: 'Create New Tag',
              onClick: () => onCreated(),
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {filteredTags.map((tag) => (
            <Tag
              key={tag.id}
              id={tag.id}
              name={tag.name}
              isSelected={tag.isSelected}
              onToggle={(id) => onToggle(id)}
            />
          ))}
        </div>
      )}
    </div>
  );

  const loading = loadingGetTags || loadingGetTagsByPaymentIds;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={action === TagAction.ASSIGN ? 'Assign Tags' : 'Unassign Tags'}
      onOk={() => onConfirm()}
      okText={action === TagAction.ASSIGN ? 'Assign Tags' : 'Unassign Tags'}
    >
      <div className="flex flex-col gap-3 max-h-[400px]">
        <P className="m-0">
          {`Select tags to ${action === TagAction.ASSIGN ? 'assign' : 'unassign'} to the selected payments.`}
        </P>
        <div className="relative">
          <MagnifyingGlassIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            value={searchTag}
            onChange={(e) => setSearchTag(e.target.value)}
            type="search"
            placeholder="Search tags..."
            className="w-full rounded-lg bg-background pl-8"
          />
        </div>
        <div className="flex flex-col gap-2 overflow-y-auto" style={{ maxHeight: '200px' }}>
          {loading ? loadingContent : mainContent}
        </div>
      </div>
    </Modal>
  );
};

interface ITagProps {
  id: number;
  name: string;
  isSelected: boolean;
  onToggle: (id: number) => void;
}

const Tag: React.FC<ITagProps> = (props) => {
  const {
    id,
    name,
    isSelected,
    onToggle,
  } = props;

  return (
    <div className="flex justify-between">
      <div className="flex gap-2 items-center">
        <Checkbox id={name} checked={isSelected} onCheckedChange={() => onToggle(id)} />
        <Badge variant="secondary" className={`${getUniqueRefreshColor(name)} hover:${getUniqueRefreshColor(name)} px-1 font-regular`}>
          {name}
        </Badge>
      </div>
    </div>
  );
};
