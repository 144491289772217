export function getUniqueRefreshColor(text?: string): string {
  const colors = [
    'bg-purple-3',
    'bg-blue-3',
    'bg-teal-3',
    'bg-green-3',
    'bg-lime-3',
    'bg-yellow-3',
    'bg-red-3',
    'bg-orange-3',
    'bg-pink-3',
    'bg-magenta-3',
  ];

  if (!text) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = (hash << 5) - hash + text.charCodeAt(i);
    hash |= 0;
  }
  const index = Math.abs(hash) % colors.length;
  return colors[index];
}
