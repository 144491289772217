import * as React from 'react';
import { first } from 'lodash';

import { LoadSpinner } from '@components';
import { IWorkItem } from '@services/workflow';
import { RefreshMembers } from '@affiliates/components';
import { TRefreshMembersProps } from '@affiliates/components/MembersWizard/types';
import { OFFER_TYPE, OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { logger } from '@common';

const { useEffect, useState } = React;

type TMembers = TRefreshMembersProps['members'];
export interface IWorkflowRefreshMembers {
  offer: GetOffersBySearchQuery_offers,
  offerSource: OFFER_TYPE;
  workItems: IWorkItem[];
  fetchMembers: () => Promise<TRefreshMembersProps['members']>;
  onClose: TRefreshMembersProps['onClose'];
  onSave: TRefreshMembersProps['onSave'];
}

export const WorkflowRefreshMembers: React.FC<IWorkflowRefreshMembers> = (props) => {
  const {
    offerSource,
    offer,
    onClose,
    workItems,
    onSave,
  } = props;
  const [members, setMembers] = useState<TMembers>([]);
  const [loadingMembers, setLoadingMembers] = useState(true);
  useEffect(() => {
    setLoadingMembers(true);
    props.fetchMembers().then((membersData) => {
      setMembers(membersData);
      setLoadingMembers(false);
    })
      .catch((e) => {
        logger.error(e);
        setLoadingMembers(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetchMembers]);
  if (loadingMembers) {
    return <LoadSpinner />;
  }
  switch (offerSource) {
    case OFFER_TYPE.PROMO_CODE: {
      const promo = first(offer.promos);
      if (!promo) {
        logger.warn('Received promo offer with no code!');
        return null;
      }
      return (
        <RefreshMembers
          offerSource={OFFER_SOURCE.SHOPIFY}
          discountAmount={promo.priceRuleAmount || 0}
          discountType={promo.priceRuleType}
          members={members}
          offerId={offer.id}
          offerName={offer.name}
          onClose={onClose}
          onSave={onSave}
          visible
          isWorkflow
          workItems={workItems}
          refreshDatesOnly={false}
          isNewFlow={offer.isNewFlow}
          percentPayout={promo.percentPayout}
          flatPayout={promo.flatPayout}
          offerStartDate={promo.startDate}
          offerEndDate={promo.endDate}
          payoutType={promo.payoutType}
        />
      );
    }
    default: {
      logger.warn('Invalid offerSource on WorkflowRefreshMembers component');
      return null;
    }
  }
};
