import 'css-chunk:src/applications/AffiliatesApp/components/OfferSummaryCard/OfferSummaryCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_bghol_157",
  "justify-content-space-between": "_justify-content-space-between_bghol_161",
  "tabular-nums": "_tabular-nums_bghol_165",
  "OfferSummaryCard": "_OfferSummaryCard_bghol_178",
  "body": "_body_bghol_187",
  "offerImageContainer": "_offerImageContainer_bghol_194",
  "bodyText": "_bodyText_bghol_205",
  "titleWrapper": "_titleWrapper_bghol_212",
  "title": "_title_bghol_212",
  "editBtn": "_editBtn_bghol_226",
  "editIcon": "_editIcon_bghol_230",
  "descriptionWrapper": "_descriptionWrapper_bghol_236",
  "additionalDetailsWrapper": "_additionalDetailsWrapper_bghol_243",
  "url": "_url_bghol_248",
  "instructionsWrapper": "_instructionsWrapper_bghol_265",
  "divider": "_divider_bghol_268",
  "footer": "_footer_bghol_273",
  "payout": "_payout_bghol_282",
  "expirationDate": "_expirationDate_bghol_285",
  "tag": "_tag_bghol_288",
  "active": "_active_bghol_300",
  "expired": "_expired_bghol_303",
  "draft": "_draft_bghol_306",
  "paused": "_paused_bghol_309",
  "archived": "_archived_bghol_312",
  "offerFormVariant": "_offerFormVariant_bghol_316",
  "linkIcon": "_linkIcon_bghol_337",
  "addndetails": "_addndetails_bghol_341",
  "mPayout": "_mPayout_bghol_374",
  "multiplePayoutSummaryCard": "_multiplePayoutSummaryCard_bghol_381",
  "imageContainer": "_imageContainer_bghol_389",
  "offerName": "_offerName_bghol_396",
  "offerTag": "_offerTag_bghol_402",
  "offerExpirationContainer": "_offerExpirationContainer_bghol_405",
  "offerLabel": "_offerLabel_bghol_409",
  "offerValue": "_offerValue_bghol_412",
  "offerDiscountConatiner": "_offerDiscountConatiner_bghol_416",
  "offerDiscount": "_offerDiscount_bghol_416",
  "offerDescription": "_offerDescription_bghol_427",
  "payoutsContainer": "_payoutsContainer_bghol_434",
  "payoutsLabel": "_payoutsLabel_bghol_439",
  "payoutsValueContainer": "_payoutsValueContainer_bghol_443",
  "payoutsValue": "_payoutsValue_bghol_443",
  "show": "_show_bghol_1"
};