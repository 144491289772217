import * as React from 'react';
import * as qs from 'qs';
import { filter } from 'lodash';

import { endpoints } from '@components';
import { IResource } from '@components';
import { useBackendServerQuery } from '@frontend/app/clients/backendServerClient';

import { useInviteContext } from './useInviteContext';

const { useEffect, useMemo, useCallback } = React;

export const useResources = () => {
  const {
 apiEndpoint, clientId, userId, resourceContext, updateResourceContext,
} = useInviteContext();

  const fetchResourcesURL = useMemo(() => {
    if (userId) {
      const query = {
        client_id: clientId,
        user_id: userId,
        active: true,
      };
      return `${apiEndpoint}/${endpoints.resourcesEndpoint}?${qs.stringify(query)}`;
    }
  }, [apiEndpoint, clientId, userId]);

  const { data: fetchedResources, isFetching: isFetchingResources, error: fetchResourcesError } = useBackendServerQuery<
    IResource[]
  >({
    fetchParams: { input: fetchResourcesURL || '' },
    useQueryParams: {
      options: {
        enabled: !!fetchResourcesURL,
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 10, // 10 minutes
      },
    },
  });

  const emailResources = useMemo(
    () =>
      filter(fetchedResources, (resource) => {
        switch (resource.type) {
          case 'gmail':
          case 'outlook':
            return true;
          default:
            return false;
        }
      }),
    [fetchedResources],
  );

  const selectResourceId = useCallback(
    (resourceId: IResource['id']) => {
      updateResourceContext((c) => ({ ...c, resourceId }));
    },
    [updateResourceContext],
  );

  useEffect(() => {
    if (emailResources && Array.isArray(emailResources) && emailResources.length > 0 && !resourceContext.resourceId) {
      const defaultResource: IResource = emailResources[0];
      selectResourceId(defaultResource.id);
    }
  }, [emailResources, selectResourceId, resourceContext.resourceId]);

  return {
    resources: emailResources,
    resourceId: resourceContext.resourceId,
    selectResourceId,
    onRequestAddEmail: resourceContext.onRequestAddEmail,
    loading: isFetchingResources,
    error: fetchResourcesError,
  };
};
