import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';
import { CatalogSelectionRuleConditionOperationType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';

export type KeyAndValue =
  | {
      collections: Array<{ id: string; name: string }>;
    }
  | {
      vendors: string[];
    }
  | {
      productTypes: string[];
    }
  | {
      tags: string[];
    }
  | {
      categories: string[];
    }
  | {
      priceMin: number;
    }
  | {
      priceMax: number;
    };

export interface AdvancedSelectionContextProps {
  label: string;
  isOpen: boolean;
  catalogCollectionId: number;
  isEditing: boolean;
  operation: CatalogSelectionRuleConditionOperationType;
  collections: Array<{ id: string; name: string }>;
  vendors: string[];
  productTypes: string[];
  priceMin: number;
  priceMax: number;
  tags: string[];
  categories: string[];
  options: Array<{ name: string; values: string[] }>;
  pageNumber: number;
  setLabel: (label: string) => void;
  setOperation: (operation: CatalogSelectionRuleConditionOperationType) => void;
  setIsOpen: (isOpen: boolean) => void;
  openEditingModal: (catalogCollectionId: number) => void;
  addConditionField: (keyAndValue: KeyAndValue) => void;
  removeConditionField: (keyAndValue: KeyAndValue) => void;
  setOptionConditionField: (name: string, values: string[]) => void;
  resetEditing: () => void;
  reset: () => void;
  setEditingDetails: (catalogCollectionId: number) => void;
  setState: (state: AdvancedSelectionContextState) => void;
  setPageNumber: (pageNumber: number) => void;
}

export type AdvancedSelectionContextState = Omit<
  AdvancedSelectionContextProps,
  | 'setLabel'
  | 'setOperation'
  | 'addConditionField'
  | 'removeConditionField'
  | 'setOptionConditionField'
  | 'setIsOpen'
  | 'openEditingModal'
  | 'resetEditing'
  | 'reset'
  | 'setEditingDetails'
  | 'setState'
  | 'setPageNumber'
>;

const initialState: AdvancedSelectionContextState = {
  label: '',
  isOpen: false,
  isEditing: false,
  catalogCollectionId: null,
  operation: CatalogSelectionRuleConditionOperationType.OR,
  collections: [],
  vendors: [],
  productTypes: [],
  priceMin: null,
  priceMax: null,
  tags: [],
  categories: [],
  options: [],
  pageNumber: 1,
};

export const AdvancedSelectionContext = createContext<AdvancedSelectionContextProps>({
  ...initialState,
  setLabel: () => {},
  setOperation: () => {},
  addConditionField: () => {},
  removeConditionField: () => {},
  setOptionConditionField: () => {},
  setIsOpen: () => {},
  openEditingModal: () => {},
  resetEditing: () => {},
  reset: () => {},
  setEditingDetails: () => {},
  setState: () => {},
  setPageNumber: () => {},
});

export enum ActionType {
  SetLabel = 'SetLabel',
  SetOperation = 'SetOperation',
  SetIsOpen = 'SetIsOpen',
  OpenEditingModal = 'OpenEditingModal',
  AddConditionField = 'AddConditionField',
  RemoveConditionField = 'RemoveConditionField',
  SetOptionConditionField = 'SetOptionConditionField',
  ResetEditing = 'ResetEditing',
  Reset = 'Reset',
  SetEditingDetails = 'SetEditingDetails',
  SetState = 'SetState',
  SetPageNumber = 'SetPageNumber',
}

export type Action =
  | {
      type: ActionType.SetLabel;
      payload: string;
    }
  | {
      type: ActionType.SetOperation;
      payload: CatalogSelectionRuleConditionOperationType;
    }
  | {
      type: ActionType.SetIsOpen;
      payload: boolean;
    }
  | {
      type: ActionType.OpenEditingModal;
      payload: { catalogCollectionId: number };
    }
  | {
      type: ActionType.AddConditionField;
      payload: KeyAndValue;
    }
  | {
      type: ActionType.RemoveConditionField;
      payload: KeyAndValue;
    }
  | {
      type: ActionType.SetOptionConditionField;
      payload: { name: string; values: string[] };
    }
  | {
      type: ActionType.ResetEditing;
    }
  | {
      type: ActionType.Reset;
  }
  | {
      type: ActionType.SetEditingDetails;
      payload: { catalogCollectionId: number };
    }
  | {
      type: ActionType.SetState;
      payload: AdvancedSelectionContextState;
    }
  | {
      type: ActionType.SetPageNumber;
      payload: number;
    };

export const AdvancedSelectionProvider: React.FC = ({ children }) => {
  const reducer = (state: AdvancedSelectionContextState, action: Action): AdvancedSelectionContextState => {
    switch (action.type) {
      case ActionType.SetLabel:
        return {
          ...state,
          label: action.payload,
        };
      case ActionType.SetOperation:
        return {
          ...state,
          operation: action.payload,
        };
      case ActionType.AddConditionField:
        return {
          ...state,
          ...action.payload,
        };
      case ActionType.RemoveConditionField:
        return {
          ...state,
          ...action.payload,
        };
      case ActionType.SetOptionConditionField:
        const option = state.options.find((o) => o.name === action.payload.name);
        const newOptions = state.options.map((o) => ({ ...o }));
        if (option) {
          newOptions.forEach((o) => {
            if (o.name === action.payload.name) {
              o.values = action.payload.values;
            }
          });
        } else {
          newOptions.push({
            name: action.payload.name,
            values: action.payload.values,
          });
        }
        return {
          ...state,
          options: newOptions,
        };
      case ActionType.SetIsOpen:
        return {
          ...state,
          isOpen: action.payload,
        };
      case ActionType.OpenEditingModal:
        return {
          ...state,
          isEditing: true,
          isOpen: true,
          catalogCollectionId: action.payload.catalogCollectionId,
        };
      case ActionType.ResetEditing:
        return {
          ...state,
          isEditing: false,
          isOpen: false,
          catalogCollectionId: null,
        };
      case ActionType.Reset:
        return initialState;
      case ActionType.SetEditingDetails:
        return {
          ...state,
          catalogCollectionId: action.payload.catalogCollectionId,
          isEditing: true,
        };
      case ActionType.SetState:
        return {
          ...state,
          ...action.payload,
        };
      case ActionType.SetPageNumber:
        return {
          ...state,
          pageNumber: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setLabel: (label: string) => dispatch({ type: ActionType.SetLabel, payload: label }),
      setOperation: (operation: CatalogSelectionRuleConditionOperationType) =>
        dispatch({ type: ActionType.SetOperation, payload: operation }),
      addConditionField: (keyAndValue: KeyAndValue) =>
        dispatch({ type: ActionType.AddConditionField, payload: keyAndValue }),
      removeConditionField: (keyAndValue: KeyAndValue) =>
        dispatch({ type: ActionType.RemoveConditionField, payload: keyAndValue }),
      setOptionConditionField: (name: string, values: string[]) =>
        dispatch({ type: ActionType.SetOptionConditionField, payload: { name, values } }),
      setIsOpen: (isOpen: boolean) => dispatch({ type: ActionType.SetIsOpen, payload: isOpen }),
      openEditingModal: (catalogCollectionId: number) =>
        dispatch({ type: ActionType.OpenEditingModal, payload: { catalogCollectionId } }),
      resetEditing: () => dispatch({ type: ActionType.ResetEditing }),
      reset: () => dispatch({ type: ActionType.Reset }),
      setEditingDetails: (catalogCollectionId: number) =>
        dispatch({ type: ActionType.SetEditingDetails, payload: { catalogCollectionId } }),
      setState: (state: AdvancedSelectionContextState) => dispatch({ type: ActionType.SetState, payload: state }),
      setPageNumber: (pageNumber: number) => dispatch({ type: ActionType.SetPageNumber, payload: pageNumber }),
    }),
    [],
  );

  const memoizedValue = useMemo<AdvancedSelectionContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <AdvancedSelectionContext.Provider value={memoizedValue}>{children}</AdvancedSelectionContext.Provider>;
};

export const useAdvancedSelectionContext = () => useContext(AdvancedSelectionContext);
