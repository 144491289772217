import * as React from 'react';

import { LoadSpinner } from '@components';
import { IWorkItem } from '@services/workflow';
import {
  WorkflowAddMembers,
  WorkflowEmptyOffers,
  WorkflowRefreshMembers,
} from '@affiliates/components';
import { OFFER_TYPE } from '@affiliates/types/globalTypes';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';

import { useWorkflowWizard } from './hooks/useWorkflowWizard';
import { isValidOffer, WorkflowOfferError } from '../../components/WorkflowOfferError';
import { STA_WORKFLOW_ACTION } from '../..';

const { useCallback } = React;
export interface IWorkflowWizardProps {
  offerSource: OFFER_TYPE;
  programId: number;
  membersIds: number[];
  workItems: IWorkItem[];
  onClose: () => void;
  staWorkflowAction: STA_WORKFLOW_ACTION;
}

export const WorkflowWizard: React.FC<IWorkflowWizardProps> = (props) => {
  const {
    offerSource,
    workItems,
    onClose: onCloseProp,
    staWorkflowAction,
    programId,
  } = props;
  const {
    showSuccessMessage,
  } = useMessagingContext();
  const {
    fetchMembers,
    loadingOffer,
    offer,
    onSave,
    isCompleted,
  } = useWorkflowWizard(props);
  const onClose = useCallback(() => {
    if (!isCompleted) {
      return onCloseProp();
    }
    switch (offerSource) {
      case (OFFER_TYPE.LINK): {
        showSuccessMessage('Link are being generated.');
        onCloseProp();
        break;
      }
      case (OFFER_TYPE.PROMO_CODE): {
        showSuccessMessage('Codes are being generated.');
        onCloseProp();
        break;
      }
      default: {
        onCloseProp();
      }
    }
  }, [isCompleted, onCloseProp, offerSource, showSuccessMessage]);
  if (loadingOffer) {
    return <LoadSpinner />;
  }
  if (!offer) {
    return (
      <WorkflowEmptyOffers
        offerSource={offerSource}
        onButtonClick={onClose}
        buttonText="Go Back"
      />
    );
  }
  if (!isValidOffer(offerSource, offer)) {
    return (
      <WorkflowOfferError
        offer={offer}
        offerSource={offerSource}
        programId={programId}
        taskId={staWorkflowAction}
      />
    );
  }
  switch (staWorkflowAction) {
    case STA_WORKFLOW_ACTION.GENERATE_LINK:
    case STA_WORKFLOW_ACTION.GENERATE_PROMO:
      return (
        <WorkflowAddMembers
          fetchMembers={fetchMembers}
          offer={offer}
          offerSource={offerSource}
          onClose={onClose}
          onSave={onSave}
          workItems={workItems}
        />
      );
    case STA_WORKFLOW_ACTION.FIX_PROMO:
      return (
        <WorkflowRefreshMembers
          offer={offer}
          offerSource={offerSource}
          onClose={onClose}
          onSave={onSave}
          workItems={workItems}
          fetchMembers={fetchMembers}
        />
      );
    default:
      logger.warn('Invalid staWorkflowAction on WorkflowWizard container');
      return null;
  }
};
