import gql from 'graphql-tag';
import { PRODUCT_V2_FRAGMENT } from './fragments/productV2';

export const GET_CATALOG_COLLECTIONS_QUERY = gql`
  query GetCatalogCollectionsQuery($brandCatalogId: Int!, $includeProductDetails: Boolean = false) {
    getCatalogCollectionsByCatalogId(brandCatalogId: $brandCatalogId, includeProductDetails: $includeProductDetails) {
      id
      brandCatalogId
      selectionType
      label
      imageUrl
      collectionId
      collection {
        name
        images
        metadata {
          importedProductCount
          importedVariantCount
        }
      }
      catalogProducts {
        id
        productId
        variantIds
        product @include(if: $includeProductDetails) {
          totalVariants
        }
      }
    }
  }
`;

export const GET_CATALOG_COLLECTION_BY_ID_QUERY = gql`
  query GetCatalogCollectionByIdQuery($id: Int!, $includeProductDetails: Boolean = false, $page: Int, $pageSize: Int) {
    getCatalogCollectionsById(id: $id, includeProductDetails: $includeProductDetails, page: $page, pageSize: $pageSize) {
      id
      brandCatalogId
      selectionType
      label
      imageUrl
      collectionId
      collection {
        name
        images
      }
      catalogProducts {
        id
        productId
        variantIds
        product @include(if: $includeProductDetails) {
          ...ProductV2Fragment
        }
      }
      totalProducts
      advanceSelectionConfig {
        conditionOperation
        collections {
          id
          name
        }
        vendors
        productTypes
        priceMin
        priceMax
        tags
        categories
        options {
          name
          values
        }
      }
    }
  }
  ${PRODUCT_V2_FRAGMENT}
`;

export const CREATE_CATALOG_COLLECTION_MUTATION = gql`
  mutation CreateCatalogCollectionMutation($input: CreateCatalogCollectionInput!) {
    createCatalogCollection(input: $input) {
      id
      brandCatalogId
      selectionType
      label
      collectionId
    }
  }
`;

export const UPDATE_CATALOG_COLLECTION_MUTATION = gql`
  mutation UpdateCatalogCollectionMutation($catalogCollectionId: Int!, $input: CreateCatalogCollectionInput!) {
    updateCatalogCollection(catalogCollectionId: $catalogCollectionId, input: $input) {
      id
      brandCatalogId
      selectionType
      label
      collectionId
    }
  }
`;

export const DELETE_CATALOG_COLLECTION_MUTATION = gql`
  mutation DeleteCatalogCollectionMutation($id: Int!) {
    deleteCatalogCollectionById(id: $id) {
      id
    }
  }
`;

export const GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY = gql`
  query GetStatsByCatalogCollectionIdQuery($catalogCollectionId: Int!) {
    getStatsByCatalogCollectionId(catalogCollectionId: $catalogCollectionId) {
      productCount
      variantCount
    }
  }
`;

export const GET_STATS_FOR_ADVANCED_COLLECTION_QUERY = gql`
  query GetStatsForAdvancedCollectionQuery(
    $advanceSelectionConfig: AdvanceSelectionRuleConfigInput!,
    $page: Int,
    $pageSize: Int
  ) {
    getStatsForAdvanceCatalogCollection(
      advanceSelectionConfig: $advanceSelectionConfig,
      page: $page,
      pageSize: $pageSize
    ) {
      productCount
      variantCount
      products {
        ...ProductV2Fragment
      }
    }
  }
  ${PRODUCT_V2_FRAGMENT}
`;

export const UPDATE_CATALOG_COLLECTION_RANKS_MUTATION = gql`
  mutation UpdateCatalogCollectionRanks($brandCatalogId: Int!, $collectionRankInput: [CollectionRankInput!]!) {
    updateCatalogCollectionRanks(brandCatalogId: $brandCatalogId, collectionRankInput: $collectionRankInput)
  }
`;
