import React, { useEffect, useMemo } from 'react';
import {
  Button,
 Card, Col, Drawer, Image, Input, message, Row, Skeleton, Space, Spinner, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import {
 ArrowsRotateIcon, BagShoppingIcon, CircleInfoIcon, PencilIcon,
 PlusIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { tagColors, useQueryParams } from '@frontend/app/hooks';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useGetMetaFieldsForProductFromShopify } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetMetaFieldsForProductFromShopify';
import styles from './ProductDetailsDrawer.scss';
import { useGetProductDetails } from '../hooks/useGetProductDetails';
import { ProductVariantsTable } from './ProductVariantsTable';
import { pfaV2Routes } from '../../routes';
import { useSyncProductInventoryByProductId } from '../hooks/useSyncProductInventory';
import { useProductDetailsDrawerContext } from './ProductDetailsDrawerContext';
import {
  currencyFormatter,
 getAspireTags, getNonAspireTags, getShopifyMetaFieldFormattedValue, simpleHash,
} from '../../utils';
import { ShopifyMetaFieldValueType } from '../../constants';

const { Title, Text, Paragraph } = Typography;

const InfoCard = ({ title, info }: { title: React.ReactNode, info: React.ReactNode[] }) => (
  <Card>
    <Space size="middle" direction="vertical">
      <Title level={5}>{title}</Title>
      {info.map((item, i) => (
        <Space direction="vertical" key={i}>
          {item}
        </Space>
        ))}
    </Space>
  </Card>
  );

export const ProductDetailsDrawer = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const productId = queryParams.get('productId');
  const location = useLocation();
  const match = useRouteMatch({
    location,
    path: [pfaV2Routes.settings.imports, pfaV2Routes.settings.collectionDetails],
    exact: true,
  });

  const open = useMemo(() => productId && !!match, [productId, match]);

  const { setIsOpen: setIsTagModalOpen } = useProductDetailsDrawerContext();

  const { product, refetch, loading } = useGetProductDetails({
    variables: {
      productId: Number(productId),
    },
    skip: !open || !productId,
    fetchPolicy: 'network-only',
  });

  const { productMetaFields, isProductMetaFieldsLoading } = useGetMetaFieldsForProductFromShopify({
    variables: {
      productId: product.externalId?.split('Product/')[1] || '',
    },
    skip: !open || !productId,
    fetchPolicy: 'network-only',
  });

  const { mutate: syncProductInventory, loading: isInventorySyncing } = useSyncProductInventoryByProductId();

  useEffect(() => {
    if (open && productId) {
      syncProductInventory({ variables: { productId: Number(productId) } });
    }
  }, [open, productId, syncProductInventory]);

  const handleProductInventorySync = (e: React.MouseEvent) => {
    e.stopPropagation();
    syncProductInventory({
      variables: { productId: Number(productId) },
      onCompleted: (data) => {
        if (data?.syncProductInventoryByProductId) {
          refetch();
          message.success('Product inventory synced successfully');
        }
      },
    });
  };

  const onClose = () => {
    queryParams.delete('productId');
    history.replace({
      search: `?${queryParams.toString()}`,
    });
  };

  const tags = useMemo(() => getNonAspireTags(product.tags || []), [product.tags]);
  const aspireTags = useMemo(() => getAspireTags(product.tags || []), [product.tags]);

  return (
    <Drawer
      placement="right"
      size="large"
      open={open}
      title={(
        <Row align="middle" justify="center">
          <Col>
            <Space align="center" size={12}>
              <div className={styles.titleIcon}>
                <BagShoppingIcon className={styles.titleImg} />
              </div>
              <Title level={4}>Product Detail</Title>
            </Space>
          </Col>
        </Row>
      )}
      width={992}
      className={styles.ProductDetailsDrawer}
      onClose={onClose}
    >
      <Space direction="vertical" size={32}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space>
              <Text className={styles.sku}>ID</Text>
              <Text weight="semibold">{product.externalId?.split('Product/')[1] || '-'}</Text>
            </Space>
          </Col>
          {/* <Col>
            <Button icon={<EyeIcon />} />
          </Col> */}
        </Row>
        <Space>
          <Text weight="semibold" style={{ whiteSpace: 'nowrap' }}>Aspire Tags</Text>
          <Tooltip title="You can add Aspire tags to your products. These will not be visible to creators.">
            <div>
              <CircleInfoIcon />
            </div>
          </Tooltip>
          <div>
            {
            aspireTags?.map((tag) => (
              <Tag key={tag} color={tagColors[simpleHash(tag) % tagColors.length]?.backgroundColor}>
                {tag}
              </Tag>
            ))
          }
            <Tag className={styles.clickableTag} onClick={() => setIsTagModalOpen(true, 'both')}>
              {
                aspireTags?.length ? <PencilIcon /> : <PlusIcon />
              }
            </Tag>
          </div>
        </Space>
        <Row gutter={24}>
          <Col span={16}>
            <Space size="middle" direction="vertical">
              <InfoCard
                title="General Information"
                info={[
                  <>
                    <Text weight="semibold">Product Name</Text>
                    <Text>{product.name}</Text>
                  </>,
                  <>
                    <Text weight="semibold">Product Description</Text>
                    <Paragraph
                      ellipsis={{ rows: 4, expandable: true, symbol: 'Read more' }}
                      className={styles.paragraph}
                    >
                      {product.description}
                    </Paragraph>
                  </>,
                ]}
              />
              <Card>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Title level={5}>Product Images</Title>
                  </Space>
                  <Space wrap>
                    {product.images?.map((image, index) => (
                      <Card className={styles.imageCard} key={image}>
                        <Image src={image} alt={`Product image ${index + 1}`} height={100} width={100} />
                      </Card>
                    ))}
                  </Space>
                </Space>
              </Card>
              <InfoCard
                title={(
                  <Space align="center">
                    <span>Inventory</span>
                    <Tooltip title="Sync Inventory">
                      <Button
                        icon={<ArrowsRotateIcon />}
                        type="link"
                        size="small"
                        loading={isInventorySyncing}
                        onClick={handleProductInventorySync}
                      />
                    </Tooltip>
                  </Space>
                )}
                info={[
                  <>
                    {!loading
                      && product?.inventory
                      && (product?.inventory?.tracksInventory ? (
                        <Tag>Tracking Inventory</Tag>
                      ) : (
                        <Tag>Inventory Not Tracked</Tag>
                      ))}
                  </>,
                  <>
                    <Text weight="semibold">Available Quantity</Text>
                    {isInventorySyncing ? <Spinner /> : <Input value={product.inventory?.totalInventory} placeholder="Stock" disabled />}
                  </>,
                ]}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space size="middle" direction="vertical">
              <InfoCard
                title="Product Organization"
                info={[
                  <>
                    <Text weight="semibold">Category</Text>
                    <Text>{product.category || '-'}</Text>
                  </>,
                  <>
                    <Text weight="semibold">Type</Text>
                    <Text>{product.productType || '-'}</Text>
                  </>,
                  <>
                    <Text weight="semibold">Tags</Text>
                    <Space size={4} wrap>
                      {tags?.map((tag) => (
                        <Tag key={tag} color={tagColors[simpleHash(tag) % tagColors.length]?.backgroundColor}>
                          {tag}
                        </Tag>
                      ))}
                    </Space>
                  </>,
                ]}
              />
              <InfoCard
                title="Pricing"
                info={[
                  <>
                    <Text weight="semibold">Price</Text>
                    {product.metadata?.priceMax && product.metadata?.priceMin !== product.metadata?.priceMax ? (
                      <Text>
                        {currencyFormatter.formatRange(Number(product.metadata?.priceMin), Number(product.metadata?.priceMax))}
                      </Text>
                    ) : (
                      <Text>
                        {product.metadata?.priceMin ? currencyFormatter.format(Number(product.metadata?.priceMin)) : '-'}
                      </Text>
                    )}
                  </>,
                ]}
              />
              {isProductMetaFieldsLoading
                ? <Skeleton active /> : !!productMetaFields?.length && (
                  <InfoCard
                    title="Additional Product Data"
                    info={productMetaFields.map((metaField) => (
                      <>
                        <Text weight="semibold">{metaField.name}</Text>
                        {getShopifyMetaFieldFormattedValue(metaField.type as ShopifyMetaFieldValueType, metaField.value)}
                      </>
                    ))}
                  />
                )}
            </Space>
          </Col>
        </Row>
        <Space direction="vertical" size="middle">
          <Title level={5}>Products Variants</Title>
          <ProductVariantsTable variants={product.productVariants ?? []} />
        </Space>
      </Space>
    </Drawer>
  );
};
