import React, { useCallback, useEffect, useMemo } from 'react';
import {
  IColumnsType, Skeleton, Table,
} from '@revfluence/fresh';
import { GetProductDetails_getProductVariantsByProductId_productVariants, GetProductDetails_getProductVariantsByProductId_productVariants_inventory } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetails';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { useAuth } from '@frontend/context/authContext';
import { InventorySource } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetMetaFieldsForVariantFromShopify } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetMetaFieldsForVariantFromShopify';
import { GetMetaFieldsForVariantFromShopify_getMetaFieldsValuesFromShopify_variantMetaFields } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetMetaFieldsForVariantFromShopify';
import { NameWithImageRenderer } from '../../components/NameWithImageRenderer/NameWithImageRenderer';
import { IProductVariantData, IVariantsRow } from '../types';
import { currencyFormatter, getShopifyMetaFieldFormattedValue } from '../../utils';
import { ShopifyMetaFieldValueType } from '../../constants';

export interface IProductVariantsTableProps {
  variants: GetProductDetails_getProductVariantsByProductId_productVariants[]
}

interface AdditionalInfoRendererProps extends IProductVariantData {
  metaField: GetMetaFieldsForVariantFromShopify_getMetaFieldsValuesFromShopify_variantMetaFields;
}
const AdditionalInfoRenderer = ({ externalId, metaField }: AdditionalInfoRendererProps) => {
  const { variantMetaFields, isVariantMetaFieldsLoading } = useGetMetaFieldsForVariantFromShopify({
    variables: {
      variantId: externalId.split('/').pop(),
    },
    fetchPolicy: 'network-only',
  });

  const metaFieldValue = variantMetaFields?.find((field) => field.key === metaField.key);

  if (isVariantMetaFieldsLoading) {
    return <Skeleton.Input active />;
  }

  return (
    <>
      {getShopifyMetaFieldFormattedValue(metaFieldValue?.type as ShopifyMetaFieldValueType, metaFieldValue?.value)}
    </>
  );
};

export const ProductVariantsTable = ({
  variants,
}: IProductVariantsTableProps) => {
  const { clientConfig, loading: isClientConfigLoading } = useGetClientConfig({
    variables: {
      clientId: useAuth().clientInfo?.id,
    },
    fetchPolicy: 'network-only',
  });

  const [additionalColumns, setAdditionalColumns] = React.useState<IColumnsType<IVariantsRow>>([]);

  useEffect(() => {
    if (clientConfig?.metaFieldsMapping?.variantMetaFields) {
      const columns = clientConfig?.metaFieldsMapping?.variantMetaFields.map((metaField) => ({
        title: metaField.name,
        dataIndex: '_raw',
        key: metaField.key,
        render: (raw) => <AdditionalInfoRenderer {...raw} metaField={metaField} />,
        width: 250,
      }));
      setAdditionalColumns(columns);
    }
  }, [clientConfig?.metaFieldsMapping?.variantMetaFields]);

  const columns = useMemo<IColumnsType<IVariantsRow>>(() => [
    {
      title: 'Product',
      dataIndex: '_raw',
      key: 'product',
      width: 250,
      render: NameWithImageRenderer,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: 200,
      key: 'sku',
    },
    {
      title: 'Inventory',
      dataIndex: 'inventory',
      width: 200,
      key: 'inventory',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: 250,
      key: 'price',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      width: 250,
      key: 'cost',
    },
    ...additionalColumns,
    // {
    //   title: 'Visibility',
    //   dataIndex: 'visibility',
    //   key: 'visibility',
    //   render: (visibility) => (
    //     <Button icon={visibility ? <EyeIcon /> : <EyeSlashIcon />} className={styles.tableRowActions} />
    //   ),
    // },
  ], [additionalColumns]);

  const getInventory = useCallback((inventory: GetProductDetails_getProductVariantsByProductId_productVariants_inventory) => {
    if (clientConfig?.inventorySettings?.inventorySource === InventorySource.LOCATION_BASED_INVENTORY) {
      return inventory?.allLocationsInventory;
    } else if (clientConfig?.inventorySettings?.inventorySource === InventorySource.ALL_AVAILABLE_INVENTORY) {
      return inventory?.inventoryQuantity;
    }
    return inventory?.sellableOnlineQuantity;
  }, [clientConfig]);

  const tableData = useMemo<IVariantsRow[]>(
    () => variants.map((variant) => {
        const transformedVariant = ({
          key: String(variant.id),
          id: String(variant.id),
          sku: variant.sku,
          title: variant.name,
          image: variant.images?.[0],
          inventory: getInventory(variant.inventory),
          price: currencyFormatter.format(variant.price),
          cost: variant?.metadata?.cost ? currencyFormatter.format(Number(variant.metadata.cost)) : null,
          visibility: true,
          externalId: variant.externalId,
        });
        return { ...transformedVariant, _raw: transformedVariant };
      }),
    [getInventory, variants],
  );

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      bordered
      loading={isClientConfigLoading}
      scroll={{ x: 'max-content' }}
    />
  );
};
