import * as React from 'react';
import {
  first,
  isEmpty,
  isNull,
  sortBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import {
  IShopifyPromoCodeFormValues,
  PrefixTypeStrategy,
  TFormOptionData,
  ProductCollections,
  RecurringCycleLimit,
  PurchaseType,
  IPayoutVariant,
  ClientForSync,
  OfferFormModes,
  PROMO_OFFER_TYPES,
} from '@affiliates/components';
import { IShopifyCredentials, useApolloClient, useGetProductCollections } from '@affiliates/hooks';
import { GetOfferById } from '@affiliates/queries/types/GetOfferById';
import {
  OFFER_SOURCE, OFFER_PROMO_PREFIX_TYPE, OFFER_PAYOUT_TYPE_PROMO, OFFER_STATUS, CLIENT_CONNECTION_STATUS,
} from '@affiliates/types/globalTypes';
import { IToastRefHandles, Toast } from '@components';
import { logger } from '@common';
import { OfferFormContainer } from './OfferFormContainer';
import { IBaseProps, IChildArgs } from './types';
import { GET_CONNECTED_SHOPIFY } from '../../queries/getConnectedShopifyQuery';
import { GetConnectedShopify_clientConnections } from '../../queries/types/GetConnectedShopify';
import { useClientFeature } from '../../contexts/ClientFeatureContext';

const { useState, useEffect, useRef } = React;
interface IProps extends IBaseProps<IShopifyPromoCodeFormValues> {
  offer: GetOfferById['offer'];
  shopifyCredentials: IShopifyCredentials;
  isSubscriptionEnable: boolean;
  mode: OfferFormModes;
}

export const EditShopifyCodeContainer: React.FC<Readonly<IProps>> = (props) => {
  const { offer, shopifyCredentials, ...remainingProps } = props;
  const [productCollectionOptions, setProductCollectionOptions] = useState<ProductCollections[]>(null);
  const [connectedShopifyData, setConnectedShopifyData] = useState<GetConnectedShopify_clientConnections[]>([]);
  const staApolloClient = useApolloClient();

  const toastRef = useRef<IToastRefHandles>();
  const collectionsQuery = useGetProductCollections();
  const { enableMultipleShopify: isEnabledMultipleShopify } = useClientFeature();
  useEffect(() => {
    if (isEnabledMultipleShopify || offer.promos[0]?.connectedClientMetadata?.length) {
      (async () => {
        const { data } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
        if (data?.clientConnections) {
          const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
          setConnectedShopifyData(allConnections);
        }
      })();
    }
  }, [isEnabledMultipleShopify, staApolloClient, offer.promos]);
  useEffect(() => {
    if (collectionsQuery?.data) {
      setProductCollectionOptions(collectionsQuery.data);
    }
    if (collectionsQuery.error) {
      logger.error(collectionsQuery.error.message);
      const toast = toastRef.current;
      toast.showMessage({
        content: 'There was an error loading Shopify collections. Please refresh the page and try again.',
        type: 'error',
      });
    }
  }, [collectionsQuery, setProductCollectionOptions]);

  const promo = first(offer.promos);
  const links = offer.links?.[0];
  let connectedClientMetadata: ClientForSync[] = promo?.connectedClientMetadata?.length ? promo.connectedClientMetadata : [];
  const allClientForSync: ClientForSync[] = connectedClientMetadata;
  connectedClientMetadata = connectedClientMetadata
    .filter((c) => c.status === CLIENT_CONNECTION_STATUS.ACTIVE)
    .map((c) => ({ ...c, isSelected: c.status === CLIENT_CONNECTION_STATUS.ACTIVE }));
  const isSameDiscount: boolean = connectedClientMetadata.length >= 1 && uniqBy(connectedClientMetadata, 'priceRuleAmount').length === 1 && connectedClientMetadata[0].priceRuleAmount === promo?.priceRuleAmount;
  let prefixType = PrefixTypeStrategy.INSTAGRAM_ONLY;
  switch (promo.prefixType) {
    case OFFER_PROMO_PREFIX_TYPE.IG_USERNAME:
      if (promo.codeSuffix.length > 0) {
        prefixType = PrefixTypeStrategy.INSTAGRAM_PLUS_SUFFIX;
      }
      break;
    case OFFER_PROMO_PREFIX_TYPE.FULL_NAME:
      prefixType = PrefixTypeStrategy.FULL_NAME_ONLY;
      if (promo.codeSuffix.length > 0) {
        prefixType = PrefixTypeStrategy.FULL_NAME_PLUS_SUFFIX;
      }
      break;
    case OFFER_PROMO_PREFIX_TYPE.FIRST_INITIAL_LAST_NAME:
      prefixType = PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY;
      if (promo.codeSuffix.length > 0) {
        prefixType = PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_PLUS_SUFFIX;
      }
      break;
    case OFFER_PROMO_PREFIX_TYPE.FIRST_NAME_LAST_INITIAL:
      prefixType = PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY;
      if (promo.codeSuffix.length > 0) {
        prefixType = PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_PLUS_SUFFIX;
      }
      break;
  }
  let NewFlowprefixType = PrefixTypeStrategy.INSTAGRAM_ONLY;
  switch (promo.prefixType) {
    case OFFER_PROMO_PREFIX_TYPE.IG_USERNAME:
      NewFlowprefixType = PrefixTypeStrategy.INSTAGRAM_ONLY;
      break;
    case OFFER_PROMO_PREFIX_TYPE.FULL_NAME:
      NewFlowprefixType = PrefixTypeStrategy.FULL_NAME_ONLY;
      break;
    case OFFER_PROMO_PREFIX_TYPE.FIRST_INITIAL_LAST_NAME:
      NewFlowprefixType = PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY;
      break;
    case OFFER_PROMO_PREFIX_TYPE.FIRST_NAME_LAST_INITIAL:
      NewFlowprefixType = PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY;
      break;
  }
  let flatPayout = null;
  let percentPayout = null;
  switch (promo.payoutType) {
    case OFFER_PAYOUT_TYPE_PROMO.CONVERSION:
      flatPayout = isNull(promo.flatPayout) ? null : promo.flatPayout.toString();
      break;
    case OFFER_PAYOUT_TYPE_PROMO.SALE:
      percentPayout = isNull(promo.percentPayout) ? null : promo.percentPayout.toString();
      break;
    case OFFER_PAYOUT_TYPE_PROMO.CONVERSION_AND_SALE:
      flatPayout = isNull(promo.flatPayout) ? null : promo.flatPayout.toString();
      percentPayout = isNull(promo.percentPayout) ? null : promo.percentPayout.toString();
      break;
  }

  let payoutOptions: IPayoutVariant[] = [];
  if (promo?.defaultPayoutId) {
    offer.payouts.forEach((payoutOption) => {
      if (payoutOption.id == promo.defaultPayoutId) {
        payoutOptions.push({ ...payoutOption, isDefault: true });
      } else {
        payoutOptions.push({ ...payoutOption, isDefault: false });
      }
    });
  }
  payoutOptions = sortBy(payoutOptions, (obj) => moment(obj.createdDate));
  // editing a shopify promo code offer
  const initialValues: IChildArgs<IShopifyPromoCodeFormValues>['initialValues'] = {
    codeSuffix: promo.codeSuffix,
    description: offer.description,
    flatPayout,
    groupName: promo.name,
    imageUrl: offer.imageUrl,
    name: offer.name,
    payoutType: promo.payoutType,
    payoutOptions,
    percentPayout,
    prefixType: offer.isNewFlow ? NewFlowprefixType : prefixType,
    priceRuleAmount: isNull(promo.priceRuleAmount) ? null : promo.priceRuleAmount.toString(),
    priceRuleType: promo.priceRuleType,
    source: OFFER_SOURCE.SHOPIFY,
    specialLimitNewCustomersOnly: promo.specialLimitNewCustomersOnly,
    specialLimitOnePerSale: promo.specialLimitOnePerSale,
    specialLimitUsageCapAmount: isNull(promo.specialLimitUsageCapAmount)
      ? null
      : promo.specialLimitUsageCapAmount.toString(),
    specialLimitUsageCapEnabled: promo.specialLimitUsageCapEnabled,
    usageLimitAmount: isNull(promo.usageLimitAmount) ? null : promo.usageLimitAmount.toString(),
    usageLimitRule: promo.usageLimitRule,
    productCollections: promo?.providerMetadata?.productCollections?.map((collection) => collection.id),
    status: promo.status,
    codePrefix: promo.codePrefix,
    activeTime: promo.startDate ? moment(promo.startDate) : null,
    activeDate: promo.startDate ? moment(promo.startDate) : null,
    endDate: promo.endDate ? moment(promo.endDate) : null,
    endTime: promo.endDate ? moment(promo.endDate) : null,
    purchaseType:
      promo.isOneTime && promo.isSubscription
        ? PurchaseType.BOTH
        : promo.isOneTime
        ? PurchaseType.ONE_TIME
        : promo.isSubscription
        ? PurchaseType.SUBSCRIPTION
        : PurchaseType.ONE_TIME,
    recurringCycleLimitAmount: Number(promo.recurringCycleLimit) == 1 ? 0 : Number(promo.recurringCycleLimit),
    offerCodePurchaseRestrictionsRule:
      promo.recurringCycleLimit === 0
        ? RecurringCycleLimit.DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS
        : promo.recurringCycleLimit === 1
        ? RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT
        : RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS,
    isEndDateEnable: !!promo?.endDate,
    isNewFlow: offer.isNewFlow,
    isMultipleShopifySyncEnabled: promo.connectedClientMetadata?.length
      ? !!promo.connectedClientMetadata.filter((client) => client.status === CLIENT_CONNECTION_STATUS.ACTIVE).length
      : false,
    multiShopifyEnabled: promo.multiShopifyEnabled,
    isSameDiscountMultipleShopify: isSameDiscount,
    clientsForSync: connectedClientMetadata,
    connectedAdvertiserForSync: connectedShopifyData.filter((c) => !c.isPrimary),
    isUngrouped: promo.isUngrouped,
    lockEditing: [],
    allClientForSync,
    productDiscounts: promo.providerMetadata.discountCombination?.productDiscounts,
    shippingDiscounts: promo.providerMetadata.discountCombination?.shippingDiscounts,
    orderDiscounts: promo.providerMetadata.discountCombination?.orderDiscounts,
    isPromoLink: offer.isPromoLink,
  isAdvancedUrlEnabled: links?.utmFields?.utm_source || links?.utmFields?.utm_medium || links?.utmFields?.utm_campaign || links?.utmFields?.utm_content || links?.utmFields?.utm_term,
    utmSource: links?.utmFields?.utm_source || '',
    utmMedium: links?.utmFields?.utm_medium || '',
    utmCampaign: links?.utmFields?.utm_campaign || '',
    utmContent: links?.utmFields?.utm_content || '',
    utmTerm: links?.utmFields?.utm_term || '',
    shopifyRedirectUrl: offer.isPromoLink ? first(offer.links).shopifyRedirectUrl : null,
    isSecureCodes: promo.isSecureCodes,
    isReadOnly: offer.isReadOnly,
    offerType: offer.isReadOnly ? PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER : PROMO_OFFER_TYPES.AMOUNT_OFF,
  };
  const offerDetails: IChildArgs<IShopifyPromoCodeFormValues>['offerDetails'] = {
    hasMembers: !isEmpty(promo.affiliates),
    id: Number(offer.id),
    imageUrl: offer.imageUrl,
    isDeleted: promo.status === OFFER_STATUS.DELETED,
    isExpired: moment(initialValues.endDate) < moment(),
    linkedId: promo.id,
    isReadOnly: offer.isReadOnly,
    isOfferArchived: !isNull(offer.archivedDate),
  };

  const formOptionData: TFormOptionData = {
    productCollectionOptions,
  };

  return (
    <>
      <OfferFormContainer
        initialValues={initialValues}
        offerDetails={offerDetails}
        shopifyCredentials={shopifyCredentials}
        formOptionData={formOptionData}
        isSubscriptionEnable={props.isSubscriptionEnable}
        connectedAdvertiserForSync={connectedShopifyData}
        {...remainingProps}
      />
      <Toast ref={toastRef} useFresh />
    </>
  );
};
