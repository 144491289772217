import { GetOfferById_offer_promos } from '@affiliates/queries/types/GetOfferById';
import { OFFER_STATUS } from '@affiliates/types/globalTypes';
import { isNull } from 'lodash';
import { isBefore, isAfter } from 'date-fns';
import { OfferMemberStatus } from '../types';

export function composePromoCodeStatus(
  currentStatus: OfferMemberStatus,
  startDate: Date | string,
  endDate: Date | string,
  offerPromo: GetOfferById_offer_promos,
  isNewFlow?: boolean,
  archivedDate?: Date | string,
  deletedDate?: Date | string,
) {
  if (!isNull(archivedDate)) {
    return OfferMemberStatus.DEACTIVATED;
  }
  if (offerPromo.status === OFFER_STATUS.PAUSED) {
    return OfferMemberStatus.PAUSED;
  }
  if (isNewFlow) {
    const today = new Date();
    const isExpired = offerPromo.isUngrouped
      ? endDate && isBefore(new Date(endDate), today)
      : offerPromo.endDate && isBefore(new Date(offerPromo.endDate), today);
    const isDeactivated = currentStatus === OfferMemberStatus.DEACTIVATED && isNull(deletedDate);
    const isDeleted = currentStatus === OfferMemberStatus.DEACTIVATED && !isNull(deletedDate);
    const isUpcoming = offerPromo.isUngrouped
      ? isAfter(new Date(startDate), today)
      : isAfter(new Date(offerPromo.startDate), today);
    const isFailed = currentStatus === OfferMemberStatus.FAILED;

    if (currentStatus === OfferMemberStatus.PENDING) return OfferMemberStatus.PENDING;
    if (isExpired) {
      if (currentStatus === OfferMemberStatus.PENDING_UPDATE) return OfferMemberStatus.PENDING_UPDATE;
      return OfferMemberStatus.EXPIRED;
    }
    if (offerPromo.endDate && isExpired) return OfferMemberStatus.EXPIRED;
    if (isDeactivated) return OfferMemberStatus.DEACTIVATED;
    if (isDeleted) return OfferMemberStatus.DELETED;
    if (isUpcoming && currentStatus !== OfferMemberStatus.PENDING_UPDATE) {
      if (isFailed) {
        return OfferMemberStatus.FAILED;
      }
      return OfferMemberStatus.UPCOMING;
    }
    return currentStatus;
  } else {
    const today = new Date();
    const isExpired = isBefore(new Date(endDate), today);
    const isDeactivated = currentStatus === OfferMemberStatus.DEACTIVATED && isNull(deletedDate);
    const isDeleted = currentStatus === OfferMemberStatus.DEACTIVATED && !isNull(deletedDate);
    const isUpcoming = isAfter(new Date(startDate), today);
    const isFailed = currentStatus === OfferMemberStatus.FAILED;
    if (currentStatus === OfferMemberStatus.PENDING) return OfferMemberStatus.PENDING;
    if (isExpired) {
      if (currentStatus === OfferMemberStatus.PENDING_UPDATE) return OfferMemberStatus.PENDING_UPDATE;
      return OfferMemberStatus.EXPIRED;
    }
    if (isDeactivated) return OfferMemberStatus.DEACTIVATED;
    if (isDeleted) return OfferMemberStatus.DELETED;
    if (isUpcoming) {
      if (isFailed) {
        return OfferMemberStatus.FAILED;
      }
      return OfferMemberStatus.UPCOMING;
    }
    return currentStatus;
  }
}
