import React from 'react';
import { Button, Space, Typography } from '@revfluence/fresh';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './Pagination.scss';

const { Text } = Typography;

export interface IPaginationProps {
  page: number;
  pageSize: number;
  items: number;
  itemsOnPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrev: () => void;
}

export const Pagination = ({
  page,
  pageSize,
  items,
  itemsOnPage,
  hasNextPage,
  hasPreviousPage,
  onNext,
  onPrev,
}: IPaginationProps) => (
  <Space className={styles.Pagination}>
    <Text type="secondary" className={styles.pageText}>
      {(page - 1) * pageSize + 1}
      -
      {(page - 1) * pageSize + itemsOnPage}
      {' '}
      of
      {' '}
      {items}
    </Text>
    <Space size={0}>
      <Button disabled={!hasPreviousPage} onClick={onPrev} type="text" aria-label="Previous Collections">
        <ChevronLeftIcon fontSize={14} />
      </Button>
      <Button disabled={!hasNextPage} onClick={onNext} type="text" aria-label="Next Collections">
        <ChevronRightIcon fontSize={14} />
      </Button>
    </Space>
  </Space>
);
