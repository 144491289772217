import * as React from 'react';
import { toLower, size, isFunction } from 'lodash';
import { logger as loggerCommon } from '@common';
import { Typography, Button, Tooltip } from '@revfluence/fresh';
import { ChartColumnIcon } from '@revfluence/fresh-icons/solid/esm';
import { YOUTUBE_CHANNEL_PREFIX } from '@frontend/utils';
import { MemberFieldSchemasQuery_schemas } from '@frontend/app/queries/types/MemberFieldSchemasQuery';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { useMessagingContext } from '@frontend/hooks';
import { getSocialHandle } from '@frontend/app/utils/getSocialHandle';
import { FetchContextProvider, getSocialAccountLink } from '@frontend/utils';
import { useAuth } from '@frontend/context/authContext';
import { TNetworkIdentifier } from '@components';
import { parseUrl } from '@frontend/app/utils/url';
import { useFetchSocialAccount } from '@frontend/app/hooks/useFetchSocialAccount';

import { FieldRow } from '../FieldRow';
import SocialIcon from './SocialIcon';
import { SocialMemberProfile } from './SocialMemberProfile/SocialMemberProfile';

import styles from './SocialRow.scss';

const logger = loggerCommon.child({
  component: 'SocialRow',
});

const {
 useCallback, useState, useMemo, useEffect,
} = React;
const { Text, Link } = Typography;

const renderCustomLabel = (fieldName: string) => <SocialIcon name={fieldName} />;

const YOUTUBE_FIELD = 'YouTube';

const SOCIAL_NETWORK_WITH_MODAL: TNetworkIdentifier[] = ['instagram', 'tiktok', 'youtube', 'pinterest'];

interface Props {
  schema: MemberFieldSchemasQuery_schemas;
  value: string;
  onInstagramInsightsUpdated?: (value: boolean) => void;
  onSave: (val: MemberInput) => Promise<void>;
}

const SocialRow = (props: Props) => {
  const { schema, value, onSave } = props;

  const [saving, setSaving] = useState(false);
  const { clientInfo } = useAuth();
  const { showErrorMessage } = useMessagingContext();

  const network = useMemo(() => toLower(schema.name) as TNetworkIdentifier, [schema]);
  const hasModal = SOCIAL_NETWORK_WITH_MODAL.includes(network);

  const accounts = useMemo(() => {
    if (value && SOCIAL_NETWORK_WITH_MODAL.includes(network)) {
      return [
        {
          username: value,
          network_identifier: network as TNetworkIdentifier,
        },
      ];
    }
  }, [network, value]);

  const { socialAccounts, loading } = useFetchSocialAccount({ accounts });

  const [showSocialMemberProfile, setShowSocialMemberProfile] = useState(false);

  const handleClick = useCallback(() => {
    if (socialAccounts && socialAccounts[0]?.id && !loading && !saving) {
      setShowSocialMemberProfile(true);
    } else if (!loading) {
      logger.error('Social Account does not exist on the platform');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowSocialMemberProfile, socialAccounts, schema, loading, saving]);

  useEffect(() => {
    if (
      network === 'instagram'
      && isFunction(props.onInstagramInsightsUpdated)
      && socialAccounts
      && socialAccounts[0]?.id
    ) {
      props.onInstagramInsightsUpdated(socialAccounts?.[0]?.has_analytics_api_access || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialAccounts, network]);

  const renderCustomValue = useCallback(
    (val: string) => {
      if (!val) {
        return <Text>-</Text>;
      }

      let socialHandle = getSocialHandle({
        network,
        username: val,
        shouldValidateUsername: true,
        shouldPrependAtSymbol: true,
      });

      if (network === 'youtube') {
        try {
          if (val?.includes('youtube.com')) {
            const parsedUrl = parseUrl(val);
            const usernameParts = parsedUrl?.pathname?.split('youtube.com')?.pop()?.split('/')?.filter(Boolean);

            let username = '';
            if (size(usernameParts) === 1) {
              username = usernameParts?.[0] ?? '';
            } else if (size(usernameParts) > 1) {
              username = usernameParts?.[1] ?? '';
            }

            socialHandle = username;
          } else {
            socialHandle = val;
            if (!socialHandle.startsWith(YOUTUBE_CHANNEL_PREFIX)) {
              socialHandle = `@${socialHandle.split('@').pop()}`;
            }
          }
        } catch {
          socialHandle = val;
        }
      }

      const url = getSocialAccountLink(network, val);

      return (
        <span className={styles.socialValue}>
          <Link className={styles.link} href={url} target="_blank" rel="noreferrer">
            {socialHandle}
          </Link>
          {hasModal && !saving && !loading && (
            <Tooltip title="View Social Analytics">
              <Button
                className={styles.btn}
                type="primary"
                size="small"
                icon={<ChartColumnIcon />}
                onClick={handleClick}
              />
            </Tooltip>
          )}
        </span>
      );
    },
    [network, hasModal, loading, saving, handleClick],
  );

  const handleSave = useCallback(
    async (val: string) => {
      try {
        setSaving(true);

        try {
          val = getSocialHandle({
            network: schema.name,
            username: val,
            shouldValidateUsername: true,
            shouldPrependAtSymbol: false,
          });
        } catch (e: Error | unknown) {
          if (e instanceof Error) {
            showErrorMessage(e.message);
          }
          throw e;
        }

        if (schema.name === YOUTUBE_FIELD && val.includes('youtube.com')) {
          const parsedUrl = parseUrl(val);
          try {
            const usernameParts = parsedUrl?.pathname?.split('youtube.com')?.pop()?.split('/')?.filter(Boolean) ?? [];

            let username = '';
            if (usernameParts.length === 1) {
              username = usernameParts[0] ?? '';
            } else if (usernameParts.length > 1) {
              username = usernameParts[1] ?? '';
            }

            val = username;
          } catch {
            throw new Error('Invalid youtube url');
          }
        }

        await onSave({
          fields: {
            [schema.id]: val,
          },
        });
        // eslint-disable-next-line
      } catch (err) {
        throw err;
      } finally {
        setSaving(false);
      }
    },
    [onSave, schema, showErrorMessage],
  );

  return (
    <div className={styles.socialRow}>
      {showSocialMemberProfile && socialAccounts?.[0]?.id && (
        <FetchContextProvider>
          <SocialMemberProfile
            id={socialAccounts[0].id}
            clientId={clientInfo.id}
            username={value}
            network={network as TNetworkIdentifier}
            onRequestClose={() => setShowSocialMemberProfile(false)}
          />
        </FetchContextProvider>
      )}
      <FieldRow
        direction="horizontal"
        label={schema.name}
        value={value}
        editable
        loading={loading}
        type={schema.type}
        choices={schema.choices ?? undefined}
        renderLabel={renderCustomLabel}
        renderValue={renderCustomValue}
        onSave={handleSave}
      />
    </div>
  );
};

export default SocialRow;
