import gql from 'graphql-tag';

import { TASK_FRAGMENT } from './fragments';

export const GET_ALL_TASKS_QUERY = gql`
  query GetAllTasksQuery($programId: Int!) {
    tasks: getAllTasks(programId: $programId) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;
